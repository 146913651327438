import React from "react";
import classNames from "classnames";
import he from "he";
import CharacterCount from "./CharacterCount";
export const TextArea = (props) => {
  var _a;
  const { maxLength, onChange, value, onFocus } = props;
  const handleChange = (event) => onChange == null ? void 0 : onChange(event.target.value);
  return /* @__PURE__ */ React.createElement("div", { className: "form-text-area-wrapper" }, /* @__PURE__ */ React.createElement(
    "textarea",
    {
      className: classNames("form-text-area", {
        "is-filled": !!value
      }),
      maxLength,
      onChange: handleChange,
      rows: 3,
      value: he.decode(value != null ? value : ""),
      onFocus
    }
  ), maxLength && /* @__PURE__ */ React.createElement(CharacterCount, { characterCount: (_a = value == null ? void 0 : value.length) != null ? _a : 0, maxLength }));
};
export default TextArea;
