import GalleryButton from "@/components/button/GalleryButton";
import DetailModalHeader from "@/components/modal/DetailModalHeader";
import Modal from "@/components/modal/Modal";
import { sanitizeUserInput } from "@/utils/string_utils";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
const ReflectionDescription = ({ description, plain = false }) => {
  if (!description)
    return;
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: plain ? "mb-3" : "bg-lifeweb-yellow h-auto p-5 prose max-w-none",
      dangerouslySetInnerHTML: { __html: sanitizeUserInput(description) }
    }
  );
};
const ReflectionContributor = ({ name }) => /* @__PURE__ */ React.createElement("div", { className: "mb-3 mt-8" }, /* @__PURE__ */ React.createElement("div", { className: "text-sm" }, "Contributed by"), /* @__PURE__ */ React.createElement("div", { className: "text-lg font-semibold" }, name));
const ReflectionImages = ({ images }) => {
  if (!images.length)
    return;
  return /* @__PURE__ */ React.createElement("div", { className: "mb-5" }, images.map(({ id, image_url }) => /* @__PURE__ */ React.createElement("img", { className: "block w-full", key: id, src: image_url })));
};
const ReflectionDetailBody = ({ reflection }) => /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(ReflectionImages, { images: reflection.group_reflection_images || [] }), reflection.group_reflection_images.length === 0 && /* @__PURE__ */ React.createElement(ReflectionDescription, { description: reflection.description }), /* @__PURE__ */ React.createElement("div", { className: "sm:px-16" }, /* @__PURE__ */ React.createElement(ReflectionContributor, { name: reflection.contributed_by }), reflection.group_reflection_images.length > 0 && /* @__PURE__ */ React.createElement(ReflectionDescription, { description: reflection.description, plain: true })));
const ReflectionDetail = ({ reflections = [], userId = "" }) => {
  const history = useHistory();
  const { groupName, id } = useParams();
  const [reflection, setReflection] = useState(null);
  useEffect(() => {
    if (!reflection)
      return;
    mixpanel.track("reflection_viewed", {
      kind: "group",
      group_name: groupName,
      reflection_id: id
    });
  }, [reflection]);
  useEffect(() => {
    setReflection(reflections.find((r) => r.id === id));
  }, [id, reflections]);
  const navigateToReflection = (reflectionId) => history.push(`/groups/${groupName}/reflections/${reflectionId}`);
  const onClose = () => history.push(`/groups/${groupName}`);
  const getIndex = () => reflections.findIndex((el) => el.id === id);
  const canNavigateNext = () => reflections.length - getIndex() !== 1;
  const canNavigatePrev = () => getIndex() !== 0;
  if (!reflection)
    return;
  return /* @__PURE__ */ React.createElement(Modal, { isVisible: true, onClose }, /* @__PURE__ */ React.createElement(
    DetailModalHeader,
    {
      editEnabled: reflection.user_id === userId,
      onClose
    }
  ), /* @__PURE__ */ React.createElement(ReflectionDetailBody, { reflection }), /* @__PURE__ */ React.createElement("div", { className: "absolute flex justify-between left-0 top-2/4 px-5 w-full" }, /* @__PURE__ */ React.createElement("div", { className: canNavigatePrev() ? "visible" : "invisible" }, /* @__PURE__ */ React.createElement(
    GalleryButton,
    {
      direction: "left",
      onClick: () => navigateToReflection(reflections[getIndex() - 1].id)
    }
  )), /* @__PURE__ */ React.createElement("div", { className: canNavigateNext() ? "visible" : "invisible" }, /* @__PURE__ */ React.createElement(
    GalleryButton,
    {
      direction: "right",
      onClick: () => navigateToReflection(reflections[getIndex() + 1].id)
    }
  ))));
};
export { ReflectionDetail as default, ReflectionDetailBody };
