import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CommentButtons from "./CommentButtons";
import CommentInput from "./CommentInput";
import CommentText from "./CommentText";
const CommentBlock = (props) => {
  const {
    comment,
    currentUser,
    index,
    isAdmin,
    onDeleteClick,
    onSaveClick,
    onStatusClick,
    onUpdateComment
  } = props;
  const { t } = useTranslation();
  const isCommentHidden = comment.status === "hidden";
  const [initialComment, setInitialComment] = useState(comment);
  const [isEditable, setIsEditable] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(isCommentHidden);
  useEffect(() => {
    setIsCollapsed(isCommentHidden);
  }, [comment.status]);
  const toggleIsEditable = () => {
    setIsEditable(!isEditable);
  };
  const handleCancelClick = () => {
    onUpdateComment(index, initialComment.text);
    toggleIsEditable();
  };
  const handleSaveClick = (updatedComment, commentIndex) => {
    onSaveClick(updatedComment, commentIndex);
    setInitialComment(updatedComment);
    toggleIsEditable();
  };
  const renderEditButtons = () => {
    if (!!currentUser && !isEditable) {
      return /* @__PURE__ */ React.createElement(
        CommentButtons,
        {
          comment,
          currentUser,
          isAdmin,
          onCollapseClick: () => setIsCollapsed(true),
          onDeleteClick,
          onEditClick: toggleIsEditable,
          onStatusClick
        }
      );
    }
  };
  const renderContent = () => {
    if (((currentUser == null ? void 0 : currentUser.is_superadmin) || isAdmin) && isCommentHidden && isCollapsed) {
      return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("span", { className: "comment-block__hidden-note" }, t("comments.hiddenComment")), /* @__PURE__ */ React.createElement(
        "button",
        {
          className: "comment-button__action-button",
          onClick: () => setIsCollapsed(false)
        },
        t("comments.view")
      ));
    } else {
      return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(CommentText, { comment: initialComment, isEditable }), !["posted", "hidden"].includes(comment.status) && /* @__PURE__ */ React.createElement("div", { className: "my-2" }, /* @__PURE__ */ React.createElement("p", null, /* @__PURE__ */ React.createElement("span", { className: "font-semibold text-red-700 uppercase" }, "Comment in review:"), " ", "Only you can see this."), /* @__PURE__ */ React.createElement("p", null, /* @__PURE__ */ React.createElement("a", { className: "font-semibold text-lifeweb-brown", href: process.env.external_url["memory_in_review_help"], target: "_blank" }, "Learn more about the review process."))), renderEditButtons());
    }
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "comment-block" }, renderContent()), isEditable && /* @__PURE__ */ React.createElement(
    CommentInput,
    {
      comment,
      index,
      onCancelClick: handleCancelClick,
      onSaveClick: handleSaveClick,
      onUpdateComment
    }
  ));
};
export default CommentBlock;
