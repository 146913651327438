import React from "react";
import ContentCard from "@/components/content_card/ContentCard";
import Panel from "@/components/panel/Panel";
import { fullName } from "@/utils/project_utils";
import { addHyperlink } from "@/utils/string_utils";
const MemoryCard = (props) => {
  const { admin, memory, onClickMemory, project, user } = props;
  const handleClickMemory = () => {
    if (onClickMemory) {
      onClickMemory(memory);
    }
  };
  const userName = memory.contributed_by;
  const isPinned = memory.display_status === "pinned";
  const contentCardProps = (() => {
    var _a;
    if (memory.memory_images.length) {
      const imageCardProps = {
        commentCount: memory.comments.filter(
          (comment) => comment.status === "posted"
        ).length,
        imageAlt: project ? `Memory image of ${fullName(project)}` : "",
        imageCount: memory.memory_images.length,
        imageUrl: memory.memory_images[0].image_url_large || memory.memory_images[0].image_url,
        isPinned,
        text: addHyperlink(memory.description),
        type: "image",
        userName,
        inReview: memory.publication_status !== "published",
        publication_status: memory.publication_status
      };
      return imageCardProps;
    }
    if (memory.memory_video) {
      const imageUrl = memory.memory_video.video_thumbnail_url || require("@/assets/images/project_default.jpg");
      const videoCardProps = {
        commentCount: memory.comments.filter(
          (comment) => comment.status === "posted"
        ).length,
        imageUrl,
        isPinned,
        text: addHyperlink(memory.description),
        type: "video",
        userName,
        inReview: memory.publication_status !== "published",
        publication_status: memory.publication_status
      };
      return videoCardProps;
    }
    if (memory.memory_quote && !memory.description) {
      const quoteCardProps = {
        commentCount: memory.comments.filter(
          (comment) => comment.status === "posted"
        ).length,
        isPinned,
        quote: memory.memory_quote.text,
        type: "quote",
        userName,
        inReview: memory.publication_status !== "published",
        publication_status: memory.publication_status
      };
      return quoteCardProps;
    }
    const textCardProps = {
      commentCount: memory.comments.filter(
        (comment) => comment.status === "posted"
      ).length,
      isPinned,
      quote: (_a = memory.memory_quote) == null ? void 0 : _a.text,
      text: addHyperlink(memory.description),
      type: "text",
      inReview: memory.publication_status !== "published",
      publication_status: memory.publication_status,
      userName
    };
    return textCardProps;
  })();
  return /* @__PURE__ */ React.createElement("div", { className: "memory-card", onClick: handleClickMemory }, /* @__PURE__ */ React.createElement(Panel, { padding: "narrow" }, /* @__PURE__ */ React.createElement(
    ContentCard,
    {
      admin,
      memory,
      user,
      ...contentCardProps
    }
  )));
};
export default MemoryCard;
