import classNames from "classnames";
import React from "react";
const Cell = (props) => {
  const { children, alignment } = props;
  return /* @__PURE__ */ React.createElement(
    "td",
    {
      className: classNames("table__cell", {
        "is-aligned-right": alignment === "right"
      })
    },
    children
  );
};
export default Cell;
