import Quill from "quill";
import MagicUrl from "quill-magic-url";
const Clipboard = Quill.import("modules/clipboard");
const Delta = Quill.import("delta");
const Link = Quill.import("formats/link");
export class PlainClipboard extends Clipboard {
  onPaste(e) {
    e.preventDefault();
    const range = this.quill.getSelection();
    const text = e.clipboardData.getData("text/plain");
    const delta = new Delta().retain(range.index).delete(range.length).insert(text);
    const index = text.length + range.index;
    const length = 0;
    this.quill.updateContents(delta, "user");
    this.quill.setSelection(index, length, "silent");
    this.quill.scrollIntoView();
  }
}
export const sanitizeLink = (url) => {
  if (/^http/.test(url) || /^mailto/.test(url)) {
    return url;
  }
  return `https://${url}`;
};
Quill.register("modules/clipboard", PlainClipboard, true);
Quill.register("modules/magicUrl", MagicUrl);
Link.sanitize = sanitizeLink;
export const modules = {
  magicUrl: {
    mailRegularExpression: null
  },
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ["bold", "italic", "underline", "strike", "blockquote", "link"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ]
  ]
};
export const formats = null;
export const limitedModules = {
  magicUrl: {
    mailRegularExpression: null
  },
  toolbar: [["bold", "italic", "underline", "strike", "link"]]
};
document.addEventListener("DOMContentLoaded", () => {
  const container = document.getElementById("editor");
  if (container) {
    const _quill = new Quill(container, {
      theme: "snow",
      modules
    });
  }
});
