import classnames from "classnames";
import React, { useState } from "react";
const Meatball = ({
  ariaLabel,
  hoverColor,
  onClick,
  position = "normal"
}) => {
  const [hovering, setHovering] = useState(false);
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classnames(
        "absolute cursor-pointer flex justify-end select-none z-20",
        {
          "-right-1 -top-1": position === "negative",
          "right-1.5 top-1.5": position === "normal"
        }
      ),
      "aria-label": ariaLabel,
      onMouseEnter: () => setHovering(true),
      onMouseLeave: () => setHovering(false),
      onClick
    },
    /* @__PURE__ */ React.createElement(
      "div",
      {
        className: classnames(
          "flex items-center justify-center rounded-full h-7 bg-opacity-0 w-7",
          { "bg-lifeweb-blue-light": !hoverColor, "bg-opacity-100": hovering }
        ),
        style: hoverColor && hovering ? { backgroundColor: hoverColor } : {}
      },
      hovering && /* @__PURE__ */ React.createElement("div", { className: "absolute inset-0 bg-white opacity-50 rounded-full z-10" }),
      /* @__PURE__ */ React.createElement("span", { className: "block leading-none text-current tracking-wider font-bold hover:opacity-100 z-20" }, "\u2022\u2022\u2022")
    )
  );
};
export default Meatball;
