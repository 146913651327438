import classNames from "classnames";
import React from "react";
import Icon from "../icon/Icon";
const PlayButton = (props) => {
  const { size } = props;
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames("play-button", {
        "is-small": size === "small"
      })
    },
    /* @__PURE__ */ React.createElement(Icon, { icon: "triangleRight" })
  );
};
export default PlayButton;
