import SalesCard, { SalesCardButton } from "@/components/cards/SalesCard";
import React from "react";
const SalesBanner = ({ project }) => project.plan === "standard" ? /* @__PURE__ */ React.createElement("div", { id: "premium-banner", className: "gap-3 grid md:grid-cols-3 my-4" }, /* @__PURE__ */ React.createElement("div", { className: "my-4" }, /* @__PURE__ */ React.createElement(SalesCard, { title: "Free Plan" }, /* @__PURE__ */ React.createElement("ul", { className: "list-[circle]" }, /* @__PURE__ */ React.createElement("li", null, "Unlimited story, photo, & video link collection"), /* @__PURE__ */ React.createElement("li", null, "Unlimited collaborators"), /* @__PURE__ */ React.createElement("li", null, "Flexible privacy options"), /* @__PURE__ */ React.createElement("li", null, "Memories reviewed before posting")), /* @__PURE__ */ React.createElement(SalesCardButton, { disabled: true }, "Current Plan"))), /* @__PURE__ */ React.createElement(SalesCard, { color: "blue", title: "Premium Plan" }, /* @__PURE__ */ React.createElement("ul", { className: "list-[circle]" }, /* @__PURE__ */ React.createElement("li", null, "Everything in the Free Plan"), /* @__PURE__ */ React.createElement("li", null, "Photo slideshow for use at events"), /* @__PURE__ */ React.createElement("li", null, "Download of all photos shared on the LifeWeb"), /* @__PURE__ */ React.createElement("li", null, "Dedicated customer support person"), /* @__PURE__ */ React.createElement("li", { className: "italic" }, "...and more!!"), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(SalesCardButton, { link: "https://www.lifeweb360.com/shop/premium" }, "Upgrade to Premium")), /* @__PURE__ */ React.createElement(
  "a",
  {
    className: "block text-center mt-2 text-lifeweb-brown",
    href: "https://www.lifeweb360.com/pricing#alacarte",
    target: "_blank"
  },
  "Individual Feature Options"
))), /* @__PURE__ */ React.createElement("div", { className: "my-4" }, /* @__PURE__ */ React.createElement(SalesCard, { title: "Memory Books" }, /* @__PURE__ */ React.createElement("ul", { className: "list-[circle]" }, /* @__PURE__ */ React.createElement("li", null, "A book you can hug"), /* @__PURE__ */ React.createElement("li", null, "Includes all shared photos & stories"), /* @__PURE__ */ React.createElement("li", null, "Bound with a high-quality, textured, heavy-weighted cover"), /* @__PURE__ */ React.createElement(SalesCardButton, { link: "https://www.lifeweb360.com/memory-book-process" }, "Order a Book"))))) : /* @__PURE__ */ React.createElement("div", { className: "gap-3 grid md:grid-cols-2 my-6" }, /* @__PURE__ */ React.createElement(SalesCard, { title: "Premium Plan \u{1F49B}" }, /* @__PURE__ */ React.createElement(SalesCardButton, { disabled: true }, "Current Plan")), /* @__PURE__ */ React.createElement(SalesCard, { title: "Memory Books" }, /* @__PURE__ */ React.createElement(SalesCardButton, { link: "https://www.lifeweb360.com/memory-book-process" }, "Order a Book")));
export default SalesBanner;
