import ConfirmationModal from "@/components/modal/ConfirmationModal";
import ModalCard from "@/components/modal/ModalCard";
import React from "react";
import { useTranslation } from "react-i18next";
const AnotherSocialAccountLinkModal = (props) => {
  const {
    visible,
    onConfirm,
    onCancel,
    newSocialProvider,
    existingSocialProvider
  } = props;
  const { t } = useTranslation();
  return /* @__PURE__ */ React.createElement(ModalCard, { position: "centerOfScreen", size: "small", isHidden: !visible }, /* @__PURE__ */ React.createElement(
    ConfirmationModal,
    {
      title: t(
        "views.memories.usersNew.different_provider_login_modal.title"
      ),
      subtitle: t(
        "views.memories.usersNew.different_provider_login_modal.subtitle",
        {
          existing_social_provider: existingSocialProvider,
          new_social_provider: newSocialProvider
        }
      ),
      confirmText: t(
        "views.memories.usersNew.different_provider_login_modal.link_account_btn"
      ),
      cancelText: t(
        "views.memories.usersNew.different_provider_login_modal.no_thanks_btn"
      ),
      onConfirm,
      onCancel,
      isOnboarding: true
    }
  ));
};
export default AnotherSocialAccountLinkModal;
