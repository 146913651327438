import Api from "@/api/Api";
import ActivityIndicator from "@/components/activity/ActivityIndicator";
import Checkbox from "@/components/form/Checkbox";
import SubmitOrCancelButtons from "@/components/form/SubmitOrCancelButtons";
import { useProjectQuery } from "@/queries";
import { flashSuccessMessage } from "@/utils/alert_utils";
import { route } from "@/utils/route_utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import { useHistory, useParams } from "react-router-dom";
import { useBreadcrumb } from "../providers/BreadcrumbContext";
import SettingsTitle from "./SettingsTitle";
const DateInput = ({
  disabled,
  inputRef,
  label,
  placeholder,
  value
}) => {
  const localDateString = value ? value.toLocaleDateString(void 0, {
    year: "numeric",
    month: "numeric",
    day: "numeric"
  }) : "";
  return /* @__PURE__ */ React.createElement("label", { className: "block max-w-xs mb-4", ref: inputRef }, /* @__PURE__ */ React.createElement("span", { className: "block mb-1" }, label), /* @__PURE__ */ React.createElement("div", { className: "flex items-center relative" }, /* @__PURE__ */ React.createElement(
    "input",
    {
      className: classNames(
        "cursor-pointer border-1/2 flex gap-2 items-center placeholder-gray-700 border-black px-2 py-3 relative rounded w-full",
        {
          "bg-gray-100": !value || disabled
        }
      ),
      placeholder,
      readOnly: true,
      value: disabled ? "" : localDateString
    }
  ), /* @__PURE__ */ React.createElement(
    "img",
    {
      className: "absolute block right-2 transform",
      src: require("@/assets/images/icons/caret-black-down.svg")
    }
  )));
};
const DateSettings = () => {
  const breadcrumb = useBreadcrumb();
  const history = useHistory();
  const { slug } = useParams();
  const queryClient = useQueryClient();
  const { data: project } = useProjectQuery(slug);
  const [changed, setChanged] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState();
  const [dateOfPassing, setDateOfPassing] = useState();
  const [hasNotPassed, setHasNotPassed] = useState(false);
  useEffect(() => {
    var _a;
    if (!project || typeof dateOfBirth === "boolean")
      return;
    breadcrumb.setCrumbs([
      {
        label: `${(_a = project.prefix) != null ? _a : ""} ${project.full_name}`,
        href: route("project", { slug })
      },
      {
        label: "Admin Settings"
      },
      {
        label: "Dates to Remember"
      }
    ]);
    mixpanel.track("settings_viewed", {
      kind: "project",
      project_id: project.id,
      type: "dates-to-remember"
    });
    const { date_of_birth: dob, date_of_passing: dop } = project;
    setDateOfBirth(dob ? /* @__PURE__ */ new Date(`${dob.split("T")[0]}T00:00:00`) : null);
    setHasNotPassed(project.living === "yes");
    setDateOfPassing(dop ? /* @__PURE__ */ new Date(`${dop.split("T")[0]}T00:00:00`) : null);
  }, [project]);
  const isoDate = (date) => {
    if (!date)
      return null;
    const timezoneOffset = date.getTimezoneOffset();
    date.setMinutes(date.getMinutes() - timezoneOffset);
    return date.toISOString().split("T")[0];
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    await Api.utility.patch(route("projectsUpdate", { slug }), {
      project: {
        date_of_birth: isoDate(dateOfBirth),
        date_of_passing: isoDate(dateOfPassing),
        living: hasNotPassed ? 1 : 0
      }
    });
  };
  const mutation = useMutation({
    mutationFn: handleSubmit,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["project", { slug }]
      });
      flashSuccessMessage("Dates to Remember successfully updated");
      history.push(route("project", { slug }));
    }
  });
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(SettingsTitle, null, "Dates to Remember"), project && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("p", { className: "my-4" }, "Visitors to ", project.preferred_name, "'s LifeWeb can choose to receive reminders on important dates to encourage them to visit the page and post additional memories or thoughts. Please complete this section if you'd like to enable these.", " ", /* @__PURE__ */ React.createElement("span", { className: "font-bold" }, "These dates will not appear on ", project.preferred_name, "'s LifeWeb.")), /* @__PURE__ */ React.createElement("form", { method: "patch", onSubmit: mutation.mutate }, /* @__PURE__ */ React.createElement("div", { className: "grid sm:grid-cols-2 sm:gap-6" }, /* @__PURE__ */ React.createElement(
    Flatpickr,
    {
      onChange: ([date]) => {
        setChanged(true);
        setDateOfBirth(date);
      },
      options: {
        maxDate: dateOfPassing || /* @__PURE__ */ new Date()
      },
      render: ({ value, ...props }, ref) => /* @__PURE__ */ React.createElement(
        DateInput,
        {
          ...props,
          inputRef: ref,
          label: `${project.preferred_name}'s Date of Birth`,
          placeholder: "Select date of birth",
          value: dateOfBirth
        }
      ),
      value: dateOfBirth
    }
  ), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
    Flatpickr,
    {
      onChange: ([date]) => {
        setChanged(true);
        setDateOfPassing(date);
      },
      options: {
        clickOpens: !hasNotPassed,
        maxDate: /* @__PURE__ */ new Date()
      },
      render: ({ value, ...props }, ref) => /* @__PURE__ */ React.createElement(
        DateInput,
        {
          ...props,
          disabled: hasNotPassed,
          inputRef: ref,
          label: `${project.preferred_name}'s Date of Passing`,
          placeholder: hasNotPassed ? "Has not passed" : "Select date of passing",
          value: dateOfPassing
        }
      )
    }
  ), /* @__PURE__ */ React.createElement(
    Checkbox,
    {
      label: "Has not passed",
      checked: hasNotPassed,
      onChange: (state) => {
        setChanged(true);
        setDateOfPassing(null);
        setHasNotPassed(state);
      }
    }
  ))), /* @__PURE__ */ React.createElement(
    SubmitOrCancelButtons,
    {
      disabled: !changed || mutation.isPending,
      onCancel: () => history.push(
        route("project", {
          slug
        })
      )
    }
  ))), !project && /* @__PURE__ */ React.createElement(ActivityIndicator, null));
};
export default DateSettings;
