import { sanitizeUserInput } from "@/utils/string_utils";
import React from "react";
import { useTranslation } from "react-i18next";
import CommentCardDetailsHeader from "./ContentCardDetailsHeader";
const InReviewBanner = () => {
  const { t } = useTranslation();
  return /* @__PURE__ */ React.createElement(
    "a",
    {
      href: process.env.external_url["memory_in_review_help"],
      target: "_blank",
      onClick: (e) => e.stopPropagation()
    },
    /* @__PURE__ */ React.createElement("div", { className: "content-card-details__review" }, /* @__PURE__ */ React.createElement("strong", null, t("views.memories.cards.inReview")), t("views.memories.cards.onlyYouSee"), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("div", { className: "content-card-details__learn_review" }, t("views.memories.cards.learnReviewProcess")))
  );
};
const InHiddenBanner = () => {
  const { t } = useTranslation();
  return /* @__PURE__ */ React.createElement(
    "a",
    {
      href: process.env.external_url["memory_in_hidden_help"],
      target: "_blank",
      onClick: (e) => e.stopPropagation()
    },
    /* @__PURE__ */ React.createElement("div", { className: "content-card-details__hidden" }, /* @__PURE__ */ React.createElement("strong", null, t("views.memories.cards.inHidden")), t("views.memories.cards.onlyYouSee"), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("div", { className: "content-card-details__learn_review" }, t("views.memories.cards.learnHiddenProcess")))
  );
};
const ContentCardDetails = (props) => {
  const {
    text,
    inReview,
    publication_status: publicationStatus,
    ...additionalProps
  } = props;
  const banner = (publicationStatus2) => {
    switch (publicationStatus2) {
      case "unpublished":
        return /* @__PURE__ */ React.createElement(InHiddenBanner, null);
      case "hidden":
      case "pending":
        return /* @__PURE__ */ React.createElement(InReviewBanner, null);
      default:
        /* @__PURE__ */ React.createElement(React.Fragment, null);
    }
  };
  return /* @__PURE__ */ React.createElement("div", { className: "content-card-details" }, /* @__PURE__ */ React.createElement(CommentCardDetailsHeader, { ...additionalProps }), /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "content-card-details__text prose max-w-none",
      dangerouslySetInnerHTML: {
        __html: sanitizeUserInput(text, additionalProps.user)
      }
    }
  ), banner(publicationStatus));
};
export { ContentCardDetails as default, InReviewBanner, InHiddenBanner };
