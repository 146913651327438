import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@/components/button/Button";
import TextArea from "@/components/form/TextArea";
import { isValueEmpty } from "@/utils/form_utils";
const CommentInput = (props) => {
  const { comment, index, onCancelClick, onSaveClick, onUpdateComment } = props;
  const { t } = useTranslation();
  const handleSaveClick = () => {
    onSaveClick(comment, index);
  };
  const isSaveButtonDisabled = isValueEmpty(comment.text);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "columns is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column comment-input__text-area-wrapper" }, /* @__PURE__ */ React.createElement(
    TextArea,
    {
      onChange: (value) => onUpdateComment(index, value),
      value: comment.text
    }
  ))), /* @__PURE__ */ React.createElement("div", { className: "columns comment-input__button-wrapper" }, /* @__PURE__ */ React.createElement("div", { className: "column is-3-mobile is-2-tablet comment-input__buttons" }, /* @__PURE__ */ React.createElement(
    "button",
    {
      className: "comment-button__action-button",
      onClick: onCancelClick
    },
    t("comments.cancel")
  )), /* @__PURE__ */ React.createElement("div", { className: "column is-4-mobile is-2-tablet" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "outline",
      onClick: handleSaveClick,
      isDisabled: isSaveButtonDisabled
    },
    t("comments.save")
  ))));
};
export default CommentInput;
