const FILE_UPLOAD_SETTINGS = {
  maxNumberOfFiles: 5,
  bulkMaxNumberOfFiles: 50,
  maxFileSize: 5242880,
  allowedFileTypes: [
    "image/jpeg",
    "image/png",
    "image/webp"
  ]
};
export default FILE_UPLOAD_SETTINGS;
