import Icon from "@/components/icon/Icon";
import React from "react";
const ActionButton = ({ icon, label, onClick }) => /* @__PURE__ */ React.createElement(
  "button",
  {
    className: "flex gap-1 items-center select-none",
    onClick,
    type: "button"
  },
  /* @__PURE__ */ React.createElement("div", { className: "bg-lifeweb-blue flex items-center justify-center rounded-full p-[5px] w-6 h-6" }, /* @__PURE__ */ React.createElement(Icon, { icon })),
  /* @__PURE__ */ React.createElement("div", null, label)
);
export default ActionButton;
