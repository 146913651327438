import { DeprecatedCheckbox as Checkbox } from "@/components/form/Checkbox";
import Label from "@/components/form/Label";
import classNames from "classnames";
import React, { useEffect } from "react";
const ActionVerification = ({
  verificationMessage,
  changeVerification,
  isItalic
}) => {
  useEffect(() => {
    changeVerification(false);
  }, []);
  const verificationMessageStyles = classNames(
    { "is-italic": isItalic },
    "px-2"
  );
  return /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-mobile is-8-tablet" }, /* @__PURE__ */ React.createElement(
    Label,
    {
      labelSize: "small",
      isRequired: true,
      isBold: true,
      isInlineBlock: true
    },
    /* @__PURE__ */ React.createElement(Checkbox, { onChange: changeVerification, isMedium: true }),
    /* @__PURE__ */ React.createElement("span", { className: verificationMessageStyles }, verificationMessage)
  )));
};
export default ActionVerification;
