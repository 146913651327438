import ReflectionForm from "@/contexts/reflections/ReflectionForm";
import React, { useEffect } from "react";
const ReflectionNew = ({ group, uploadPath, user }) => {
  useEffect(() => {
    mixpanel.track("reflection_started", {
      kind: "group",
      group_name: group.slug
    });
  }, []);
  return /* @__PURE__ */ React.createElement("div", { className: "max-w-screen-sm mx-auto" }, /* @__PURE__ */ React.createElement("p", { className: "mb-4" }, "Add a reflection by sharing an inspirational photo or thoughtful note to honor everyone in our community who has passed away."), /* @__PURE__ */ React.createElement(ReflectionForm, { group, uploadPath, user }));
};
export default ReflectionNew;
