import Color from "color";
export const isLightBackground = (bgColor) => {
  return Color(bgColor).isLight();
};
export const getHighContrastTextColor = (bgColor) => {
  return isLightBackground(bgColor) ? "text-black" : "text-white";
};
export const fakeAlpha = (color, alpha) => {
  const source = Color(color).alpha(alpha).rgb().object();
  const r = Math.round((1 - alpha) * 255 + alpha * source.r);
  const g = Math.round((1 - alpha) * 255 + alpha * source.g);
  const b = Math.round((1 - alpha) * 255 + alpha * source.b);
  return `rgb(${r}, ${g}, ${b})`;
};
