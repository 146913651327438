document.addEventListener("DOMContentLoaded", () => {
  let fieldsChanged = false;
  let submittingForm = false;
  const inputs = document.querySelectorAll("form[data-confirm-nav] input");
  inputs.forEach((input) => {
    input.addEventListener("change", () => {
      fieldsChanged = true;
    });
    input.addEventListener("keyup", () => {
      fieldsChanged = true;
    });
  });
  const submits = document.querySelectorAll(
    'form[data-confirm-nav] input[type="submit"]'
  );
  submits.forEach((submit) => {
    submit.addEventListener("click", () => {
      submittingForm = true;
    });
  });
  window.addEventListener("beforeunload", (e) => {
    if (fieldsChanged && !submittingForm) {
      e.preventDefault();
      (e || window.event).returnValue = "";
      return "";
    } else {
      return void 0;
    }
  });
});
