import useHelpScoutBeacon from "@/helpers/hooks/useHelpScoutBeacon";
import Modeler from "@/utils/modeler/modeler";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React, { StrictMode } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import GroupHeader from "./components/GroupHeader";
import GroupFooter from "./GroupFooter";
import GroupIntro from "./GroupIntro";
import GroupProjectNew from "./GroupProjectNew";
import GroupProjects from "./GroupProjects";
const queryClient = new QueryClient();
const Group = ({ categories, group, user }) => {
  useHelpScoutBeacon("838df549-c596-4261-9afc-a408d6359043");
  const modeledGroup = new Modeler(group).build();
  const modeledUser = new Modeler(user).build();
  return /* @__PURE__ */ React.createElement(StrictMode, null, /* @__PURE__ */ React.createElement(QueryClientProvider, { client: queryClient }, /* @__PURE__ */ React.createElement(Router, null, /* @__PURE__ */ React.createElement(GroupHeader, null), /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(Route, { path: "/groups/:slug/:member_name/new" }, /* @__PURE__ */ React.createElement(GroupProjectNew, null)), /* @__PURE__ */ React.createElement(Route, { path: "/groups/:slug/:member_name/report" }, /* @__PURE__ */ React.createElement(GroupProjectNew, { report: true })), /* @__PURE__ */ React.createElement(Route, { path: "/groups/:slug" }, /* @__PURE__ */ React.createElement(GroupIntro, { group: modeledGroup }), /* @__PURE__ */ React.createElement(
    GroupProjects,
    {
      categories,
      group: modeledGroup,
      user: modeledUser
    }
  ), /* @__PURE__ */ React.createElement(GroupFooter, null)))), /* @__PURE__ */ React.createElement(ReactQueryDevtools, { initialIsOpen: false })));
};
export default Group;
