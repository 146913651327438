import classNames from "classnames";
import React from "react";
const ButtonLink = ({
  children,
  className = "",
  href,
  internal = false,
  onClick = () => {
  },
  style = {}
}) => {
  return /* @__PURE__ */ React.createElement(
    "a",
    {
      className: classNames(
        {
          "bg-white": !("backgroundColor" in style) && !(className == null ? void 0 : className.includes("bg-"))
        },
        className,
        "border",
        { "border-transparent": !("borderColor" in style) },
        "font-semibold",
        "hover:text-black",
        "inline-block",
        "px-5",
        "py-3",
        "rounded-lg",
        "text-center",
        "text-gray-900",
        "text-md",
        "uppercase"
      ),
      href,
      onClick,
      target: internal ? void 0 : "_blank",
      style
    },
    children
  );
};
const RectangularButtonLink = ({
  children,
  className = "",
  href,
  internal = false,
  onClick = () => {
  },
  style = {}
}) => {
  return /* @__PURE__ */ React.createElement(
    "a",
    {
      className: `bg-lifeweb-blue font-semibold hover:text-white block px-5 py-3 text-center text-white text-md w-full ${className}`,
      href,
      onClick,
      target: internal ? void 0 : "_blank",
      style
    },
    children
  );
};
export { ButtonLink as default, RectangularButtonLink };
