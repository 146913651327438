import React, { useMemo } from "react";
import Button from "@/components/button/Button";
import Cell from "@/components/table/Cell";
import Row from "@/components/table/Row";
import { capitalize } from "@/utils/string_utils";
import { fullName } from "@/utils/user_utils";
const ProjectUserRow = ({
  currentUser,
  isHighlighted,
  onEdit,
  projectUser
}) => {
  const { user } = projectUser;
  const status = useMemo(() => {
    let rv = projectUser.status;
    if (rv === "requested") {
      rv = "Access Requested";
    } else if (rv === "restricted") {
      rv = "Access Denied";
    }
    return capitalize(rv);
  }, [projectUser]);
  return /* @__PURE__ */ React.createElement(Row, { isHighlighted }, /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Cell, null, fullName(user), user.email ? ` (${user.email})` : ""), /* @__PURE__ */ React.createElement(Cell, null, projectUser.status === "requested" ? /* @__PURE__ */ React.createElement("strong", { className: "text-red-700" }, status) : status), /* @__PURE__ */ React.createElement(Cell, { alignment: "right" }, /* @__PURE__ */ React.createElement("div", { id: user.id, className: "user-manager__edit-wrapper" }, currentUser.id !== user.id && /* @__PURE__ */ React.createElement(Button, { color: "outline", onClick: onEdit }, "Manage")))));
};
export default ProjectUserRow;
