import "@/locales/i18n";
import * as Sentry from "@sentry/react";
import "chartkick/chart.js";
import WebpackerReact from "react-components-rails";
import "../stylesheets/bulma.css";
import "../stylesheets/tailwind.css";
import AccessRequest from "@/contexts/projects/AccessRequest";
import App from "@/App";
import Breadcrumb from "@/components/Breadcrumb";
import CurateMemory from "@/contexts/settings/CurateMemory";
import EditMemory from "@/contexts/settings/EditMemory";
import FixImage from "@/contexts/admin/FixImage";
import Group from "@/contexts/groups";
import Login from "@/contexts/auth/Login";
import MemoriesIndex from "@/contexts/memories/MemoriesIndex";
import MemoriesNew from "@/contexts/memories/MemoriesNew";
import NewBook from "@/contexts/projects/NewBook";
import NewInvitation from "@/contexts/registrations/NewInvitation";
import NewProject from "@/contexts/projects/ProjectNew";
import NewRegistration from "@/contexts/registrations/NewRegistration";
import PasswordReset from "@/contexts/password/PasswordReset";
import { Playground } from "@/contexts/playground";
import ReflectionEdit from "@/contexts/reflections/ReflectionEdit";
import ReflectionNew from "@/contexts/reflections/ReflectionNew";
import SalesBanner from "@/contexts/settings/SalesBanner";
import Search from "@/contexts/search";
import Settings from "@/contexts/settings";
import SocialConnectedAccounts from "@/components/socialConnectedAccounts/SocialConnectedAccounts";
import SocialLoginButtons from "@/components/socialLoginButtons/SocialLoginButtons";
import UserManager from "@/contexts/settings/UserManager";
require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
export {};
Sentry.init({
  dsn: process.env.REACT_SENTRY_DSN,
  release: process.env.GIT_SHA || "",
  environment: process.env.NAMESPACE_ENV || process.env.RAILS_ENV || "development"
});
window.Sentry = Sentry;
const axios = require("axios");
window.axios = axios;
window.addEventListener(
  "dragover",
  function(e) {
    e.preventDefault();
  },
  false
);
window.addEventListener(
  "drop",
  function(e) {
    e.preventDefault();
  },
  false
);
require("@/helpers/confirm_unfinished_form");
require("@/helpers/copy_to_clipboard");
require("@/helpers/modal");
require("@/helpers/section_toggle");
require("@/helpers/quill_editor");
require("@/helpers/share_project");
require("@/helpers/navbar");
require("@/helpers/required_form_fields");
require("@/helpers/form_input");
require("@/helpers/alerts");
require("@/helpers/obituary");
WebpackerReact.setup({
  AccessRequest,
  App,
  Breadcrumb,
  CurateMemory,
  EditMemory,
  FixImage,
  Group,
  Login,
  MemoriesIndex,
  MemoriesNew,
  NewBook,
  NewInvitation,
  NewProject,
  NewRegistration,
  PasswordReset,
  Playground,
  ReflectionEdit,
  ReflectionNew,
  SalesBanner,
  Search,
  Settings,
  SocialConnectedAccounts,
  SocialLoginButtons,
  UserManager
});
