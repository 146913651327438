import classNames from "classnames";
import React from "react";
const NavigationSection = ({
  children,
  link,
  mobile,
  title
}) => /* @__PURE__ */ React.createElement(
  "div",
  {
    className: classNames("bg-white divide-lifeweb-blue divide-y", {
      "sm:hidden": mobile
    })
  },
  link ? /* @__PURE__ */ React.createElement("a", { href: link, className: "block font-semibold m-0 px-3 py-2 peer" }, title) : /* @__PURE__ */ React.createElement("div", { className: "bg-lifeweb-blue font-semibold px-3 py-2 text-white peer" }, title),
  children
);
export default NavigationSection;
