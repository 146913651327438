import classNames from "classnames";
import React from "react";
import Icon from "@/components/icon/Icon";
const IconButton = (props) => {
  const { icon, link, onClick, size, text } = props;
  if (link) {
    return /* @__PURE__ */ React.createElement(
      "a",
      {
        className: classNames("icon-button", {
          "is-extra-small": size === "extra-small",
          "is-small": size === "small",
          "is-regular": size === "regular" || !size
        }),
        href: link,
        onClick
      },
      /* @__PURE__ */ React.createElement("div", { className: "icon-button__content" }, /* @__PURE__ */ React.createElement(Icon, { icon }), /* @__PURE__ */ React.createElement("span", null, text))
    );
  }
  return /* @__PURE__ */ React.createElement(
    "button",
    {
      className: classNames("icon-button", {
        "is-extra-small": size === "extra-small",
        "is-small": size === "small",
        "is-regular": size === "regular" || !size
      }),
      onClick
    },
    /* @__PURE__ */ React.createElement("div", { className: "icon-button__content" }, /* @__PURE__ */ React.createElement(Icon, { icon }), /* @__PURE__ */ React.createElement("span", null, text))
  );
};
export default IconButton;
