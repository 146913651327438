export const objectivePronoun = (project) => {
  switch (project.pronoun) {
    case "she":
      return "her";
    case "he":
      return "him";
    case "they":
      return "them";
    default:
      return project.pronoun;
  }
};
export const possessivePronoun = (project) => {
  switch (project.pronoun) {
    case "she":
      return "her";
    case "he":
      return "his";
    case "they":
      return "their";
    default:
      return project.pronoun;
  }
};
