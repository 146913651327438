import React from "react";
import { useTranslation } from "react-i18next";
import CommentManager from "@/components/comment/CommentManager";
import ConfirmationModal from "@/components/modal/ConfirmationModal";
import Modal from "@/components/modal/Modal";
const MemoryComments = (props) => {
  const {
    closeConfirmationModal,
    comment,
    comments,
    currentUser,
    isAdmin,
    isConfirmationModalVisible,
    onDeleteClick,
    onDeleteConfirm,
    onSaveClick,
    onStatusClick,
    onUpdateComment
  } = props;
  const { t } = useTranslation();
  const renderComments = () => {
    if (comments.length) {
      const sortedComments = comments.sort(
        (a, b) => b.created_at.localeCompare(a.created_at)
      );
      return /* @__PURE__ */ React.createElement(
        CommentManager,
        {
          comments: sortedComments,
          currentUser,
          isAdmin,
          onDeleteClick,
          onSaveClick,
          onStatusClick,
          onUpdateComment
        }
      );
    }
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, renderComments(), /* @__PURE__ */ React.createElement(Modal, { size: "medium", isVisible: isConfirmationModalVisible }, /* @__PURE__ */ React.createElement(
    ConfirmationModal,
    {
      cancelText: t("modal.cancel"),
      closeText: t("modal.close"),
      confirmText: t("modal.delete"),
      onClose: closeConfirmationModal,
      onConfirm: () => onDeleteConfirm(comment),
      title: t("modal.areYouSure")
    }
  )));
};
export default MemoryComments;
