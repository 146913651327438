import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Input from "@/components/form/Input";
import TypeaheadResults from "./TypeaheadResults";
const Typeahead = (props) => {
  const {
    availableResults,
    emptyResultsText,
    isHighlighted,
    onPressEnter,
    shouldDisplayEmptyResults,
    placeholder,
    selectAllEnabled = true,
    selectedResults,
    sort,
    srLabel = "",
    updateResults,
    withDropdownCaret
  } = props;
  const [inputValue, setInputValue] = useState("");
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isTypeaheadFocused, setIsTypeaheadFocused] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const id = placeholder.replace(/\s/g, "-").toLowerCase();
  useEffect(() => {
    const results = availableResults.filter(
      (result) => result.name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setSearchResults(results);
  }, [availableResults, inputValue]);
  const handleUpdateText = (text) => {
    setInputValue(text);
  };
  const handleSelectResults = () => {
    closeTypeahead();
    setInputValue("");
  };
  const handleEnterPress = () => {
    closeTypeahead();
    if (searchResults.length === 0) {
      onPressEnter(inputValue);
    }
  };
  const closeTypeahead = () => {
    setIsInputFocused(false);
    setIsTypeaheadFocused(false);
    setInputValue("");
  };
  const handleBlurInput = () => {
    setIsInputFocused(false);
  };
  const handleFocusInput = () => {
    setIsInputFocused(true);
    setIsTypeaheadFocused(true);
  };
  const handleLeaveTypeahead = () => {
    setIsTypeaheadFocused(false);
    setInputValue("");
  };
  const handleEnterTypeahead = () => {
    if (isInputFocused) {
      setIsTypeaheadFocused(true);
    }
  };
  const areResultsVisible = isInputFocused || isTypeaheadFocused;
  const renderInput = () => {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: classNames("typeahead", {
          "is-open": areResultsVisible
        }),
        onMouseEnter: handleEnterTypeahead,
        onMouseLeave: handleLeaveTypeahead
      },
      /* @__PURE__ */ React.createElement("span", { className: "block h-0 invisible mb-0 mx-4 sm:whitespace-nowrap" }, placeholder),
      /* @__PURE__ */ React.createElement("span", { className: "block -my-2" }, srLabel && /* @__PURE__ */ React.createElement("label", { className: "sr-only", htmlFor: id }, srLabel), /* @__PURE__ */ React.createElement(
        Input,
        {
          id,
          isHighlighted,
          onBlur: handleBlurInput,
          onChange: handleUpdateText,
          onFocus: handleFocusInput,
          onPressEnter: handleEnterPress,
          placeholder: isInputFocused ? "Type to search" : placeholder,
          style: areResultsVisible ? "borderless" : null,
          value: inputValue,
          withoutFocusShadow: true
        }
      )),
      /* @__PURE__ */ React.createElement("div", { id: "typeahead-results", tabIndex: 0 }, /* @__PURE__ */ React.createElement(
        TypeaheadResults,
        {
          availableResults: searchResults,
          emptyResultsText,
          shouldDisplayEmptyResults,
          isVisible: areResultsVisible,
          onApplyClick: handleSelectResults,
          selectAllEnabled,
          selectedResults,
          sort,
          updateResults
        }
      ))
    );
  };
  const renderInputWithCaret = (focused) => {
    return /* @__PURE__ */ React.createElement("div", { className: "select-wrapper with-caret" }, renderInput(), !focused && /* @__PURE__ */ React.createElement("div", { className: "select-caret" }, /* @__PURE__ */ React.createElement(
      "img",
      {
        alt: "View options",
        src: require("@/assets/images/icons/caret-black-down.svg")
      }
    )));
  };
  return withDropdownCaret ? renderInputWithCaret(isInputFocused) : renderInput();
};
export default Typeahead;
