import classNames from "classnames";
import React from "react";
const checkedIcon = require("@/assets/images/icons/selected.svg");
const DeprecatedCheckbox = (props) => {
  const { onChange, isMedium } = props;
  const inputStyles = classNames("form-checkbox", {
    "is-medium": isMedium
  });
  return /* @__PURE__ */ React.createElement(
    "input",
    {
      type: "checkbox",
      onChange: (e) => {
        onChange(e.target.checked);
      },
      className: inputStyles
    }
  );
};
const Checkbox = ({ label, checked, onChange }) => {
  return /* @__PURE__ */ React.createElement("label", { className: "flex gap-2 justify-start items-center relative" }, /* @__PURE__ */ React.createElement(
    "input",
    {
      type: "checkbox",
      checked: !!checked,
      onChange: (e) => {
        onChange(e.target.checked);
      },
      className: "absolute w-5 h-5 opacity-0 peer"
    }
  ), /* @__PURE__ */ React.createElement("div", { className: "w-5 h-5 border border-black peer-checked:hidden select-none" }), /* @__PURE__ */ React.createElement(
    "img",
    {
      src: checkedIcon,
      className: "hidden peer-checked:block w-5 h-5 select-none"
    }
  ), /* @__PURE__ */ React.createElement("div", null, label));
};
export { Checkbox as default, DeprecatedCheckbox };
