import React from "react";
import ErrorMessage from "@/components/form/ErrorMessage";
import ThemeCheckbox from "./ThemeCheckbox";
import ThemeTag from "./ThemeTag";
const ThemeRail = (props) => {
  const {
    isRemovable,
    onAddTheme,
    onAddNewLifeThread,
    onRemoveTheme,
    selectedThemes,
    themes,
    validationMessage
  } = props;
  const renderThemes = (theme, isSelected) => {
    if (isRemovable) {
      return /* @__PURE__ */ React.createElement(
        ThemeCheckbox,
        {
          onAddTheme,
          onRemoveTheme,
          theme,
          isSelected
        }
      );
    }
    return /* @__PURE__ */ React.createElement(ThemeTag, { isSelected }, theme.name);
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "theme-rail" }, themes.map((theme) => {
    const selectedTheme = selectedThemes && selectedThemes.find((t) => t.id === theme.id);
    const isSelected = !!selectedTheme;
    return /* @__PURE__ */ React.createElement("div", { className: "theme-rail__theme-wrapper", key: theme.id }, renderThemes(theme, isSelected));
  }), onAddNewLifeThread && /* @__PURE__ */ React.createElement(
    "a",
    {
      className: "theme-rail__add_custom_life_thread",
      onClick: onAddNewLifeThread
    },
    "Add Custom Life Thread"
  )), validationMessage && /* @__PURE__ */ React.createElement(ErrorMessage, { message: validationMessage }));
};
export default ThemeRail;
