import React, {
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import Api from "@/api/Api";
import ActivityIndicator from "@/components/activity/ActivityIndicator";
import GalleryButton from "@/components/button/GalleryButton";
import Modal from "@/components/modal/Modal";
import { flashMessageFromResponseError } from "@/utils/alert_utils";
import Modeler from "@/utils/modeler/modeler";
import { route } from "@/utils/route_utils";
import MemoryShow from "./MemoryShow";
import MemoryShowHeader from "./MemoryShowHeader";
const focusedInputs = {
  comment: false,
  name: false
};
const MemoryShowModal = (props) => {
  var _a;
  const {
    currentUser,
    isAdmin,
    memories,
    onClickClose,
    onNavigate,
    onUpdateMemory,
    project,
    selectedMemoryId,
    shouldDisplayNavigation,
    modeledProjectUser,
    setModalNotificationSettingVisible,
    setModeledProjectUser
  } = props;
  const [focuesdInputs, setFocusedInputs] = useState(focusedInputs);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMemory, setSelectedMemory] = useState(null);
  const history = useHistory();
  const { search } = useLocation();
  const areAnyInputsFocusedRef = useRef(false);
  const memoryIdRef = useRef(selectedMemoryId);
  const onNavigateRef = useRef(onNavigate);
  const shouldNavigateRef = useRef(shouldDisplayNavigation);
  const areAnyInputsFocused = useMemo(() => {
    return Object.values(focuesdInputs).find((n) => n);
  }, [focuesdInputs]);
  const handleNavigateMemories = (increment) => {
    const currentIndex = memories.findIndex(
      (memory) => memory.id === selectedMemoryId
    );
    let newIndex = increment === "next" ? currentIndex + 1 : currentIndex - 1;
    newIndex = (newIndex < 0 ? memories.length - 1 : newIndex) % memories.length;
    const { id: memoryId } = memories[newIndex];
    const url = route("projectMemory", {
      slug: project.slug,
      memory_id: memoryId
    }) + search;
    history.push(url);
  };
  useEffect(() => {
    areAnyInputsFocusedRef.current = areAnyInputsFocused;
  }, [areAnyInputsFocused]);
  useEffect(() => {
    memoryIdRef.current = selectedMemoryId;
  }, [selectedMemoryId]);
  useEffect(() => {
    onNavigateRef.current = onNavigate;
  }, [onNavigate]);
  useEffect(() => {
    shouldNavigateRef.current = shouldDisplayNavigation;
  }, [shouldDisplayNavigation]);
  useEffect(() => {
    if (selectedMemoryId) {
      const memory = memories[selectedMemoryId];
      if (memory) {
        setSelectedMemory(memory);
      } else {
        fetchMemory(selectedMemoryId);
      }
    } else {
      setSelectedMemory(null);
    }
  }, [selectedMemoryId]);
  useEffect(() => {
    const handler = (e) => {
      const key = e.key;
      if (key === "Escape" && memoryIdRef.current) {
        onClickClose();
      }
    };
    window.addEventListener("keydown", handler);
    return () => window.removeEventListener("keydown", handler);
  }, []);
  useEffect(() => {
    const handler = (e) => {
      const key = e.key;
      if (!areAnyInputsFocusedRef.current && shouldNavigateRef.current && onNavigateRef.current && (key === "ArrowLeft" || key === "ArrowRight")) {
        const increment = key === "ArrowLeft" ? "previous" : "next";
        onNavigateRef.current(increment);
      }
    };
    window.addEventListener("keydown", handler);
    return () => window.removeEventListener("keydown", handler);
  }, []);
  const fetchMemory = async (id) => {
    setIsLoading(true);
    try {
      const url = route("memory", { id });
      const response = await Api.utility.get(url);
      const memory = new Modeler(response.data).build();
      setSelectedMemory(memory);
    } catch (err) {
      flashMessageFromResponseError(err);
    }
    setIsLoading(false);
  };
  const handleSetInputFocus = (input, value) => {
    setFocusedInputs({
      ...focuesdInputs,
      [input]: value
    });
  };
  const handleUpdateMemory = (id, newMemory) => {
    onUpdateMemory(id, newMemory);
    if (id === selectedMemoryId) {
      setSelectedMemory(newMemory);
    }
  };
  const renderNavigation = () => {
    if (shouldDisplayNavigation) {
      return /* @__PURE__ */ React.createElement("div", { className: "memory-show__navigation" }, /* @__PURE__ */ React.createElement(
        GalleryButton,
        {
          direction: "left",
          onClick: () => handleNavigateMemories("previous")
        }
      ), isLoading && /* @__PURE__ */ React.createElement(ActivityIndicator, null), /* @__PURE__ */ React.createElement(
        GalleryButton,
        {
          direction: "right",
          onClick: () => handleNavigateMemories("next")
        }
      ));
    }
    if (isLoading) {
      return /* @__PURE__ */ React.createElement("div", { className: "memory-show__navigation is-centered" }, /* @__PURE__ */ React.createElement(ActivityIndicator, null));
    }
  };
  const isVisible = !!(selectedMemoryId && selectedMemory);
  const shouldAllowEdit = selectedMemory && (currentUser == null ? void 0 : currentUser.id) && ((_a = selectedMemory.user) == null ? void 0 : _a.id) === currentUser.id || isAdmin;
  return /* @__PURE__ */ React.createElement(Modal, { isFullHeight: true, isVisible, onClose: onClickClose }, /* @__PURE__ */ React.createElement(
    MemoryShowHeader,
    {
      currentUser,
      memory: selectedMemory,
      onClickClose,
      project,
      shouldAllowEdit
    }
  ), renderNavigation(), /* @__PURE__ */ React.createElement(
    MemoryShow,
    {
      currentUser,
      isAdmin,
      memory: selectedMemory,
      onBlurInput: (input) => handleSetInputFocus(input, false),
      onFocusInput: (input) => handleSetInputFocus(input, true),
      onUpdateMemory: handleUpdateMemory,
      project,
      modeledProjectUser,
      setModalNotificationSettingVisible,
      setModeledProjectUser
    }
  ));
};
export default MemoryShowModal;
