import CloseButton from "@/components/modal/CloseButton";
import Modal from "@/components/modal/Modal";
import { H2 } from "@/components/typography";
import { route } from "@/utils/route_utils";
import React, { useEffect } from "react";
const HonorModal = ({ group, onClose, user, visible }) => {
  useEffect(() => {
    if (!visible)
      return;
    mixpanel.track("groupmodal_viewed", {
      kind: "group",
      group_name: group.slug,
      type: "honor-member"
    });
  }, [visible]);
  return /* @__PURE__ */ React.createElement(Modal, { onClose, isVisible: visible }, /* @__PURE__ */ React.createElement("div", { className: "flex justify-end" }, /* @__PURE__ */ React.createElement(CloseButton, { onClose })), /* @__PURE__ */ React.createElement("div", { className: "max-w-md mx-auto" }, /* @__PURE__ */ React.createElement(
    "img",
    {
      className: "block mx-auto w-1/3",
      src: group.logo_url,
      alt: group.name
    }
  ), /* @__PURE__ */ React.createElement(H2, { className: "text-center" }, group.reporting_enabled ? "Three" : "Two", " ways to honor a", " ", group.member_name, ":"), /* @__PURE__ */ React.createElement("div", { className: "my-6" }, /* @__PURE__ */ React.createElement("h3", { className: "font-semibold my-3 text-xl" }, "1. Add a memory"), /* @__PURE__ */ React.createElement("p", null, "Search for a ", group.member_name, " and visit the ", group.member_name, "'s LifeWeb tribute page. Post a story or photo that reminds you of your time together. Share the ", group.member_name, "'s page to encourage others to add more.")), /* @__PURE__ */ React.createElement("div", { className: "my-6" }, /* @__PURE__ */ React.createElement("h3", { className: "font-semibold my-3 text-xl" }, "2. Add a ", group.member_name), /* @__PURE__ */ React.createElement("div", { className: "md:grid grid-cols-4 gap-8" }, /* @__PURE__ */ React.createElement("p", { className: "col-span-3 mb-4" }, "If a ", group.member_name, " isn't listed here, you can create a new LifeWeb tribute page to add memories and invite others to contribute.", " ", !(user == null ? void 0 : user.id) && /* @__PURE__ */ React.createElement("span", { className: "block italic" }, "(Free LifeWeb 360 account required)")), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
    "a",
    {
      className: "border border-lifeweb-blue block font-semibold px-3 py-3 rounded text-center text-lifeweb-blue",
      href: route("groupProjectNew", {
        member_name: group.member_name,
        slug: group.slug
      })
    },
    "Add"
  )))), group.reporting_enabled && /* @__PURE__ */ React.createElement("div", { className: "my-6" }, /* @__PURE__ */ React.createElement("h3", { className: "font-semibold my-3 text-xl" }, "3. Report a ", group.member_name, "'s passing"), /* @__PURE__ */ React.createElement("div", { className: "md:grid grid-cols-4 gap-8" }, /* @__PURE__ */ React.createElement("p", { className: "col-span-3 mb-4" }, "Report the passing of a ", group.member_name, " who isn't on the page without creating the LifeWeb tribute page yourself.", !(user == null ? void 0 : user.id) && /* @__PURE__ */ React.createElement("span", { className: "block italic" }, "(No account necessary)")), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
    "a",
    {
      className: "border border-lifeweb-blue block font-semibold px-3 py-3 rounded text-center text-lifeweb-blue",
      href: route("groupProjectReport", {
        member_name: group.member_name,
        slug: group.slug
      })
    },
    "Report"
  ))))));
};
export default HonorModal;
