import Icon from "@/components/icon/Icon";
import classNames from "classnames";
import React from "react";
import { NavLink } from "react-router-dom";
const NavigationItem = ({
  link = "",
  mobile,
  onClick,
  submenu,
  title
}) => {
  const baseClassName = classNames(
    "flex items-center justify-between m-0 peer-[]:pl-6 px-3 py-2 w-full",
    {
      "sm:hidden": submenu || mobile
    }
  );
  return link ? /* @__PURE__ */ React.createElement(
    NavLink,
    {
      className: (isActive) => classNames(baseClassName, {
        "font-semibold text-gray-700": isActive
      }),
      to: link,
      onClick
    },
    title,
    submenu && /* @__PURE__ */ React.createElement("div", { className: "w-6" }, /* @__PURE__ */ React.createElement(Icon, { icon: "galleryArrow", rotation: 180 }))
  ) : /* @__PURE__ */ React.createElement("button", { className: baseClassName, onClick }, title, submenu && /* @__PURE__ */ React.createElement("div", { className: "w-6" }, /* @__PURE__ */ React.createElement(Icon, { icon: "galleryArrow", rotation: 180 })));
};
export default NavigationItem;
