import { RectangularButton } from "@/components/button/Button";
import { RectangularButtonLink } from "@/components/button/ButtonLink";
import FbIcon from "@/components/icon/facebook";
import CloseButton from "@/components/modal/CloseButton";
import Modal from "@/components/modal/Modal";
import { H1 } from "@/components/typography";
import { isMobileDevice } from "@/utils/mobile_utils";
import { route } from "@/utils/route_utils";
import Pluralize from "pluralize";
import React, { useState } from "react";
const ShareModal = ({ isVisible = false, onClose, group }) => {
  const [linkCopied, setLinkCopied] = useState(false);
  const [sharedOnFacebook, setSharedOnFacebook] = useState(false);
  const getShareUrl = () => {
    const { origin, search } = window.location;
    return origin + route("groupShow", { slug: group.slug }) + search;
  };
  const mailtoURL = () => "mailto:" + encodeURI(
    `?subject=Share your memories of ${group.name} ${Pluralize.plural(
      group.member_name || "member"
    )}!
      &body=Help us honor the ${Pluralize.plural(
      group.member_name || "member"
    )} of ${group.name} here: ${getShareUrl()}`
  );
  const smsURL = () => "sms:" + encodeURI(
    `?&body=Help us honor the ${Pluralize.plural(
      group.member_name || "member"
    )} of ${group.name} here: ${getShareUrl()}`
  );
  const handleCopyLink = () => {
    window.navigator.clipboard.writeText(getShareUrl());
    trackShare("link");
    setLinkCopied(true);
  };
  const trackShare = (type) => mixpanel.track("page_shared", {
    kind: "group",
    group_name: group.slug,
    type
  });
  const handleFacebookShare = () => {
    window.FB.ui(
      {
        method: "share",
        href: getShareUrl(),
        quote: `Share memories and reflect on the special moments lived with ${Pluralize.plural(
          group.member_name || "member"
        )} of ${group.name} who have passed.`
      },
      () => {
        setSharedOnFacebook(true);
        trackShare("facebook");
      }
    );
  };
  if (!group)
    return;
  return /* @__PURE__ */ React.createElement(Modal, { isVisible, onClose }, /* @__PURE__ */ React.createElement("div", { className: "flex justify-end mb-4" }, /* @__PURE__ */ React.createElement(CloseButton, { onClose })), /* @__PURE__ */ React.createElement("div", { className: "flex flex-col items-center justify-center mx-auto max-w-md" }, /* @__PURE__ */ React.createElement("img", { className: "mb-4 w-44", src: group.logo_url, alt: group.name }), /* @__PURE__ */ React.createElement(H1, { className: "text-center" }, "Share ", group.name, "'s commemoration!"), /* @__PURE__ */ React.createElement("p", { className: "mb-5" }, "Help gather memories by sharing this page with others who might like to view or contribute."), /* @__PURE__ */ React.createElement("div", { className: "grid max-w-xs w-full" }, /* @__PURE__ */ React.createElement(
    RectangularButtonLink,
    {
      href: mailtoURL(),
      onClick: () => trackShare("email")
    },
    "Send via Email"
  ), isMobileDevice() && /* @__PURE__ */ React.createElement(
    RectangularButtonLink,
    {
      href: smsURL(),
      onClick: () => trackShare("sms")
    },
    "Send via SMS"
  ), /* @__PURE__ */ React.createElement(
    RectangularButton,
    {
      onClick: handleCopyLink,
      style: linkCopied ? { backgroundColor: "rgba(209, 213, 219)" } : {}
    },
    linkCopied ? "Link copied!" : "Copy Link"
  ), /* @__PURE__ */ React.createElement(
    RectangularButton,
    {
      onClick: handleFacebookShare,
      style: sharedOnFacebook ? { backgroundColor: "rgba(209, 213, 219)" } : {}
    },
    sharedOnFacebook ? "Shared on Facebook" : /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(FbIcon, null), /* @__PURE__ */ React.createElement("span", { className: "ml-2" }, "Share"))
  ))));
};
export default ShareModal;
