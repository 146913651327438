import ActivityIndicator from "@/components/activity/ActivityIndicator";
import { useBreadcrumb } from "@/contexts/providers/BreadcrumbContext";
import { useProjectQuery } from "@/queries";
import { route } from "@/utils/route_utils";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import SettingsTitle from "../SettingsTitle";
import UserManager from "../UserManager";
const UsersManage = () => {
  const breadcrumb = useBreadcrumb();
  const { slug } = useParams();
  const { data: project } = useProjectQuery(slug);
  useEffect(() => {
    var _a;
    if (!project)
      return;
    breadcrumb.setCrumbs([
      {
        label: `${(_a = project.prefix) != null ? _a : ""} ${project.full_name}`,
        href: route("project", { slug })
      },
      {
        label: "Admin Settings"
      },
      {
        label: "User Management"
      }
    ]);
    mixpanel.track("settings_viewed", {
      kind: "project",
      project_id: project.id,
      type: "user-management"
    });
  }, [project]);
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(SettingsTitle, null, "User Management"), project && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("p", { className: "mb-4" }, "Invite other Admins to help you build and manage", " ", project.preferred_name, "'s LifeWeb."), /* @__PURE__ */ React.createElement(UserManager, { project })), !project && /* @__PURE__ */ React.createElement(ActivityIndicator, null));
};
export default UsersManage;
