import React from "react";
import Button from "@/components/button/Button";
import PlayButton from "@/components/button/PlayButton";
import Icon from "@/components/icon/Icon";
import Thumbnail from "@/components/thumbnail/Thumbnail";
const VideoPreview = (props) => {
  const { onRemove, thumbnailUrl } = props;
  const url = thumbnailUrl || require("@/assets/images/project_default.jpg");
  return /* @__PURE__ */ React.createElement("div", { className: "video-preview" }, /* @__PURE__ */ React.createElement(Thumbnail, { url }), onRemove && /* @__PURE__ */ React.createElement(Button, { color: "removable", onClick: onRemove }, /* @__PURE__ */ React.createElement(Icon, { icon: "xContained" })), /* @__PURE__ */ React.createElement(PlayButton, { size: "small" }));
};
export default VideoPreview;
