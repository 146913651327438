import ConfirmationModal from "@/components/modal/ConfirmationModal";
import ModalCard from "@/components/modal/ModalCard";
import React from "react";
import { useTranslation } from "react-i18next";
const FirstSocialAccountLinkModal = (props) => {
  const { visible, onConfirm, onCancel, newSocialProvider } = props;
  const { t } = useTranslation();
  return /* @__PURE__ */ React.createElement(ModalCard, { position: "centerOfScreen", size: "small", isHidden: !visible }, /* @__PURE__ */ React.createElement(
    ConfirmationModal,
    {
      title: t("views.memories.usersNew.account_not_linked.title"),
      subtitle: t(
        "views.memories.usersNew.account_not_linked.subtitle",
        { new_social_provider: newSocialProvider }
      ),
      confirmText: t(
        "views.memories.usersNew.account_not_linked.link_account_btn"
      ),
      cancelText: t(
        "views.memories.usersNew.account_not_linked.no_thanks_btn"
      ),
      onConfirm,
      onCancel,
      isOnboarding: true
    }
  ));
};
export default FirstSocialAccountLinkModal;
