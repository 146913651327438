import Button from "@/components/button/Button";
import InputGroup from "@/components/form/InputGroup";
import CloseButton from "@/components/modal/CloseButton";
import Modal from "@/components/modal/Modal";
import { isValueEmpty } from "@/utils/form_utils";
import React from "react";
import { useTranslation } from "react-i18next";
const MemoriesAddLifeThreadModal = (props) => {
  const {
    isVisible,
    lifeThreadName,
    onChangeLifeThread,
    onAddLifeThread,
    onClose
  } = props;
  const { t } = useTranslation();
  const buttonDisabled = isValueEmpty(lifeThreadName);
  return /* @__PURE__ */ React.createElement("div", { className: "add-life-thread__modal-wrapper" }, /* @__PURE__ */ React.createElement(Modal, { isVisible, size: "small" }, /* @__PURE__ */ React.createElement("div", { className: "add-life-thread__close-wrapper" }, /* @__PURE__ */ React.createElement(CloseButton, { onClose })), /* @__PURE__ */ React.createElement("div", { className: "columns" }, /* @__PURE__ */ React.createElement("div", { className: "column is-centered is-mobile" }, /* @__PURE__ */ React.createElement("h1", { className: "add-life-thread__modal-header" }, t("views.memories.modals.addLifeThread.header")))), /* @__PURE__ */ React.createElement("div", { className: "columns" }, /* @__PURE__ */ React.createElement("div", { className: "column is-centered  is-mobile add-life-thread__name" }, /* @__PURE__ */ React.createElement(
    InputGroup,
    {
      label: t("views.memories.modals.addLifeThread.label"),
      labelSize: "small",
      placeholder: t(
        "views.memories.modals.addLifeThread.placeholder"
      ),
      maxLength: 70,
      onChange: (value) => onChangeLifeThread(value),
      value: lifeThreadName
    }
  ))), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-10-mobile is-5-tablet" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "primary",
      isDisabled: buttonDisabled,
      onClick: onAddLifeThread
    },
    t("views.memories.modals.addLifeThread.button")
  )))));
};
export default MemoriesAddLifeThreadModal;
