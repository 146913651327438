import classNames from "classnames";
import React from "react";
const ContentCardQuote = (props) => {
  const { text } = props;
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames("content-card-quote", {
        "is-small": text.length > 55
      })
    },
    /* @__PURE__ */ React.createElement("span", null, text)
  );
};
export default ContentCardQuote;
