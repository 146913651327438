import React from "react";
import { useTranslation } from "react-i18next";
const ContentCardDetailsHeader = (props) => {
  const { commentCount, imageCount, userName } = props;
  const { t } = useTranslation();
  const commentCountText = (() => {
    if (commentCount > 1) {
      return t("views.memories.cards.commentMultiple", {
        count: commentCount
      });
    } else if (commentCount === 1) {
      return t("views.memories.cards.commentSingle");
    }
    return t("views.memories.cards.commentAdd");
  })();
  const imageCountText = (() => {
    if (!imageCount) {
      return null;
    }
    if (imageCount > 1) {
      return /* @__PURE__ */ React.createElement("span", null, t("views.memories.cards.imageMultiple", {
        count: imageCount
      }));
    }
    return /* @__PURE__ */ React.createElement("span", null, t("views.memories.cards.imageSingle"));
  })();
  return /* @__PURE__ */ React.createElement("div", { className: "content-card-details-header" }, /* @__PURE__ */ React.createElement("div", { className: "content-card-details-header__top" }, /* @__PURE__ */ React.createElement("span", null, t("views.memories.cards.contributedBy")), imageCountText), /* @__PURE__ */ React.createElement("div", { className: "content-card-details-header__bottom" }, /* @__PURE__ */ React.createElement("h5", null, userName), /* @__PURE__ */ React.createElement("span", null, commentCountText)));
};
export default ContentCardDetailsHeader;
