import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
const IMG_WIDTH = [337.5, 700];
const IMG_HEIGHT = [138, 300, 462, 625, 787, 950];
export default function BookPage({
  block,
  selected,
  setBlockTranslate
}) {
  const imageRef = useRef(null);
  const [transX, setTransX] = useState(0);
  const [transY, setTransY] = useState(0);
  const x = block.x + (block.offset_x || 0);
  const y = block.y + (block.offset_y || 0);
  const contributor = block.contributor ? `Contributed by: ${block.contributor}` : null;
  const hasContributorText = block.content_type === "text" && block.content.indexOf(contributor) !== -1;
  const onImageLoad = () => {
    var _a;
    if ((_a = imageRef.current) == null ? void 0 : _a.naturalHeight) {
      const blockWidth = IMG_WIDTH[block.width - 1];
      const blockHeight = IMG_HEIGHT[block.height - 1];
      const imgRatio = imageRef.current.naturalWidth / imageRef.current.naturalHeight;
      const blockRatio = blockWidth / blockHeight;
      if (imgRatio > blockRatio) {
        const imgScaleHeight = imageRef.current.naturalHeight / (imageRef.current.naturalWidth / blockWidth);
        const shiftY = (blockHeight - imgScaleHeight) / 2;
        if (y < 3) {
          if (3 - y > y + block.height - 3) {
            setTransY(shiftY);
          } else if (3 - y < y + block.height - 3) {
            setTransY(-shiftY);
          }
        } else {
          setTransY(-shiftY);
        }
      } else if (imgRatio < blockRatio) {
        const imgScaleWidth = imageRef.current.naturalWidth / (imageRef.current.naturalHeight / blockHeight);
        const shiftX = (blockWidth - imgScaleWidth) / 2;
        if (x > 0) {
          setTransX(-shiftX);
        } else if (block.width === 1) {
          setTransX(shiftX);
        }
      }
    }
  };
  useEffect(() => {
    if (block.block_id) {
      setBlockTranslate(block.block_id, transX / 100, transY / 100);
    }
  }, [block.block_id, transX, transY]);
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames(
        `books__block books__block-w-${block.width} books__block-h-${block.height} books__block-x-${x} books__block-y-${y}`,
        {
          books__block_selected: selected
        }
      )
    },
    block.content_type === "image" ? /* @__PURE__ */ React.createElement(
      "img",
      {
        ref: imageRef,
        src: block.content,
        className: "books__image_content",
        style: {
          transform: `translate(${transX}px, ${transY}px)`
        },
        onLoad: onImageLoad
      }
    ) : /* @__PURE__ */ React.createElement("div", { className: "books__text-block-content" }, block.quote && /* @__PURE__ */ React.createElement("div", { className: "books__text-block-quote" }, block.quote), block.quote && /* @__PURE__ */ React.createElement("br", null), !hasContributorText ? block.content : block.content.slice(0, block.content.indexOf(contributor)), hasContributorText && /* @__PURE__ */ React.createElement("div", { className: "books__text-block-contributor-container" }, /* @__PURE__ */ React.createElement("span", { className: "books__text-block-contributor" }, contributor), block.content.split(contributor).length > 1 && block.content.split(contributor)[1]))
  );
}
