import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "@/api/Api";
import Button from "@/components/button/Button";
import CloseButton from "@/components/modal/CloseButton";
import Modal from "@/components/modal/Modal";
import { flashMessageFromResponseError } from "@/utils/alert_utils";
import { route } from "@/utils/route_utils";
import classNames from "classnames";
const toggleRelationship = (selected, item) => selected.includes(item) ? selected.filter((i) => i !== item) : [...selected, item];
const Relationships = ({ selected, setSelected }) => {
  const relationshipTypes = [
    "Family",
    "Friend",
    "Classmate",
    "Community",
    "Colleague",
    "Faith",
    "Other"
  ];
  return /* @__PURE__ */ React.createElement("div", { className: "flex flex-wrap justify-center gap-4 max-w-md mx-auto my-5" }, relationshipTypes.map((name) => /* @__PURE__ */ React.createElement(
    "button",
    {
      key: name,
      className: classNames(
        "border border-lifeweb-blue cursor-pointer rounded-md px-3 py-1 select-none",
        {
          "lifeweb-text-blue": !selected.includes(name),
          "bg-lifeweb-blue text-white": selected.includes(name)
        }
      ),
      onClick: () => setSelected(
        (current) => toggleRelationship(current, name)
      ),
      type: "button"
    },
    name
  )));
};
const AccessRequestModal = ({
  afterSubmit,
  isVisible,
  onClose,
  project
}) => {
  const [message, setMessage] = useState("");
  const [selectedRelationships, setSelectedRelationships] = useState(
    []
  );
  const { t } = useTranslation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = route("projectRequestAccess", {
      project_id: project.id
    });
    const body = {
      project_user: {
        request_message: message,
        relationship_attributes: {
          relationship_types_attributes: selectedRelationships.map(
            (relationship) => ({
              relationship_type: relationship.toLowerCase()
            })
          )
        }
      }
    };
    try {
      await Api.utility.post(url, body);
      afterSubmit();
    } catch (error) {
      flashMessageFromResponseError(error);
    }
  };
  return /* @__PURE__ */ React.createElement(Modal, { isVisible, size: "medium" }, /* @__PURE__ */ React.createElement("div", { className: "flex justify-end" }, /* @__PURE__ */ React.createElement(CloseButton, { onClose })), /* @__PURE__ */ React.createElement("form", { className: "p-5", method: "post", onSubmit: handleSubmit }, /* @__PURE__ */ React.createElement("div", { className: "access-request-modal__header-wrapper" }, /* @__PURE__ */ React.createElement("h1", { className: "mb-6" }, t("views.projects.modals.accessRequest.headers"))), /* @__PURE__ */ React.createElement("div", { className: "columns" }, /* @__PURE__ */ React.createElement("div", { className: "column" }, /* @__PURE__ */ React.createElement("p", { className: "mb-6" }, "To help the admins of ", project.full_name, "'s LifeWeb review your request, please provide the following information:"), /* @__PURE__ */ React.createElement("p", { className: "mb-3" }, "Select all the ways you define your relationship with", " ", project.preferred_name, ".", /* @__PURE__ */ React.createElement("span", { className: "text-red-700" }, " * Required")), /* @__PURE__ */ React.createElement(
    Relationships,
    {
      selected: selectedRelationships,
      setSelected: setSelectedRelationships
    }
  ))), /* @__PURE__ */ React.createElement("label", null, "Include a message to the admins of ", project.preferred_name, "'s LifeWeb:", /* @__PURE__ */ React.createElement(
    "textarea",
    {
      className: "block border border-black rounded-md my-2 px-3 py-2 w-full",
      onInput: (e) => setMessage(e.currentTarget.value),
      placeholder: "Type your message here.",
      rows: 4,
      value: message
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "mx-auto my-6 sm:w-1/2 w-full" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "secondary",
      isDisabled: selectedRelationships.length === 0
    },
    "Send Request"
  ))));
};
export default AccessRequestModal;
