import classNames from "classnames";
import React from "react";
const Toggle = ({ description, onClick, on }) => {
  return /* @__PURE__ */ React.createElement("label", { "aria-description": description, className: "block text-center" }, /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames("mx-auto relative rounded-full transition w-14", {
        "bg-lifeweb-gold": on,
        "bg-gray-700": !on
      })
    },
    /* @__PURE__ */ React.createElement(
      "div",
      {
        className: classNames(
          "bg-white border h-7 w-7 rounded-full transition",
          {
            "border-lifeweb-gold translate-x-full": on,
            "border-gray-700": !on
          }
        )
      }
    ),
    /* @__PURE__ */ React.createElement(
      "input",
      {
        type: "checkbox",
        className: "absolute cursor-pointer h-full inset-0 opacity-0 w-full",
        onClick
      }
    )
  ), /* @__PURE__ */ React.createElement("div", { className: "text-sm" }, on ? "On" : "Off"));
};
export default Toggle;
