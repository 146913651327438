import ConfirmationModal from "@/components/modal/ConfirmationModal";
import ModalCard from "@/components/modal/ModalCard";
import facebookAuthCallback from "@/helpers/facebook_auth_callback";
import googleAuthCallback from "@/helpers/google_auth_callback";
import Modeler from "@/utils/modeler/modeler";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
const SocialConnectedAccounts = (props) => {
  const { serializer_user, generatedFormAuthenticityToken, googleApiAppId } = props;
  const user = new Modeler(serializer_user).build();
  const defaultSocialAccounts = user.social_users;
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [socialAccounts, setSocialAccountsState] = useState(
    defaultSocialAccounts
  );
  const [providerName, setProviderName] = useState("");
  let hasGoogleAccount;
  let hasFacebookAccount;
  let googleSocialAccounts;
  let facebookSocialAccounts;
  if (Array.isArray(socialAccounts)) {
    googleSocialAccounts = socialAccounts.filter(
      (socialAccount) => socialAccount.humanized_provider_name === "Google"
    );
    facebookSocialAccounts = socialAccounts.filter(
      (socialAccount) => socialAccount.humanized_provider_name === "Facebook"
    );
    hasGoogleAccount = googleSocialAccounts.length >= 1;
    hasFacebookAccount = facebookSocialAccounts.length >= 1;
  } else {
    hasGoogleAccount = false;
    hasFacebookAccount = false;
  }
  const errorHandlerAccountDontMatch = (providerName2) => {
    setModalVisible(true);
    setProviderName(providerName2);
  };
  const onConfirm = (e) => {
    setModalVisible(false);
    if (providerName === "google") {
      googleAuthHandler();
    } else if (providerName === "facebook") {
      facebookAuthHandler();
    }
  };
  const onCancel = () => {
    setModalVisible(false);
  };
  const facebookAuthHandler = async (e) => {
    window.FB.login(async (response) => {
      if (response.status == "connected") {
        try {
          const responseCallback = await facebookAuthCallback(
            response,
            false,
            true
          );
          const socialUsers = new Modeler(
            responseCallback.data.social_users
          ).build();
          setSocialAccountsState(socialUsers);
        } catch (err) {
          if (err.response.status == "400") {
            errorHandlerAccountDontMatch("facebook");
            setModalVisible(true);
          }
        }
      }
    });
  };
  const googleAuthHandler = async (e) => {
    const googleClient = window.google.accounts.oauth2.initCodeClient({
      client_id: googleApiAppId,
      scope: "email profile",
      ux_mode: "popup",
      callback: async (response, err) => {
        try {
          const responseCallback = await googleAuthCallback(
            response,
            generatedFormAuthenticityToken,
            false,
            true
          );
          const socialUsers = new Modeler(
            responseCallback.data.social_users
          ).build();
          setSocialAccountsState(socialUsers);
        } catch (err2) {
          if (err2.response.status == "400") {
            errorHandlerAccountDontMatch("google");
          }
        }
      }
    });
    googleClient.requestCode();
  };
  return /* @__PURE__ */ React.createElement("div", { className: "columns is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column-1 profile__social-icon-wrapper" }, hasGoogleAccount ? /* @__PURE__ */ React.createElement("button", { className: "profile__fab-button profile__google-fab-button" }) : /* @__PURE__ */ React.createElement(
    "button",
    {
      className: "profile__fab-button profile__google-fab-button-inactive",
      onClick: googleAuthHandler
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "column-1 profile__social-icon-wrapper" }, hasFacebookAccount ? /* @__PURE__ */ React.createElement("button", { className: "profile__fab-button profile__facebook-fab-button" }) : /* @__PURE__ */ React.createElement(
    "button",
    {
      className: "profile__fab-button profile__facebook-fab-button-inactive",
      onClick: facebookAuthHandler
    }
  )), /* @__PURE__ */ React.createElement(
    ModalCard,
    {
      position: "centerOfScreen",
      size: "small",
      isHidden: !modalVisible
    },
    /* @__PURE__ */ React.createElement(
      ConfirmationModal,
      {
        title: t("views.memories.usersNew.connected_account.title"),
        subtitle: t(
          "views.memories.usersNew.connected_account.subtitle",
          { social_provider: providerName }
        ),
        confirmText: t(
          "views.memories.usersNew.connected_account.try_again"
        ),
        cancelText: t(
          "views.memories.usersNew.connected_account.no_thanks_btn"
        ),
        onConfirm,
        onCancel,
        isOnboarding: true
      }
    )
  ));
};
export default SocialConnectedAccounts;
