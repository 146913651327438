import classNames from "classnames";
import React from "react";
const NavigationAnchor = ({ link, mobile, title }) => /* @__PURE__ */ React.createElement(
  "a",
  {
    className: classNames(
      "flex items-center justify-between m-0 peer-[]:pl-6 px-3 py-2 w-full",
      {
        "sm:hidden": mobile
      }
    ),
    href: link
  },
  title
);
export default NavigationAnchor;
