import { H1 } from "@/components/typography";
import { useProjectQuery } from "@/queries";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
const defaultCoverImageSrc = require("@/assets/images/project_default.jpg");
const AccessRestricted = () => {
  const { slug } = useParams();
  const { search } = useLocation();
  const action = new URLSearchParams(search).get("action");
  const { data: project } = useProjectQuery(slug);
  return /* @__PURE__ */ React.createElement("div", { className: "section with-gradient-background min-h-screen text-black" }, project && /* @__PURE__ */ React.createElement("div", { className: "my-6 text-center" }, /* @__PURE__ */ React.createElement(
    "img",
    {
      className: "block max-w-xs mx-auto mb-8",
      src: project.cover_image_url || defaultCoverImageSrc
    }
  ), /* @__PURE__ */ React.createElement(H1, null, project.prefix, " ", project.full_name, "'s LifeWeb is restricted"), /* @__PURE__ */ React.createElement("div", { className: "max-w-lg mx-auto my-8" }, /* @__PURE__ */ React.createElement("p", { className: "mb-5" }, "Thank you for your interest in", " ", action === "comment" ? "commenting" : "sharing a memory", " to celebrate ", project.preferred_name, "."), /* @__PURE__ */ React.createElement("p", { className: "mb-5" }, "At this time, the admins for ", project.preferred_name, "'s LifeWeb have limited the ability to", " ", action === "comment" ? "comment" : "post memories", " to a select group of contributors to maintain a curated tribute. While we greatly value your intention to contribute, we are not accepting submissions from the wider public at this time."), /* @__PURE__ */ React.createElement("p", null, "Thank you for your understanding and for joining us in honoring", " ", project.preferred_name, "."))));
};
export default AccessRestricted;
