import Api from "@/api/Api";
import { flashErrorMessage } from "@/utils/alert_utils";
import Modeler from "@/utils/modeler/modeler";
import { route } from "@/utils/route_utils";
import React, { useEffect, useState } from "react";
const GroupUpdate = ({ group }) => {
  const [update, setUpdate] = useState();
  useEffect(() => {
    const fetchUpdates = async () => {
      try {
        const { data } = await Api.utility.get(
          route("groupUpdates", { group_id: group.id })
        );
        if (!data.data)
          return;
        setUpdate(new Modeler(data).build());
      } catch (error) {
        flashErrorMessage("Error fetching group updates");
      }
    };
    fetchUpdates();
  }, []);
  if (!update)
    return;
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "bg-white mb-6 rounded border-2 sm:mx-6 px-4 py-3",
      style: { borderColor: group.secondary_color }
    },
    /* @__PURE__ */ React.createElement("div", { className: "flex flex-col md:flex-row gap-4 items-start" }, update.image_url && /* @__PURE__ */ React.createElement(
      "img",
      {
        src: update.image_url,
        className: "block max-w-[150px] mx-auto my-0",
        alt: "Group update image"
      }
    ), /* @__PURE__ */ React.createElement("div", { className: "flex-1 text-left prose max-w-none" }, /* @__PURE__ */ React.createElement("p", { className: "font-semibold leading-tight mb-3 text-lg" }, update.title), /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "leading-normal",
        dangerouslySetInnerHTML: { __html: update.content }
      }
    )))
  );
};
export default GroupUpdate;
