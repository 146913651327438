document.addEventListener("DOMContentLoaded", () => {
  const formInputs = document.querySelectorAll("input");
  const updateInput = (input) => {
    if (input.value !== "") {
      input.classList.add("is-filled");
    } else {
      input.classList.remove("is-filled");
    }
  };
  if (formInputs) {
    formInputs.forEach((input) => {
      updateInput(input);
      input.addEventListener("keyup", () => {
        updateInput(input);
      });
    });
  }
});
