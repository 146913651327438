import classNames from "classnames";
import React, { useState } from "react";
import Icon from "../icon/Icon";
import MobileMenuButton from "./MobileMenuButton";
const logo = require("@/assets/images/logo/layers-dark.svg");
export const HeaderActions = ({ children }) => /* @__PURE__ */ React.createElement("div", { className: "flex items-center gap-4 relative text-lifeweb-blue" }, children);
export const HeaderButton = ({
  children,
  icon,
  link,
  onClick
}) => {
  const className = "flex flex-col items-center justify-center gap-1 text-lifeweb-blue";
  const IconContents = () => /* @__PURE__ */ React.createElement("div", { className: "max-w-[1.5rem]" }, " ", /* @__PURE__ */ React.createElement(Icon, { icon }), " ");
  return link ? /* @__PURE__ */ React.createElement(
    "a",
    {
      href: link,
      className,
      onClick,
      target: link.startsWith("http") ? "_blank" : void 0
    },
    /* @__PURE__ */ React.createElement(IconContents, null),
    /* @__PURE__ */ React.createElement("div", null, children)
  ) : /* @__PURE__ */ React.createElement("button", { className, onClick }, /* @__PURE__ */ React.createElement(IconContents, null), /* @__PURE__ */ React.createElement("div", null, children));
};
export const HeaderTitleSection = ({
  children
}) => /* @__PURE__ */ React.createElement("div", { className: "flex gap-6 items-center py-4 px-8 relative" }, /* @__PURE__ */ React.createElement("a", { href: "https://www.lifeweb360.com" }, /* @__PURE__ */ React.createElement("img", { className: "block m-0", src: logo, alt: "LifeWeb 360 logo" })), /* @__PURE__ */ React.createElement("div", { className: "hidden sm:block" }, children));
export const HeaderTitle = ({ link, children }) => /* @__PURE__ */ React.createElement("a", { href: link, className: "block font-semibold mb-1 text-slate-900 text-xl" }, children);
const Header = ({ children, mobileMenu }) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  return /* @__PURE__ */ React.createElement("header", { className: "bg-white shadow-md relative" }, /* @__PURE__ */ React.createElement(
    MobileMenuButton,
    {
      onClick: () => setMobileNavOpen((current) => !current),
      open: mobileNavOpen
    }
  ), /* @__PURE__ */ React.createElement("div", { className: "flex items-center justify-center sm:justify-between relative" }, children), /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames(
        "sm:hidden sm:right-6 sm:max-w-[260px] w-full z-40",
        {
          absolute: mobileNavOpen,
          hidden: !mobileNavOpen
        }
      )
    },
    mobileMenu
  ));
};
export default Header;
