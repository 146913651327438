import { fullName as userFullName } from "./user_utils";
export const memoryEmail = (memory) => {
  if (memory.user) {
    return memory.user.email;
  } else {
    return memory.email;
  }
};
export const fullName = (memory) => {
  return userFullName(memory).trim() == "" ? userFullName(memory.user) : userFullName(memory);
};
