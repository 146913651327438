import React, { useEffect, useMemo, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Api from "@/api/Api";
import ActionVerification from "@/components/actionVerification/ActionVerification";
import Button from "@/components/button/Button";
import ErrorMessage from "@/components/form/ErrorMessage";
import { Input } from "@/components/form/Input";
import InputGroup from "@/components/form/InputGroup";
import { Label } from "@/components/form/Label";
import TextEditor from "@/components/form/TextEditor";
import ImageGalleryManager from "@/components/image_gallery_manager/ImageGalleryManager";
import BackLink from "@/components/link/BackLink";
import ConfirmationModal from "@/components/modal/ConfirmationModal";
import Modal from "@/components/modal/Modal";
import MemoriesThemes from "@/contexts/memories/components/MemoriesThemes";
import MemoryAddVideo from "@/contexts/memories/modules/MemoryAddVideo";
import { flashMessageFromResponseError } from "@/utils/alert_utils";
import Modeler from "@/utils/modeler/modeler";
import { route } from "@/utils/route_utils";
import { isUUID } from "@/utils/string_utils";
import MemoriesAddLifeThreadModal from "../memories/components/MemoriesAddLifeThreadModal";
const EditMemory = (props) => {
  const { groups, memory_data, project, project_themes, user, userData, uploadPath } = props;
  const { t } = useTranslation();
  const memory = new Modeler(memory_data).build();
  const projectUser = new Modeler(userData).build();
  const initialFormState = {
    first_name: memory.first_name ? memory.first_name : "",
    last_name: memory.last_name ? memory.last_name : "",
    description: memory.description ? memory.description : "",
    images: memory.memory_images,
    text: memory.memory_quote ? memory.memory_quote.text : "",
    themes: memory.themes,
    video_thumbnail_url: memory.memory_video ? memory.memory_video.video_thumbnail_url : "",
    video_url: memory.memory_video ? memory.memory_video.video_url : ""
  };
  const formRef = useRef(initialFormState);
  const allImages = useRef(memory.memory_images);
  const returnInnerDescriptionLength = (description) => {
    const span = document.createElement("span");
    span.innerHTML = description;
    return span.innerText.trim().length;
  };
  const [descriptionLength, setDescriptionLength] = useState(
    memory.description ? returnInnerDescriptionLength(memory.description) : 0
  );
  const [form, setForm] = useState(initialFormState);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isVideoModalVisible, setIsVideoModalVisible] = useState(false);
  const [isVideoThumbnailVisible, setIsVideoThumbnailVisible] = useState(!!memory.memory_video);
  const [themes, setThemes] = useState(project_themes);
  const [themeValidation, setThemeValidation] = useState("");
  const [memoryContentValidation, setMemoryContentValidation] = useState("");
  const [eventTypes, setEventTypes] = useState({
    memory_images: false,
    memory_phrases: false,
    memory_first_name: false,
    memory_last_name: false
  });
  const [onBehalfConfirmation, setOnBehalfConfirmation] = useState(false);
  const [newImageAdded, setNewImagesAdded] = useState(false);
  const uppyUploadRef = useRef(null);
  const onBehalfFirstNameValidationMessage = form.first_name.trim() === "" && form.last_name.trim() !== "" ? "Field is required" : null;
  const onBehalfLastNameValidationMessage = form.first_name.trim() !== "" && form.last_name.trim() === "" ? "Field is required" : null;
  const [isAddLifeThreadModalVisible, setIsAddLifeThreadModalVisible] = useState(false);
  const [newLifeThreadName, setNewLifeThreadName] = useState("");
  useEffect(() => {
    formRef.current = form;
  });
  const backLinkData = useMemo(() => {
    const projectMemory = {
      text: "Memory",
      route: route("projectMemory", {
        memory_id: memory.id,
        slug: project.slug
      })
    };
    if (!document.referrer)
      return projectMemory;
    const referrerParts = new URL(document.referrer).pathname.split("/");
    const lastReferrerPart = referrerParts[referrerParts.length - 1];
    if (referrerParts.length < 3 && !isUUID(lastReferrerPart)) {
      return {
        text: "LifeWeb",
        route: route("project", { slug: project.slug })
      };
    } else {
      return projectMemory;
    }
  }, [memory, project]);
  const formatBody = (localImagesData) => {
    const {
      first_name,
      last_name,
      text,
      images,
      description,
      video_thumbnail_url,
      video_url
    } = formRef.current;
    const formattedImages = [...allImages.current, ...localImagesData].map(
      (i) => {
        const shouldDelete = !images.includes(i);
        if (!i.thumbnailUrl) {
          return {
            _destroy: shouldDelete,
            id: i.id
          };
        } else {
          return {
            image: i.jsonData
          };
        }
      }
    );
    const formattedThemes = form.themes.map((formTheme) => formTheme.id);
    const formattedMemoryQuote = () => {
      if (memory.memory_quote) {
        return {
          id: memory.memory_quote.id,
          text
        };
      }
      return {
        text
      };
    };
    const formattedMemoryVideo = () => {
      const memoryVideo = memory.memory_video;
      if (memoryVideo && video_url === "") {
        return {
          _destroy: true,
          id: memoryVideo.id
        };
      } else if (video_url) {
        return {
          id: memoryVideo ? memoryVideo.id : null,
          video_thumbnail_url,
          video_url
        };
      } else {
        return null;
      }
    };
    const isOnBehalf = first_name.length > 0;
    if (text === "") {
      if (memory.memory_quote) {
        return {
          memory: {
            first_name,
            last_name,
            is_on_behalf: isOnBehalf,
            project_id: project.id,
            user_id: user ? user.id : "",
            description,
            memory_images_attributes: formattedImages,
            memory_quote_attributes: {
              _destroy: true,
              id: memory.memory_quote.id
            },
            memory_video_attributes: formattedMemoryVideo(),
            theme_ids: formattedThemes
          }
        };
      } else {
        return {
          memory: {
            first_name,
            last_name,
            is_on_behalf: isOnBehalf,
            project_id: project.id,
            user_id: user ? user.id : "",
            description,
            memory_images_attributes: formattedImages,
            memory_video_attributes: formattedMemoryVideo(),
            theme_ids: formattedThemes
          }
        };
      }
    }
    return {
      memory: {
        first_name,
        last_name,
        is_on_behalf: isOnBehalf,
        project_id: project.id,
        user_id: user ? user.id : "",
        description,
        memory_images_attributes: formattedImages,
        memory_quote_attributes: formattedMemoryQuote(),
        memory_video_attributes: formattedMemoryVideo(),
        theme_ids: formattedThemes
      }
    };
  };
  const removeImageField = (image) => {
    const images = form.images.filter((i) => i.id !== image.id);
    const newAllImages = allImages.current.filter((i) => {
      if (i.thumbnailUrl) {
        if (i.id !== image.id) {
          return i;
        }
      } else {
        return i;
      }
    });
    setForm({
      ...form,
      images: [...images]
    });
    allImages.current = newAllImages;
    setEventTypes({
      ...eventTypes,
      memory_images: true
    });
  };
  const onNewImageAdded = (value) => {
    setEventTypes({
      ...eventTypes,
      memory_images: value
    });
    setNewImagesAdded(value);
  };
  const updateField = (field, value) => {
    setForm({
      ...form,
      [field]: value
    });
    if (field === "text") {
      setEventTypes({
        ...eventTypes,
        memory_phrases: true
      });
    }
    if (field === "first_name") {
      setEventTypes({
        ...eventTypes,
        memory_first_name: true
      });
    }
    if (field === "last_name") {
      setEventTypes({
        ...eventTypes,
        memory_last_name: true
      });
    }
  };
  const updateDescriptionField = (field, value) => {
    setForm({
      ...form,
      [field]: value
    });
    const span = document.createElement("span");
    span.innerHTML = value;
    setDescriptionLength(span.innerText.trim().length);
  };
  const addTheme = (theme) => {
    setForm({
      ...form,
      themes: [...form.themes, theme]
    });
  };
  const removeTheme = (theme) => {
    const entry = form.themes.find((formTheme) => formTheme.id === theme.id);
    const filteredThemes = form.themes.filter(
      (formTheme) => formTheme !== entry
    );
    setForm({
      ...form,
      themes: filteredThemes
    });
  };
  const updateThemes = (selectedThemes) => {
    setForm({
      ...form,
      themes: selectedThemes
    });
  };
  const refreshProjectThemes = async () => {
    const url = route("themesIndex", {
      project_id: project.id,
      status: "active"
    });
    try {
      const response = await Api.utility.get(url);
      const data = new Modeler(response.data).build();
      setThemes(data);
      const inactiveTheme = form.themes.find(
        (theme) => !data.some((formTheme) => theme.id === formTheme.id)
      );
      if (inactiveTheme) {
        removeTheme(inactiveTheme);
      }
    } catch (err) {
    }
  };
  const handleCreateTheme = async (name) => {
    if ((projectUser == null ? void 0 : projectUser.role) === "admin") {
      const url = route("themesIndex");
      try {
        const body = {
          theme: {
            name,
            project_id: project.id
          }
        };
        const response = await Api.utility.post(url, body);
        const newTheme = new Modeler(response.data).build();
        addTheme(newTheme);
        refreshProjectThemes();
      } catch (err) {
        flashMessageFromResponseError(err);
      }
    } else {
      return null;
    }
  };
  const handleUpdate = () => {
    let isFormValid = true;
    const available = form.images.length !== 0 || newImageAdded || !!form.video_url || descriptionLength !== 0;
    if (!available) {
      isFormValid = false;
      setMemoryContentValidation(t("form.validation.memory.memoryContent"));
    } else {
      setMemoryContentValidation("");
    }
    if (!!onBehalfFirstNameValidationMessage || !!onBehalfLastNameValidationMessage) {
      isFormValid = false;
    }
    if (form.themes.length === 0) {
      isFormValid = false;
      setThemeValidation(t("form.validation.memory.memoryTheme"));
    } else {
      setThemeValidation("");
    }
    if (isFormValid) {
      if (newImageAdded) {
        uppyUploadRef.current();
      } else {
        updateMemory([]);
      }
    }
  };
  const updateMemory = async (images) => {
    const url = route("memory", { id: memory.id });
    const body = formatBody(images);
    try {
      await Api.utility.patch(url, body);
      window.location.href = document.referrer || route("contributions", { slug: project.slug });
    } catch (err) {
      flashMessageFromResponseError(err);
    }
  };
  const deleteMemory = async () => {
    const url = route("memory", { id: memory.id });
    try {
      await Api.utility.delete(url);
      window.location.href = route("project", { slug: project.slug });
    } catch (err) {
      flashMessageFromResponseError(err);
    }
  };
  const handleAddVideo = () => {
    setIsVideoModalVisible(true);
  };
  const closeVideoModal = () => {
    setIsVideoModalVisible(false);
  };
  const fetchVideoThumbnail = async () => {
    setIsLoading(true);
    const videoUrl = form.video_url;
    const url = route("memoryVideosThumbnail", {
      video_url: videoUrl
    });
    try {
      const response = await Api.utility.get(url);
      if (Object.keys(response.data).length) {
        const thumbnailUrl = response.data.thumbnail_url;
        updateField("video_thumbnail_url", thumbnailUrl);
      }
      setIsVideoThumbnailVisible(true);
      closeVideoModal();
    } catch (err) {
      flashMessageFromResponseError(err);
    }
    setIsLoading(false);
  };
  const removeVideo = () => {
    setForm({
      ...form,
      video_thumbnail_url: "",
      video_url: ""
    });
    setIsVideoThumbnailVisible(false);
  };
  const onChangeLifeThread = (lifeThreadName) => {
    setNewLifeThreadName(lifeThreadName);
  };
  const onAddLifeThread = async () => {
    handleCreateTheme(newLifeThreadName);
    setIsAddLifeThreadModalVisible(false);
  };
  const onAddLifeThreadModalOnClose = () => {
    setIsAddLifeThreadModalVisible(false);
  };
  const onCustomLifeThreadClicked = () => {
    setNewLifeThreadName("");
    setIsAddLifeThreadModalVisible(true);
  };
  const representeeFullNameSet = (projectUser == null ? void 0 : projectUser.role) === "admin" && form.first_name.trim() !== "" && form.last_name.trim() !== "";
  const contentAvailable = form.images.length !== 0 || newImageAdded || !!form.video_url || descriptionLength !== 0;
  const themesAvailable = form.themes.length > 0;
  const updateButtonAvailable = () => {
    const available = themesAvailable && contentAvailable && !onBehalfFirstNameValidationMessage && !onBehalfLastNameValidationMessage;
    if (!representeeFullNameSet) {
      return available;
    }
    return available && onBehalfConfirmation;
  };
  const trackMemoryHighlightLink = () => {
    mixpanel.track("view_memory_highlight_example", {
      kind: "project",
      group_name: groups.map((group) => group.slug).join(","),
      project_id: project.id,
      type: "button"
    });
  };
  return /* @__PURE__ */ React.createElement("div", { className: "edit-memory" }, /* @__PURE__ */ React.createElement("div", { className: "edit-memory__top" }, /* @__PURE__ */ React.createElement(
    BackLink,
    {
      text: `Back to ${backLinkData.text}`,
      to: backLinkData.route
    }
  )), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("h1", { className: "edit-memory__header" }, t("views.settings.projectSettings.contributions.header")), /* @__PURE__ */ React.createElement("div", { className: "edit-memory__section-container" }, (projectUser == null ? void 0 : projectUser.role) === "admin" && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-8-tablet edit-memory__onbehalf-label-wrapper px-2" }, /* @__PURE__ */ React.createElement(
    Label,
    {
      labelSize: "small",
      labelNote: "(Optional)",
      withoutPadding: true
    },
    /* @__PURE__ */ React.createElement("span", null, !initialFormState.first_name && !initialFormState.last_name && /* @__PURE__ */ React.createElement(React.Fragment, null, "Posted as", " ", /* @__PURE__ */ React.createElement("strong", null, user.first_name, " ", user.last_name), ".", " "), "As an Admin, you can post this memory on behalf of someone else.")
  ))), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile edit-memory__name-wrapper" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-4-tablet" }, /* @__PURE__ */ React.createElement(
    Input,
    {
      placeholder: t(
        "form.placeholder.memoryOnBehalf.firstName"
      ),
      onChange: (value) => updateField("first_name", value),
      value: form.first_name
    }
  ), onBehalfFirstNameValidationMessage && /* @__PURE__ */ React.createElement(
    ErrorMessage,
    {
      message: onBehalfFirstNameValidationMessage
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-4-tablet" }, /* @__PURE__ */ React.createElement(
    Input,
    {
      placeholder: t(
        "form.placeholder.memoryOnBehalf.lastName"
      ),
      onChange: (value) => updateField("last_name", value),
      value: form.last_name
    }
  ), onBehalfLastNameValidationMessage && /* @__PURE__ */ React.createElement(ErrorMessage, { message: onBehalfLastNameValidationMessage }))), representeeFullNameSet ? /* @__PURE__ */ React.createElement(
    ActionVerification,
    {
      verificationMessage: t(
        "form.label.memory.memoryOnBehalfConfirmation"
      ),
      changeVerification: setOnBehalfConfirmation,
      isItalic: true
    }
  ) : null), /* @__PURE__ */ React.createElement("div", { className: "columns is-mobile is-centered" }, /* @__PURE__ */ React.createElement("div", { className: "column is-8-tablet is-12-mobile" }, /* @__PURE__ */ React.createElement("h2", null, /* @__PURE__ */ React.createElement(Label, { isBold: true, isRequired: true, labelSize: "large" }, t("form.label.memory.memoryShare"))), /* @__PURE__ */ React.createElement("div", { className: "py-2" }, /* @__PURE__ */ React.createElement(
    ImageGalleryManager,
    {
      afterUpload: updateMemory,
      images: form.images,
      isVideoVisible: isVideoThumbnailVisible,
      onNewImagesAdded: onNewImageAdded,
      onAddVideoClick: handleAddVideo,
      onRemoveImage: removeImageField,
      onRemoveVideo: removeVideo,
      uppyUploadRef,
      uploadPath,
      videoThumbnailUrl: form.video_thumbnail_url
    }
  )))), isVideoThumbnailVisible && /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-8-tablet edit-memory__video_url" }, /* @__PURE__ */ React.createElement("a", { href: form.video_url, target: "_blank" }, form.video_url))), /* @__PURE__ */ React.createElement("div", { className: "columns is-mobile is-centered" }, /* @__PURE__ */ React.createElement("div", { className: "column is-8-tablet is-12-mobile" }, /* @__PURE__ */ React.createElement(
    TextEditor,
    {
      onChange: (value) => updateDescriptionField("description", value),
      value: form.description,
      validationMessage: memoryContentValidation
    }
  )))), /* @__PURE__ */ React.createElement("div", { className: "edit-memory__section-container" }, /* @__PURE__ */ React.createElement(
    MemoriesThemes,
    {
      createTheme: handleCreateTheme,
      themes: form.themes,
      onAddTheme: addTheme,
      onRemoveTheme: removeTheme,
      onAddNewLifeThread: (projectUser == null ? void 0 : projectUser.role) === "admin" && onCustomLifeThreadClicked,
      project,
      defaultThemes: themes,
      updateThemes,
      user,
      themeValidation
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "edit-memory__section-container" }, /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-8-tablet" }, /* @__PURE__ */ React.createElement(
    InputGroup,
    {
      description: /* @__PURE__ */ React.createElement("p", { className: "form-label__description" }, /* @__PURE__ */ React.createElement(
        Trans,
        {
          i18nKey: "form.description.memory.memorySnippet",
          values: { preferredName: project.preferred_name }
        },
        /* @__PURE__ */ React.createElement(
          "a",
          {
            className: "link",
            href: process.env.external_url["memory_highlights"],
            onClick: trackMemoryHighlightLink,
            target: "_blank"
          }
        )
      )),
      isLabelBold: true,
      label: t("form.label.memory.memorySnippet.primary"),
      labelNote: t("form.label.memory.memorySnippet.note"),
      labelSize: "large",
      maxLength: 70,
      onChange: (value) => updateField("text", value),
      value: form.text
    }
  )))), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-8-tablet" }, /* @__PURE__ */ React.createElement("div", { className: "edit-memory__bottom" }, /* @__PURE__ */ React.createElement("div", { className: "edit-memory__remove-wrapper" }, /* @__PURE__ */ React.createElement(
    "button",
    {
      className: "edit-memory__remove",
      onClick: () => setIsConfirmationModalVisible(true)
    },
    t(
      "views.settings.projectSettings.contributions.remove"
    )
  )), /* @__PURE__ */ React.createElement("div", { className: "edit-memory__update-wrapper m-4 md:my-0 md:w-96 w-full" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "outline",
      onClick: () => window.location.href = backLinkData.route
    },
    "Cancel"
  )), /* @__PURE__ */ React.createElement("div", { className: "edit-memory__update-wrapper" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "secondary",
      onClick: handleUpdate,
      isDisabled: !updateButtonAvailable()
    },
    t(
      "views.settings.projectSettings.contributions.update"
    )
  )))))), /* @__PURE__ */ React.createElement(Modal, { size: "medium", isVisible: isConfirmationModalVisible }, /* @__PURE__ */ React.createElement(
    ConfirmationModal,
    {
      cancelText: t(
        "views.settings.projectSettings.contributions.confirmationModal.cancel"
      ),
      closeText: t(
        "views.settings.projectSettings.contributions.confirmationModal.close"
      ),
      confirmText: t(
        "views.settings.projectSettings.contributions.confirmationModal.confirm"
      ),
      onClose: () => setIsConfirmationModalVisible(false),
      onConfirm: deleteMemory,
      subtitle: t(
        "views.settings.projectSettings.contributions.confirmationModal.subtitle"
      ),
      title: t(
        "views.settings.projectSettings.contributions.confirmationModal.title"
      )
    }
  )), /* @__PURE__ */ React.createElement(Modal, { isVisible: isVideoModalVisible }, /* @__PURE__ */ React.createElement(
    MemoryAddVideo,
    {
      isLoading,
      video_url: form.video_url,
      onCloseClick: closeVideoModal,
      onSaveClick: fetchVideoThumbnail,
      onUpdateField: updateField
    }
  )), /* @__PURE__ */ React.createElement(
    MemoriesAddLifeThreadModal,
    {
      isVisible: isAddLifeThreadModalVisible,
      lifeThreadName: newLifeThreadName,
      onAddLifeThread,
      onChangeLifeThread,
      onClose: onAddLifeThreadModalOnClose
    }
  ));
};
export default EditMemory;
