import classNames from "classnames";
import React from "react";
import Button from "@/components/button/Button";
import Icon from "@/components/icon/Icon";
const ConfirmationModal = (props) => {
  const {
    cancelText,
    closeText,
    confirmText,
    isOnboarding,
    message,
    onCancel,
    onClose,
    onConfirm,
    subtitle,
    title,
    termsAndCondition
  } = props;
  const renderCancelButton = !!onCancel || !!onClose;
  return /* @__PURE__ */ React.createElement("div", { className: "modal-confirmation" }, onClose && /* @__PURE__ */ React.createElement("div", { className: "modal-confirmation__top" }, /* @__PURE__ */ React.createElement("button", { className: "modal-confirmation__close", onClick: onClose }, closeText, /* @__PURE__ */ React.createElement(Icon, { icon: "xSolo" }))), /* @__PURE__ */ React.createElement("div", { className: "modal-confirmation__content" }, /* @__PURE__ */ React.createElement(
    "h1",
    {
      className: classNames("modal-confirmation__header", {
        "is-large": isOnboarding
      })
    },
    title
  ), subtitle && /* @__PURE__ */ React.createElement(
    "p",
    {
      className: classNames("modal-confirmation__subtitle", {
        "has-small-margin": isOnboarding
      })
    },
    subtitle
  ), message && /* @__PURE__ */ React.createElement("h5", { className: "modal-confirmation__message" }, message), /* @__PURE__ */ React.createElement("div", { className: "modal-confirmation__actions" }, renderCancelButton && /* @__PURE__ */ React.createElement(Button, { color: "outline", onClick: onCancel || onClose }, cancelText), /* @__PURE__ */ React.createElement(
    Button,
    {
      color: isOnboarding ? "primary" : "secondary",
      onClick: onConfirm
    },
    confirmText
  )), termsAndCondition && termsAndCondition));
};
export default ConfirmationModal;
