import ActionButton from "@/components/navigation/ActionButton";
import { route } from "@/utils/route_utils";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
const StayConnectedButton = ({ group, user }) => {
  const history = useHistory();
  const [subscribed, setSubscribed] = useState(!!(user == null ? void 0 : user.id));
  useEffect(() => {
    if (!(user == null ? void 0 : user.id))
      return;
    setSubscribed(
      user.subscriptions.some(
        (s) => s.subscribable_id === group.id
      )
    );
  }, [user]);
  if (!group.stay_connected_enabled || subscribed)
    return;
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    ActionButton,
    {
      icon: "heart",
      label: "Stay Connected",
      onClick: () => history.push(route("groupStayConnected", { slug: group.slug }))
    }
  ));
};
export default StayConnectedButton;
