import Api from "@/api/Api";
import GalleryButton from "@/components/button/GalleryButton";
import PlayButton from "@/components/button/PlayButton";
import Icon from "@/components/icon/Icon";
import CloseButton from "@/components/modal/CloseButton";
import Modal from "@/components/modal/Modal";
import { H2 } from "@/components/typography";
import useSortedCategories from "@/helpers/hooks/use_sorted_categories";
import { flashErrorMessage } from "@/utils/alert_utils";
import { deserialize } from "@/utils/object_utils";
import { route } from "@/utils/route_utils";
import { addHyperlink, sanitizeUserInput } from "@/utils/string_utils";
import { getHighContrastTextColor } from "@/utils/text_utils";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useHistory, useParams } from "react-router-dom";
const Description = ({ description }) => /* @__PURE__ */ React.createElement(
  "div",
  {
    className: "mb-8",
    dangerouslySetInnerHTML: {
      __html: sanitizeUserInput(addHyperlink(description))
    }
  }
);
const Contributor = ({ contributedBy }) => /* @__PURE__ */ React.createElement("div", { className: "mb-4" }, /* @__PURE__ */ React.createElement("p", { className: "text-sm font-light" }, "Contributed by"), /* @__PURE__ */ React.createElement("p", { className: "text-lg font-medium" }, contributedBy));
const GroupMemoryDetailModal = ({ group, memories }) => {
  var _a, _b;
  const [categories, setCategories] = useState();
  const sortedCategories = useSortedCategories(categories);
  const filteredCategories = Object.entries(sortedCategories).filter(
    ([key, values]) => {
      return key !== "Category" && !values.some(({ parent }) => parent.featured);
    }
  );
  const [memory, setMemory] = useState(null);
  const [errorLoadingVideo, setErrorLoadingVideo] = useState(false);
  const history = useHistory();
  const { groupName, id } = useParams();
  useEffect(() => {
    if (!memory)
      return;
    mixpanel.track("memory_viewed", {
      kind: "group",
      group_name: group.slug,
      memory_id: memory.id
    });
  }, [memory]);
  const onClose = () => history.push(`/groups/${groupName}`);
  useEffect(() => {
    const fetchMemory = async () => {
      try {
        const response = await Api.utility.get(
          route("groupMemory", { group_id: group.id, id })
        );
        const data = deserialize(response.data);
        setMemory(data);
        const groupProject = data.group_projects.find(
          ({ group_id }) => group_id === group.id
        );
        if (groupProject.group_categories) {
          const groupCategories = groupProject.group_categories;
          setCategories(groupCategories);
        }
      } catch (err) {
        flashErrorMessage("Memory not found");
      }
    };
    fetchMemory();
  }, [id]);
  const trackNavigateToProject = (source) => {
    mixpanel.track("project_viewed_from_group", {
      kind: "group",
      group_name: groupName,
      project_id: memory.project.id,
      source,
      type: "button"
    });
  };
  const currentMemoryIndex = memories && memory ? memories.findIndex(({ id: memoryId }) => memoryId === (memory == null ? void 0 : memory.id)) : null;
  const handleNavigateNext = () => {
    const nextIndex = currentMemoryIndex === memories.length - 1 ? 0 : currentMemoryIndex + 1;
    history.push(
      route("groupMemoryShow", {
        slug: group.slug,
        id: memories[nextIndex].id
      })
    );
  };
  const handleNavigatePrevious = () => {
    const previousIndex = currentMemoryIndex === 0 ? memories.length - 1 : currentMemoryIndex - 1;
    history.push(
      route("groupMemoryShow", {
        slug: group.slug,
        id: memories[previousIndex].id
      })
    );
  };
  if (!memory)
    return;
  const videoThumbnail = ((_a = memory.memory_video) == null ? void 0 : _a.video_thumbnail_url) || require("@/assets/images/project_default.jpg");
  return /* @__PURE__ */ React.createElement(Modal, { size: "medium", isVisible: !!memory, onClose }, /* @__PURE__ */ React.createElement("div", { className: "flex justify-end" }, /* @__PURE__ */ React.createElement(CloseButton, { onClose })), memories.length > 1 && /* @__PURE__ */ React.createElement("div", { className: "absolute w-full flex justify-between inset-x-0 -mt-6 sm:px-2 top-1/2" }, /* @__PURE__ */ React.createElement(GalleryButton, { direction: "left", onClick: handleNavigatePrevious }), /* @__PURE__ */ React.createElement(GalleryButton, { direction: "right", onClick: handleNavigateNext })), /* @__PURE__ */ React.createElement("div", { className: "sm:px-6 py-4" }, /* @__PURE__ */ React.createElement(
    "div",
    {
      style: { borderColor: group.memory_card_color },
      className: "border mb-8"
    },
    /* @__PURE__ */ React.createElement(
      "div",
      {
        style: { backgroundColor: group.memory_card_color },
        className: `flex font-semibold items-center justify-center p-3 uppercase ${getHighContrastTextColor(
          group.memory_card_color
        )}`
      },
      "This memory honors"
    ),
    /* @__PURE__ */ React.createElement("div", { className: "text-center p-3" }, /* @__PURE__ */ React.createElement(H2, null, /* @__PURE__ */ React.createElement(
      "a",
      {
        className: "flex items-end justify-center underline",
        href: route("project", { slug: memory.project.slug }),
        onClick: () => trackNavigateToProject("memory"),
        target: "_blank"
      },
      memory.project.prefix,
      " ",
      memory.project.full_name,
      /* @__PURE__ */ React.createElement("span", { className: "block ml-2 -mt-1 w-3" }, /* @__PURE__ */ React.createElement(Icon, { icon: "openInNew" }))
    )), filteredCategories.map(([key, values]) => /* @__PURE__ */ React.createElement("p", { key }, key !== "Category" && /* @__PURE__ */ React.createElement("span", { className: "font-semibold" }, key, ":"), " ", values.map(({ name }) => name).join(", "))))
  ), /* @__PURE__ */ React.createElement("article", { className: "px-2 sm:px-4" }, memory.kind === "text" ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Description, { description: memory.description }), /* @__PURE__ */ React.createElement(Contributor, { contributedBy: memory.contributed_by })) : /* @__PURE__ */ React.createElement("div", null, ((_b = memory.memory_video) == null ? void 0 : _b.video_url) && (errorLoadingVideo ? /* @__PURE__ */ React.createElement("div", { className: "memory-display__video-wrapper" }, /* @__PURE__ */ React.createElement("a", { href: memory.memory_video.video_url, target: "_blank" }, /* @__PURE__ */ React.createElement("img", { src: videoThumbnail }), /* @__PURE__ */ React.createElement(PlayButton, null))) : /* @__PURE__ */ React.createElement("div", { className: "memory-display__video-wrapper" }, /* @__PURE__ */ React.createElement(
    ReactPlayer,
    {
      url: memory.memory_video.video_url,
      controls: true,
      onError: () => setErrorLoadingVideo(true)
    }
  ))), memory.memory_images.map((image) => /* @__PURE__ */ React.createElement("img", { key: image.id, className: "mb-6", src: image.image_url })), /* @__PURE__ */ React.createElement(Contributor, { contributedBy: memory.contributed_by }), /* @__PURE__ */ React.createElement(Description, { description: memory.description })))));
};
export default GroupMemoryDetailModal;
