import Api from "@/api/Api";
import Button from "@/components/button/Button";
import Input from "@/components/form/Input";
import ValidationLabel from "@/components/form/ValidationLabel";
import CloseButton from "@/components/modal/CloseButton";
import Modal from "@/components/modal/Modal";
import { H2 } from "@/components/typography";
import { flashMessageFromResponseError } from "@/utils/alert_utils";
import { validateEmailAddress } from "@/utils/form_utils";
import { route } from "@/utils/route_utils";
import { capitalize } from "@/utils/string_utils";
import Pluralize from "pluralize";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
const SuccessMessage = ({ group, onClose }) => /* @__PURE__ */ React.createElement("div", { className: "text-center" }, /* @__PURE__ */ React.createElement(H2, { className: "mt-0 text-center" }, "Thank you!"), /* @__PURE__ */ React.createElement(
  "img",
  {
    className: "block mx-auto w-1/3",
    src: group.logo_url,
    alt: group.name
  }
), /* @__PURE__ */ React.createElement("p", { className: "mt-4 mb-8" }, "You are now subscribed to ", group.name, "."), /* @__PURE__ */ React.createElement("div", { className: "mx-auto md:w-24" }, /* @__PURE__ */ React.createElement(Button, { color: "outline", onClick: onClose }, "Close")));
const StayConnectedForm = ({ group, onClose, onSuccess, user }) => {
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState();
  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = async () => {
    setSubmitting(true);
    if (!user) {
      const valid = validateEmailAddress(email);
      if (!valid) {
        setSubmitting(false);
        return setEmailValid(false);
      }
    }
    const body = {
      email,
      name: "connected",
      group_id: group.id,
      user_id: user == null ? void 0 : user.id
    };
    try {
      await Api.utility.post(route("subscriptions"), body);
      onSuccess();
      setSubmitting(false);
      mixpanel.track("groupsubscribe_complete", {
        kind: "group",
        group_name: group.slug,
        type: user ? "user" : "visitor"
      });
    } catch (err) {
      flashMessageFromResponseError(err);
    }
  };
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(H2, { className: "mt-0 text-center" }, "Stay connected with ", group.name, "!"), /* @__PURE__ */ React.createElement(
    "img",
    {
      className: "block mx-auto w-1/3",
      src: group.logo_url,
      alt: group.name
    }
  ), /* @__PURE__ */ React.createElement("div", { className: "max-w-sm mx-auto" }, /* @__PURE__ */ React.createElement("p", null, "Subscribe to get a monthly summary of:"), /* @__PURE__ */ React.createElement("ul", { className: "list-disc list-inside px-2" }, /* @__PURE__ */ React.createElement("li", null, Pluralize.plural(capitalize(group.member_name || "member")), " added to the group"), /* @__PURE__ */ React.createElement("li", null, "Newly shared memories"), /* @__PURE__ */ React.createElement("li", null, "Announcements for the ", group.name, " community"))), !(user == null ? void 0 : user.id) && /* @__PURE__ */ React.createElement("div", { className: "max-w-sm mx-auto my-6" }, /* @__PURE__ */ React.createElement(
    ValidationLabel,
    {
      error: emailValid === false && "Please enter a valid email address",
      htmlFor: "email",
      valid: emailValid !== false
    },
    "Email"
  ), /* @__PURE__ */ React.createElement(
    Input,
    {
      id: "email",
      type: "email",
      onChange: setEmail,
      placeholder: "Enter your email",
      value: email
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "flex gap-4 md:max-w-xs mx-auto my-6 w-full" }, /* @__PURE__ */ React.createElement(Button, { color: "outline", onClick: onClose }, "Cancel"), /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "secondary",
      fullWidth: true,
      isDisabled: submitting,
      onClick: handleSubmit
    },
    "Subscribe"
  )));
};
const StayConnected = ({ group, onSuccess, user }) => {
  const history = useHistory();
  const { search } = useLocation();
  const jotformId = new URLSearchParams(search).get("jotform_id");
  const [subscribed, setSubscribed] = useState(false);
  useEffect(() => {
    var _a;
    if (!group)
      return;
    (_a = window.jotformEmbedHandler) == null ? void 0 : _a.call(
      window,
      `iframe[id='JotFormIFrame-${jotformId || group.experimental_stay_connected_jotform_id}'`,
      "https://form.jotform.com/"
    );
    mixpanel.track("groupsubscribe_start", {
      kind: "group",
      group_name: group.slug,
      form_id: jotformId || group.experimental_stay_connected_jotform_id || null,
      type: user ? "user" : "visitor"
    });
  }, [group]);
  const handleClose = () => {
    if (subscribed && onSuccess) {
      onSuccess();
    }
    onClose();
  };
  const onClose = () => history.push(route("groupShow", { slug: group.slug }));
  if (!group || !user)
    return;
  return /* @__PURE__ */ React.createElement(Modal, { isVisible: true, onClose: handleClose }, /* @__PURE__ */ React.createElement("div", { className: "flex justify-end" }, /* @__PURE__ */ React.createElement(CloseButton, { onClose })), !subscribed && !(group == null ? void 0 : group.experimental_stay_connected_jotform_id) && /* @__PURE__ */ React.createElement(
    StayConnectedForm,
    {
      group,
      onClose,
      onSuccess: () => setSubscribed(true),
      user
    }
  ), !subscribed && (group == null ? void 0 : group.experimental_stay_connected_jotform_id) && /* @__PURE__ */ React.createElement(
    "iframe",
    {
      id: `JotFormIFrame-${jotformId || group.experimental_stay_connected_jotform_id}`,
      className: "mix-h-[50vh] w-full overflow-hidden",
      src: `https://form.jotform.com/${jotformId || group.experimental_stay_connected_jotform_id}`
    }
  ), subscribed && /* @__PURE__ */ React.createElement(SuccessMessage, { group, onClose: handleClose }));
};
export default StayConnected;
