import React, { useEffect, useState } from "react";
import { useResponsive } from "react-hooks-responsive";
import { useTranslation } from "react-i18next";
import Api from "@/api/Api";
import Modal from "@/components/modal/Modal";
import Table from "@/components/table/Table";
import { useIdentityQuery } from "@/queries";
import {
  flashMessageFromResponseError,
  flashSuccessMessage
} from "@/utils/alert_utils";
import { buildPath } from "@/utils/api_utils";
import Modeler from "@/utils/modeler/modeler";
import { breakpoints } from "@/utils/screen_utils";
import InvitationSentModal from "./modules/InvitationSentModal";
import ProjectUserRow from "./modules/ProjectUserRow";
import ManagerHeaderActions from "./modules/user_manager/ManagerHeaderActions";
import UserManagerModal from "./modules/user_manager/UserManagerModal";
import NewUserModal from "./modules/user_manager/UserModal";
const UserManager = (props) => {
  const { project } = props;
  const { t } = useTranslation();
  const { screenIsAtMost } = useResponsive(breakpoints);
  const isMobile = screenIsAtMost("mobile");
  const { data: currentUser } = useIdentityQuery();
  const currentModeledProjectUser = currentUser ? currentUser.project_users.find(
    (pu) => pu.project.id === project.id
  ) : null;
  const [admins, setAdmins] = useState([]);
  const [users, setUsers] = useState([]);
  const [restrictedUsers, setRestrictedUsers] = useState([]);
  const [adminsPage, setAdminsPage] = useState(1);
  const [restrictedUsersPage, setRestrictedUsersPage] = useState(1);
  const [usersPage, setUsersPage] = useState(1);
  const [totalAdminPages, setTotalAdminPages] = useState(1);
  const [totalRestrictedUserPages, setTotalRestrictedUserPages] = useState(1);
  const [totalUserPages, setTotalUserPages] = useState(1);
  const [selectedProjectUser, setSelectedProjectUser] = useState(null);
  const [modalAction, setModalAction] = useState(null);
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const [isUserManagerModalVisible, setIsUserManagerModalVisible] = useState(false);
  const [isInvitationSentModalVisible, setIsInvitationSentModalVisible] = useState(false);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
  const columnName = "views.settings.projectSettings.userManagement.table.columns.name";
  const columnStatus = "views.settings.projectSettings.userManagement.table.columns.status";
  const tableColumns = [
    {
      displayName: t(columnName),
      propertyName: "first_name"
    },
    {
      displayName: t(columnStatus),
      propertyName: "status"
    }
  ];
  const sharedFormState = {
    email: "",
    firstName: "",
    lastName: "",
    project_user_id: "",
    user_id: ""
  };
  const initialFormStateStandard = {
    ...sharedFormState,
    role: "standard"
  };
  const initialFormStateAdmin = {
    ...sharedFormState,
    role: "admin"
  };
  const [form, setForm] = useState(initialFormStateStandard);
  const updateField = (field, value) => {
    setForm({
      ...form,
      [field]: value
    });
  };
  const getData = async (role, status, page) => {
    const url = buildPath("api/v1/project_users", {
      project_id: project.id,
      role,
      page,
      status
    });
    try {
      const response = await Api.utility.get(url);
      const totalPages = response.data.meta.total;
      const data = new Modeler(response.data).build();
      if (role === "admin") {
        setAdmins(data);
        setTotalAdminPages(totalPages);
      } else {
        if (status === "restricted") {
          setRestrictedUsers(data);
          setTotalRestrictedUserPages(totalPages);
        } else {
          setUsers(data);
          setTotalUserPages(totalPages);
        }
      }
    } catch (err) {
      flashMessageFromResponseError(err);
    }
  };
  const getAdmins = (page) => getData("admin", ["invited", "active"], page);
  const getRestrictedUsers = (page) => getData(["follower", "standard"], "restricted", page);
  const getUsers = (page) => getData(["follower", "standard"], ["invited", "active", "requested"], page);
  useEffect(() => {
    getAdmins(adminsPage);
  }, [adminsPage]);
  useEffect(() => {
    getRestrictedUsers(restrictedUsersPage);
  }, [restrictedUsersPage]);
  useEffect(() => {
    getUsers(usersPage);
  }, [usersPage]);
  useEffect(() => {
    if (!isUserModalVisible && !isConfirmationModalVisible) {
      getAdmins(adminsPage);
      getUsers(usersPage);
    }
  }, [isUserModalVisible, isConfirmationModalVisible]);
  const refreshUsers = () => {
    getAdmins(adminsPage);
    getRestrictedUsers(restrictedUsersPage);
    getUsers(usersPage);
    setIsUserManagerModalVisible(false);
  };
  const buildEditBody = () => {
    return {
      id: form.project_user_id,
      role: form.role,
      user_attributes: {
        id: form.user_id,
        email: form.email,
        first_name: form.firstName,
        last_name: form.lastName
      }
    };
  };
  const saveEditUser = async () => {
    const url = `api/v1/project_users/${selectedProjectUser.id}`;
    const body = buildEditBody();
    try {
      await Api.utility.put(url, body);
      setIsUserManagerModalVisible(false);
      setIsConfirmationModalVisible(false);
      flashSuccessMessage(
        t(
          "views.settings.projectSettings.userManagement.successMessages.updated"
        )
      );
    } catch (err) {
      flashMessageFromResponseError(err);
    }
  };
  const setInvitationSent = (hasNewInvites = false) => {
    setIsUserModalVisible(false);
    if (hasNewInvites) {
      setIsInvitationSentModalVisible(true);
    }
  };
  const handleManageUserClick = (user) => {
    setSelectedProjectUser(user);
    setIsUserManagerModalVisible(true);
  };
  const renderRow = (projectUser, index) => {
    const isHighlighted = index % 2 === 0;
    return /* @__PURE__ */ React.createElement(
      ProjectUserRow,
      {
        currentUser,
        isHighlighted,
        key: projectUser.id,
        onEdit: () => handleManageUserClick(projectUser),
        projectUser
      }
    );
  };
  const renderAdminHeaderActions = () => {
    return /* @__PURE__ */ React.createElement(
      ManagerHeaderActions,
      {
        onActionClicked: () => {
          setForm(initialFormStateAdmin);
          setModalAction("admin");
          setIsUserModalVisible(true);
        },
        project,
        role: "admin"
      }
    );
  };
  const renderRestrictedUserHeaderActions = () => /* @__PURE__ */ React.createElement(
    ManagerHeaderActions,
    {
      onActionClicked: () => {
        setForm(initialFormStateStandard);
        setModalAction("contributor");
        setIsUserModalVisible(true);
      },
      project,
      role: "restricted"
    }
  );
  const renderUserHeaderActions = () => {
    return /* @__PURE__ */ React.createElement(
      ManagerHeaderActions,
      {
        onActionClicked: () => {
          setForm(initialFormStateStandard);
          setModalAction("contributor");
          setIsUserModalVisible(true);
        },
        project,
        role: "standard"
      }
    );
  };
  if (!currentUser || !currentModeledProjectUser || currentModeledProjectUser.role !== "admin")
    return;
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
    Table,
    {
      columns: tableColumns,
      currentPage: adminsPage,
      data: admins,
      handleChangePage: (page) => setAdminsPage(page),
      isHiddenMobile: isMobile,
      renderHeaderActions: renderAdminHeaderActions,
      renderRow,
      totalPages: totalAdminPages,
      withActionColumn: true
    }
  ), project.privacy_mode === "invite_only" ? /* @__PURE__ */ React.createElement("p", { className: "mb-4 mt-8" }, "This LifeWeb is currently set to Invite-Only mode: You and other Admins control who can view and post memories on", " ", project.preferred_name, "'s LifeWeb by inviting Contributors or approving access requests below.") : /* @__PURE__ */ React.createElement("p", { className: "mb-4 mt-8" }, "This LifeWeb is currently set to Community mode so visitors can easily contribute to the page. You can also send an email invitation to anyone not listed here."), /* @__PURE__ */ React.createElement(
    Table,
    {
      columns: tableColumns,
      currentPage: usersPage,
      data: users,
      emptyText: t(
        "views.settings.projectSettings.userManagement.table.emptyText"
      ),
      handleChangePage: (page) => setUsersPage(page),
      isHiddenMobile: isMobile,
      renderHeaderActions: renderUserHeaderActions,
      renderRow,
      totalPages: totalUserPages,
      withActionColumn: true
    }
  ), restrictedUsers.length > 0 && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("p", { className: "mb-4 mt-8" }, "Restricted users have previously been denied access by an Admin of this page. You can update their status below."), /* @__PURE__ */ React.createElement(
    Table,
    {
      columns: tableColumns,
      currentPage: restrictedUsersPage,
      data: restrictedUsers,
      handleChangePage: (page) => setRestrictedUsersPage(page),
      isHiddenMobile: isMobile,
      renderHeaderActions: renderRestrictedUserHeaderActions,
      renderRow,
      totalPages: totalRestrictedUserPages,
      withActionColumn: true
    }
  )), /* @__PURE__ */ React.createElement(Modal, { size: "medium", isVisible: isUserModalVisible }, /* @__PURE__ */ React.createElement(
    NewUserModal,
    {
      onClose: setInvitationSent,
      project,
      inviteMode: modalAction,
      isAdmin: true,
      currentUser,
      modeledProjectUser: currentModeledProjectUser
    }
  )), /* @__PURE__ */ React.createElement(Modal, { size: "medium", isVisible: isUserManagerModalVisible }, /* @__PURE__ */ React.createElement(
    UserManagerModal,
    {
      onClose: refreshUsers,
      projectUser: selectedProjectUser,
      project
    }
  )), /* @__PURE__ */ React.createElement(Modal, { size: "small", isVisible: isInvitationSentModalVisible }, /* @__PURE__ */ React.createElement(
    InvitationSentModal,
    {
      onClose: () => setIsInvitationSentModalVisible(false),
      title: t(
        "views.settings.projectSettings.userManagement.invitationSentModal.title"
      )
    }
  )));
};
export default UserManager;
