import Api from "@/api/Api";
import ActivityIndicator from "@/components/activity/ActivityIndicator";
import Button from "@/components/button/Button";
import Input from "@/components/form/Input";
import Label from "@/components/form/Label";
import TextEditor from "@/components/form/TextEditor";
import ConfirmationModal from "@/components/modal/ConfirmationModal";
import Modal from "@/components/modal/Modal";
import {
  flashMessageFromResponseError,
  flashSuccessMessage
} from "@/utils/alert_utils";
import Modeler from "@/utils/modeler/modeler";
import { route } from "@/utils/route_utils";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
const ProjectUpdateForm = ({ project }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { id, slug } = useParams();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(!!id);
  const [notifySubscribers, setNotifySubscribers] = useState(!id);
  const [title, setTitle] = useState("");
  useEffect(() => {
    const fetchProjectUpdate = async () => {
      const url = route("projectUpdate", { id });
      const response = await Api.utility.get(url);
      const update = new Modeler(response.data).build();
      setContent(update.content);
      setTitle(update.title);
      setLoading(false);
    };
    if (id)
      fetchProjectUpdate();
  }, []);
  const handleDelete = async () => {
    await Api.utility.delete(route("projectUpdate", { id }));
    flashSuccessMessage("Update Removed");
    window.location.href = route("updates", { slug: project.slug });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      notify_subscribers: notifySubscribers,
      project_update: {
        content,
        title,
        project_id: project.id
      }
    };
    const method = id ? "patch" : "post";
    const url = id ? route("projectUpdate", { id }) : route("projectUpdates", { project_id: project.id });
    try {
      await Api.utility[method](url, body);
      window.location.href = route("updates", { slug: project.slug });
    } catch (err) {
      flashMessageFromResponseError(err);
    }
  };
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("form", { method: "post", onSubmit: handleSubmit }, !loading && project && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "my-6" }, /* @__PURE__ */ React.createElement(Label, { isRequired: true }, "Enter the title of your update."), /* @__PURE__ */ React.createElement(Input, { maxLength: 100, onChange: setTitle, value: title })), /* @__PURE__ */ React.createElement("div", { className: "my-6" }, /* @__PURE__ */ React.createElement(Label, { isRequired: true }, "Enter the details of your update."), /* @__PURE__ */ React.createElement(TextEditor, { onChange: setContent, value: content })), /* @__PURE__ */ React.createElement("div", { className: "my-6" }, /* @__PURE__ */ React.createElement("label", { className: "text-md" }, /* @__PURE__ */ React.createElement(
    "input",
    {
      checked: notifySubscribers,
      className: "mr-2",
      name: "notify_subscribers",
      onChange: (e) => setNotifySubscribers(e.target.checked),
      type: "checkbox"
    }
  ), "Also send as an email to anyone who has asked to stay in touch with ", project.preferred_name, "'s LifeWeb.")), /* @__PURE__ */ React.createElement("div", { className: "flex items-center justify-end" }, /* @__PURE__ */ React.createElement("div", null, id && /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "cursor-pointer font-semibold mx-8 text-lifeweb-brown",
      onClick: () => setConfirmDelete(true)
    },
    "Remove Update"
  )), /* @__PURE__ */ React.createElement("div", { className: "flex gap-4 md:max-w-xs w-full" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "outline",
      onClick: () => history.push(route("updates", { slug }))
    },
    "Cancel"
  ), /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "secondary",
      fullWidth: true,
      isDisabled: !content.trim() || !title.trim()
    },
    id ? "Save Changes" : "Post Update"
  )))), loading && /* @__PURE__ */ React.createElement(ActivityIndicator, null)), /* @__PURE__ */ React.createElement(Modal, { size: "medium", isVisible: confirmDelete }, /* @__PURE__ */ React.createElement(
    ConfirmationModal,
    {
      cancelText: t(
        "views.settings.projectSettings.contributions.confirmationModal.cancel"
      ),
      closeText: t(
        "views.settings.projectSettings.contributions.confirmationModal.close"
      ),
      confirmText: "Remove Update",
      onClose: () => setConfirmDelete(false),
      onConfirm: handleDelete,
      subtitle: "Once an update is removed, it cannot be restored.",
      title: t(
        "views.settings.projectSettings.contributions.confirmationModal.title"
      )
    }
  )));
};
export default ProjectUpdateForm;
