import classNames from "classnames";
import React from "react";
const ThemeTag = (props) => {
  const { children, isClickable, isSelected } = props;
  return /* @__PURE__ */ React.createElement(
    "span",
    {
      className: classNames("theme", {
        "is-selected": isSelected,
        "is-clickable": isClickable
      })
    },
    children
  );
};
export default ThemeTag;
