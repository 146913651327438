import React, { useEffect, useRef, useState } from "react";
import Api from "@/api/Api";
import Button from "@/components/button/Button";
import Label from "@/components/form/Label";
import TextEditor from "@/components/form/TextEditor";
import ImageGalleryManager from "@/components/image_gallery_manager/ImageGalleryManager";
import ConfirmationModal from "@/components/modal/ConfirmationModal";
import Modal from "@/components/modal/Modal";
import ImageUploader from "@/components/uploaders/ImageUploader";
import ReflectionPreview from "@/contexts/reflections/ReflectionPreview";
import { flashMessageFromResponseError } from "@/utils/alert_utils";
import Modeler from "@/utils/modeler/modeler";
import { route } from "@/utils/route_utils";
import { useTranslation } from "react-i18next";
const ReflectionForm = ({ group, reflection, uploadPath, user }) => {
  const existingReflection = reflection ? new Modeler(reflection).build() : {};
  const [attachments, setAttachments] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deprecatedManagerHasImages, setDeprecatedManagerHasImages] = useState(false);
  const [description, setDescription] = useState(
    existingReflection.description || ""
  );
  const [descriptionValid, setDescriptionValid] = useState(false);
  const [previewing, setPreviewing] = useState(false);
  const [previewImages, setPreviewImages] = useState([]);
  const [uploadedImages, setUploadedImages] = useState(
    existingReflection.group_reflection_images || []
  );
  const { t } = useTranslation();
  const uppyUpload = useRef(null);
  useEffect(() => {
    if (!attachments.length)
      return;
    handleSubmit();
  }, [attachments]);
  useEffect(() => {
    if (description) {
      setDescriptionValid(
        new DOMParser().parseFromString(description, "text/html").documentElement.textContent.trim().length > 0
      );
    }
  }, [description]);
  const handleDeleteReflection = async () => {
    const url = route("groupReflection", {
      group_id: group.id,
      id: existingReflection.id
    });
    await Api.utility.delete(url);
    window.location.assign(`/groups/${group.slug}`);
  };
  const handleDestroyImage = (image) => {
    setUploadedImages(
      uploadedImages.map((i) => {
        if (i.id === image.id) {
          i._destroy = true;
        }
        return i;
      })
    );
  };
  const handleSubmit = async () => {
    const body = {
      description,
      group_id: group.id,
      group_reflection_images_attributes: [
        ...attachments.map(({ jsonData }) => ({
          image: jsonData
        })),
        ...uploadedImages.filter((image) => image._destroy)
      ]
    };
    let id = existingReflection.id || "";
    const method = reflection ? "patch" : "post";
    const url = route("groupReflection", { group_id: group.id, id });
    try {
      const response = await Api.utility[method](url, body);
      ({
        data: { id }
      } = response.data);
      mixpanel.track("reflection_created", {
        kind: "group",
        group_name: group.slug,
        reflection_id: id
      });
      window.location.assign(`/groups/${group.slug}/reflections/${id}`);
    } catch (err) {
      flashMessageFromResponseError(err);
    }
  };
  const optionallyUploadImagesAndSubmit = () => {
    if (previewImages.length || deprecatedManagerHasImages) {
      return uppyUpload.current();
    }
    handleSubmit();
  };
  const modeledReflection = () => ({
    contributed_by: `${user.first_name} ${user.last_name}`,
    description,
    group_reflection_images: [
      ...previewImages.map(({ blob }) => ({
        image_url: URL.createObjectURL(blob)
      })),
      ...uploadedImages.filter((image) => !image._destroy)
    ]
  });
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("h2", null, /* @__PURE__ */ React.createElement(
    Label,
    {
      description: "A reflection can contain text and up to 5 images.",
      isBold: true,
      isRequired: true,
      labelSize: "large"
    },
    "Add content"
  )), uploadedImages.length > 0 && /* @__PURE__ */ React.createElement(
    ImageGalleryManager,
    {
      afterUpload: setAttachments,
      images: uploadedImages.filter((image) => !image._destroy),
      isVideoVisible: false,
      onNewImagesAdded: () => setDeprecatedManagerHasImages(true),
      onRemoveImage: handleDestroyImage,
      uppyUploadRef: uppyUpload,
      uploadPath
    }
  ), !uploadedImages.length && /* @__PURE__ */ React.createElement(
    ImageUploader,
    {
      afterUpload: setAttachments,
      onChangePreviewImages: setPreviewImages,
      previewImages,
      uploadPath,
      uppyUploadRef: uppyUpload
    }
  ), /* @__PURE__ */ React.createElement("div", { className: "mb-12 mt-4" }, /* @__PURE__ */ React.createElement("p", null, "Add your text here:"), /* @__PURE__ */ React.createElement(TextEditor, { onChange: setDescription, value: description })), /* @__PURE__ */ React.createElement("div", { className: "flex gap-8 items-center justify-end" }, existingReflection.id && /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "cursor-pointer font-semibold text-lifeweb-brown",
      onClick: () => setConfirmDelete(true)
    },
    "Remove Reflection"
  ), /* @__PURE__ */ React.createElement("div", { className: "sm:w-1/4 w-full" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "secondary",
      isDisabled: !descriptionValid && !previewImages.length,
      onClick: () => existingReflection.id ? optionallyUploadImagesAndSubmit() : setPreviewing(true)
    },
    `${existingReflection.id ? "Update" : "Preview"} Reflection`
  ))), /* @__PURE__ */ React.createElement("p", { className: "my-8" }, "By submitting a reflection, you are agreeing to LifeWeb 360's", " ", /* @__PURE__ */ React.createElement(
    "a",
    {
      className: "text-lifeweb-brown",
      href: "https://www.lifeweb360.com/terms-of-use",
      target: "_blank"
    },
    "Terms of Use"
  ), " ", "and", " ", /* @__PURE__ */ React.createElement(
    "a",
    {
      className: "text-lifeweb-brown",
      href: "https://www.lifeweb360.com/privacy-policy",
      target: "_blank"
    },
    "Privacy Policy"
  ))), previewing && /* @__PURE__ */ React.createElement(
    ReflectionPreview,
    {
      onClose: () => setPreviewing(false),
      onSubmit: optionallyUploadImagesAndSubmit,
      reflection: modeledReflection()
    }
  ), /* @__PURE__ */ React.createElement(Modal, { size: "medium", isVisible: confirmDelete }, /* @__PURE__ */ React.createElement(
    ConfirmationModal,
    {
      cancelText: t(
        "views.settings.projectSettings.contributions.confirmationModal.cancel"
      ),
      closeText: t(
        "views.settings.projectSettings.contributions.confirmationModal.close"
      ),
      confirmText: "Remove Reflection",
      onClose: () => setConfirmDelete(false),
      onConfirm: handleDeleteReflection,
      subtitle: "Once a reflection is removed, it cannot be restored.",
      title: t(
        "views.settings.projectSettings.contributions.confirmationModal.title"
      )
    }
  )));
};
export default ReflectionForm;
