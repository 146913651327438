import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@/components/button/Button";
const ManagerHeaderActions = (props) => {
  const { onActionClicked, role } = props;
  const { t } = useTranslation();
  const adminHeaderLocaleText = "views.settings.projectSettings.userManagement.headers.admin";
  const userHeaderLocaleText = "views.settings.projectSettings.userManagement.headers.user";
  const adminActionLocaleText = "views.settings.projectSettings.userManagement.invitations.admin";
  const userActionLocaleText = "views.settings.projectSettings.userManagement.invitations.user";
  return /* @__PURE__ */ React.createElement("div", { className: "user-manager__header-actions-wrapper" }, /* @__PURE__ */ React.createElement("h2", null, role === "admin" && t(adminHeaderLocaleText), role !== "admin" && role !== "restricted" && t(userHeaderLocaleText), role === "restricted" && "Restricted"), role !== "restricted" && /* @__PURE__ */ React.createElement("div", { className: "user-manager__header-actions" }, /* @__PURE__ */ React.createElement("div", { className: "user-manager__invite-wrapper" }, /* @__PURE__ */ React.createElement(Button, { color: "secondary", onClick: onActionClicked }, role === "admin" ? t(adminActionLocaleText) : t(userActionLocaleText)))));
};
export default ManagerHeaderActions;
