import React from "react";
import PlayButton from "@/components/button/PlayButton";
const ContentCardBanner = (props) => {
  const { type, imageAlt = "", imageUrl, inReview } = props;
  const renderPlayButton = () => {
    if (type === "video") {
      return /* @__PURE__ */ React.createElement(PlayButton, null);
    }
  };
  return /* @__PURE__ */ React.createElement("div", { className: "content-card-banner" }, /* @__PURE__ */ React.createElement("div", { className: "content-card-banner__image" }, /* @__PURE__ */ React.createElement("img", { alt: imageAlt, src: imageUrl }), renderPlayButton(), inReview && /* @__PURE__ */ React.createElement("div", { className: "content-card-banner__review_shade" })));
};
export default ContentCardBanner;
