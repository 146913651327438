import Api from "@/api/Api";
import { buildPath } from "@/utils/api_utils";
import Modeler from "@/utils/modeler/modeler";
import { route } from "@/utils/route_utils";
import { arrayMoveImmutable } from "array-move";
import classNames from "classnames";
import moment from "moment";
import React, {
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import BookPage from "./BookPage";
const NewBook = (props) => {
  const { project, memories, themes_count, googleApiAppId } = props;
  const projectData = new Modeler(project).build();
  const [selectedMemoryId, setSelectedMemoryId] = useState(
    Object.keys(memories)[0]
  );
  const memoryPosition = useRef({});
  const memoryTranslate = useRef({});
  const initialBookConfig = useMemo(
    () => Object.values(memories).map((memory) => ({
      memory_id: memory.id,
      template: 0
    })),
    []
  );
  const [configurations, setConfigurations] = useState(initialBookConfig);
  const [pageLayout, setPageLayout] = useState([]);
  const pageListRef = useRef(null);
  const [accessToken, setAccessToken] = useState("");
  const [tokenExpiresAt, setTokenExpiresAt] = useState(null);
  const [createLoading, setCreateLoading] = useState(false);
  const [createError, setCreateError] = useState("");
  const [retryCount, setRetryCount] = useState(0);
  const savedRetryCount = useRef(null);
  const retryCountCallback = () => {
    setRetryCount(retryCount + 1);
  };
  useEffect(() => {
    savedRetryCount.current = retryCountCallback;
  });
  const getMemoryIndex = (memoryId) => {
    return configurations.findIndex((config) => config.memory_id === memoryId);
  };
  const initThemeList = () => {
    const newThemesList = [...themes_count];
    newThemesList.sort((itemA, itemB) => itemB[1] - itemA[1]);
    return newThemesList;
  };
  const [sortThemes, setSortThemes] = useState(initThemeList());
  const handleReset = () => {
    setSortThemes(initThemeList());
    setConfigurations(initialBookConfig);
    setNewMemoryIndex(0);
    setSelectedMemoryId(initialBookConfig[0].memory_id);
  };
  const sortConfigurations = (newThemeOrder) => {
    const newConfigs = [...configurations];
    newConfigs.sort((confA, confB) => {
      const memA = memories[confA.memory_id];
      const memB = memories[confB.memory_id];
      if (memA.id === "cover") {
        return -1;
      }
      if (memB.id === "cover") {
        return 1;
      }
      const themesA = [...memA.themes];
      const themesB = [...memB.themes];
      let noInfinite = newThemeOrder.length;
      let rst = 0;
      while (noInfinite > 0) {
        noInfinite -= 1;
        if (themesA.length === 0) {
          rst = -1;
          break;
        }
        if (themesB.length === 0) {
          rst = 1;
          break;
        }
        for (const themePair of newThemeOrder) {
          const themeInA = themesA.indexOf(themePair[0]);
          const themeInB = themesB.indexOf(themePair[0]);
          if (themeInA >= 0) {
            if (themeInB < 0) {
              rst = -1;
              break;
            }
            themesA.splice(themeInA, 1);
          }
          if (themeInB >= 0) {
            if (themeInA < 0) {
              rst = 1;
              break;
            }
            themesB.splice(themeInB, 1);
          }
        }
      }
      return rst;
    });
    setConfigurations(newConfigs);
  };
  const handleIndexOnSave = () => {
    const currentIndex = getMemoryIndex(selectedMemoryId);
    if (currentIndex !== newMemoryIndex) {
      setConfigurations(
        arrayMoveImmutable(configurations, currentIndex, newMemoryIndex)
      );
    }
  };
  const [newMemoryIndex, setNewMemoryIndex] = useState(
    getMemoryIndex(selectedMemoryId)
  );
  const handleIndexOnChange = (newIndex) => {
    setNewMemoryIndex(Number(newIndex.target.value));
  };
  const handleMemoryOnClick = (selected, doScroll = true) => {
    setSelectedMemoryId(selected);
    setNewMemoryIndex(getMemoryIndex(selected));
    if (doScroll && memoryPosition.current[selected]) {
      const { page, row } = memoryPosition.current[selected];
      pageListRef.current.scrollTo({
        top: page * 1150 + row * 150,
        behavior: "smooth"
      });
    }
  };
  const handleOnTemplateChange = (index) => {
    const newConfigurations = [...configurations];
    newConfigurations[newMemoryIndex].template = index;
    setConfigurations(newConfigurations);
  };
  const SortableItem = SortableElement(({ value }) => /* @__PURE__ */ React.createElement("div", { className: "books__sort-item" }, `${value[0]} x ${value[1]}`));
  const SortableList = SortableContainer(
    ({ items }) => {
      return /* @__PURE__ */ React.createElement("div", { className: "books__sort-container" }, items.map((theme, index) => /* @__PURE__ */ React.createElement(SortableItem, { ...{ key: theme[0], index, value: theme } })));
    }
  );
  const handleCreate = async (token = null) => {
    if (createLoading) {
      return false;
    }
    setCreateLoading(true);
    setCreateError("");
    const url = buildPath(window.location.pathname.replace("new", "create"));
    const pages = [...pageLayout];
    for (const page of pages) {
      for (const block of page.blocks) {
        if (block.content_type === "image" && memoryTranslate.current[block.block_id]) {
          block.trans_x = memoryTranslate.current[block.block_id].transX;
          block.trans_y = memoryTranslate.current[block.block_id].transY;
        }
      }
    }
    const body = {
      project: projectData,
      pages,
      access_token: token || accessToken
    };
    try {
      const response = await Api.utility.post(url, body);
      const data = response.data;
      console.log(data);
    } catch (err) {
      console.error(err);
      setCreateError(err.message);
    }
    setCreateLoading(false);
  };
  const handleGoogleAuthCallback = async () => {
    try {
      const googleClient = window.google.accounts.oauth2.initCodeClient({
        client_id: googleApiAppId,
        scope: [
          "email",
          "profile",
          "https://www.googleapis.com/auth/drive"
        ].join(" "),
        ux_mode: "popup",
        callback: async (response) => {
          const url = route("bookGoogleAuth");
          const body = {
            code: response.code
          };
          try {
            const response2 = await Api.utility.post(url, body);
            const credentials = response2.data;
            setAccessToken(credentials.access_token);
            setTokenExpiresAt(moment(new Date(credentials.expires_at * 1e3)));
            handleCreate(credentials.access_token);
          } catch (err) {
            console.error(err);
            setCreateError(err.message);
            setCreateLoading(false);
          }
        }
      });
      googleClient.requestCode();
    } catch (err) {
      setCreateLoading(false);
      setCreateError(err.error);
    }
  };
  const handleOnCreateClicked = () => {
    if (moment() > tokenExpiresAt) {
      handleGoogleAuthCallback();
    } else {
      handleCreate();
    }
  };
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newSortOrder = arrayMoveImmutable(sortThemes, oldIndex, newIndex);
    setSortThemes(newSortOrder);
  };
  const newPageFromMemory = (memory, templateIdx) => {
    const newPageLayout = [];
    const template = memory.templates[templateIdx];
    for (const page of template.pages) {
      newPageLayout.push({
        template_page_id: page.page_id,
        blocks: page.elements.map((element) => ({
          ...element,
          memory_id: memory.id
        }))
      });
    }
    let heightLeft = template.height % 6;
    if (heightLeft === 0) {
      heightLeft = 6;
    }
    return {
      newPageLayout,
      heightLeft,
      heightRight: template.width === 2 ? heightLeft : 0
    };
  };
  useEffect(() => {
    let newPageLayout = [];
    let currentPageHeightLeft = 0;
    let currentPageHeightRight = 0;
    for (let configureIdx = 0; configureIdx < configurations.length; configureIdx += 1) {
      const configure = configurations[configureIdx];
      const memory = memories[configure.memory_id];
      const template = memory.templates[configure.template];
      const currentPage = newPageLayout[newPageLayout.length - 1];
      if (newPageLayout.length === 0) {
        memoryPosition.current[memory.id] = {
          page: 0,
          row: 0
        };
        const initPages = newPageFromMemory(memory, configure.template);
        newPageLayout = newPageLayout.concat(initPages.newPageLayout);
        currentPageHeightRight = initPages.heightRight;
        currentPageHeightLeft = initPages.heightLeft;
      } else {
        if (currentPageHeightLeft > currentPageHeightRight && currentPageHeightRight + template.height <= 6 && template.width === 1) {
          memoryPosition.current[memory.id] = {
            page: newPageLayout.length - 1,
            row: currentPageHeightRight
          };
          for (const element of template.pages[0].elements) {
            const newElement = {
              ...element,
              memory_id: memory.id,
              offset_x: 1,
              offset_y: currentPageHeightRight,
              source_template_page_id: template.pages[0].page_id
            };
            currentPage.blocks.push(newElement);
          }
          currentPageHeightRight += template.height;
          if (currentPageHeightLeft < currentPageHeightRight) {
            currentPageHeightLeft = currentPageHeightRight;
          }
        } else if (currentPageHeightLeft + template.height <= 6) {
          memoryPosition.current[memory.id] = {
            page: newPageLayout.length - 1,
            row: currentPageHeightLeft
          };
          for (const element of template.pages[0].elements) {
            const newElement = {
              ...element,
              memory_id: memory.id,
              offset_x: 0,
              offset_y: currentPageHeightLeft,
              source_template_page_id: template.pages[0].page_id
            };
            currentPage.blocks.push(newElement);
          }
          currentPageHeightLeft += template.height;
          if (template.width === 2) {
            currentPageHeightRight = currentPageHeightLeft;
          }
        } else {
          memoryPosition.current[memory.id] = {
            page: newPageLayout.length,
            row: 0
          };
          const initPages = newPageFromMemory(memory, configure.template);
          newPageLayout = newPageLayout.concat(initPages.newPageLayout);
          currentPageHeightRight = initPages.heightRight;
          currentPageHeightLeft = initPages.heightLeft;
        }
      }
    }
    let block_id_count = 1;
    for (const page of newPageLayout) {
      for (const block of page.blocks) {
        block.block_id = block_id_count.toString();
        block_id_count += 1;
      }
    }
    setPageLayout(newPageLayout);
  }, [configurations]);
  useEffect(() => {
    sortConfigurations(sortThemes);
  }, [sortThemes]);
  const MemorySelectListItem = SortableElement(({ value }) => {
    var _a, _b;
    const { index, memory_id } = value;
    let topBorder = false;
    const memory = memories[memory_id];
    if (index > 0) {
      const aboveConfig = configurations[index - 1];
      if (((_a = memoryPosition.current[memory_id]) == null ? void 0 : _a.page) !== ((_b = memoryPosition.current[aboveConfig.memory_id]) == null ? void 0 : _b.page)) {
        topBorder = true;
      }
    }
    const { num_of_images, brief_text, themes } = memory;
    const placeHolders = [];
    for (let i = 0; i < num_of_images; i++) {
      placeHolders.push(/* @__PURE__ */ React.createElement("div", { key: i, className: "books__image-placeholder" }));
    }
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: classNames("books__content-list-item", {
          "books__content-list-item-active": value.memory_id === selectedMemoryId,
          "books__content-list-item-top-border": topBorder
        }),
        key: memory_id + "-list-item-" + index,
        onClick: () => handleMemoryOnClick(memory_id)
      },
      /* @__PURE__ */ React.createElement("div", { className: "books__placeholder-container" }, /* @__PURE__ */ React.createElement("div", null, `${value.index}. `), /* @__PURE__ */ React.createElement("div", { className: "books__brif-description" }, themes.join(", "), (memory_id === "cover" || memory_id === "obituary") && memory_id)),
      /* @__PURE__ */ React.createElement("div", { className: "books__placeholder-container" }, placeHolders, /* @__PURE__ */ React.createElement("div", { className: "books__brif-description" }, brief_text))
    );
  });
  const MemorySelectList = SortableContainer(({ items }) => {
    return /* @__PURE__ */ React.createElement("div", { className: "books__content-list" }, items.map((config, index) => /* @__PURE__ */ React.createElement(
      MemorySelectListItem,
      {
        ...{
          key: config.memory_id + "-memory-list-item" + index,
          index,
          value: { ...config, index }
        }
      }
    )));
  });
  const handleMemoryIndexOnMoved = ({ oldIndex, newIndex }) => {
    const newConfigurations = arrayMoveImmutable(
      configurations,
      oldIndex,
      newIndex
    );
    setConfigurations(newConfigurations);
    setNewMemoryIndex(newIndex);
    setSelectedMemoryId(newConfigurations[newIndex].memory_id);
  };
  const selectedMemory = memories[selectedMemoryId];
  const setBlockTranslate = (blockId, transX, transY) => {
    memoryTranslate.current[blockId] = {
      transX,
      transY
    };
  };
  const handleSaveConfiguration = async () => {
    const configData = {
      sortThemes,
      configurations
    };
    const configFileName = `${projectData.id}, ${new Date(
      Date.now()
    ).toUTCString()}`;
    const configJSON = JSON.stringify(configData);
    const configBlob = new Blob([configJSON], { type: "application/json" });
    const configURL = window.URL.createObjectURL(configBlob);
    const downloadLink = document.createElement("a");
    downloadLink.href = configURL;
    downloadLink.download = configFileName + ".json";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const handleLoadConfiguration = () => {
    document.getElementById("config-file-input").click();
  };
  const updateThemeCounts = (themes) => {
    const newThemes = themes.map((theme) => {
      const matchTheme = sortThemes.find(
        (sortTheme) => sortTheme[0] === theme[0]
      );
      return matchTheme ? [theme[0], matchTheme[1]] : theme;
    });
    return newThemes;
  };
  const filterConfiguration = (configurationsA, configurationsB) => {
    const filteredConfigurations = configurationsA.filter(
      (configurationA) => {
        return configurationsB.some(
          (configurationB) => configurationB.memory_id === configurationA.memory_id
        ) === false;
      }
    );
    return filteredConfigurations;
  };
  const filterSortThemes = (themesA, themesB) => {
    const filteredThemes = themesA.filter((themeA) => {
      return themesB.some((themeB) => themeB[0] === themeA[0]) === false;
    });
    return filteredThemes;
  };
  const rearrangeSortThemes = (themes) => {
    const newThemes = updateThemeCounts(themes);
    const excludedThemes = filterSortThemes(newThemes, sortThemes);
    const newSortThemes = excludedThemes.length > 0 ? filterSortThemes(newThemes, excludedThemes).concat(
      filterSortThemes(
        sortThemes,
        filterSortThemes(newThemes, excludedThemes)
      )
    ) : newThemes.concat(filterSortThemes(sortThemes, newThemes));
    setSortThemes(newSortThemes);
    return newSortThemes;
  };
  const rearrangeConfigurations = (newConfigurations) => {
    const excludedConfigurations = filterConfiguration(
      newConfigurations,
      configurations
    );
    excludedConfigurations.length > 0 ? setConfigurations(
      filterConfiguration(newConfigurations, excludedConfigurations).concat(
        filterConfiguration(
          configurations,
          filterConfiguration(newConfigurations, excludedConfigurations)
        )
      )
    ) : setConfigurations(
      newConfigurations.concat(
        filterConfiguration(configurations, newConfigurations)
      )
    );
  };
  const handleOnConfigurationLoad = (event) => {
    const file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const configData = JSON.parse(e.target.result.toString());
      const newSortThemes = rearrangeSortThemes(configData.sortThemes);
      sortConfigurations(newSortThemes);
      rearrangeConfigurations(configData.configurations);
    };
    fileReader.readAsText(file);
  };
  return /* @__PURE__ */ React.createElement("div", { className: "books" }, /* @__PURE__ */ React.createElement("div", { className: "books__navbar" }, /* @__PURE__ */ React.createElement("label", null, "Sort by: "), /* @__PURE__ */ React.createElement(SortableList, { ...{ items: sortThemes, onSortEnd, axis: "x" } }), /* @__PURE__ */ React.createElement(
    "div",
    {
      style: { marginRight: 20 }
    },
    `Page Count: ${pageLayout.length}`
  ), /* @__PURE__ */ React.createElement(
    "button",
    {
      className: "books__save-config-button",
      onClick: handleSaveConfiguration
    },
    "Save Configuration"
  ), /* @__PURE__ */ React.createElement(
    "button",
    {
      className: "books__load-config-button",
      onClick: handleLoadConfiguration
    },
    "Load Configuration"
  ), /* @__PURE__ */ React.createElement(
    "input",
    {
      id: "config-file-input",
      className: "books__config-file-input",
      type: "file",
      onChange: (value) => handleOnConfigurationLoad(value)
    }
  ), /* @__PURE__ */ React.createElement("button", { className: "books__reset-button", onClick: handleReset }, "Reset"), /* @__PURE__ */ React.createElement(
    "button",
    {
      className: "books__create-button",
      onClick: handleOnCreateClicked
    },
    createLoading ? "Creating..." : "Create"
  )), createError.length > 0 && /* @__PURE__ */ React.createElement("div", { className: "books__error-msg" }, createError), /* @__PURE__ */ React.createElement("div", { className: "books__container" }, /* @__PURE__ */ React.createElement("div", { className: "books__one_column" }, /* @__PURE__ */ React.createElement(
    MemorySelectList,
    {
      ...{
        items: configurations,
        onSortEnd: handleMemoryIndexOnMoved,
        distance: 1,
        lockAxis: "y",
        lockToContainerEdges: true,
        lockOffset: "0%"
      }
    }
  )), /* @__PURE__ */ React.createElement("div", { ref: pageListRef, className: "books__one_column books__page-list" }, pageLayout.map((page, pageIdx) => /* @__PURE__ */ React.createElement(
    BookPage,
    {
      selectedMemoryId,
      key: `${page.template_page_id}-${pageIdx}`,
      pageLayout: page,
      handleMemoryOnClick,
      setBlockTranslate
    }
  ))), /* @__PURE__ */ React.createElement("div", { className: "books__one_column books__properties-container" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("label", { className: "books__index-label" }, "Memory Id: "), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement(
    "a",
    {
      href: `/${projectData.slug}/${selectedMemoryId}`,
      target: "_blank"
    },
    selectedMemoryId
  )), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("label", { className: "books__index-label" }, "Lifet Threads:"), /* @__PURE__ */ React.createElement("br", null), selectedMemory.themes.join(", ")), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("label", { className: "books__index-label" }, "Position Index: "), /* @__PURE__ */ React.createElement(
    "input",
    {
      className: "books__index-input",
      type: "text",
      size: 3,
      pattern: "[0-9]*",
      onChange: handleIndexOnChange,
      value: newMemoryIndex
    }
  ), /* @__PURE__ */ React.createElement("button", { className: "books__save-button", onClick: handleIndexOnSave }, "Update")), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("label", { className: "books__index-label" }, "Templates: "), /* @__PURE__ */ React.createElement("div", { className: "books__templates-container" }, selectedMemory.templates.map(
    (template, Templateindex) => /* @__PURE__ */ React.createElement(
      "div",
      {
        className: classNames("books__template", {
          "books__template-active": configurations[getMemoryIndex(selectedMemoryId)].template === Templateindex
        }),
        key: selectedMemoryId + "-template-" + Templateindex,
        onClick: () => handleOnTemplateChange(Templateindex)
      },
      /* @__PURE__ */ React.createElement("img", { src: template.preview })
    )
  ))))));
};
export default NewBook;
