import { sanitizeUserInput } from "@/utils/string_utils";
import classNames from "classnames";
import React from "react";
const ContentCardDescription = (props) => {
  const { text, inReview, user } = props;
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames("content-card-description prose max-w-none", {
        "content-card-description__review": inReview
      }),
      dangerouslySetInnerHTML: { __html: sanitizeUserInput(text, user) }
    }
  );
};
export default ContentCardDescription;
