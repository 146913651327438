import Api from "@/api/Api";
import Icon from "@/components/icon/Icon";
import { H1 } from "@/components/typography";
import { flashMessageFromResponseError } from "@/utils/alert_utils";
import Modeler from "@/utils/modeler/modeler";
import { route } from "@/utils/route_utils";
import React, { useState } from "react";
import SearchResults from "./SearchResults";
const Search = () => {
  const [projects, setProjects] = useState([]);
  const [query, setQuery] = useState("");
  const [search, setSearch] = useState("");
  const [showResults, setShowResults] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await Api.utility.get(route("search", { q: search }));
      setProjects(new Modeler(response.data).build());
      setQuery(search);
      setShowResults(true);
    } catch (error) {
      flashMessageFromResponseError(error);
    }
  };
  return /* @__PURE__ */ React.createElement("div", { className: "flex-1 px-4 py-12 text-center" }, /* @__PURE__ */ React.createElement(H1, null, "Find a LifeWeb"), /* @__PURE__ */ React.createElement("p", { className: "mb-6 text-sm" }, "Having trouble finding a LifeWeb? Search for the person's name below and we'll do our best to get you there!"), /* @__PURE__ */ React.createElement(
    "form",
    {
      className: "flex gap-4 items-stretch justify-center",
      onSubmit: handleSubmit
    },
    /* @__PURE__ */ React.createElement("label", { className: "sr-only", htmlFor: "search" }, "Enter a name..."),
    /* @__PURE__ */ React.createElement(
      "input",
      {
        className: "border-1/2 border-black font-base px-4 py-2 placeholder-gray-400 text-gray-900",
        id: "search",
        onInput: (e) => setSearch(e.currentTarget.value),
        placeholder: "Enter a name...",
        type: "text",
        value: search
      }
    ),
    /* @__PURE__ */ React.createElement(
      "button",
      {
        className: `${search.length < 2 ? "bg-gray-300" : "bg-lifeweb-blue"} px-8 inline-flex items-center`,
        disabled: search.length < 2,
        type: "submit"
      },
      /* @__PURE__ */ React.createElement("span", { className: "sr-only" }, "Search"),
      /* @__PURE__ */ React.createElement(Icon, { alt: "Search icon", icon: "search" })
    )
  ), showResults && /* @__PURE__ */ React.createElement(SearchResults, { results: projects, search: query }));
};
export default Search;
