import Api from "@/api/Api";
import ActivityIndicator from "@/components/activity/ActivityIndicator";
import {
  useIdentityQuery,
  useProjectQuery,
  useProjectUserQuery
} from "@/queries";
import { flashMessageFromResponseError } from "@/utils/alert_utils";
import Modeler from "@/utils/modeler/modeler";
import { route } from "@/utils/route_utils";
import {
  keepPreviousData,
  useInfiniteQuery,
  useQuery
} from "@tanstack/react-query";
import queryString from "query-string";
import React, { useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import Joyride from "react-joyride";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import MemoriesFilterBar from "../memories/components/MemoriesFilterBar";
import MemoriesHeader from "../memories/components/MemoriesHeader";
import MemoriesPlaceholder from "../memories/MemoriesPlaceholder";
import MemoryShowModal from "../memories/modules/MemoryShowModal";
import ProjectMemoryTile from "./components/ProjectMemoryTile";
import MemoriesOnboardingModal from "./modules/MemoriesOnboardingModal";
import ProjectOnboardingTooltip from "./modules/ProjectOnboardingTooltip";
const getQueryFromSearch = (search) => {
  const queryParams = new URLSearchParams(search);
  const getAsResult = (param) => (queryParams.get(param) || "").split(",").filter((item) => item).map((item) => ({ id: item, name: item }));
  const q = {
    sort: queryParams.get("sort") || "-created_at",
    themes: getAsResult("themes"),
    users: getAsResult("users")
  };
  return q;
};
const ProjectDetail = () => {
  const history = useHistory();
  const { memory_id, slug } = useParams();
  const { search } = useLocation();
  const { data: project } = useProjectQuery(slug);
  const { data: projectUser } = useProjectUserQuery(slug);
  const { data: user } = useIdentityQuery();
  const { data: contributors } = useQuery({
    placeholderData: [],
    queryKey: ["project", { slug }, "contributors"],
    queryFn: async () => {
      try {
        const response = await Api.utility.get(
          route("projectContributors", { project_id: slug })
        );
        return response.data;
      } catch (error) {
        return Promise.reject(error);
      }
    }
  });
  const fetchMemories = async ({ pageParam }) => {
    try {
      const url = queryString.stringifyUrl(
        {
          url: route("projectMemories", { project_id: slug }),
          query: {
            sort: query.sort,
            themes: query.themes.map(({ name }) => name).join(",") || void 0,
            users: query.users.map(({ name }) => name).join(",") || void 0,
            "page[number]": pageParam
          }
        },
        { arrayFormat: "bracket" }
      );
      const response = await Api.utility.get(url);
      const modeledData = new Modeler(response.data).build();
      return { links: response.data.links, data: modeledData };
    } catch (err) {
      return Promise.reject(flashMessageFromResponseError(err));
    }
  };
  const {
    data: memoriesData,
    fetchNextPage,
    isFetchingNextPage,
    isFetching: isFetchingMemories,
    isLoading: isLoadingMemories,
    refetch: refetchMemories
  } = useInfiniteQuery({
    queryKey: ["memories", { project: slug }],
    queryFn: fetchMemories,
    getNextPageParam: (lastPage) => {
      var _a, _b, _c;
      return (_c = (_b = (_a = lastPage == null ? void 0 : lastPage.links) == null ? void 0 : _a.next) == null ? void 0 : _b.match(/page\[number]=(\d+)/)) == null ? void 0 : _c[1];
    },
    initialPageParam: 0,
    placeholderData: keepPreviousData
  });
  const { ref: inViewRef, inView } = useInView();
  useEffect(() => {
    if (inView && !isFetchingMemories) {
      fetchNextPage();
    }
  }, [inView, isFetchingMemories]);
  const memories = useMemo(() => {
    if (!memoriesData)
      return [];
    return memoriesData.pages.map((page) => page.data).flat();
  }, [memoriesData]);
  const groupedMemories = useMemo(() => {
    const chunkSize = 6;
    const result = [];
    for (let i = 0; i < memories.length; i += chunkSize) {
      const chunk = memories.slice(i, i + chunkSize);
      result.push(chunk);
    }
    return result;
  }, [memories]);
  const { data: memoryQuotes } = useQuery({
    placeholderData: [],
    queryKey: ["memoryQuotes", { project: slug }],
    queryFn: async () => {
      try {
        const { data } = await Api.utility.get(
          route("memoryQuotes", { project_id: slug })
        );
        return new Modeler(data).build();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    refetchOnWindowFocus: false
  });
  const isAdmin = (projectUser == null ? void 0 : projectUser.role) === "admin";
  const [query, setQuery] = useState(getQueryFromSearch(search));
  useEffect(() => {
    refetchMemories({ cancelRefetch: true });
  }, [search, fetchNextPage]);
  useEffect(() => {
    const params = new URLSearchParams();
    for (const key in query) {
      if (query.hasOwnProperty(key) && query[key].length > 0) {
        if (key === "sort") {
          if (query.sort !== "-created_at") {
            params.set("sort", query.sort);
          }
        } else {
          params.set(key, query[key].map(({ name }) => name).join(","));
        }
      }
    }
    history.replace({ search: params.toString() });
  }, [query, history]);
  const onboardingSteps = [
    {
      target: window.innerWidth < 640 ? "#main-menu-button" : "#settings-button",
      content: /* @__PURE__ */ React.createElement("div", { className: "text-center" }, "Use the Settings menu to customize ", project == null ? void 0 : project.preferred_name, "'s LifeWeb."),
      title: "You've created a LifeWeb!",
      disableBeacon: true
    },
    {
      target: "body",
      closeLabel: "Post memories later",
      content: /* @__PURE__ */ React.createElement(MemoriesOnboardingModal, { project }),
      placement: "center",
      title: "Time for memories!"
    }
  ];
  const [isOnboardingJoyrideVisible, setIsOnboardingJoyrideVisible] = useState(false);
  useEffect(() => {
    if (!projectUser)
      return;
    const onboardingCookie = document.cookie.includes("onboarding=true");
    if (onboardingCookie) {
      document.cookie = "onboarding=true; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      window.setTimeout(() => setIsOnboardingJoyrideVisible(true), 200);
    }
  }, [projectUser]);
  if (!project)
    return;
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    Joyride,
    {
      run: isOnboardingJoyrideVisible,
      steps: onboardingSteps,
      tooltipComponent: ProjectOnboardingTooltip,
      disableCloseOnEsc: true,
      disableOverlayClose: true,
      disableScrolling: true,
      hideCloseButton: true
    }
  ), /* @__PURE__ */ React.createElement(
    MemoriesHeader,
    {
      isAdmin,
      project,
      projectUser
    }
  ), /* @__PURE__ */ React.createElement(
    MemoriesFilterBar,
    {
      onChangeQuery: setQuery,
      project,
      query,
      themes: project.themes.filter((theme) => theme.status === "active"),
      userNames: contributors
    }
  ), /* @__PURE__ */ React.createElement("div", { className: "bg-lifeweb-gradient md:py-32 py-52" }, groupedMemories.length > 0 && /* @__PURE__ */ React.createElement("div", { className: "flex flex-col md:grid md:grid-cols-2 lg:grid-cols-3 gap-8 items-center max-w-lg md:max-w-screen-xl mx-auto px-4 py-24 md:px-9 md:py-10" }, groupedMemories.map((chunk, index) => /* @__PURE__ */ React.createElement(React.Fragment, { key: index }, chunk.map((memory) => /* @__PURE__ */ React.createElement(
    ProjectMemoryTile,
    {
      key: memory.id,
      user: projectUser,
      memory
    }
  )), memoryQuotes[index] && /* @__PURE__ */ React.createElement(
    Link,
    {
      to: route("projectMemory", {
        slug,
        memory_id: memoryQuotes[index].memory_id
      }),
      className: "block md:col-span-2 lg:col-span-3 text-slate-950 font-cursive text-6xl text-center py-8",
      key: memoryQuotes[index].id
    },
    memoryQuotes[index].text
  ))), memories.length > 0 && /* @__PURE__ */ React.createElement("div", { ref: inViewRef })), /* @__PURE__ */ React.createElement("div", { className: "flex justify-center p-3" }, !isLoadingMemories && memories.length === 0 && /* @__PURE__ */ React.createElement(MemoriesPlaceholder, { project }), (isLoadingMemories || isFetchingNextPage) && /* @__PURE__ */ React.createElement(ActivityIndicator, null))), /* @__PURE__ */ React.createElement(
    MemoryShowModal,
    {
      currentUser: user,
      isAdmin: !!isAdmin,
      memories,
      memoryIds: memories.map((memory) => memory.id),
      onClickClose: () => history.push(route("project", { slug })),
      onNavigate: () => void 0,
      onUpdateMemory: () => refetchMemories(),
      project,
      selectedMemoryId: memory_id,
      modeledProjectUser: projectUser,
      shouldDisplayNavigation: true,
      setModalNotificationSettingVisible: () => void 0,
      setModeledProjectUser: () => void 0
    }
  ));
};
export default ProjectDetail;
