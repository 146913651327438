import { useMemo } from "react";
const useSortedCategories = (categories = [], options = {}) => {
  const sortByFeatured = (a, b) => (b.featured ? 1 : 0) - (a.featured ? 1 : 0);
  const sortByName = (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  const sortByPriority = (a, b) => (a.priority || 9999) - (b.priority || 9999);
  const sortedCategories = useMemo(() => {
    if (categories.length === 0)
      return {};
    if (categories.every((category) => !category.parent_id)) {
      categories.sort(
        categories.some((category) => !!category.priority) ? sortByPriority : sortByName
      );
      return { Category: categories };
    }
    const sorted = {};
    const parents = [
      ...categories.filter((category) => !category.parent_id),
      ...categories.filter((category) => category.parent && Object.keys(category.parent).length > 0).map(({ parent }) => parent)
    ];
    parents.sort(
      parents.some((category) => !!category.priority) ? sortByPriority : sortByName
    );
    if (options.sort === "featured")
      parents.sort(sortByFeatured);
    parents.forEach(({ name }) => {
      sorted[name] = [];
    });
    const children = categories.filter((category) => category.parent_id);
    children.forEach((child) => {
      const parent = child.parent || parents.find(({ id }) => id === child.parent_id);
      if (parent)
        sorted[parent.name].push(child);
    });
    Object.keys(sorted).forEach((key) => {
      sorted[key].sort(
        sorted[key].some((category) => !!category.priority) ? sortByPriority : sortByName
      );
    });
    return sorted;
  }, [categories]);
  return sortedCategories;
};
export { useSortedCategories as default };
