import Button from "@/components/button/Button";
import React from "react";
const ProjectOnboardingTooltip = ({
  isLastStep,
  step,
  closeProps,
  primaryProps,
  tooltipProps
}) => /* @__PURE__ */ React.createElement("div", { className: "max-w-screen-sm px-1 w-screen" }, /* @__PURE__ */ React.createElement(
  "div",
  {
    className: "bg-white rounded-md p-4 md:p-6",
    ...tooltipProps
  },
  step.title && /* @__PURE__ */ React.createElement("div", { className: "flex justify-center" }, /* @__PURE__ */ React.createElement("h2", { className: "font-heading my-4 text-3xl text-center leading-9 font-semibold text-black" }, step.title)),
  /* @__PURE__ */ React.createElement("div", { className: "mb-6" }, step.content),
  /* @__PURE__ */ React.createElement("div", { className: "max-w-xs mx-auto" }, !isLastStep && /* @__PURE__ */ React.createElement(Button, { color: "primary", ...primaryProps }, step.nextLabel || "OK"), isLastStep && /* @__PURE__ */ React.createElement(
    "button",
    {
      className: "block underline text-lifeweb-blue mx-auto text-center",
      ...closeProps
    },
    step.closeLabel || "Close"
  ))
));
export default ProjectOnboardingTooltip;
