import React from "react";
import { useResponsive } from "react-hooks-responsive";
import { useTranslation } from "react-i18next";
import IconButton from "@/components/button/IconButton";
import CloseButton from "@/components/modal/CloseButton";
import { route } from "@/utils/route_utils";
import { breakpoints } from "@/utils/screen_utils";
const MemoryShowHeader = (props) => {
  var _a;
  const { currentUser, memory, onClickClose, project, shouldAllowEdit } = props;
  const { t } = useTranslation();
  const { screenIsAtMost } = useResponsive(breakpoints);
  const isTouch = screenIsAtMost("tablet");
  const editMemoryLink = shouldAllowEdit && (currentUser == null ? void 0 : currentUser.id) === ((_a = memory.user) == null ? void 0 : _a.id) ? route("editMemory", { slug: project.slug, memory_id: memory.id }) : route("projectCurateMemory", {
    slug: project.slug,
    memory_id: memory.id
  });
  const renderEditMobile = () => {
    if (shouldAllowEdit) {
      return /* @__PURE__ */ React.createElement(
        IconButton,
        {
          icon: "pencil",
          link: editMemoryLink,
          size: "small",
          text: t("views.memories.actions.edit")
        }
      );
    }
    return /* @__PURE__ */ React.createElement("div", null);
  };
  const renderContent = () => {
    if (isTouch) {
      return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
        "div",
        {
          className: shouldAllowEdit ? "memories__header-row" : "memories__header-row-justified-end"
        },
        renderEditMobile(),
        /* @__PURE__ */ React.createElement(CloseButton, { onClose: onClickClose })
      ));
    }
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: shouldAllowEdit ? "memories__header-row" : "memories__header-row-justified-end"
      },
      shouldAllowEdit && /* @__PURE__ */ React.createElement(
        IconButton,
        {
          icon: "pencil",
          link: editMemoryLink,
          size: "small",
          text: t("views.memories.actions.edit")
        }
      ),
      /* @__PURE__ */ React.createElement(CloseButton, { onClose: onClickClose })
    );
  };
  return /* @__PURE__ */ React.createElement("div", { className: "memories__header" }, renderContent());
};
export default MemoryShowHeader;
