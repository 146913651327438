import Button from "@/components/button/Button";
import IconButton from "@/components/button/IconButton";
import Modal from "@/components/modal/Modal";
import React, { useState } from "react";
import { ReflectionDetailBody } from "./ReflectionDetail";
const ReflectionPreview = ({ onClose, onSubmit, reflection }) => {
  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = () => {
    setSubmitting(true);
    onSubmit();
  };
  return /* @__PURE__ */ React.createElement(Modal, { isVisible: !!reflection, onClose }, /* @__PURE__ */ React.createElement("div", { className: "flex items-center justify-between mb-4" }, /* @__PURE__ */ React.createElement(
    IconButton,
    {
      icon: "arrow",
      onClick: onClose,
      size: "small",
      text: "Edit Reflection"
    }
  ), /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "primary",
      isDisabled: submitting,
      onClick: handleSubmit,
      style: { maxWidth: "10rem" }
    },
    submitting ? "Submitting..." : "Submit Reflection"
  )), /* @__PURE__ */ React.createElement(ReflectionDetailBody, { reflection }));
};
export default ReflectionPreview;
