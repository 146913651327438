import Uppy from "@uppy/core";
import FileInput from "@uppy/file-input";
import ThumbnailGenerator from "@uppy/thumbnail-generator";
import XHRUpload from "@uppy/xhr-upload";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "@/api/Api";
import Button from "@/components/button/Button";
import BackButton from "@/components/modal/BackButton";
import CloseButton from "@/components/modal/CloseButton";
import Modal from "@/components/modal/Modal";
import {
  flashErrorMessage,
  flashMessageFromResponseError
} from "@/utils/alert_utils";
import Modeler from "@/utils/modeler/modeler";
import { route } from "@/utils/route_utils";
import FILE_UPLOAD_SETTINGS from "@/variables/file_uploads";
const ProjectImageModal = (props) => {
  const {
    isOnboarding,
    isVisible,
    onClose,
    onUpdateProject,
    onUpdateProjectField,
    onUpdateStepNumber,
    project,
    stepNumber,
    modeledProjectUser
  } = props;
  const [displayImage, setDisplayImage] = useState(
    isOnboarding ? null : project.cover_image_url
  );
  const [image, setImage] = useState(null);
  const [saveEnabled, setSaveEnabled] = useState(true);
  const targetRef = useRef(null);
  const { t } = useTranslation();
  const header = isOnboarding ? t("views.memories.projectNew.image.titles.upload") : t("views.memories.projectEdit.image.header");
  const updateImage = (selectedImage) => {
    setImage(selectedImage);
    setDisplayImage(selectedImage.thumbnailUrl);
  };
  const handleSaveClick = () => {
    if (isOnboarding) {
      onUpdateProjectField(image);
      onUpdateStepNumber(stepNumber + 1);
    } else {
      saveImage();
    }
  };
  const saveImage = async () => {
    const url = route("projectsUpdate", { slug: project.slug });
    const body = {
      project: {
        cover_image: image.jsonData
      }
    };
    setSaveEnabled(false);
    try {
      const response = await Api.utility.patch(url, body);
      const updatedProject = new Modeler(response.data).build();
      onUpdateProject(updatedProject);
      onClose();
    } catch (err) {
      flashMessageFromResponseError(err);
    }
    setSaveEnabled(true);
  };
  useEffect(() => {
    const uppy = new Uppy({
      autoProceed: true,
      restrictions: {
        maxFileSize: FILE_UPLOAD_SETTINGS.maxFileSize,
        allowedFileTypes: FILE_UPLOAD_SETTINGS.allowedFileTypes,
        maxNumberOfFiles: 1
      }
    });
    uppy.use(FileInput, {
      target: targetRef.current,
      locale: {
        strings: {
          chooseFiles: ""
        }
      }
    });
    uppy.use(ThumbnailGenerator, {
      thumbnailHeight: 600
    });
    uppy.use(XHRUpload, {
      endpoint: "/upload"
    });
    uppy.on("restriction-failed", (file, error) => {
      flashErrorMessage(error.toString());
    });
    uppy.on("complete", (result) => {
      const formattedImage = result.successful.map((selectedImage) => ({
        id: selectedImage.id,
        jsonData: JSON.stringify(selectedImage.response.body.data),
        thumbnailUrl: selectedImage.uploadURL
      }));
      updateImage(formattedImage[0]);
      setSaveEnabled(true);
      uppy.cancelAll();
    });
    uppy.on("thumbnail:generated", (file, preview) => {
      setDisplayImage(preview);
      setSaveEnabled(false);
    });
  }, [isVisible]);
  const renderTopButton = () => {
    if (isOnboarding) {
      return /* @__PURE__ */ React.createElement("div", { className: "project-image-modal__back-wrapper" }, /* @__PURE__ */ React.createElement(BackButton, { onBack: () => onUpdateStepNumber(stepNumber - 1) }));
    } else {
      return /* @__PURE__ */ React.createElement("div", { className: "project-image-modal__close-wrapper" }, /* @__PURE__ */ React.createElement(CloseButton, { onClose }));
    }
  };
  const renderUploadedImage = () => {
    return /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-6-tablet project-image-modal__image-wrapper" }, /* @__PURE__ */ React.createElement("img", { src: displayImage })));
  };
  const renderSaveButton = () => {
    return /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-6-tablet" }, /* @__PURE__ */ React.createElement(
      Button,
      {
        color: "primary",
        loading: !saveEnabled,
        isDisabled: !saveEnabled,
        onClick: handleSaveClick
      },
      t("views.memories.projectEdit.saveButton")
    )));
  };
  return /* @__PURE__ */ React.createElement(Modal, { isVisible, size: "medium" }, /* @__PURE__ */ React.createElement("div", { className: "project-image-modal" }, renderTopButton(), /* @__PURE__ */ React.createElement("div", { className: "columns" }, /* @__PURE__ */ React.createElement("div", { className: "column" }, /* @__PURE__ */ React.createElement("h1", null, header))), isOnboarding && /* @__PURE__ */ React.createElement("div", { className: "columns" }, /* @__PURE__ */ React.createElement("div", { className: "column" }, /* @__PURE__ */ React.createElement("h5", null, t("views.memories.projectNew.image.text.change")))), !!displayImage && renderUploadedImage(), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-6-tablet" }, /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames("project-image-modal__upload-button", {
        "project-image-modal__replace-button": !!displayImage
      }),
      ref: targetRef
    }
  ))), !!displayImage && renderSaveButton(), /* @__PURE__ */ React.createElement("div", { className: "columns" }, /* @__PURE__ */ React.createElement("div", { className: "column" }, /* @__PURE__ */ React.createElement("p", null, t("views.memories.projectNew.image.text.fileSize"))))));
};
export default ProjectImageModal;
