import BackLink from "@/components/link/BackLink";
import { useProjectQuery } from "@/queries";
import { route } from "@/utils/route_utils";
import { addHyperlink } from "@/utils/string_utils";
import React from "react";
import { useParams } from "react-router-dom";
const Obituary = () => {
  const { slug } = useParams();
  const { data: project } = useProjectQuery(slug);
  if (!project)
    return;
  const obituary = addHyperlink(project.obituary);
  return /* @__PURE__ */ React.createElement("div", { className: "section with-medium-gradient-background with-min-height" }, /* @__PURE__ */ React.createElement("div", { className: "container is-desktop" }, /* @__PURE__ */ React.createElement(
    BackLink,
    {
      to: route("project", { slug }),
      text: `Back to ${project.preferred_name}'s LifeWeb`
    }
  ), /* @__PURE__ */ React.createElement("h1", { className: "obituary__header" }, "Obituary"), /* @__PURE__ */ React.createElement("div", { className: "obituary__content" }, /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "obituary__obituary prose max-w-none",
      dangerouslySetInnerHTML: { __html: obituary }
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "obituary__view-memories-wrapper" }, /* @__PURE__ */ React.createElement("div", { className: "column" }, /* @__PURE__ */ React.createElement(
    "a",
    {
      className: "button is-primary obituary__small-button",
      href: route("project", { slug })
    },
    "View Memories"
  )))));
};
export default Obituary;
