document.addEventListener("DOMContentLoaded", () => {
  const navbarBurger = document.querySelector(".navbar__burger");
  const navbarBurgerSecondary = document.querySelector(
    ".navbar__burger--secondary"
  );
  const navLinks = document.querySelectorAll(".navbar__link");
  const navLinksContainer = document.querySelector(".navbar__links");
  const navMenu = document.getElementById("navMenu");
  const navLinksMobile = document.querySelectorAll(".navbar__link--mobile");
  const navLinksDesktop = document.querySelectorAll(".navbar__link--desktop");
  const myLifewebs = document.getElementById("my-lifewebs");
  const lifewebsList = document.querySelector(".lifewebs-list");
  const projectHeader = document.querySelector(".project-header");
  const applicationContainer = document.querySelector(".application");
  const mobileTopTransparent = document.querySelector(
    ".navbar__mobile-top--transparent"
  );
  const mobileTopWhite = document.querySelector(".navbar__mobile-top--white");
  if (navbarBurgerSecondary && navbarBurger) {
    navbarBurgerSecondary.addEventListener("click", () => {
      window.scrollTo(0, 0);
      if (projectHeader) {
        projectHeader.classList.toggle("is-overflow-hidden");
      }
      const target = navbarBurger.dataset.target;
      const $target = document.getElementById(target);
      navbarBurger.classList.toggle("is-active");
      $target.classList.toggle("is-active");
      navLinksContainer.classList.toggle("is-active");
      mobileTopTransparent.classList.toggle("is-active");
      mobileTopWhite.classList.toggle("is-active");
      navLinks.forEach((link) => {
        link.classList.toggle("is-active");
      });
      if (navbarBurger.classList.contains("is-active")) {
        disableScroll();
      } else {
        enableScroll();
      }
    });
  }
  if (navbarBurger) {
    navbarBurger.addEventListener("click", () => {
      window.scrollTo(0, 0);
      if (projectHeader) {
        projectHeader.classList.toggle("is-overflow-hidden");
      }
      const target = navbarBurger.dataset.target;
      const $target = document.getElementById(target);
      navbarBurger.classList.toggle("is-active");
      $target.classList.toggle("is-active");
      navLinksContainer.classList.toggle("is-active");
      if (mobileTopTransparent && mobileTopWhite) {
        mobileTopTransparent.classList.toggle("is-active");
        mobileTopWhite.classList.toggle("is-active");
      }
      navLinks.forEach((link) => {
        link.classList.toggle("is-active");
      });
      if (navbarBurger.classList.contains("is-active")) {
        disableScroll();
      } else {
        enableScroll();
      }
    });
  }
  if (myLifewebs) {
    myLifewebs.addEventListener("click", () => {
      lifewebsList.classList.toggle("is-active");
    });
  }
  function resize() {
    if (navbarBurger && navbarBurger.classList.contains("is-active") || navbarBurgerSecondary && navbarBurgerSecondary.classList.contains("is-active")) {
      if (projectHeader) {
        projectHeader.classList.add("is-overflow-hidden");
      }
      if (mobileTopWhite && mobileTopTransparent) {
        mobileTopWhite.classList.remove("is-active");
        mobileTopTransparent.classList.add("is-active");
      }
      navbarBurger.classList.remove("is-active");
      navMenu.classList.remove("is-active");
      navLinksContainer.classList.remove("is-active");
      navLinksMobile.forEach((link) => {
        link.classList.remove("is-active");
      });
      navLinksDesktop.forEach((link) => {
        link.classList.add("is-active");
      });
    }
    if (!navbarBurger)
      return;
    if (navbarBurger.classList.contains("is-active")) {
      disableScroll();
    } else {
      enableScroll();
    }
  }
  window.onresize = resize;
  function disableScroll() {
    applicationContainer.classList.add("is-scroll-disabled");
  }
  function enableScroll() {
    applicationContainer.classList.remove("is-scroll-disabled");
  }
});
