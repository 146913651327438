class Modeler {
  constructor(data, includes = null) {
    this._data = null;
    this._indexData = [];
    this._includes = [];
    this._isIndex = false;
    this._isIndexBuilt = false;
    this._isModelBuilt = false;
    this.build = (includeRelationships = true) => {
      if (this._isIndex) {
        return this.buildIndex();
      }
      return this.buildModel(includeRelationships);
    };
    this.get = (...attributes) => {
      if (this._isIndex) {
        return this.getIndexAttributes(attributes);
      }
      return this.getModelAttributes(attributes);
    };
    // ***************
    // PRIVATE METHODS
    // ***************
    this.buildIndex = () => {
      if (this._isIndexBuilt) {
        return this._indexData.map((entity) => entity.build());
      } else {
        this.createIndexModelers();
        this._isIndexBuilt = true;
        return this.buildIndex();
      }
    };
    this.buildModel = (includeRelationships) => {
      if (this._isModelBuilt) {
        return this._model;
      }
      const data = this._data;
      const keys = ["id", ...Object.keys(data.attributes)];
      if ("relationships" in data && includeRelationships) {
        keys.push(...Object.keys(data.relationships));
      }
      this.getModelAttributes(keys);
      this._isModelBuilt = true;
      return this._model;
    };
    this.createIndexModelers = () => {
      for (const entity of this._data) {
        const modeler = new Modeler(this.createModelData(entity), this._includes);
        this._indexData.push(modeler);
      }
    };
    this.createModelData = (model) => {
      return {
        data: model
      };
    };
    this.findRelationship = (id, type) => {
      return this._includes.find(
        (include) => include.id === id && include.type === type
      );
    };
    this.getIndexAttributes = (attributes) => {
      if (this._isIndexBuilt) {
        return this._indexData.map(
          (entity) => entity.get(...attributes)
        );
      } else {
        this.createIndexModelers();
        this._isIndexBuilt = true;
        return this.getIndexAttributes(attributes);
      }
    };
    this.getModelAttributes = (attributes) => {
      const pulledAttributes = {};
      for (const key of attributes) {
        pulledAttributes[key] = this.getSingleAttribute(key);
      }
      if (attributes.length > 1) {
        return pulledAttributes;
      }
      return pulledAttributes[attributes[0]];
    };
    this.getSingleAttribute = (attribute) => {
      if (attribute in this._model) {
        return this._model[attribute];
      }
      const data = this._data;
      if (attribute === "id") {
        const value = data.id;
        this._model[attribute] = value;
        return value;
      } else if (attribute in data.attributes) {
        const value = data.attributes[attribute];
        this._model[attribute] = value;
        return value;
      } else if (data.relationships && attribute in data.relationships) {
        const relationship = data.relationships[attribute];
        if (Array.isArray(relationship.data)) {
          const builtData = relationship.data.map((relation) => {
            const included = this.findRelationship(relation.id, relation.type);
            if (included === void 0) {
              return {
                id: relation.id
              };
            }
            return new Modeler(
              this.createModelData(included),
              this._includes
            ).build(true);
          });
          this._model[attribute] = builtData;
          return builtData;
        } else {
          if (relationship.data === null) {
            this._model[attribute] = null;
            return null;
          }
          const included = this.findRelationship(
            relationship.data.id,
            relationship.data.type
          );
          if (included === void 0) {
            const emptyBuildData = {
              id: relationship.data.id
            };
            this._model[attribute] = emptyBuildData;
            return emptyBuildData;
          }
          const builtData = new Modeler(this.createModelData(included)).build(
            false
          );
          this._model[attribute] = builtData;
          return builtData;
        }
      }
      return void 0;
    };
    if (!data || typeof data !== "object" || !("data" in data) || !data.data) {
      this._data = {
        id: null,
        type: null,
        attributes: {}
      };
      this._model = {};
      this._isModelBuilt = true;
      return null;
    }
    this._data = data.data;
    if (Array.isArray(this._data)) {
      this._isIndex = true;
      this._model = [];
    } else {
      this._model = {};
    }
    if (includes) {
      this._includes = includes;
    } else if (data.included) {
      this._includes = data.included;
    }
  }
}
export { Modeler as default };
