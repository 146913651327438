import classNames from "classnames";
import React from "react";
import Icon from "@/components/icon/Icon";
const incrementIcon = (to) => {
  return to === "next" ? "triangleRight" : "triangleLeft";
};
const Paginator = (props) => {
  const { currentPage, onChangePage, totalPages } = props;
  const hasMoreThanOnePage = totalPages > 1;
  const canShowLeftIncrement = hasMoreThanOnePage && currentPage > 1;
  const canShowRightIncrement = hasMoreThanOnePage && currentPage !== totalPages;
  const incrementPage = (to) => {
    if (to === "next") {
      return currentPage === totalPages ? totalPages : currentPage + 1;
    }
    return currentPage > 1 ? currentPage - 1 : 1;
  };
  const renderPageIncrement = (to) => {
    const page = incrementPage(to);
    return /* @__PURE__ */ React.createElement("a", { className: "paginator__increment", onClick: () => onChangePage(page) }, /* @__PURE__ */ React.createElement(Icon, { icon: incrementIcon(to) }));
  };
  const renderPageLink = (page) => {
    return /* @__PURE__ */ React.createElement(
      "a",
      {
        className: classNames("paginator__page", {
          "is-active": currentPage === page
        }),
        key: page,
        onClick: () => onChangePage(page)
      },
      page
    );
  };
  const renderPageLinks = () => {
    const pageNumbers = Array.from(Array(5).keys()).map((_value, index) => {
      return index + currentPage - 2;
    }).filter((pageNumber) => pageNumber > 1 && pageNumber < totalPages);
    return /* @__PURE__ */ React.createElement(React.Fragment, null, pageNumbers[0] !== 2 && hasMoreThanOnePage && /* @__PURE__ */ React.createElement("span", { className: "paginator__ellipsis" }, "..."), pageNumbers.map((pageNumber) => renderPageLink(pageNumber)), !!pageNumbers.length && !!(pageNumbers[pageNumbers.length - 1] !== totalPages - 1) && hasMoreThanOnePage && /* @__PURE__ */ React.createElement("span", { className: "paginator__ellipsis" }, "..."));
  };
  if (totalPages < 2) {
    return null;
  }
  return /* @__PURE__ */ React.createElement("div", { className: "paginator" }, canShowLeftIncrement && renderPageIncrement("prev"), renderPageLink(1), renderPageLinks(), totalPages > 1 && renderPageLink(totalPages), canShowRightIncrement && renderPageIncrement("next"));
};
export default Paginator;
