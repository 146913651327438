import Api from "@/api/Api";
import ActivityIndicator from "@/components/activity/ActivityIndicator";
import SubmitOrCancelButtons from "@/components/form/SubmitOrCancelButtons";
import TextEditor from "@/components/form/TextEditor";
import { useBreadcrumb } from "@/contexts/providers/BreadcrumbContext";
import { flashSuccessMessage } from "@/utils/alert_utils";
import Modeler from "@/utils/modeler/modeler";
import { route } from "@/utils/route_utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import SettingsTitle from "../SettingsTitle";
const WelcomeMessageEdit = () => {
  const breadcrumb = useBreadcrumb();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { slug } = useParams();
  const [changed, setChanged] = useState(false);
  const [project, setProject] = useState();
  useEffect(() => {
    const fetchProject = async () => {
      var _a;
      const { data } = await Api.utility.get(route("projectsUpdate", { slug }));
      const modeledProject = new Modeler(data).build();
      setProject(modeledProject);
      breadcrumb.setCrumbs([
        {
          label: `${(_a = modeledProject.prefix) != null ? _a : ""} ${modeledProject.full_name}`,
          href: route("project", { slug })
        },
        {
          label: "Admin Settings"
        },
        {
          label: "Welcome Message"
        }
      ]);
      mixpanel.track("settings_viewed", {
        kind: "project",
        project_id: modeledProject.id,
        type: "welcome-message"
      });
    };
    fetchProject();
  }, []);
  const handleUpdateWelcomeMessage = async (e) => {
    e.preventDefault();
    await Api.utility.patch(route("projectsUpdate", { slug }), { project });
  };
  const mutation = useMutation({
    mutationFn: handleUpdateWelcomeMessage,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["project", { slug }]
      });
      flashSuccessMessage("Welcome Message successfully updated");
      history.push(route("project", { slug }));
    }
  });
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(SettingsTitle, null, "Welcome Message"), project && /* @__PURE__ */ React.createElement("form", { method: "patch", onSubmit: mutation.mutate }, /* @__PURE__ */ React.createElement("p", { className: "mb-4" }, "Welcome your community and let them know how to use this LifeWeb page. Need inspiration? See examples of messages other families have written", /* @__PURE__ */ React.createElement(
    "a",
    {
      href: "https://www.lifeweb360.com/welcome-message-ideas",
      target: "_blank",
      className: "text-lifeweb-brown"
    },
    " ",
    "here"
  ), "."), /* @__PURE__ */ React.createElement(
    TextEditor,
    {
      onChange: (welcomeMessage, _, source) => {
        setChanged(source === "user");
        setProject({ ...project, welcome_message: welcomeMessage });
      },
      value: project.welcome_message
    }
  ), /* @__PURE__ */ React.createElement(
    SubmitOrCancelButtons,
    {
      disabled: !changed,
      onCancel: () => history.push(route("project", {
        slug
      }))
    }
  )), !project && /* @__PURE__ */ React.createElement(ActivityIndicator, null));
};
export default WelcomeMessageEdit;
