import { useEffect, useState } from "react";
export const useOnLoadImages = (ref) => {
  const [status, setStatus] = useState(false);
  const updateStatus = (images) => {
    setStatus(
      images.map((image) => image.complete).every((item) => item === true)
    );
  };
  useEffect(() => {
    if (!(ref == null ? void 0 : ref.current))
      return;
    const imagesLoaded = Array.from(ref.current.querySelectorAll("img"));
    if (imagesLoaded.length === 0) {
      setStatus(true);
      return;
    }
    imagesLoaded.forEach((image) => {
      image.addEventListener("load", () => updateStatus(imagesLoaded), {
        once: true
      });
      image.addEventListener("error", () => updateStatus(imagesLoaded), {
        once: true
      });
    });
  }, [ref]);
  return status;
};
