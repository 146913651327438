import Api from "@/api/Api";
import { H2 } from "@/components/typography";
import Modeler from "@/utils/modeler/modeler";
import { route } from "@/utils/route_utils";
import Color from "color";
import indefinite from "indefinite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { NewProjectForm } from "../projects/ProjectNew";
const GroupProjectNew = ({ report = false }) => {
  const { slug } = useParams();
  const [group, setGroup] = useState();
  const [success, setSuccess] = useState(false);
  const [user, setUser] = useState();
  useEffect(() => {
    if (report)
      return;
    mixpanel.track("project_started", {
      kind: "project",
      type: "create"
    });
  }, [report]);
  useEffect(() => {
    const fetchGroup = async () => {
      const url = route("group", { slug });
      const response = await Api.utility.get(url);
      setGroup(new Modeler(response.data).build());
    };
    const fetchUserIdentity = async () => {
      try {
        const response = await Api.utility.get(route("userShow"));
        setUser(new Modeler(response.data).build());
      } catch (e) {
        setUser(null);
      }
    };
    fetchGroup();
    fetchUserIdentity();
  }, []);
  useEffect(() => {
    if (!group || !report)
      return;
    mixpanel.track("groupreport_started", {
      kind: "group",
      group_name: group.slug
    });
  }, [group, report]);
  const afterSubmit = (_) => {
    mixpanel.track("groupreport_submitted", {
      kind: "group",
      group_name: group.slug
    });
    setSuccess(true);
  };
  if (!group)
    return;
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", { className: "p-8", style: { backgroundColor: group.primary_color } }, /* @__PURE__ */ React.createElement("a", { href: route("groupShow", { slug }) }, /* @__PURE__ */ React.createElement(
    "img",
    {
      className: "block max-h-52 mx-auto",
      src: group.logo_url,
      alt: group.name
    }
  ))), /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "min-h-screen sm:p-10",
      style: {
        backgroundColor: Color(group.secondary_color).alpha(0.5).string()
      }
    },
    /* @__PURE__ */ React.createElement("div", { className: "bg-white max-w-screen-md mx-auto p-6 min-h-screen sm:min-h-0 sm:p-10 sm:rounded" }, !success ? /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(H2, { className: "text-center" }, report ? `Report a ${group.member_name}'s passing` : "Create a LifeWeb"), report ? /* @__PURE__ */ React.createElement("p", { className: "my-4" }, "Please complete the form below to report the passing of", " ", indefinite(group.member_name), " to", " ", /* @__PURE__ */ React.createElement("span", { className: "font-bold" }, group.name), ". (", /* @__PURE__ */ React.createElement("span", { className: "font-bold text-red-700" }, "*"), " indicates a required field)") : /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("p", { className: "my-4" }, "Thank you for creating a LifeWeb for", " ", indefinite(group.name, {
      articleOnly: true
    }), " ", /* @__PURE__ */ React.createElement("span", { className: "font-bold" }, group.name), " ", group.member_name || "member", "!"), /* @__PURE__ */ React.createElement("p", { className: "my-4" }, "To get started, we need some information about the", " ", (group == null ? void 0 : group.member_name) || "person", " you'd like to honor. You can always edit this information later. (", /* @__PURE__ */ React.createElement("span", { className: "font-bold text-red-700" }, "*"), " indicates a required field)")), /* @__PURE__ */ React.createElement(
      NewProjectForm,
      {
        afterSubmit: report ? afterSubmit : void 0,
        report,
        group,
        user
      }
    )) : /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(H2, { className: "text-center" }, "Thank you!"), /* @__PURE__ */ React.createElement("p", { className: "my-4" }, "We sincerely appreciate you taking the time to share information about this ", group.member_name, " with us. We will review your submission and add this ", group.member_name, " to the group once validated."), /* @__PURE__ */ React.createElement("div", { className: "flex justify-center" }, /* @__PURE__ */ React.createElement(
      "a",
      {
        className: "border border-lifeweb-blue block font-semibold mt-8 px-3 py-3 rounded text-center text-lifeweb-blue md:w-auto w-full",
        href: route("groupShow", { slug })
      },
      "Return to ",
      group.name
    ))))
  ));
};
export default GroupProjectNew;
