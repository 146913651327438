import ConfirmationModal from "@/components/modal/ConfirmationModal";
import ModalCard from "@/components/modal/ModalCard";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
const CreateAccountWithSocialModal = (props) => {
  const { visible, onConfirm, onCancel } = props;
  const { t } = useTranslation();
  return /* @__PURE__ */ React.createElement(ModalCard, { position: "centerOfScreen", size: "small", isHidden: !visible }, /* @__PURE__ */ React.createElement(
    ConfirmationModal,
    {
      title: t("views.memories.usersNew.no_account_modal.title"),
      subtitle: t(
        "views.memories.usersNew.no_account_modal.subtitle"
      ),
      confirmText: t(
        "views.memories.usersNew.no_account_modal.create_account_btn"
      ),
      cancelText: t(
        "views.memories.usersNew.no_account_modal.no_thanks_btn"
      ),
      onConfirm,
      onCancel,
      isOnboarding: true,
      termsAndCondition: /* @__PURE__ */ React.createElement("p", { className: "authentication__text" }, /* @__PURE__ */ React.createElement(Trans, { i18nKey: "legal.account" }, /* @__PURE__ */ React.createElement("a", { href: t("legal.termsOfUse"), target: "_blank" }), /* @__PURE__ */ React.createElement("a", { href: t("legal.privacyPolicy"), target: "_blank" })))
    }
  ));
};
export default CreateAccountWithSocialModal;
