import Api from "@/api/Api";
import ActivityIndicator from "@/components/activity/ActivityIndicator";
import Button from "@/components/button/Button";
import Input from "@/components/form/Input";
import Select from "@/components/form/Select";
import SubmitOrCancelButtons from "@/components/form/SubmitOrCancelButtons";
import { useBreadcrumb } from "@/contexts/providers/BreadcrumbContext";
import { useProjectQuery } from "@/queries";
import { flashSuccessMessage } from "@/utils/alert_utils";
import { route } from "@/utils/route_utils";
import FILE_UPLOAD_SETTINGS from "@/variables/file_uploads";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Uppy from "@uppy/core";
import FileInput from "@uppy/file-input";
import ThumbnailGenerator from "@uppy/thumbnail-generator";
import XHRUpload from "@uppy/xhr-upload";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import SettingsTitle from "../SettingsTitle";
const defaultImageSrc = require("@/assets/images/project_default.jpg");
const uppy = new Uppy({
  restrictions: {
    maxFileSize: FILE_UPLOAD_SETTINGS.maxFileSize,
    allowedFileTypes: FILE_UPLOAD_SETTINGS.allowedFileTypes,
    maxNumberOfFiles: 1
  }
});
const ProjectDetailsEdit = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { slug } = useParams();
  const breadcrumb = useBreadcrumb();
  const { data } = useProjectQuery(slug);
  const formRef = useRef(null);
  const fileInputRef = useRef(null);
  const [formValid, setFormValid] = useState(false);
  const [coverImageError, setCoverImageError] = useState();
  const [coverImageJSON, setCoverImageJSON] = useState("");
  const [project, setProject] = useState();
  const [submitting, setSubmitting] = useState(false);
  const handleUpdateProject = async () => {
    await Api.utility.patch(route("projectsUpdate", { slug }), {
      project: { cover_image: coverImageJSON, ...project }
    });
  };
  const mutation = useMutation({
    mutationFn: handleUpdateProject,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["project", { slug }]
      });
      flashSuccessMessage("LifeWeb Details successfully updated");
      history.push(route("project", { slug }));
    }
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    if (!formRef.current.checkValidity()) {
      setSubmitting(false);
      return formRef.current.reportValidity();
    }
    if (fileInputRef.current.files.length > 0) {
      return uppy.upload();
    } else {
      mutation.mutate();
    }
  };
  useEffect(() => {
    var _a;
    if (!data || project)
      return;
    mixpanel.track("settings_viewed", {
      kind: "project",
      project_id: data.id,
      type: "details"
    });
    breadcrumb.setCrumbs([
      {
        label: `${(_a = data.prefix) != null ? _a : ""} ${data.full_name}`,
        href: route("project", { slug })
      },
      {
        label: "Admin Settings"
      },
      {
        label: "LifeWeb Details"
      }
    ]);
    setProject(data);
  }, [data]);
  useEffect(() => {
    if (!uppy.getPlugin("FileInput")) {
      uppy.use(FileInput, {
        target: fileInputRef.current
      });
    }
    if (!uppy.getPlugin("XHRUpload")) {
      uppy.use(XHRUpload, {
        endpoint: "/upload",
        formData: true
      });
    }
    if (!uppy.getPlugin("ThumbnailGenerator")) {
      uppy.use(ThumbnailGenerator, {
        thumbnailHeight: 600
      });
    }
    uppy.on("thumbnail:generated", (_, preview) => {
      setProject((prev) => ({ ...prev, cover_image_url: preview }));
    });
    uppy.on(
      "restriction-failed",
      (_, error) => setCoverImageError(error.toString())
    );
    uppy.on("upload-success", (_, response) => {
      setCoverImageJSON(JSON.stringify(response.body.data));
    });
  }, []);
  useEffect(() => {
    if (!coverImageJSON)
      return;
    mutation.mutate();
  }, [coverImageJSON]);
  const handleAttachFile = (e) => {
    setCoverImageError("");
    const file = e.currentTarget.files[0];
    const acceptedTypes = ["image/jpeg", "image/png", "image/gif"];
    const maxSize = 5 * 1024 * 1024;
    const reader = new FileReader();
    if (!acceptedTypes.includes(file.type)) {
      return setCoverImageError("Unsupported file type");
    }
    if (file && file.size > maxSize) {
      return setCoverImageError("Image file size must be less than 5 MB");
    }
    reader.addEventListener(
      "load",
      () => {
        uppy.cancelAll();
        uppy.addFile({ name: file.name, type: file.type, data: file });
      },
      false
    );
    reader.readAsDataURL(file);
    setFormValid(formRef.current.checkValidity());
  };
  const handleUpdateProjectAttribute = (attribute) => (e) => {
    setFormValid(formRef.current.checkValidity());
    setProject((prev) => ({ ...prev, [attribute]: e.target.value }));
  };
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(SettingsTitle, null, "LifeWeb Details"), project && /* @__PURE__ */ React.createElement(
    "form",
    {
      className: "mt-8",
      method: "patch",
      onSubmit: handleSubmit,
      ref: formRef
    },
    /* @__PURE__ */ React.createElement("div", { className: submitting ? "opacity-60" : "" }, /* @__PURE__ */ React.createElement("div", { className: "grid md:grid-cols-2 gap-2 md:gap-8 my-4" }, /* @__PURE__ */ React.createElement("label", null, "Their full name ", /* @__PURE__ */ React.createElement("span", { className: "text-red-700" }, "*"), /* @__PURE__ */ React.createElement(
      Input,
      {
        isHighlighted: true,
        onInput: handleUpdateProjectAttribute("full_name"),
        required: true,
        value: project.full_name
      }
    )), /* @__PURE__ */ React.createElement("label", null, "Prefixes for their name", /* @__PURE__ */ React.createElement(
      Input,
      {
        isHighlighted: true,
        onInput: handleUpdateProjectAttribute("prefix"),
        placeholder: "Ms., Dr., etc.",
        value: project.prefix
      }
    ))), /* @__PURE__ */ React.createElement("div", { className: "grid md:grid-cols-2 gap-2 md:gap-8 my-4" }, /* @__PURE__ */ React.createElement("label", null, "Their preferred first name or nickname", " ", /* @__PURE__ */ React.createElement("span", { className: "text-red-700" }, "*"), /* @__PURE__ */ React.createElement(
      Input,
      {
        isHighlighted: true,
        onInput: handleUpdateProjectAttribute("preferred_name"),
        required: true,
        value: project.preferred_name
      }
    )), /* @__PURE__ */ React.createElement("label", null, "Their pronoun ", /* @__PURE__ */ React.createElement("span", { className: "text-red-700" }, "*"), /* @__PURE__ */ React.createElement(
      Select,
      {
        isHighlighted: true,
        onInput: handleUpdateProjectAttribute("pronoun"),
        options: [
          { label: "She", value: "she" },
          { label: "He", value: "he" },
          { label: "They", value: "they" }
        ],
        placeholder: "Select pronoun",
        value: project.pronoun
      }
    ))), /* @__PURE__ */ React.createElement("div", { className: "md:max-w-[50%] my-6 w-full" }, /* @__PURE__ */ React.createElement("label", { className: "block my-2" }, "Profile photo"), /* @__PURE__ */ React.createElement("img", { src: project.cover_image_url || defaultImageSrc }), /* @__PURE__ */ React.createElement(
      "input",
      {
        type: "file",
        ref: fileInputRef,
        className: "hidden",
        onInput: handleAttachFile
      }
    ), coverImageError && /* @__PURE__ */ React.createElement("p", { className: "text-red-700" }, coverImageError), /* @__PURE__ */ React.createElement("div", { className: "mb-2 mt-6" }, /* @__PURE__ */ React.createElement(
      Button,
      {
        color: "outline",
        onClick: () => fileInputRef.current.click(),
        type: "button"
      },
      "Upload New Image"
    )), /* @__PURE__ */ React.createElement("p", { className: "my-2" }, "(Images must not exceed 5MB)"))),
    /* @__PURE__ */ React.createElement(
      SubmitOrCancelButtons,
      {
        disabled: !formValid || submitting || mutation.isPending,
        onCancel: () => history.push(
          route("project", {
            slug
          })
        )
      }
    )
  ), !project && /* @__PURE__ */ React.createElement(ActivityIndicator, null));
};
export default ProjectDetailsEdit;
