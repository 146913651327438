import React, { useEffect, useState } from "react";
import Api from "@/api/Api";
import ActivityIndicator from "@/components/activity/ActivityIndicator";
import Label from "@/components/form/Label";
import SubmitOrCancelButtons from "@/components/form/SubmitOrCancelButtons";
import TextArea from "@/components/form/TextArea";
import Icon from "@/components/icon/Icon";
import { useProjectQuery, useProjectUserQuery } from "@/queries";
import { flashSuccessMessage } from "@/utils/alert_utils";
import { route } from "@/utils/route_utils";
import { capitalize } from "@/utils/string_utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { useHistory, useParams } from "react-router-dom";
import { useBreadcrumb } from "../providers/BreadcrumbContext";
import SettingsTitle from "./SettingsTitle";
const relationships = [
  "friend",
  "family",
  "classmate",
  "community",
  "colleague",
  "faith",
  "other"
];
const iconSources = {
  unchecked: {
    friend: require("@/assets/images/icons/default/relationships-friend-default.svg"),
    family: require("@/assets/images/icons/default/relationships-family-default.svg"),
    classmate: require("@/assets/images/icons/default/relationships-classmate-default.svg"),
    community: require("@/assets/images/icons/default/relationships-community-default.svg"),
    colleague: require("@/assets/images/icons/default/relationships-colleague-default.svg"),
    faith: require("@/assets/images/icons/default/relationships-faith-default.svg"),
    other: require("@/assets/images/icons/default/relationships-other-default.svg")
  },
  checked: {
    friend: require("@/assets/images/icons/selected/relationships-friend-selected.svg"),
    family: require("@/assets/images/icons/selected/relationships-family-selected.svg"),
    classmate: require("@/assets/images/icons/selected/relationships-classmate-selected.svg"),
    community: require("@/assets/images/icons/selected/relationships-community-selected.svg"),
    colleague: require("@/assets/images/icons/selected/relationships-colleague-selected.svg"),
    faith: require("@/assets/images/icons/selected/relationships-faith-selected.svg"),
    other: require("@/assets/images/icons/selected/relationships-other-selected.svg")
  }
};
const RelationshipCheckbox = ({ name, onChange, checked }) => /* @__PURE__ */ React.createElement("label", { className: "block m-0 p-2 relative" }, /* @__PURE__ */ React.createElement(
  "div",
  {
    className: classNames(
      "border border-lifeweb-blue shadow-md inline-flex flex-col flex-wrap items-center justify-center py-3 px-1 rounded w-24",
      { "bg-lifeweb-blue-light": checked }
    )
  },
  checked && /* @__PURE__ */ React.createElement("div", { className: "absolute right-1 -top-1 drop-shadow-lg" }, /* @__PURE__ */ React.createElement(Icon, { icon: "xContained" })),
  /* @__PURE__ */ React.createElement(
    "img",
    {
      alt: `${name} icon`,
      src: iconSources[checked ? "checked" : "unchecked"][name],
      className: "block mb-1"
    }
  ),
  /* @__PURE__ */ React.createElement("div", { className: "font-lifeweb-blue font-medium" }, capitalize(name)),
  /* @__PURE__ */ React.createElement(
    "input",
    {
      className: "absolute cursor-pointer h-full inset-0 opacity-0 w-full",
      type: "checkbox",
      name,
      onChange,
      checked
    }
  )
));
const SettingsRelationships = () => {
  const breadcrumb = useBreadcrumb();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { slug } = useParams();
  const { data: project } = useProjectQuery(slug);
  const { data: projectUser } = useProjectUserQuery(slug);
  const [changed, setChanged] = useState(false);
  const [description, setDescription] = useState("");
  const [relationshipTypes, setRelationshipTypes] = useState();
  useEffect(() => {
    var _a;
    if (!projectUser || !project || relationshipTypes)
      return;
    breadcrumb.setCrumbs([
      {
        label: `${(_a = project.prefix) != null ? _a : ""} ${project.full_name}`,
        href: route("project", { slug })
      },
      {
        label: "My Settings"
      },
      {
        label: "Relationship"
      }
    ]);
    mixpanel.track("settings_viewed", {
      kind: "project",
      project_id: project.id,
      type: "relationship"
    });
    setRelationshipTypes(projectUser.relationship_types);
    setDescription(
      projectUser.relationship ? projectUser.relationship.description : ""
    );
  }, [project, projectUser]);
  const handleUpdateRelationships = async (e) => {
    e.preventDefault();
    await Api.utility.patch(
      route("projectUsersUpdate", { project_user_id: projectUser.id }),
      {
        project_user: {
          relationship_attributes: {
            description,
            relationship_types_attributes: relationshipTypes
          }
        }
      }
    );
  };
  const mutation = useMutation({
    mutationFn: handleUpdateRelationships,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["projectUser"]
      });
      flashSuccessMessage("Relationship successfully updated");
      history.push(route("project", { slug }));
    }
  });
  const toggleRelationship = (e) => {
    const { checked, name } = e.target;
    setChanged(true);
    setRelationshipTypes((current) => {
      if (checked) {
        return [...current, { relationship_type: name }];
      } else {
        return current.filter((r) => r.relationship_type !== name);
      }
    });
  };
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(SettingsTitle, null, "Relationship"), projectUser && project && /* @__PURE__ */ React.createElement("form", { method: "patch", onSubmit: mutation.mutate }, /* @__PURE__ */ React.createElement("p", { className: "my-4" }, "Select all the ways you define your relationship with", " ", project.preferred_name, ".", /* @__PURE__ */ React.createElement("span", { className: "ml-2 text-red-700" }, "* Choose at least one")), /* @__PURE__ */ React.createElement("div", { className: "flex gap-1 items-center justify-center mb-8 flex-wrap" }, relationshipTypes && relationships.map((type) => /* @__PURE__ */ React.createElement(
    RelationshipCheckbox,
    {
      key: type,
      name: type,
      onChange: toggleRelationship,
      checked: relationshipTypes.some(
        (r) => r.relationship_type === type
      )
    }
  ))), /* @__PURE__ */ React.createElement(Label, { labelSize: "small" }, "Would you like to add more detail about your relationship? This information will be shared on the LifeWeb along with your memories as additional context.", /* @__PURE__ */ React.createElement("div", { className: "my-2" }, /* @__PURE__ */ React.createElement(
    TextArea,
    {
      maxLength: 100,
      onChange: (value) => {
        setDescription(value);
        setChanged(true);
      },
      value: description
    }
  ))), /* @__PURE__ */ React.createElement(
    SubmitOrCancelButtons,
    {
      disabled: !changed,
      onCancel: () => history.push(
        route("project", {
          slug
        })
      )
    }
  )), !projectUser && /* @__PURE__ */ React.createElement(ActivityIndicator, null));
};
export default SettingsRelationships;
