document.addEventListener("DOMContentLoaded", () => {
  const forms = document.querySelectorAll("[data-require-fields]");
  const formInputs = document.querySelectorAll("[data-is-required]");
  const submitButton = document.querySelector("[data-is-disabled]");
  const checkRequiredFields = () => {
    let isValid = true;
    formInputs.forEach((input) => {
      if (input.value === "") {
        isValid = false;
      }
    });
    return isValid;
  };
  if (forms) {
    formInputs.forEach((input) => {
      input.addEventListener("keyup", () => {
        submitButton.disabled = !checkRequiredFields();
      });
    });
  }
});
