import {
  flashMessageFromResponseError,
  flashSuccessMessage
} from "@/utils/alert_utils";
import { validate } from "@/utils/form_utils";
import { route } from "@/utils/route_utils";
const axios = require("axios");
document.addEventListener("DOMContentLoaded", () => {
  const submitButton = document.getElementById("submit-button");
  const emails = document.getElementById("email-field");
  const emailError = document.getElementById(
    "email-error-message"
  );
  const message = document.getElementById("message-field");
  const projectId = document.getElementById(
    "project-id-field"
  );
  const shareLifeWebModal = document.querySelector("#share-lifeweb-modal");
  if (submitButton) {
    submitButton.addEventListener("click", handleSubmit);
  }
  const rules = { email: { required: true, type: "email" } };
  function handleSubmit() {
    const emailValues = emails.value.replace(/\s+/g, "");
    const emailArray = emailValues.split(",");
    const emailValdiationMessages = emailArray.map((email) => {
      const formattedEmail = {
        email
      };
      return validate(formattedEmail, rules);
    });
    const areEmailsInvalid = emailValdiationMessages.find(
      (validation) => validation[0] === false
    );
    if (!areEmailsInvalid) {
      emailError.classList.add("is-hidden");
      submitForm();
    } else {
      emailError.classList.remove("is-hidden");
    }
  }
  function submitForm() {
    const emailValues = emails.value.replace(/\s+/g, "");
    const emailArray = emailValues.split(",");
    const url = route("projectsEmail", { project_id: projectId.value });
    axios({
      method: "post",
      url,
      data: {
        project: {
          emails: emailArray,
          message: message.value
        }
      }
    }).then((response) => {
      shareLifeWebModal.classList.remove("is-active");
      flashSuccessMessage("LifeWeb Shared");
    }).catch((error) => {
      flashMessageFromResponseError(error);
    });
  }
});
