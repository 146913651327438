import Autolinker from "autolinker";
import DOMPurify from "isomorphic-dompurify";
const addHyperlink = (paragraph) => {
  return Autolinker.link(paragraph, { newWindow: true, email: false });
};
const capitalize = (word) => {
  if (typeof word !== "string") {
    return "";
  }
  return word.charAt(0).toUpperCase() + word.slice(1);
};
const isUUID = (uuid) => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
};
const startCase = (sentence) => {
  if (typeof sentence !== "string") {
    return "";
  }
  const words = sentence.split(/(\s+)/).filter((match) => match.trim().length > 0);
  return words.map(capitalize).join(" ");
};
const sanitizeUserInput = (text, user) => {
  let options = {};
  if (user == null ? void 0 : user.is_superadmin) {
    options = {
      ADD_ATTR: ["target"],
      ADD_TAGS: ["iframe"]
    };
  }
  return DOMPurify.sanitize(text, options);
};
export { addHyperlink, capitalize, isUUID, sanitizeUserInput, startCase };
