import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@/components/button/Button";
import FilterHeader from "@/components/filter_header/FilterHeader";
import Icon from "@/components/icon/Icon";
import Modal from "@/components/modal/Modal";
const MemoriesFilterModal = (props) => {
  const {
    onApplySelection,
    isVisible,
    onClose,
    selectedUsers: users,
    selectedThemes: themes,
    themeOptions,
    userNames
  } = props;
  const checkboxIcon = require("@/assets/images/icons/selected.svg");
  const [selectedThemes, setSelectedThemes] = useState(themes);
  const [selectedUsers, setSelectedUsers] = useState(users);
  const { t } = useTranslation();
  useEffect(() => {
    setSelectedUsers(users);
  }, [users]);
  useEffect(() => {
    setSelectedThemes(themes);
  }, [themes]);
  const handleOnChange = (selection, result) => {
    const source = selection === "users" ? selectedUsers : selectedThemes;
    const setter = selection === "users" ? setSelectedUsers : setSelectedThemes;
    const entry = source.find((r) => r.name === result.name);
    if (!entry) {
      setter([...source, result]);
    } else {
      const results = source.filter((r) => r !== entry);
      setter(results);
    }
  };
  const handleClickApply = () => {
    onApplySelection(selectedUsers, selectedThemes);
  };
  const renderCheckboxes = (selection) => {
    const options = selection === "users" ? userNames : themeOptions;
    const selected = selection === "users" ? selectedUsers : selectedThemes;
    return [...options].map((result) => {
      const isSelected = !!selected.find((r) => r.name === result.name);
      return /* @__PURE__ */ React.createElement("div", { key: result.id }, /* @__PURE__ */ React.createElement(
        "label",
        {
          htmlFor: result.id,
          className: "typeahead-results__result-wrapper"
        },
        /* @__PURE__ */ React.createElement("div", { className: "checkbox" }, isSelected && /* @__PURE__ */ React.createElement("img", { className: "checkbox", src: checkboxIcon })),
        result.name
      ), /* @__PURE__ */ React.createElement(
        "input",
        {
          hidden: true,
          id: result.id,
          onChange: () => handleOnChange(selection, result),
          type: "checkbox"
        }
      ));
    });
  };
  const themeCheckboxes = useMemo(() => {
    return renderCheckboxes("themes");
  }, [themeOptions, selectedThemes]);
  const userCheckboxes = useMemo(() => {
    return renderCheckboxes("users");
  }, [userNames, selectedUsers]);
  const handleClear = (selection) => {
    if (selection === "users") {
      setSelectedUsers([]);
    } else {
      setSelectedThemes([]);
    }
  };
  const handleSelectAll = (selection) => {
    if (selection === "users") {
      setSelectedUsers(userNames);
    } else {
      setSelectedThemes(themeOptions);
    }
  };
  return /* @__PURE__ */ React.createElement(Modal, { isFullHeight: true, isVisible, noPadding: true }, /* @__PURE__ */ React.createElement("div", { className: "modal__header" }, /* @__PURE__ */ React.createElement("div", { className: "modal-confirmation__top" }, /* @__PURE__ */ React.createElement("button", { className: "modal-confirmation__close", onClick: onClose }, t("modal.close"), /* @__PURE__ */ React.createElement(Icon, { icon: "xSolo" }))), /* @__PURE__ */ React.createElement("div", { className: "modal-confirmation__content" }, /* @__PURE__ */ React.createElement("h1", { className: "modal-confirmation__header" }, t("views.memories.filters.title")))), /* @__PURE__ */ React.createElement("div", { className: "memory-bar__modal-filters" }, /* @__PURE__ */ React.createElement(
    FilterHeader,
    {
      title: t("views.memories.filters.lifeThread"),
      onClear: () => handleClear("themes"),
      onSelectAll: () => handleSelectAll("themes")
    },
    themeCheckboxes
  ), /* @__PURE__ */ React.createElement(
    FilterHeader,
    {
      title: t("views.memories.filters.contributors"),
      onClear: () => handleClear("users"),
      onSelectAll: () => handleSelectAll("users")
    },
    userCheckboxes
  )), /* @__PURE__ */ React.createElement("div", { className: "modal__footer" }, /* @__PURE__ */ React.createElement(Button, { color: "outline", onClick: handleClickApply }, t("button.apply"))));
};
export default MemoriesFilterModal;
