import classNames from "classnames";
import React, { useState } from "react";
import CharacterCount from "./CharacterCount";
export const Input = (props) => {
  const {
    id,
    isHighlighted,
    isInvalid,
    maxLength,
    onBlur,
    onChange,
    onFocus,
    onInput,
    onPressEnter,
    onToggle,
    placeholder,
    readOnly,
    required,
    style,
    toggle,
    toggleText,
    type,
    value,
    withoutFocusShadow
  } = props;
  const [characterCount, setCharacterCount] = useState(value == null ? void 0 : value.length);
  const handleChange = (event) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };
  const handleOnKeyUp = (event) => {
    if (maxLength) {
      setCharacterCount(value.length);
    }
    if (onPressEnter && event.key === "Enter") {
      onPressEnter();
    }
  };
  const styles = classNames("form-input", {
    "has-large-padding": maxLength,
    "is-borderless": style === "borderless",
    "is-error": isInvalid,
    "is-filled": value,
    "is-highlighted": isHighlighted,
    "is-without-shadow": withoutFocusShadow
  });
  return /* @__PURE__ */ React.createElement("div", { className: "form-input-wrapper my-2" }, /* @__PURE__ */ React.createElement(
    "input",
    {
      id,
      className: styles,
      maxLength,
      onBlur,
      onChange: handleChange,
      onInput,
      onFocus,
      onKeyUp: handleOnKeyUp,
      placeholder,
      required,
      type: type || "text",
      value: value || "",
      readOnly
    }
  ), toggle && /* @__PURE__ */ React.createElement("span", { className: "toggle-password-button", onClick: onToggle }, toggleText), maxLength && /* @__PURE__ */ React.createElement(CharacterCount, { characterCount, maxLength }));
};
export default Input;
