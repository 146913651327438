import React from "react";
import { useTranslation } from "react-i18next";
import TypeaheadGroup from "@/components/form/TypeaheadGroup";
import ThemeRail from "@/components/theme/ThemeRail";
import { objectivePronoun, possessivePronoun } from "@/utils/pronoun_utils";
const MemoriesThemes = (props) => {
  const {
    createTheme,
    customDescription,
    defaultThemes,
    isDescriptionHidden,
    onAddTheme,
    onRemoveTheme,
    onAddNewLifeThread,
    project,
    themes,
    themeValidation,
    updateThemes,
    user
  } = props;
  const { t } = useTranslation();
  const emptyResultsText = t("typeahead.empty");
  const description = customDescription ? customDescription : t("form.description.memory.memoryTheme", {
    objectivePronoun: objectivePronoun(project),
    possessivePronoun: possessivePronoun(project),
    preferredName: project.preferred_name
  });
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-8-tablet" }, /* @__PURE__ */ React.createElement(
    TypeaheadGroup,
    {
      availableResults: themes,
      description: !isDescriptionHidden && description,
      emptyResultsText,
      isLabelBold: true,
      isRequired: true,
      label: t("form.label.memory.memoryTheme"),
      labelSize: "large",
      onPressEnter: createTheme,
      placeholder: t("form.placeholder.memory.memoryTheme"),
      selectedResults: themes,
      shouldDisplayEmptyResults: user && project.creator_id === user.id,
      shouldHideTextInput: true,
      updateResults: updateThemes
    }
  ))), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-8-tablet" }, /* @__PURE__ */ React.createElement(
    ThemeRail,
    {
      isRemovable: true,
      onAddTheme,
      onAddNewLifeThread,
      onRemoveTheme,
      selectedThemes: themes,
      themes: defaultThemes,
      validationMessage: themeValidation
    }
  ))));
};
export default MemoriesThemes;
