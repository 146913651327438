import { possessivePronoun } from "@/utils/pronoun_utils";
import { route } from "@/utils/route_utils";
import React from "react";
const MemoriesPlaceholder = ({ project }) => /* @__PURE__ */ React.createElement("div", { className: "bg-white max-w-lg mx-auto my-4 p-2.5 rounded-md" }, /* @__PURE__ */ React.createElement("div", { className: "bg-lifeweb-yellow mb-4 p-5" }, /* @__PURE__ */ React.createElement("p", { className: "mb-2" }, project.memories_count ? `Sorry, no matches to display. Keep adding to ${project.preferred_name}'s LifeWeb by posting some of your own memories.` : `Help get ${project.preferred_name}'s LifeWeb started by posting some of your own memories.`), /* @__PURE__ */ React.createElement("p", { className: "mb-2" }, "The memories you share will be cherished by all who love", " ", project.preferred_name, " and will encourage others to contribute their own heartfelt memories."), /* @__PURE__ */ React.createElement("p", { className: "mb-2" }, "Also, be sure to invite others who have photos and stories of", " ", project.preferred_name, " to join you in celebrating", " ", possessivePronoun(project), " life."), /* @__PURE__ */ React.createElement("div", { className: "flex justify-center w-full" }, /* @__PURE__ */ React.createElement(
  "a",
  {
    href: route("memoriesNew", { slug: project.slug }),
    className: "button is-primary my-4 max-w-xs"
  },
  "Post a Memory"
))), /* @__PURE__ */ React.createElement("div", { className: "my-2" }, /* @__PURE__ */ React.createElement("p", { className: "text-sm font-sans" }, "Contributed by"), /* @__PURE__ */ React.createElement("p", { className: "text-lg font-semibold" }, "You")));
export default MemoriesPlaceholder;
