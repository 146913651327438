import { RectangularButton } from "@/components/button/Button";
import { RectangularButtonLink } from "@/components/button/ButtonLink";
import CloseButton from "@/components/modal/CloseButton";
import React, { useState } from "react";
const defaultCoverImageSrc = require("@/assets/images/project_default.jpg");
import FbIcon from "@/components/icon/facebook";
import { H1 } from "@/components/typography";
import { isMobileDevice } from "@/utils/mobile_utils";
import { mailtoURL, smsURL } from "@/utils/project_utils";
import { possessivePronoun } from "@/utils/pronoun_utils";
import Modal from "./Modal";
import { route } from "@/utils/route_utils";
const ShareModal = ({ isVisible = false, onClose, project }) => {
  const [linkCopied, setLinkCopied] = useState(false);
  const [sharedOnFacebook, setSharedOnFacebook] = useState(false);
  const handleCopyLink = () => {
    const { origin, pathname } = window.location;
    window.navigator.clipboard.writeText(origin + pathname);
    trackShare("link");
    setLinkCopied(true);
  };
  const trackShare = (type) => mixpanel.track("page_shared", {
    kind: "project",
    project_id: project.id,
    type
  });
  const handleFacebookShare = () => {
    window.FB.ui(
      {
        method: "share",
        href: window.location.origin + route("project", { slug: project.slug }),
        quote: `Please take a moment to share a memory of ${project.preferred_name}. We're creating a scrapbook of everyone's stories and photos to celebrate ${possessivePronoun(
          project
        )}. If you've already shared something on Facebook, please add here as well so family members not on Facebook can view ${possessivePronoun(
          project
        )}. Thank you.`
      },
      () => {
        setSharedOnFacebook(true);
        trackShare("facebook");
      }
    );
  };
  return /* @__PURE__ */ React.createElement(Modal, { isVisible, onClose }, /* @__PURE__ */ React.createElement("div", { className: "flex justify-end mb-4" }, /* @__PURE__ */ React.createElement(CloseButton, { onClose })), /* @__PURE__ */ React.createElement("div", { className: "flex flex-col items-center justify-center mx-auto max-w-md" }, /* @__PURE__ */ React.createElement(
    "img",
    {
      className: "mb-4 w-44",
      src: project.cover_image_url || defaultCoverImageSrc,
      alt: project.full_name
    }
  ), /* @__PURE__ */ React.createElement(H1, { className: "text-center" }, "Share ", project.preferred_name, "'s LifeWeb"), /* @__PURE__ */ React.createElement("p", { className: "mb-5" }, "Help gather memories by sharing this page with others who might like to view or contribute."), /* @__PURE__ */ React.createElement("div", { className: "grid max-w-xs w-full" }, /* @__PURE__ */ React.createElement(
    RectangularButtonLink,
    {
      href: mailtoURL(project),
      onClick: () => trackShare("email")
    },
    "Send via Email"
  ), isMobileDevice() && /* @__PURE__ */ React.createElement(
    RectangularButtonLink,
    {
      href: smsURL(project),
      onClick: () => trackShare("sms")
    },
    "Send via SMS"
  ), /* @__PURE__ */ React.createElement(
    RectangularButton,
    {
      onClick: handleCopyLink,
      style: linkCopied ? { backgroundColor: "rgba(209, 213, 219)" } : {}
    },
    linkCopied ? "Link copied!" : "Copy Link"
  ), /* @__PURE__ */ React.createElement(
    RectangularButton,
    {
      onClick: handleFacebookShare,
      style: sharedOnFacebook ? { backgroundColor: "rgba(209, 213, 219)" } : {}
    },
    sharedOnFacebook ? "Shared on Facebook" : /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(FbIcon, null), /* @__PURE__ */ React.createElement("span", { className: "ml-2" }, "Share"))
  ))));
};
export default ShareModal;
