import classNames from "classnames";
import React from "react";
const Icon = ({ alt = "", icon, rotation }) => {
  const getIconSource = (i) => {
    return iconSources[i];
  };
  const iconSources = {
    add: require("@/assets/images/icons/add.svg"),
    arrow: require("@/assets/images/icons/arrow.svg"),
    caret: require("@/assets/images/icons/caret-black.svg"),
    combinedShape: require("@/assets/images/icons/combined-shape.svg"),
    facebook: require("@/assets/images/icons/facebook.svg"),
    facebookRoundActiveBlue: require("@/assets/images/icons/facebook_icon_blue_round_active.svg"),
    facebookRoundInactive: require("@/assets/images/icons/facebook_icon_round_inactive.svg"),
    galleryArrow: require("@/assets/images/icons/gallery-arrow.svg"),
    gears: require("@/assets/images/icons/gears.svg"),
    googleRoundActive: require("@/assets/images/icons/google_icon_round_active.svg"),
    googleRoundInactive: require("@/assets/images/icons/google_icon_round_inactive.svg"),
    hamburger: require("@/assets/images/icons/burger.svg"),
    heart: require("@/assets/images/icons/heart.svg"),
    heartFilled: require("@/assets/images/icons/heart-dark.svg"),
    helpFilled: require("@/assets/images/icons/help-dark.svg"),
    hide: require("@/assets/images/icons/hide.svg"),
    logIn: require("@/assets/images/icons/requested.svg"),
    openInNew: require("@/assets/images/icons/up-right-from-square.svg"),
    openInNewBlue: require("@/assets/images/icons/up-right-from-square-blue.svg"),
    pencil: require("@/assets/images/icons/pencil.svg"),
    pin: require("@/assets/images/icons/pin.svg"),
    pinFilled: require("@/assets/images/icons/pin-filled.svg"),
    profileFilled: require("@/assets/images/icons/profile-dark.svg"),
    search: require("@/assets/images/icons/ui-icons-search-white.svg"),
    share: require("@/assets/images/icons/share-2.svg"),
    signUp: require("@/assets/images/icons/sign-up.svg"),
    show: require("@/assets/images/icons/show.svg"),
    triangleLeft: require("@/assets/images/icons/triangle-left.svg"),
    triangleRight: require("@/assets/images/icons/triangle-right.svg"),
    upload: require("@/assets/images/icons/upload.svg"),
    xContained: require("@/assets/images/icons/x-contained.svg"),
    xSolo: require("@/assets/images/icons/x-solo.svg")
  };
  return /* @__PURE__ */ React.createElement(
    "img",
    {
      alt,
      "aria-hidden": "true",
      className: classNames("icon", {
        "is-rotated-180": rotation === 180
      }),
      src: getIconSource(icon)
    }
  );
};
export default Icon;
