import { flashErrorMessage } from "@/utils/alert_utils";
document.addEventListener("DOMContentLoaded", () => {
  const buttons = document.querySelectorAll("[data-copy]");
  const copyTextToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.style.position = "fixed";
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
    } catch (err) {
      flashErrorMessage("Unable to copy");
    }
    document.body.removeChild(textArea);
  };
  buttons.forEach((button) => {
    button.addEventListener("click", () => {
      const url = button.dataset.copy;
      copyTextToClipboard(url);
      button.innerHTML = "Copied!";
      setTimeout(() => {
        button.innerHTML = "Copy Link";
      }, 3e3);
    });
  });
});
