import React from "react";
import Icon from "@/components/icon/Icon";
const BackLink = (props) => {
  const { goBack, text, to } = props;
  if (goBack) {
    return /* @__PURE__ */ React.createElement("button", { className: "back-link", onClick: () => window.history.back() }, /* @__PURE__ */ React.createElement(Icon, { icon: "arrow" }), /* @__PURE__ */ React.createElement("span", { className: "back-link__text" }, text));
  }
  return /* @__PURE__ */ React.createElement("a", { className: "back-link", href: to }, /* @__PURE__ */ React.createElement(Icon, { icon: "arrow" }), /* @__PURE__ */ React.createElement("span", { className: "back-link__text" }, text));
};
export default BackLink;
