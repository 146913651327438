import Api from "@/api/Api";
import Button from "@/components/button/Button";
import FilterableMultiSelect from "@/components/form/FilterableMultiSelect";
import Input from "@/components/form/Input";
import Select from "@/components/form/Select";
import { H2 } from "@/components/typography";
import MemoriesIndex from "@/contexts/memories/MemoriesIndex";
import useSortedCategories from "@/helpers/hooks/use_sorted_categories";
import { flashMessageFromResponseError } from "@/utils/alert_utils";
import Modeler from "@/utils/modeler/modeler";
import { route } from "@/utils/route_utils";
import FILE_UPLOAD_SETTINGS from "@/variables/file_uploads";
import Uppy from "@uppy/core";
import FileInput from "@uppy/file-input";
import ThumbnailGenerator from "@uppy/thumbnail-generator";
import XHRUpload from "@uppy/xhr-upload";
import React, { useEffect, useRef, useState } from "react";
const defaultImageSrc = require("@/assets/images/project_default.jpg");
const uppy = new Uppy({
  restrictions: {
    maxFileSize: FILE_UPLOAD_SETTINGS.maxFileSize,
    allowedFileTypes: FILE_UPLOAD_SETTINGS.allowedFileTypes,
    maxNumberOfFiles: 1
  }
});
const NewProjectForm = ({ afterSubmit, report, group, user }) => {
  var _a;
  const [submitting, setSubmitting] = useState(false);
  const [coverImage, setCoverImage] = useState("");
  const [coverImageJSON, setCoverImageJSON] = useState("");
  const [coverImageError, setCoverImageError] = useState();
  const [fullName, setFullName] = useState("");
  const [obituaryUrl, setObituaryUrl] = useState("");
  const [preferredName, setPreferredName] = useState("");
  const [prefix, setPrefix] = useState("");
  const [pronoun, setPronoun] = useState();
  const [relationship, setRelationship] = useState("");
  const [reporterEmail, setReporterEmail] = useState("");
  const [reporterName, setReporterName] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const fileInputRef = useRef(null);
  const formRef = useRef(null);
  const availableCategories = group ? report || !((_a = user == null ? void 0 : user.group_users) == null ? void 0 : _a.find(
    ({ group_id, role }) => group_id === group.id && role === "admin"
  )) ? group.group_categories.filter(
    (c) => {
      var _a2;
      return !c.featured && !((_a2 = c == null ? void 0 : c.parent) == null ? void 0 : _a2.featured);
    }
  ) : group.group_categories : [];
  const sortedCategories = useSortedCategories(availableCategories);
  const handleBack = () => {
    const slug = group == null ? void 0 : group.slug;
    window.location.href = slug ? route("groupShow", { slug }) : "/";
  };
  const handleAttachFile = (e) => {
    setCoverImageError("");
    const file = e.currentTarget.files[0];
    const acceptedTypes = ["image/jpeg", "image/png", "image/gif"];
    const maxSize = 5 * 1024 * 1024;
    const reader = new FileReader();
    if (!acceptedTypes.includes(file.type)) {
      return setCoverImageError("Unsupported file type");
    }
    if (file && file.size > maxSize) {
      return setCoverImageError("Image file size must be less than 5 MB");
    }
    reader.addEventListener(
      "load",
      () => {
        uppy.cancelAll();
        uppy.addFile({ name: file.name, type: file.type, data: file });
      },
      false
    );
    reader.readAsDataURL(file);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    if (!formRef.current.checkValidity()) {
      setSubmitting(false);
      return formRef.current.reportValidity();
    }
    if (fileInputRef.current.files.length > 0) {
      return handleUploadImage();
    } else {
      return group ? submitGroupProject() : submitProject();
    }
  };
  useEffect(() => {
    if (!uppy.getPlugin("FileInput")) {
      uppy.use(FileInput, {
        target: fileInputRef.current
      });
    }
    if (!uppy.getPlugin("XHRUpload")) {
      uppy.use(XHRUpload, {
        endpoint: "/upload",
        formData: true
      });
    }
    if (!uppy.getPlugin("ThumbnailGenerator")) {
      uppy.use(ThumbnailGenerator, {
        thumbnailHeight: 600
      });
    }
    uppy.on("thumbnail:generated", (_, preview) => {
      setCoverImage(preview);
    });
    uppy.on(
      "restriction-failed",
      (_, error) => setCoverImageError(error.toString())
    );
    uppy.on("upload-success", (_, response) => {
      setCoverImageJSON(JSON.stringify(response.body.data));
    });
  }, []);
  const handleUploadImage = () => {
    uppy.upload();
  };
  useEffect(() => {
    if (!coverImageJSON)
      return;
    group ? submitGroupProject() : submitProject();
  }, [coverImageJSON]);
  const submitGroupProject = async () => {
    try {
      const url = route("groupProjects", { group_id: group.id });
      const body = {
        group_project: {
          group_id: group.id,
          group_category_ids: selectedCategories.map(
            (category) => category.value
          ),
          project_attributes: {
            cover_image: coverImageJSON,
            full_name: fullName,
            obituary_url: obituaryUrl,
            preferred_name: preferredName,
            prefix,
            pronoun
          }
        },
        report,
        reporter_email: reporterEmail,
        reporter_name: reporterName,
        relationship
      };
      const response = await Api.utility.post(url, body);
      const record = new Modeler(response.data).build();
      if (afterSubmit)
        return afterSubmit(record);
      window.location.href = route("project", {
        slug: record.project.slug
      });
    } catch (error) {
      flashMessageFromResponseError(error);
      setSubmitting(false);
    }
  };
  const submitProject = async () => {
    try {
      const url = route("projects");
      const body = {
        project: {
          full_name: fullName,
          cover_image: coverImageJSON,
          preferred_name: preferredName,
          prefix,
          pronoun
        }
      };
      const response = await Api.utility.post(url, body);
      const record = new Modeler(response.data.project).build();
      mixpanel.track("project_created", {
        kind: "project",
        project_id: record.id
      });
      if (afterSubmit)
        return afterSubmit(record);
      window.location.href = response.data.redirect_path;
    } catch (error) {
      flashMessageFromResponseError(error);
      setSubmitting(false);
    }
  };
  return /* @__PURE__ */ React.createElement("form", { className: "mt-8", method: "post", onSubmit: handleSubmit, ref: formRef }, /* @__PURE__ */ React.createElement("div", { className: submitting ? "opacity-60" : "" }, /* @__PURE__ */ React.createElement("div", { className: "grid md:grid-cols-2 gap-2 md:gap-8 my-4" }, /* @__PURE__ */ React.createElement("label", null, "Their full name ", /* @__PURE__ */ React.createElement("span", { className: "text-red-700" }, "*"), /* @__PURE__ */ React.createElement(
    Input,
    {
      isHighlighted: true,
      onInput: (e) => setFullName(e.currentTarget.value),
      required: true,
      value: fullName
    }
  )), /* @__PURE__ */ React.createElement("label", null, "Prefixes for their name", /* @__PURE__ */ React.createElement(
    Input,
    {
      isHighlighted: true,
      onInput: (e) => setPrefix(e.currentTarget.value),
      placeholder: "Ms., Dr., etc.",
      value: prefix
    }
  ))), /* @__PURE__ */ React.createElement("div", { className: "grid md:grid-cols-2 gap-2 md:gap-8 my-4" }, /* @__PURE__ */ React.createElement("label", null, "Their preferred first name or nickname", " ", /* @__PURE__ */ React.createElement("span", { className: "text-red-700" }, "*"), /* @__PURE__ */ React.createElement(
    Input,
    {
      isHighlighted: true,
      onInput: (e) => setPreferredName(e.currentTarget.value),
      required: true,
      value: preferredName
    }
  )), /* @__PURE__ */ React.createElement("label", null, "Their pronoun ", /* @__PURE__ */ React.createElement("span", { className: "text-red-700" }, "*"), /* @__PURE__ */ React.createElement(
    Select,
    {
      isHighlighted: true,
      onInput: (e) => setPronoun(e.currentTarget.value),
      options: [
        { label: "She", value: "she" },
        { label: "He", value: "he" },
        { label: "They", value: "they" }
      ],
      placeholder: "Select pronoun",
      value: pronoun
    }
  ))), report && /* @__PURE__ */ React.createElement("label", null, "Link to an obituary online, if available", /* @__PURE__ */ React.createElement(
    Input,
    {
      isHighlighted: true,
      onInput: (e) => setObituaryUrl(e.currentTarget.value),
      placeholder: "https://www.example.com/obituarypage",
      value: obituaryUrl
    }
  )), /* @__PURE__ */ React.createElement("p", { className: "my-6 font-bold" }, "Upload Photo"), report ? /* @__PURE__ */ React.createElement("p", { className: "my-4" }, "If you have a photo of this ", group.member_name, ", please upload it here. Images must not exceed 5MB.") : /* @__PURE__ */ React.createElement("p", { className: "my-4" }, "Customize their page with a photo. You can change their photo at any time. Images must not exceed 5MB."), /* @__PURE__ */ React.createElement("div", { className: "md:w-1/2 mx-auto my-4 w-full" }, (coverImage || !report) && /* @__PURE__ */ React.createElement("img", { src: coverImage || defaultImageSrc }), /* @__PURE__ */ React.createElement(
    "input",
    {
      type: "file",
      ref: fileInputRef,
      className: "hidden",
      onInput: handleAttachFile
    }
  ), coverImageError && /* @__PURE__ */ React.createElement("p", { className: "text-red-700" }, coverImageError), /* @__PURE__ */ React.createElement("div", { className: "px-8 py-6" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "outline",
      onClick: () => fileInputRef.current.click(),
      type: "button"
    },
    "Upload Image"
  ))), group && Object.entries(sortedCategories).length > 0 && /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("p", { className: "my-6 font-bold" }, group.name, " Information"), /* @__PURE__ */ React.createElement("p", null, "If possible, please provide the following information about this", " ", group.member_name || "person", ". Feel free to skip if you aren't sure."), Object.entries(sortedCategories).map(([name, values]) => /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "gap-4 grid md:grid-cols-5 items-center md:px-8 my-6",
      key: name
    },
    /* @__PURE__ */ React.createElement("div", { className: "col-span-2" }, name),
    /* @__PURE__ */ React.createElement("div", { className: "col-span-3" }, /* @__PURE__ */ React.createElement(
      FilterableMultiSelect,
      {
        limit: group.group_categories.find(
          (category) => category.name === name
        ).subcategory_limit,
        options: values.map(({ id, name: categoryName }) => ({
          label: categoryName,
          value: id
        })),
        selected: selectedCategories,
        setStateFunction: setSelectedCategories
      }
    ))
  ))), report && /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("p", { className: "mb-6 mt-8 font-bold" }, "Additional Information"), !(user == null ? void 0 : user.id) && /* @__PURE__ */ React.createElement("div", { className: "grid md:grid-cols-2 gap-2 md:gap-8 my-4" }, /* @__PURE__ */ React.createElement("label", null, "Your name ", /* @__PURE__ */ React.createElement("span", { className: "text-red-700" }, "*"), /* @__PURE__ */ React.createElement(
    Input,
    {
      isHighlighted: true,
      onInput: (e) => setReporterName(e.currentTarget.value),
      required: true,
      value: reporterName
    }
  )), /* @__PURE__ */ React.createElement("label", null, "Your email ", /* @__PURE__ */ React.createElement("span", { className: "text-red-700" }, "*"), /* @__PURE__ */ React.createElement(
    Input,
    {
      isHighlighted: true,
      onInput: (e) => setReporterEmail(e.currentTarget.value),
      required: true,
      type: "email",
      value: reporterEmail
    }
  ))), /* @__PURE__ */ React.createElement("label", { className: "my-4" }, "Your relationship to this ", group.member_name, /* @__PURE__ */ React.createElement(
    Input,
    {
      isHighlighted: true,
      onInput: (e) => setRelationship(e.currentTarget.value),
      value: relationship
    }
  )))), /* @__PURE__ */ React.createElement("div", { className: "flex gap-4 items-center justify-end mt-12" }, /* @__PURE__ */ React.createElement("div", { className: "sm:w-1/4 w-full" }, /* @__PURE__ */ React.createElement(Button, { color: "outline", onClick: handleBack, type: "button" }, "Cancel")), /* @__PURE__ */ React.createElement("div", { className: "sm:w-1/4 w-full" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "secondary",
      isDisabled: !fullName || !preferredName || !pronoun || submitting || !(user == null ? void 0 : user.id) && report && (!reporterName || !reporterEmail),
      type: "submit"
    },
    submitting ? "Submitting" : "Submit"
  ))));
};
const NewProject = ({ project }) => {
  useEffect(() => {
    mixpanel.track("project_started", {
      kind: "project",
      type: "create"
    });
  }, []);
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", { className: "absolute flex h-screen justify-center min-h-screen overscroll-contain overflow-hidden py-4 md:py-12 px-2 w-screen z-50" }, /* @__PURE__ */ React.createElement("div", { className: "bg-white max-w-screen-sm rounded-md overflow-y-scroll px-6 py-6 w-full" }, /* @__PURE__ */ React.createElement(H2, { className: "text-center" }, "Create a LifeWeb"), /* @__PURE__ */ React.createElement("p", { className: "my-4" }, "To get started, we need some information about the person you'd like to honor. You can always edit this information later. (", /* @__PURE__ */ React.createElement("span", { className: "font-bold text-red-700" }, "*"), " indicates a required field)"), /* @__PURE__ */ React.createElement(NewProjectForm, null))), /* @__PURE__ */ React.createElement("div", { className: "h-screen w-screen fixed bg-black bg-opacity-30 top-0 z-40" }), /* @__PURE__ */ React.createElement(MemoriesIndex, { backdrop: true, serialized_project: project }));
};
export { NewProject as default, NewProjectForm };
