export const areValuesEmpty = (form, fields) => {
  return fields.find((field) => form[field] === "") !== void 0;
};
export const isValueEmpty = (field) => {
  if (typeof field === "string" && field === "") {
    return true;
  }
  return typeof field === "undefined" || field === null;
};
export const isObjectEmpty = (object) => {
  return Object.keys(object).length === 0 && object.constructor === Object;
};
export const validate = (state, rules) => {
  const validations = {};
  Object.keys(state).forEach((field) => {
    const value = state[field];
    const rule = rules[field];
    if (!rule || value === void 0) {
      return;
    }
    if (isValueEmpty(value)) {
      return rule.required ? validations[field] = "Field is required" : null;
    }
    if (rule.type) {
      const result = validateField(value, rule.type);
      if (result !== true) {
        validations[field] = result;
      }
    }
    if (rule.match) {
      const matchValue = state[rule.match];
      if (value !== matchValue) {
        return validations[field] = "Password confirmation doesn't match password";
      }
    }
  });
  if (isObjectEmpty(validations)) {
    return [true, {}];
  }
  return [false, validations];
};
export const validateField = (value, type = false) => {
  if (!type) {
    return true;
  }
  switch (type) {
    case "email":
      return validateEmailAddress(value) || "Invalid email address";
    case "password":
      return validatePassword(value) || " ";
  }
  return true;
};
export const validateEmailAddress = (emailAddress) => {
  const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(emailAddress);
};
export const validatePassword = (password) => {
  const pattern = /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[~!@#$%^&*()\-_+=\/\[\]|{}<>,.?:'";`]).{10,}$/;
  return pattern.test(password);
};
export const filterOutEmptyRows = (form, requiredFields) => {
  return form.filter((row) => {
    let shouldRemove = true;
    requiredFields.forEach((field) => {
      if (!!row[field]) {
        shouldRemove = false;
      }
    });
    if (!shouldRemove) {
      return row;
    }
  });
};
export const ignoreEmptyRow = (validationMessages) => {
  let shouldIgnore = true;
  const validationKeys = Object.keys(validationMessages);
  const validationLength = validationKeys.length;
  if (validationLength !== 3) {
    return false;
  }
  validationKeys.forEach((key) => {
    if (validationMessages[key] !== "Field is required") {
      shouldIgnore = false;
    }
  });
  return shouldIgnore;
};
