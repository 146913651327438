import ButtonLink from "@/components/button/ButtonLink";
import { route } from "@/utils/route_utils";
import React from "react";
const MemoriesOnboardingModal = ({
  project
}) => {
  return /* @__PURE__ */ React.createElement("div", { className: "md:mx-6" }, /* @__PURE__ */ React.createElement("p", { className: "mb-4" }, "Get things started by posting some of your own memories."), /* @__PURE__ */ React.createElement("p", { className: "mb-8" }, "Everything you share will be cherished by all who love", " ", project.preferred_name, ", and will encourage others to contribute their own."), /* @__PURE__ */ React.createElement("div", { className: "flex justify-center" }, /* @__PURE__ */ React.createElement(
    ButtonLink,
    {
      className: "bg-lifeweb-gold w-full md:w-1/3 normal-case",
      href: route("memoriesNew", { slug: project.slug }),
      internal: true
    },
    "Post a Memory"
  )));
};
export default MemoriesOnboardingModal;
