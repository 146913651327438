import classNames from "classnames";
import React from "react";
const Panel = (props) => {
  const { children, padding } = props;
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames("panel", {
        "with-narrow-padding": padding === "narrow" || !padding,
        "with-wide-padding": padding === "wide"
      })
    },
    children
  );
};
export default Panel;
