import classNames from "classnames";
import React from "react";
const ModalCard = (props) => {
  const {
    children,
    isHidden,
    noMargin,
    noPadding,
    position,
    selfRef,
    size,
    withZIndex,
    ...extraProps
  } = props;
  const classes = classNames("modal-card", {
    "has-no-margin": noMargin,
    "is-absolute-left": position === "left",
    "is-absolute-right": position === "right",
    "is-absolute-top": position === "top",
    "is-center-of-screen": position === "centerOfScreen",
    "is-absolute-center-full-screen": position === "centerFullScreen",
    "is-invisible": isHidden,
    "is-small": size === "small",
    "is-medium": size === "medium",
    "is-large": size === "large",
    "with-no-margin": noMargin,
    "with-z-index": withZIndex
  });
  return /* @__PURE__ */ React.createElement("div", { className: classes, ref: selfRef, ...extraProps }, /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames("modal-card-body", {
        "has-no-padding": noPadding
      })
    },
    children
  ));
};
export default ModalCard;
