export const areEqual = (arr1, arr2) => {
  if (arr1 === arr2) {
    return true;
  }
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (const [index, value] of arr1.entries()) {
    if (arr2[index] !== value) {
      return false;
    }
  }
  return true;
};
export const createIncrementalArray = (length, offest = 0) => {
  if (length < 0 || length % 1 !== 0) {
    throw new Error("Invalid length provided");
  }
  return Array.from(Array(length).keys()).map((n) => n + offest);
};
export const chunkArray = (array, size = 5) => {
  const chunks = [];
  for (let position = 0; position <= array.length; position += size) {
    if (array[position] !== void 0) {
      chunks.push(array.slice(position, position + size));
    }
  }
  return chunks;
};
export const replaceItemInArray = (currentArray, newItem) => {
  return currentArray.map((obj) => {
    if (obj.id === newItem.id) {
      return newItem;
    } else {
      return obj;
    }
  });
};
