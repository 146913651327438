import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "@/api/Api";
import Button from "@/components/button/Button";
import { DeprecatedCheckbox as Checkbox } from "@/components/form/Checkbox";
import Label from "@/components/form/Label";
import BackLink from "@/components/link/BackLink";
import ConfirmationModal from "@/components/modal/ConfirmationModal";
import Modal from "@/components/modal/Modal";
import ThemeRail from "@/components/theme/ThemeRail";
import Thumbnail from "@/components/thumbnail/Thumbnail";
import { H3 } from "@/components/typography";
import VideoPreview from "@/components/video_preview/VideoPreview";
import { flashMessageFromResponseError } from "@/utils/alert_utils";
import { fullName, memoryEmail } from "@/utils/memory_utils";
import Modeler from "@/utils/modeler/modeler";
import { route } from "@/utils/route_utils";
import { sanitizeUserInput } from "@/utils/string_utils";
import MemoriesAddLifeThreadModal from "../memories/components/MemoriesAddLifeThreadModal";
const CurateMemory = (props) => {
  const { memory_data, project, project_themes, isAdmin } = props;
  const { t } = useTranslation();
  const memory = new Modeler(memory_data).build();
  const email = memoryEmail(memory);
  const initialFormState = {
    publication_status: memory.publication_status,
    themes: memory.themes
  };
  const referrerParts = new URL(document.referrer).pathname.split("/");
  const [confirmHideMemory, setConfirmHideMemory] = useState(false);
  const [form, setForm] = useState(initialFormState);
  const [themes, setThemes] = useState(project_themes);
  const [themeValidation, setThemeValidation] = useState("");
  const [isAddLifeThreadModalVisible, setIsAddLifeThreadModalVisible] = useState(false);
  const [newLifeThreadName, setNewLifeThreadName] = useState("");
  const addTheme = (theme) => {
    setForm({
      ...form,
      themes: [...form.themes, theme]
    });
  };
  const backLinkText = () => {
    if (!document.referrer)
      return "Back";
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    if (referrerParts[1] === "projects") {
      return "Back to Dashboard";
    } else if (referrerParts.length > 2 && uuidRegex.test(referrerParts[2])) {
      return "Back to Memory";
    } else {
      return "Back to LifeWeb";
    }
  };
  const removeTheme = (theme) => {
    const entry = form.themes.find(
      (formTheme) => formTheme.id === theme.id
    );
    const filteredThemes = form.themes.filter(
      (formTheme) => formTheme !== entry
    );
    setForm({
      ...form,
      themes: filteredThemes
    });
  };
  const refreshProjectThemes = async () => {
    const url = route("themesIndex", {
      project_id: project.id,
      status: "active"
    });
    try {
      const response = await Api.utility.get(url);
      const data = new Modeler(response.data).build();
      setThemes(data);
      const inactiveTheme = form.themes.find(
        (theme) => !data.some((formTheme) => theme.id === formTheme.id)
      );
      if (inactiveTheme) {
        removeTheme(inactiveTheme);
      }
    } catch (err) {
      flashMessageFromResponseError(err);
    }
  };
  const handleCreateTheme = async (name) => {
    if (isAdmin) {
      const url = route("themesIndex");
      try {
        const body = {
          theme: {
            name,
            project_id: project.id
          }
        };
        const response = await Api.utility.post(url, body);
        const newTheme = new Modeler(response.data).build();
        addTheme(newTheme);
        refreshProjectThemes();
      } catch (err) {
        flashMessageFromResponseError(err);
      }
    } else {
      return null;
    }
  };
  const handleSubmit = () => {
    if (form.themes.length === 0) {
      setThemeValidation("At least one Life Thread is required.");
    } else {
      setThemeValidation("");
    }
    const isThemeValid = form.themes.length > 0;
    if (isThemeValid) {
      submitForm();
    }
  };
  const hideMemory = async () => {
    const body = { memory: { publication_status: "unpublished" } };
    const url = route("memory", { id: memory.id });
    const redirectLocation = referrerParts[1] === "projects" ? "memoryCuration" : "project";
    try {
      await Api.utility.patch(url, body);
      window.location.href = route(redirectLocation, { slug: project.slug });
    } catch (err) {
      flashMessageFromResponseError(err);
    }
  };
  const submitForm = async () => {
    const formattedThemes = form.themes.map((formTheme) => formTheme.id);
    const url = route("memory", { id: memory.id });
    const body = {
      memory: {
        publication_status: form.publication_status,
        theme_ids: formattedThemes
      }
    };
    try {
      await Api.utility.patch(url, body);
      if (document.referrer.includes("memory_curation") || document.referrer === "") {
        window.location.href = route("memoryCuration", { slug: project.slug });
      } else {
        window.location.href = document.referrer;
      }
    } catch (err) {
      flashMessageFromResponseError(err);
    }
  };
  const onChangeLifeThread = (lifeThreadName) => {
    setNewLifeThreadName(lifeThreadName);
  };
  const onAddLifeThread = async () => {
    handleCreateTheme(newLifeThreadName);
    setIsAddLifeThreadModalVisible(false);
  };
  const onAddLifeThreadModalOnClose = () => {
    setIsAddLifeThreadModalVisible(false);
  };
  const onCustomLifeThreadClicked = () => {
    setIsAddLifeThreadModalVisible(true);
  };
  return /* @__PURE__ */ React.createElement("div", { className: "curate-memory sub-settings" }, /* @__PURE__ */ React.createElement("div", { className: "curate-memory__top-nav" }, /* @__PURE__ */ React.createElement(BackLink, { text: backLinkText(), goBack: true })), /* @__PURE__ */ React.createElement("div", { className: "sub-settings__header-wrapper" }, /* @__PURE__ */ React.createElement("h1", { className: "sub-settings__header" }, t("views.settings.projectSettings.curateMemory.header"))), /* @__PURE__ */ React.createElement("div", { className: "max-w-3xl mx-auto" }, /* @__PURE__ */ React.createElement("div", { className: "text-lg" }, /* @__PURE__ */ React.createElement("p", { className: "my-4" }, "Life Threads keep memories organized on ", project.preferred_name, "'s LifeWeb. Review the memory and update the Life Threads below."), /* @__PURE__ */ React.createElement("p", { className: "my-4" }, "You can also hide the memory if you no longer want it visible on the LifeWeb.")), /* @__PURE__ */ React.createElement("div", { className: "bg-white mt-6 p-4" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("strong", null, "Contributor:"), " ", fullName(memory), " ", email && `(${email})`), memory.relationship_description && /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("strong", null, "Relationship:"), " ", memory.relationship_description)), /* @__PURE__ */ React.createElement("div", { className: "bg-lifeweb-yellow mb-12 p-4" }, /* @__PURE__ */ React.createElement("div", { className: "image-preview-rail" }, memory.memory_images.map((memoryImage) => {
    return /* @__PURE__ */ React.createElement("div", { className: "image-preview-rail__image", key: memoryImage.id }, /* @__PURE__ */ React.createElement(
      Thumbnail,
      {
        alt: "Memory image thumbnail",
        url: memoryImage.image_url
      }
    ));
  })), memory.memory_video && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "curate-memory__thumbnail-wrapper" }, /* @__PURE__ */ React.createElement(
    VideoPreview,
    {
      thumbnailUrl: memory.memory_video.video_thumbnail_url
    }
  )), /* @__PURE__ */ React.createElement(
    "a",
    {
      className: "curate-memory__video_url",
      href: memory.memory_video.video_url,
      target: "_blank"
    },
    memory.memory_video.video_url
  )), /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "prose",
      dangerouslySetInnerHTML: {
        __html: sanitizeUserInput(memory.description)
      }
    }
  )), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(H3, null, "Update Life Threads"), /* @__PURE__ */ React.createElement("p", null, "Select the appropriate categories for this memory.", " ", /* @__PURE__ */ React.createElement("strong", null, "Please choose at least one.")), /* @__PURE__ */ React.createElement("div", { className: "my-6" }, /* @__PURE__ */ React.createElement(
    ThemeRail,
    {
      isRemovable: true,
      onAddTheme: addTheme,
      onAddNewLifeThread: isAdmin && onCustomLifeThreadClicked,
      onRemoveTheme: removeTheme,
      selectedThemes: form.themes,
      themes,
      validationMessage: themeValidation
    }
  )), memory.publication_status === "unpublished" && /* @__PURE__ */ React.createElement("div", { className: "my-6" }, /* @__PURE__ */ React.createElement(Label, { labelSize: "small", isBold: true, isInlineBlock: true }, /* @__PURE__ */ React.createElement(
    Checkbox,
    {
      onChange: () => setForm({
        ...form,
        publication_status: form.publication_status === "published" ? "unpublished" : "published"
      }),
      isMedium: true
    }
  ), /* @__PURE__ */ React.createElement("span", { className: "px-2" }, "Publish this memory. If left unchecked, it will remain hidden.")))), /* @__PURE__ */ React.createElement("div", { className: "grid grid-cols-2 gap-4 items-center my-12" }, /* @__PURE__ */ React.createElement("div", { className: "text-center" }, memory.publication_status === "published" && /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "cursor-pointer font-semibold mx-8 text-lifeweb-brown",
      onClick: () => setConfirmHideMemory(true)
    },
    "Hide Memory"
  )), /* @__PURE__ */ React.createElement("div", { className: "grid grid-cols-2 gap-6" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "outline",
      type: "button",
      onClick: () => window.history.back()
    },
    "Cancel"
  ), /* @__PURE__ */ React.createElement(Button, { color: "secondary", onClick: handleSubmit }, "Update"))), /* @__PURE__ */ React.createElement(Modal, { size: "medium", isVisible: confirmHideMemory }, /* @__PURE__ */ React.createElement(
    ConfirmationModal,
    {
      cancelText: "Cancel",
      closeText: "Close",
      confirmText: "Hide Memory",
      onClose: () => setConfirmHideMemory(false),
      onConfirm: hideMemory,
      subtitle: "If you change your mind later, you can publish it again by editing the memory.",
      title: "Are you sure?"
    }
  )), /* @__PURE__ */ React.createElement(
    MemoriesAddLifeThreadModal,
    {
      isVisible: isAddLifeThreadModalVisible,
      lifeThreadName: newLifeThreadName,
      onAddLifeThread,
      onChangeLifeThread,
      onClose: onAddLifeThreadModalOnClose
    }
  )));
};
export default CurateMemory;
