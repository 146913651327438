import Api from "@/api/Api";
import ActivityIndicator from "@/components/activity/ActivityIndicator";
import Button from "@/components/button/Button";
import Input from "@/components/form/Input";
import Toggle from "@/components/form/Toggle";
import CloseButton from "@/components/modal/CloseButton";
import Modal from "@/components/modal/Modal";
import { H2 } from "@/components/typography";
import { useBreadcrumb } from "@/contexts/providers/BreadcrumbContext";
import { useProjectQuery, useProjectThemesQuery } from "@/queries";
import { flashSuccessMessage } from "@/utils/alert_utils";
import { route } from "@/utils/route_utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import SettingsTitle from "../SettingsTitle";
const LifeThreadModal = ({
  onClose,
  project,
  theme,
  visible
}) => {
  const queryClient = useQueryClient();
  const { slug } = useParams();
  const [name, setName] = useState("");
  const formRef = useRef(null);
  useEffect(() => {
    var _a;
    return setName((_a = theme == null ? void 0 : theme.name) != null ? _a : "");
  }, [theme]);
  const handleSubmit = async (e) => {
    if (!formRef.current.checkValidity()) {
      return formRef.current.reportValidity();
    }
    e.preventDefault();
    const url = theme ? route("theme", { id: theme.id }) : route("themesIndex");
    const method = theme ? "patch" : "post";
    await Api.utility[method](url, { theme: { name, project_id: project.id } });
    flashSuccessMessage(`Life Thread ${theme ? "updated" : "added"}`);
    onClose();
  };
  const mutation = useMutation({
    mutationFn: handleSubmit,
    onSuccess: () => queryClient.invalidateQueries({
      queryKey: ["project", { slug }, "themes"]
    })
  });
  return /* @__PURE__ */ React.createElement(Modal, { isVisible: visible, onClose }, /* @__PURE__ */ React.createElement("div", { className: "flex justify-end" }, /* @__PURE__ */ React.createElement(CloseButton, { onClose })), /* @__PURE__ */ React.createElement(H2, { className: "text-center" }, theme ? "Edit" : "Add", " Life Thread"), /* @__PURE__ */ React.createElement("form", { method: "post", onSubmit: mutation.mutate, ref: formRef }, /* @__PURE__ */ React.createElement("label", null, "Life Thread", /* @__PURE__ */ React.createElement(
    Input,
    {
      isHighlighted: true,
      onInput: (e) => setName(e.currentTarget.value),
      required: true,
      value: name
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "max-w-xs mx-auto mb-4 mt-8" }, /* @__PURE__ */ React.createElement(Button, { color: "secondary", fullWidth: true, type: "submit", isDisabled: !name }, "Save"))));
};
const TD = ({ children }) => /* @__PURE__ */ React.createElement("td", { className: "align-middle px-5 py-2" }, children);
const ThemeVisibility = ({ theme }) => {
  const queryClient = useQueryClient();
  const { slug } = useParams();
  if (theme.source === "default") {
    return /* @__PURE__ */ React.createElement(TD, null, /* @__PURE__ */ React.createElement("span", { className: "hidden sm:block text-center text-sm" }, "Default threads may not be hidden"), /* @__PURE__ */ React.createElement("span", { className: "block sm:hidden text-center text-sm" }, "Default"));
  }
  const handleToggle = async () => {
    await Api.utility.patch(route("theme", { id: theme.id }), {
      theme: { status: theme.status === "active" ? "inactive" : "active" }
    });
  };
  const mutation = useMutation({
    mutationFn: handleToggle,
    onSuccess: () => queryClient.invalidateQueries({
      queryKey: ["project", { slug }, "themes"]
    })
  });
  return /* @__PURE__ */ React.createElement(TD, null, /* @__PURE__ */ React.createElement(
    Toggle,
    {
      description: `${theme.name} Life Thread visibility`,
      onClick: mutation.mutate,
      on: theme.status === "active"
    }
  ));
};
const ThemesEdit = () => {
  const breadcrumb = useBreadcrumb();
  const { slug } = useParams();
  const { data: project } = useProjectQuery(slug);
  const { data: themes } = useProjectThemesQuery(slug);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState(null);
  useEffect(() => {
    var _a;
    if (!project)
      return;
    breadcrumb.setCrumbs([
      {
        label: `${(_a = project.prefix) != null ? _a : ""} ${project.full_name}`,
        href: route("project", { slug })
      },
      {
        label: "Admin Settings"
      },
      {
        label: "Life Threads"
      }
    ]);
    mixpanel.track("settings_viewed", {
      kind: "project",
      project_id: project.id,
      type: "life-threads"
    });
  }, [project]);
  const handleCloseModal = () => {
    setModalVisible(false);
    setSelectedTheme(null);
  };
  const handleEditTheme = (theme) => {
    setModalVisible(true);
    setSelectedTheme(theme);
  };
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(SettingsTitle, null, "Life Threads"), project && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("p", { className: "mb-4" }, "Customize Life Threads for ", project.preferred_name, "'s LifeWeb, which are used to organize the memories so they are easier for everyone to explore and build on over time."), /* @__PURE__ */ React.createElement("p", { className: "mb-4" }, "Learn more about custom Life Threads", " ", /* @__PURE__ */ React.createElement(
    "a",
    {
      className: "text-lifeweb-brown",
      href: "https://www.lifeweb360.com/life-threads-tips",
      target: "_blank",
      rel: "noreferrer"
    },
    "here"
  ), ".")), themes && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "flex justify-end mb-4" }, /* @__PURE__ */ React.createElement("div", { className: "sm:w-40" }, /* @__PURE__ */ React.createElement(Button, { color: "secondary", onClick: () => handleEditTheme(null) }, "Add Life Thread"))), /* @__PURE__ */ React.createElement("table", { className: "mb-8 table-auto w-full" }, /* @__PURE__ */ React.createElement("thead", null, /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("th", { className: "px-5 py-2" }, "Life Thread"), /* @__PURE__ */ React.createElement("th", { className: "px-5 py-2" }, "Associated Memories"), /* @__PURE__ */ React.createElement("th", { className: "text-center py-2" }, "Visibility"), /* @__PURE__ */ React.createElement("th", null))), /* @__PURE__ */ React.createElement("tbody", null, themes.map((theme, i) => /* @__PURE__ */ React.createElement(
    "tr",
    {
      className: classNames("h-16", {
        "bg-lifeweb-yellow": !(i % 2)
      }),
      key: theme.id
    },
    /* @__PURE__ */ React.createElement(TD, null, /* @__PURE__ */ React.createElement(
      "button",
      {
        className: "block underline font-semibold text-left",
        onClick: () => handleEditTheme(theme)
      },
      theme.name
    )),
    /* @__PURE__ */ React.createElement(TD, null, theme.memories.length),
    /* @__PURE__ */ React.createElement(ThemeVisibility, { theme }),
    /* @__PURE__ */ React.createElement("td", { className: "align-middle hidden sm:table-cell px-5 py-2" }, /* @__PURE__ */ React.createElement(
      "button",
      {
        className: "block underline font-semibold text-right",
        onClick: () => handleEditTheme(theme)
      },
      "Edit"
    ))
  ))))), !project || !themes && /* @__PURE__ */ React.createElement(ActivityIndicator, null), /* @__PURE__ */ React.createElement(
    LifeThreadModal,
    {
      onClose: handleCloseModal,
      project,
      theme: selectedTheme,
      visible: modalVisible
    }
  ));
};
export default ThemesEdit;
