document.addEventListener("DOMContentLoaded", () => {
  const closeButtons = document.querySelectorAll("[data-close-modal]");
  closeButtons.forEach((closeButton) => {
    closeButton.addEventListener("click", () => {
      const modalId = closeButton.dataset.closeModal;
      const modal = document.getElementById(modalId);
      modal.classList.remove("is-active");
    });
  });
});
