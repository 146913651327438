import { isLightBackground } from "@/utils/text_utils";
import classnames from "classnames";
import indefinite from "indefinite";
import React, { useState } from "react";
import HonorModal from "./modal/HonorModal";
const HonorButton = ({ group, user }) => {
  const [honorModalOpen, setHonorModalOpen] = useState(false);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    "button",
    {
      className: classnames(
        "flex-shrink-0 font-semibold px-5 py-3 rounded-lg text-center text-md uppercase w-full sm:w-auto",
        {
          "text-white": !isLightBackground(group.primary_color)
        }
      ),
      onClick: () => setHonorModalOpen(true),
      style: {
        backgroundColor: group.primary_color
      },
      type: "button"
    },
    "Honor ",
    indefinite(group.member_name)
  ), /* @__PURE__ */ React.createElement(
    HonorModal,
    {
      group,
      onClose: () => setHonorModalOpen(false),
      user,
      visible: honorModalOpen
    }
  ));
};
export default HonorButton;
