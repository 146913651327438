import Api from "@/api/Api";
import ActivityIndicator from "@/components/activity/ActivityIndicator";
import Button from "@/components/button/Button";
import FilterableMultiSelect from "@/components/form/FilterableMultiSelect";
import CloseButton from "@/components/modal/CloseButton";
import Modal from "@/components/modal/Modal";
import { H1 } from "@/components/typography";
import useSortedCategories from "@/helpers/hooks/use_sorted_categories";
import {
  flashErrorMessage,
  flashMessageFromResponseError
} from "@/utils/alert_utils";
import Modeler from "@/utils/modeler/modeler";
import { route } from "@/utils/route_utils";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
const GroupProjectEdit = ({ group }) => {
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();
  const { groupName, id, memberName } = useParams();
  const sortedCategories = useSortedCategories(group == null ? void 0 : group.group_categories);
  const onClose = () => history.push(`/groups/${groupName}`);
  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      const url = route("groupProject", { group_id: group.id, id });
      const body = {
        group_project: {
          group_category_ids: selectedCategories.map(
            (category) => category.value
          )
        }
      };
      await Api.utility.patch(url, body);
      window.location.href = route("groupProjectShow", {
        id,
        slug: groupName,
        member_name: memberName
      });
    } catch (error) {
      flashMessageFromResponseError(error);
      setSubmitting(false);
    }
  };
  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await Api.utility.get(
          route("groupProject", { group_id: group.id, id })
        );
        setProject(new Modeler(response.data).build());
      } catch (err) {
        flashErrorMessage(`${group.member_name} not found`);
      }
    };
    fetchProject();
  }, [id]);
  useEffect(() => {
    if (!project)
      return;
    setSelectedCategories(
      project.group_categories.map(
        ({ id: categoryId, name: categoryName }) => ({
          label: categoryName,
          value: categoryId
        })
      )
    );
    setLoading(false);
  }, [project]);
  if (!project)
    return;
  return /* @__PURE__ */ React.createElement(Modal, { size: "medium", isVisible: !!project, onClose }, /* @__PURE__ */ React.createElement("div", { className: "flex justify-end" }, /* @__PURE__ */ React.createElement(CloseButton, { onClose })), !loading && /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", { className: "max-w-md mx-auto" }, /* @__PURE__ */ React.createElement("div", { className: "flex justify-center" }, /* @__PURE__ */ React.createElement(H1, null, "Edit Filters")), /* @__PURE__ */ React.createElement("p", { className: "mb-8 mt-6" }, "Edit the filter values assigned to", " ", /* @__PURE__ */ React.createElement("span", { className: "font-bold" }, project.project.full_name)), Object.entries(sortedCategories).map(([name, values]) => {
    var _a;
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "gap-4 grid md:grid-cols-5 items-center my-6",
        key: name
      },
      /* @__PURE__ */ React.createElement("div", { className: "col-span-2" }, name),
      /* @__PURE__ */ React.createElement("div", { className: "col-span-3" }, /* @__PURE__ */ React.createElement(
        FilterableMultiSelect,
        {
          limit: (_a = group.group_categories.find(
            (category) => category.name === name
          )) == null ? void 0 : _a.subcategory_limit,
          options: values.map(
            ({ id: categoryId, name: categoryName }) => ({
              label: categoryName,
              value: categoryId
            })
          ),
          selected: selectedCategories,
          setStateFunction: setSelectedCategories
        }
      ))
    );
  })), /* @__PURE__ */ React.createElement("div", { className: "flex gap-4 items-center justify-end mt-12" }, /* @__PURE__ */ React.createElement("div", { className: "sm:w-1/4 w-full" }, /* @__PURE__ */ React.createElement(Button, { color: "outline", onClick: onClose, type: "button" }, "Cancel")), /* @__PURE__ */ React.createElement("div", { className: "sm:w-1/4 w-full" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "secondary",
      isDisabled: submitting,
      onClick: handleSubmit,
      type: "button"
    },
    submitting ? "Submitting" : "Save"
  )))), loading && /* @__PURE__ */ React.createElement(ActivityIndicator, null));
};
export default GroupProjectEdit;
