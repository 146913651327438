import CloseButton from "@/components/modal/CloseButton";
import Modal from "@/components/modal/Modal";
import { H3 } from "@/components/typography";
import { useProjectQuery } from "@/queries";
import { route } from "@/utils/route_utils";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import ProjectUpdateForm from "./ProjectUpdateForm";
const NewProjectUpdate = () => {
  const history = useHistory();
  const { slug } = useParams();
  const { data: project } = useProjectQuery(slug);
  const handleClose = () => history.push(route("project", { slug }));
  return /* @__PURE__ */ React.createElement(Modal, { isVisible: true, onClose: handleClose }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", { className: "flex items-start justify-end" }, /* @__PURE__ */ React.createElement(CloseButton, { onClose: handleClose })), /* @__PURE__ */ React.createElement(H3, { className: "mt-0 text-center" }, "Post a New Update"), project && /* @__PURE__ */ React.createElement(ProjectUpdateForm, { project })));
};
export default NewProjectUpdate;
