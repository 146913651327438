import classNames from "classnames";
import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
const Button = (props) => {
  const {
    children,
    color,
    fullWidth,
    id,
    isDisabled,
    isFacebookBtn,
    isGoogleBtn,
    loading,
    onClick,
    onMouseDown,
    style = {},
    type
  } = props;
  const classes = () => color || isGoogleBtn || isFacebookBtn || fullWidth ? classNames("button", {
    "is-disabled": isDisabled,
    "is-outline": color === "outline",
    "is-primary": color === "primary",
    "is-removable": color === "removable",
    "is-secondary": color === "secondary",
    "is-white": color === "white",
    "is-google-button": isGoogleBtn,
    "is-facebook-button": isFacebookBtn,
    "full-width": fullWidth
  }) : "bg-white border border-transparent font-semibold hover:text-black inline-block px-5 py-3 rounded-lg text-gray-700 text-md uppercase";
  return /* @__PURE__ */ React.createElement(
    "button",
    {
      className: classes(),
      disabled: isDisabled,
      onClick,
      onMouseDown,
      id,
      style,
      type
    },
    loading ? /* @__PURE__ */ React.createElement(ClipLoader, { size: 25, color: "white", loading: true }) : children
  );
};
const RectangularButton = ({
  children,
  className = "",
  onClick,
  style = {}
}) => {
  return /* @__PURE__ */ React.createElement(
    "button",
    {
      className: `bg-lifeweb-blue font-semibold hover:text-white flex items-center justify-center mb-6 outline-none px-5 py-3 text-center text-white text-md w-full ${className}`,
      onClick,
      style
    },
    children
  );
};
export { Button as default, RectangularButton };
