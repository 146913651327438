import ReflectionForm from "@/contexts/reflections/ReflectionForm";
import React from "react";
const ReflectionEdit = ({ group, reflection, uploadPath, user }) => /* @__PURE__ */ React.createElement("div", { className: "max-w-screen-sm mx-auto" }, /* @__PURE__ */ React.createElement("p", { className: "mb-4" }, "Edit your reflection by sharing an inspirational photo or thoughtful note to honor everyone in our community who has passed away."), /* @__PURE__ */ React.createElement(
  ReflectionForm,
  {
    group,
    reflection,
    uploadPath,
    user
  }
));
export default ReflectionEdit;
