import Breadcrumb from "@/components/Breadcrumb";
import React, { createContext, useContext, useState } from "react";
const BreadcrumbContext = createContext({
  crumbs: [],
  setCrumbs: () => null
});
export const useBreadcrumb = () => useContext(BreadcrumbContext);
export const BreadcrumbProvider = ({ children }) => {
  const [crumbs, setCrumbs] = useState([]);
  return /* @__PURE__ */ React.createElement(BreadcrumbContext.Provider, { value: { crumbs, setCrumbs } }, /* @__PURE__ */ React.createElement(Breadcrumb, { crumbs }), children);
};
