import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "@/api/Api";
import Button from "@/components/button/Button";
import InputGroup from "@/components/form/InputGroup";
import SelectGroup from "@/components/form/SelectGroup";
import BackButton from "@/components/modal/BackButton";
import CloseButton from "@/components/modal/CloseButton";
import Modal from "@/components/modal/Modal";
import { flashMessageFromResponseError } from "@/utils/alert_utils";
import { areValuesEmpty } from "@/utils/form_utils";
import Modeler from "@/utils/modeler/modeler";
import { pronounOptions } from "@/utils/project_utils";
import { route } from "@/utils/route_utils";
const ProjectNameModal = (props) => {
  const {
    isOnboarding,
    isVisible,
    onClose,
    onUpdateProject,
    onUpdateProjectField,
    onUpdateStepNumber,
    project,
    stepNumber
  } = props;
  const initialFormState = {
    full_name: project.full_name || "",
    prefix: project.prefix || "",
    preferred_name: project.preferred_name || "",
    pronoun: project.pronoun || ""
  };
  const [form, setForm] = useState(initialFormState);
  const { t } = useTranslation();
  const isSaveButtonDisabled = areValuesEmpty(form, [
    "full_name",
    "preferred_name",
    "pronoun"
  ]);
  const updateField = (field, value) => {
    if (isOnboarding) {
      onUpdateProjectField(field, value);
    }
    setForm({
      ...form,
      [field]: value
    });
  };
  const goBackOneStep = () => {
    onUpdateStepNumber(stepNumber - 1);
  };
  const goToNextStep = () => {
    onUpdateStepNumber(stepNumber + 1);
  };
  const handleSaveClick = () => {
    if (isOnboarding) {
      goToNextStep();
    } else {
      saveProject();
    }
  };
  const saveProject = async () => {
    const url = route("projectsUpdate", { slug: project.slug });
    const body = {
      project: {
        ...form
      }
    };
    try {
      const response = await Api.utility.patch(url, body);
      const updatedProject = new Modeler(response.data).build();
      onUpdateProject(updatedProject);
      onClose();
    } catch (err) {
      flashMessageFromResponseError(err);
    }
  };
  const renderTopButton = () => {
    if (isOnboarding) {
      return /* @__PURE__ */ React.createElement("div", { className: "project-name-modal__back-wrapper" }, /* @__PURE__ */ React.createElement(BackButton, { onBack: goBackOneStep }));
    } else {
      return /* @__PURE__ */ React.createElement("div", { className: "project-name-modal__close-wrapper" }, /* @__PURE__ */ React.createElement(CloseButton, { onClose }));
    }
  };
  return /* @__PURE__ */ React.createElement(Modal, { isVisible, size: "medium" }, /* @__PURE__ */ React.createElement("div", { className: "project-name-modal" }, renderTopButton(), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column" }, /* @__PURE__ */ React.createElement("h1", null, t("views.memories.projectEdit.name.header")))), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-10-tablet" }, /* @__PURE__ */ React.createElement(
    InputGroup,
    {
      isRequired: true,
      label: t(
        "views.memories.projectEdit.name.labels.fullName"
      ),
      labelSize: "small",
      onChange: (value) => updateField("full_name", value),
      placeholder: t(
        "views.memories.projectEdit.name.placeholders.fullName"
      ),
      value: form.full_name
    }
  ), isOnboarding && /* @__PURE__ */ React.createElement("div", { className: "project-name-modal__subtext-wrapper" }, t("views.memories.projectEdit.name.subtext.fullName")))), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-10-tablet" }, /* @__PURE__ */ React.createElement(
    InputGroup,
    {
      label: t(
        "views.memories.projectEdit.name.labels.prefixes"
      ),
      labelSize: "small",
      maxLength: 20,
      onChange: (value) => updateField("prefix", value),
      placeholder: t(
        "views.memories.projectEdit.name.placeholders.prefixes"
      ),
      value: form.prefix
    }
  ))), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-10-tablet project-name-modal__preferred-name-wrapper" }, /* @__PURE__ */ React.createElement(
    InputGroup,
    {
      isRequired: true,
      label: t(
        "views.memories.projectEdit.name.labels.preferred"
      ),
      labelSize: "small",
      onChange: (value) => updateField("preferred_name", value),
      placeholder: t(
        "views.memories.projectEdit.name.placeholders.preferred"
      ),
      value: form.preferred_name
    }
  ), isOnboarding && /* @__PURE__ */ React.createElement("div", { className: "project-name-modal__subtext-wrapper" }, t("views.memories.projectEdit.name.subtext.preferred")))), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-10-tablet" }, /* @__PURE__ */ React.createElement(
    SelectGroup,
    {
      isRequired: true,
      label: t(
        "views.memories.projectEdit.name.labels.pronouns"
      ),
      labelSize: "small",
      onChange: (value) => updateField("pronoun", value),
      placeholder: t(
        "views.memories.projectEdit.name.placeholders.pronouns"
      ),
      options: pronounOptions(),
      value: form.pronoun
    }
  ))), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-6-tablet project-name-modal__save-button-wrapper" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "primary",
      isDisabled: isSaveButtonDisabled,
      onClick: handleSaveClick
    },
    t("views.memories.projectEdit.saveButton")
  )))));
};
export default ProjectNameModal;
