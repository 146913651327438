import classNames from "classnames";
import React from "react";
const MobileMenuButton = ({ onClick, open }) => /* @__PURE__ */ React.createElement(
  "button",
  {
    "aria-controls": "main-menu",
    "aria-expanded": open,
    className: "absolute p-4 sm:hidden bottom-0 top-0 left-0 z-40",
    id: "main-menu-button",
    onClick
  },
  /* @__PURE__ */ React.createElement("span", { className: "sr-only" }, "Toggle Main Menu"),
  /* @__PURE__ */ React.createElement("div", { className: "flex flex-col gap-[4px]" }, /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames(
        "h-[1px] w-4 bg-lifeweb-blue duration-150 transition-transform origin-center",
        { "rotate-45 translate-y-[0.3rem]": open }
      )
    }
  ), /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames(
        "h-[1px] w-4 bg-lifeweb-blue duration-150 transition-opacity",
        { "opacity-0": open }
      )
    }
  ), /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames(
        "h-[1px] w-4 bg-lifeweb-blue duration-150 transition-transform origin-center",
        { "-rotate-45 -translate-y-[0.3rem]": open }
      )
    }
  ))
);
export default MobileMenuButton;
