import ErrorMessage from "@/components/form/ErrorMessage";
import { Input } from "@/components/form/Input";
import Label from "@/components/form/Label";
import EmbedIcon from "@/components/icon/EmbedIcon";
import { isValueEmpty } from "@/utils/form_utils";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
const NewPassword = (props) => {
  const { passwordPlaceholder, onUpdateUserField, userPassword } = props;
  const { t } = useTranslation();
  const [isFocus, setIsFocus] = useState(false);
  const [toggleText, setToggleText] = useState("SHOW");
  const [fieldType, setFieldType] = useState("password");
  const [minimumLength, setMinimumLenght] = useState(false);
  const [upperCaseLetter, setUpperCaseLetter] = useState(false);
  const [lowerCaseLetter, setLowerCaseLetter] = useState(false);
  const [numericCharacter, setNumericCharacter] = useState(false);
  const [specialCharacter, setSpecialCharacter] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const validatePassword = (value) => {
    setIsFocus(true);
    onUpdateUserField("password", value);
    const mustContainData = {
      upper: value.toLowerCase() !== value,
      lower: value.toUpperCase() !== value,
      number: /\d/.test(value),
      special: /[~`!@#$%\^&*+=\-\[\]\\';,./()_{}|\\":<>\?]/g.test(value),
      min_lenght: value.length >= 10
    };
    if (mustContainData.upper)
      setUpperCaseLetter(true);
    else
      setUpperCaseLetter(false);
    if (mustContainData.lower)
      setLowerCaseLetter(true);
    else
      setLowerCaseLetter(false);
    if (mustContainData.number)
      setNumericCharacter(true);
    else
      setNumericCharacter(false);
    if (mustContainData.special)
      setSpecialCharacter(true);
    else
      setSpecialCharacter(false);
    if (mustContainData.min_lenght)
      setMinimumLenght(true);
    else
      setMinimumLenght(false);
    if (isValueEmpty(value))
      setValidationMessage("Field is required");
    else
      setValidationMessage("");
  };
  const renderSubText = (criteria, subtext) => {
    if (!isFocus) {
      return /* @__PURE__ */ React.createElement(EmbedIcon, { icon: "tick", size: "small", isActive: false }, t(subtext));
    } else {
      if (!criteria) {
        return /* @__PURE__ */ React.createElement(EmbedIcon, { icon: "cross", size: "small", isActive: true }, t(subtext));
      } else {
        return /* @__PURE__ */ React.createElement(EmbedIcon, { icon: "tick", size: "small", isActive: true }, t(subtext));
      }
    }
  };
  const handlePasswordToggle = () => {
    if (toggleText === "SHOW") {
      setToggleText("HIDE");
      setFieldType(null);
    } else {
      setToggleText("SHOW");
      setFieldType("password");
    }
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-10-mobile is-5-tablet" }, /* @__PURE__ */ React.createElement("div", { className: "form-input-group" }, /* @__PURE__ */ React.createElement(Label, { htmlFor: "user_password", labelSize: "small", withoutPadding: true }, t("views.memories.usersNew.labels.password")), /* @__PURE__ */ React.createElement(
    Input,
    {
      id: "user_password",
      onChange: (value) => validatePassword(value),
      placeholder: passwordPlaceholder,
      type: fieldType,
      toggle: true,
      toggleText,
      onToggle: handlePasswordToggle,
      value: userPassword
    }
  ), validationMessage && /* @__PURE__ */ React.createElement(ErrorMessage, { message: validationMessage })))), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-10-mobile is-5-tablet user-new__password-subtext" }, /* @__PURE__ */ React.createElement("p", { className: "authentication__subtext" }, t("Your password must be") + ":"), /* @__PURE__ */ React.createElement("p", { className: "authentication__subtext" }, renderSubText(minimumLength, "At least 10 Characters")), /* @__PURE__ */ React.createElement("p", { className: "authentication__subtext" }, renderSubText(
    upperCaseLetter,
    "Include at least 1 uppercase letter (A-Z)"
  )), /* @__PURE__ */ React.createElement("p", { className: "authentication__subtext" }, renderSubText(
    lowerCaseLetter,
    "Include at least 1 lowercase letters (a-z)"
  )), /* @__PURE__ */ React.createElement("p", { className: "authentication__subtext" }, renderSubText(
    numericCharacter,
    "Include at least 1 numeric character (0-9)"
  )), /* @__PURE__ */ React.createElement("p", { className: "authentication__subtext" }, renderSubText(
    specialCharacter,
    "include at lease 1 special character (such as !, %, @, or #)."
  )))));
};
export default NewPassword;
