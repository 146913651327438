import Api from "@/api/Api";
import Button from "@/components/button/Button";
import Input from "@/components/form/Input";
import InputGroup from "@/components/form/InputGroup";
import TextArea from "@/components/form/TextArea";
import ValidationLabel from "@/components/form/ValidationLabel";
import FbIcon from "@/components/icon/facebook";
import Icon from "@/components/icon/Icon";
import {
  flashMessageFromResponseError,
  flashSuccessMessage
} from "@/utils/alert_utils";
import { buildPath } from "@/utils/api_utils";
import { areValuesEmpty, validateEmailAddress } from "@/utils/form_utils";
import { invitationExpireDays } from "@/variables/constants";
import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
const UserModal = (props) => {
  const {
    onClose,
    project,
    inviteMode,
    isAdmin,
    currentUser,
    modeledProjectUser
  } = props;
  const { t } = useTranslation();
  const [emailValidationMessages, setEmailValidationMessages] = useState({});
  const [tabView, setTabView] = useState("email");
  const newEmptyUser = () => ({
    uuid: Date.now(),
    firstName: "",
    lastName: "",
    email: ""
  });
  const [users, setUsers] = useState([newEmptyUser()]);
  const [loading, setLoading] = useState(false);
  const [emailContent, setEmailContent] = useState("");
  const facebookDescription = t("views.memories.share.facebookDesc", {
    preferred_name: project.preferred_name
  });
  const onUpdate = (uuid, field, value) => {
    const newUsers = [...users];
    const idx = newUsers.findIndex((ele) => ele.uuid === uuid);
    const newUser = {
      ...newUsers.splice(idx, 1)[0]
    };
    newUser[field] = value;
    newUsers.splice(idx, 0, newUser);
    setUsers(newUsers);
  };
  let isSendButtonDisabled = false;
  const isUserEmpty = (user) => {
    return areValuesEmpty(user, ["email", "firstName", "lastName"]);
  };
  for (const user of users) {
    if (isUserEmpty(user)) {
      isSendButtonDisabled = true;
      break;
    }
  }
  const switchTab = () => {
    setTabView(tabView === "facebook" ? "email" : "facebook");
  };
  const onCopyLink = () => {
    navigator.clipboard.writeText(project.project_url);
    flashSuccessMessage(t("views.memories.share.copied"));
  };
  const onAddUser = () => {
    const newUsers = [...users];
    newUsers.push(newEmptyUser());
    setUsers(newUsers);
  };
  const onRemoveEmail = (uuid) => {
    const newUsers = [...users];
    const idx = newUsers.findIndex((ele) => ele.uuid === uuid);
    newUsers.splice(idx, 1);
    setUsers(newUsers);
  };
  const onUpdateEmail = (value) => {
    setEmailContent(value);
  };
  const onSendEmail = async () => {
    setLoading(true);
    const role = inviteMode === "admin" ? "admin" : "standard";
    const url = buildPath("api/v1/users/invite_user", {
      project_id: project.id
    });
    const body = {
      users: users.map((user) => ({
        email: user.email,
        first_name: user.firstName,
        last_name: user.lastName
      })),
      message: emailContent,
      role
    };
    try {
      await Api.utility.post(url, body);
      onClose(true);
    } catch (err) {
      flashMessageFromResponseError(err);
    }
    setLoading(false);
  };
  const onShareFacebook = () => {
    window.FB.ui(
      {
        method: "share",
        href: project.project_url,
        quote: facebookDescription
      },
      (response) => {
        window.flash("LifeWeb Shared", "success");
      }
    );
  };
  const validateEmail = (e, user) => {
    const valid = validateEmailAddress(e.target.value);
    setEmailValidationMessages({
      ...emailValidationMessages,
      [user.uuid]: valid ? "" : "Invalid email address"
    });
  };
  const renderEmailContent = () => /* @__PURE__ */ React.createElement(React.Fragment, null, isAdmin && /* @__PURE__ */ React.createElement("div", { className: "user-modal__description-2" }, t(
    `views.settings.projectSettings.userManagement.userModal.desc2${inviteMode}`,
    { daysExpire: invitationExpireDays }
  )), users.map((user, idx) => /* @__PURE__ */ React.createElement("div", { key: user.uuid }, idx > 0 && /* @__PURE__ */ React.createElement("div", { className: "user-modal__line_seperator" }), /* @__PURE__ */ React.createElement("div", { className: "user-modal__name-wrapper" }, idx > 0 && /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "user-modal__remove_icon",
      onClick: () => onRemoveEmail(user.uuid)
    },
    /* @__PURE__ */ React.createElement(Icon, { icon: "xSolo" })
  ), /* @__PURE__ */ React.createElement(
    InputGroup,
    {
      isRequired: true,
      label: t("form.label.userModal.name"),
      onChange: (value) => onUpdate(user.uuid, "firstName", value),
      placeholder: t("form.placeholder.userModal.firstName"),
      value: user.firstName
    }
  ), /* @__PURE__ */ React.createElement(
    InputGroup,
    {
      label: "",
      onChange: (value) => onUpdate(user.uuid, "lastName", value),
      placeholder: t("form.placeholder.userModal.lastName"),
      value: user.lastName
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "user-modal__email-wrapper" }, /* @__PURE__ */ React.createElement("div", { className: "mb-4 text-lg" }, /* @__PURE__ */ React.createElement(
    ValidationLabel,
    {
      error: emailValidationMessages[user.uuid],
      valid: !emailValidationMessages[user.uuid]
    },
    "Their Email"
  ), /* @__PURE__ */ React.createElement("span", { className: "form-label is-red" }, " * Required")), /* @__PURE__ */ React.createElement(
    Input,
    {
      type: "email",
      onBlur: (e) => validateEmail(e, user),
      onChange: (value) => onUpdate(user.uuid, "email", value),
      placeholder: t("form.placeholder.userModal.email"),
      value: user.email
    }
  )))), /* @__PURE__ */ React.createElement("button", { className: "user-modal__remove", onClick: onAddUser }, t(
    "views.settings.projectSettings.userManagement.userModal.addMoreUser"
  )), /* @__PURE__ */ React.createElement("div", { className: "user-modal__your-message" }, /* @__PURE__ */ React.createElement("label", { className: "form-label" }, t(
    "views.settings.projectSettings.userManagement.userModal.yourMessage"
  ))), /* @__PURE__ */ React.createElement(TextArea, { onChange: onUpdateEmail, value: emailContent }), /* @__PURE__ */ React.createElement("div", { className: "user-modal__action-wrapper" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "secondary",
      loading,
      isDisabled: loading || isSendButtonDisabled || Object.values(emailValidationMessages).some((msg) => msg),
      onClick: onSendEmail
    },
    t("views.memories.share.send")
  )));
  const renderFacebook = () => /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "modal-share-lifeweb__facebook-message" }, facebookDescription), /* @__PURE__ */ React.createElement("div", { className: "user-modal__action-wrapper" }, /* @__PURE__ */ React.createElement(Button, { color: "secondary", onClick: onShareFacebook }, /* @__PURE__ */ React.createElement("span", { className: "modal-share-lifeweb__fb-button" }, /* @__PURE__ */ React.createElement(FbIcon, null)), t("views.memories.share.share"))));
  return /* @__PURE__ */ React.createElement("div", { className: "user-modal" }, /* @__PURE__ */ React.createElement("div", { className: "modal-confirmation__top" }, /* @__PURE__ */ React.createElement(
    "button",
    {
      className: "modal-confirmation__close",
      onClick: () => onClose(false)
    },
    t("modal.close"),
    /* @__PURE__ */ React.createElement(Icon, { icon: "xSolo" })
  )), /* @__PURE__ */ React.createElement("div", { className: "modal-confirmation__content columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-10-tablet" }, /* @__PURE__ */ React.createElement("div", { className: "modal-confirmation__hero_img" }, /* @__PURE__ */ React.createElement(
    "img",
    {
      src: project.cover_image_url_large || require("@/assets/images/project_default.jpg")
    }
  )), /* @__PURE__ */ React.createElement("h1", { className: "modal-confirmation__header" }, t(
    `views.settings.projectSettings.userManagement.userModal.title${inviteMode}`,
    { preferredName: project.preferred_name }
  )), /* @__PURE__ */ React.createElement("div", { className: "user-modal__description-wrapper" }, t(
    `views.settings.projectSettings.userManagement.userModal.desc1${inviteMode}`,
    { preferredName: project.preferred_name }
  )))), /* @__PURE__ */ React.createElement("div", { className: "user-modal__form-wrapper" }, inviteMode === "share" && /* @__PURE__ */ React.createElement("div", { className: "columns is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-10-tablet modal-share-lifeweb__tab-wrapper" }, /* @__PURE__ */ React.createElement(
    "button",
    {
      onClick: switchTab,
      className: classNames("button modal-share-lifeweb__tab", {
        "is-active": tabView === "email"
      })
    },
    t("views.memories.share.email")
  ), /* @__PURE__ */ React.createElement(
    "button",
    {
      onClick: switchTab,
      className: classNames(
        "button modal-share-lifeweb__tab is-wide",
        {
          "is-active": tabView === "facebook"
        }
      )
    },
    t("views.memories.share.facebook")
  ), /* @__PURE__ */ React.createElement(
    "button",
    {
      onClick: onCopyLink,
      className: "button modal-share-lifeweb__tab is-wide",
      style: { borderColor: "transparent" }
    },
    t("views.memories.share.copyLink")
  ))), tabView === "email" ? renderEmailContent() : renderFacebook()));
};
export default UserModal;
