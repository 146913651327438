import React from "react";
import { useTranslation } from "react-i18next";
import Icon from "../icon/Icon";
const CloseButton = (props) => {
  const { onClose } = props;
  const { t } = useTranslation();
  const text = "views.settings.projectSettings.userManagement.confirmationModal.close";
  return /* @__PURE__ */ React.createElement("button", { className: "modal-confirmation__close", onClick: onClose }, t(text), /* @__PURE__ */ React.createElement(Icon, { icon: "xSolo" }));
};
export default CloseButton;
