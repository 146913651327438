import ButtonLink from "@/components/button/ButtonLink";
import {
  getHighContrastTextColor,
  isLightBackground
} from "@/utils/text_utils";
import classNames from "classnames";
import Pluralize from "pluralize";
import React from "react";
import GroupUpdate from "./GroupUpdate";
const GroupIntro = ({ group }) => {
  const bannerAlt = `${group.name} banner: Honoring our ${Pluralize.plural(
    group.member_name
  )}`;
  const hasLightBackground = isLightBackground(group.primary_color);
  const logoImage = /* @__PURE__ */ React.createElement(
    "img",
    {
      alt: group.name,
      className: classNames("block mx-auto mb-0", {
        "max-w-md w-full": group.banner_columns === 2
      }),
      src: group.logo_url
    }
  );
  const trackCtaClick = () => {
    mixpanel.track("groupcta_clicked", {
      kind: "group",
      group_name: group.slug,
      type: "button"
    });
    return true;
  };
  return /* @__PURE__ */ React.createElement("section", null, /* @__PURE__ */ React.createElement("header", { style: { backgroundColor: group.primary_color } }, group.banner_image_url && /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "bg-[center_bottom] bg-cover bg-no-repeat hidden sm:block",
      style: { backgroundImage: `url(${group.banner_image_url})` }
    },
    /* @__PURE__ */ React.createElement(
      "img",
      {
        alt: bannerAlt,
        src: group.banner_image_url,
        className: "invisible max-h-80"
      }
    )
  ), group.banner_image_mobile_url && /* @__PURE__ */ React.createElement(
    "img",
    {
      src: group.banner_image_mobile_url,
      className: "block sm:hidden w-full",
      alt: bannerAlt
    }
  ), /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames(
        "max-w-screen-lg mx-auto py-9 px-5 sm:px-9 text-center",
        {
          "grid sm:grid-cols-4 gap-8": group.banner_columns === 2,
          "text-black": hasLightBackground,
          "text-white": !hasLightBackground
        }
      )
    },
    group.group_website_url ? /* @__PURE__ */ React.createElement(
      "a",
      {
        className: "block mx-auto w-full mb-0",
        href: group.group_website_url,
        target: "_blank"
      },
      logoImage
    ) : logoImage,
    /* @__PURE__ */ React.createElement("div", { className: "sm:col-span-3 place-self-center" }, /* @__PURE__ */ React.createElement("h1", { className: "font-nunito font-semibold mb-4 text-4xl" }, group.title), group.subgroup_name && /* @__PURE__ */ React.createElement("p", { className: "mb-4 text-3xl" }, group.subgroup_name), /* @__PURE__ */ React.createElement(
      "div",
      {
        className: classNames("max-w-none mb-4 prose text-2xl", {
          "prose-invert": !hasLightBackground
        }),
        dangerouslySetInnerHTML: { __html: group.description }
      }
    ), /* @__PURE__ */ React.createElement(GroupUpdate, { group }), group.cta_url && /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
      ButtonLink,
      {
        href: group.cta_url,
        onClick: trackCtaClick,
        style: {
          backgroundColor: group.button_color,
          borderColor: group.primary_color
        }
      },
      /* @__PURE__ */ React.createElement(
        "span",
        {
          className: getHighContrastTextColor(group.button_color)
        },
        group.cta_text || "See More"
      )
    )))
  )));
};
export default GroupIntro;
