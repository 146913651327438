import React from "react";
import Modal from "@/components/modal/Modal";
import Button from "@/components/button/Button";
import CloseButton from "@/components/modal/CloseButton";
const AccessRequestSentModal = (props) => {
  const {
    isVisible,
    onClose,
    project
  } = props;
  const handleButtonOnClick = () => {
    window.location.href = "/";
  };
  return /* @__PURE__ */ React.createElement(Modal, { noPadding: true, isVisible, size: "medium" }, /* @__PURE__ */ React.createElement("div", { className: "access-request-modal__close-wrapper" }, /* @__PURE__ */ React.createElement(CloseButton, { onClose })), /* @__PURE__ */ React.createElement("div", { className: "access-request-modal" }, /* @__PURE__ */ React.createElement("div", { className: "access-request-modal__header-wrapper" }, /* @__PURE__ */ React.createElement("h1", null, "Request Submitted")), /* @__PURE__ */ React.createElement("div", { className: "columns" }, /* @__PURE__ */ React.createElement("div", { className: "column access-request-modal__message" }, /* @__PURE__ */ React.createElement("p", null, "We'll send you an email notification if the Admins of ", project.preferred_name, "'s LifeWeb approve your request."))), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-10-mobile is-5-tablet" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "primary",
      onClick: handleButtonOnClick
    },
    "My LifeWebs"
  )))));
};
export default AccessRequestSentModal;
