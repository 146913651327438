import React from "react";
const Icon = () => /* @__PURE__ */ React.createElement(
  "svg",
  {
    className: "inline",
    xmlns: "http://www.w3.org/2000/svg",
    width: "20",
    height: "20",
    viewBox: "0 0 20 20"
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fill: "#FFF",
      fillRule: "evenodd",
      d: "M20 2.143v15.714C20 19.04 19.04 20 17.857 20H14.05v-7.91h2.705l.389-3.019h-3.094V7.143c0-.875.241-1.469 1.496-1.469h1.598V2.978c-.277-.036-1.223-.12-2.33-.12-2.304 0-3.884 1.405-3.884 3.99v2.228H8.214v3.018h2.719V20h-8.79C.96 20 0 19.04 0 17.857V2.143C0 .96.96 0 2.143 0h15.714C19.04 0 20 .96 20 2.143z"
    }
  )
);
export default Icon;
