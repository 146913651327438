import Api from "@/api/Api";
import Button from "@/components/button/Button";
import Select from "@/components/form/Select";
import Icon from "@/components/icon/Icon";
import { H1 } from "@/components/typography";
import {
  flashMessageFromResponseError,
  flashSuccessMessage
} from "@/utils/alert_utils";
import { route } from "@/utils/route_utils";
import { capitalize } from "@/utils/string_utils";
import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
const UserInviteFormBody = ({
  handleConfirm,
  onClose,
  project,
  projectUser
}) => {
  const { t } = useTranslation();
  const { user } = projectUser;
  const handleSendInvite = async () => {
    const url = route("inviteUsers", {
      project_id: project.id,
      role: projectUser.role
    });
    const body = {
      users: [{ ...user }]
    };
    try {
      await Api.utility.post(url, body);
      flashSuccessMessage("Invitation Sent");
      onClose();
    } catch (err) {
      flashMessageFromResponseError(err);
    }
  };
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("dl", null, /* @__PURE__ */ React.createElement("dt", null, "Name"), /* @__PURE__ */ React.createElement("dd", null, user.first_name, " ", user.last_name), /* @__PURE__ */ React.createElement("dt", null, "Email"), /* @__PURE__ */ React.createElement("dd", null, user.email), /* @__PURE__ */ React.createElement("dt", null, "Invitation sent"), /* @__PURE__ */ React.createElement("dd", null, new Date(user.created_at).toLocaleDateString(void 0, {
    day: "numeric",
    month: "long",
    year: "numeric"
  }))), /* @__PURE__ */ React.createElement("div", { className: "mx-auto my-6 sm:w-1/3 w-full" }, /* @__PURE__ */ React.createElement(Button, { color: "secondary", onClick: handleSendInvite }, "Re-send Invite")), /* @__PURE__ */ React.createElement(
    "button",
    {
      className: "text-red-700 text-center w-full",
      color: "secondary",
      onClick: handleConfirm
    },
    t(
      "views.settings.projectSettings.userManagement.userModal.remove"
    )
  ));
};
const UserInviteForm = ({
  onConfirm,
  onClose,
  project,
  projectUser
}) => {
  const { t } = useTranslation();
  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(true);
    onConfirm();
  };
  const handleRemoveUser = async () => {
    const url = route("projectUsersUpdate", {
      project_user_id: projectUser.id
    });
    try {
      await Api.utility.delete(url);
      flashSuccessMessage(
        t(
          "views.settings.projectSettings.userManagement.successMessages.removed"
        )
      );
      onClose(false);
    } catch (err) {
      flashMessageFromResponseError(err);
    }
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, !confirm && /* @__PURE__ */ React.createElement(
    UserInviteFormBody,
    {
      handleConfirm,
      onClose,
      project,
      projectUser
    }
  ), confirm && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("p", { className: "my-4" }, "If you remove this user, their account will no longer be able to view or contribute to this LifeWeb."), /* @__PURE__ */ React.createElement("div", { className: "modal-confirmation__actions" }, /* @__PURE__ */ React.createElement(Button, { color: "outline", onClick: () => onClose(false) }, "Cancel"), /* @__PURE__ */ React.createElement(Button, { color: "secondary", onClick: handleRemoveUser }, "Remove"))));
};
const UserRequestForm = ({ onClose, projectUser }) => {
  const { user } = projectUser;
  const handleSubmit = async (status) => {
    const url = route("projectUsersUpdate", {
      project_user_id: projectUser.id
    });
    const body = {
      id: projectUser.id,
      status
    };
    try {
      await Api.utility.put(url, body);
      flashSuccessMessage(
        status === "active" ? "User Approved" : "Access Denied for User"
      );
      onClose();
    } catch (err) {
      flashMessageFromResponseError(err);
    }
  };
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("dl", null, /* @__PURE__ */ React.createElement("dt", null, "Name"), /* @__PURE__ */ React.createElement("dd", null, user.first_name, " ", user.last_name), projectUser.status === "restricted" && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("dt", null, "Current status:"), /* @__PURE__ */ React.createElement("dd", null, "Access Denied")), projectUser.relationship_types.length > 0 && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("dt", null, "Relationship"), /* @__PURE__ */ React.createElement("dd", null, projectUser.relationship_types.map(({ relationship_type }) => capitalize(relationship_type)).join(", "))), /* @__PURE__ */ React.createElement("dt", null, "Request submitted"), /* @__PURE__ */ React.createElement("dd", null, new Date(projectUser.created_at).toLocaleDateString(void 0, {
    day: "numeric",
    month: "long",
    year: "numeric"
  })), projectUser.request_message && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("dt", null, "Request message"), /* @__PURE__ */ React.createElement("dd", null, projectUser.request_message))), /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames("mb-6 mt-8", {
        "gap-4 grid sm:px-12 sm:grid-cols-2": projectUser.status !== "restricted",
        "flex justify-center": projectUser.status === "restricted"
      })
    },
    projectUser.status !== "restricted" && /* @__PURE__ */ React.createElement(
      "button",
      {
        className: "border border-red-700 font-bold rounded-md text-red-700",
        onClick: () => handleSubmit("restricted")
      },
      "Deny Access"
    ),
    /* @__PURE__ */ React.createElement(Button, { color: "secondary", onClick: () => handleSubmit("active") }, "Approve Access")
  ));
};
const UserRoleForm = ({ onClose, project, projectUser }) => {
  const [role, setRole] = useState(projectUser.role);
  const { user } = projectUser;
  const handleUpdateUser = async (options = {}) => {
    const url = route("projectUsersUpdate", {
      project_user_id: projectUser.id
    });
    const body = {
      id: projectUser.id,
      ...options
    };
    try {
      await Api.utility.put(url, body);
      flashSuccessMessage("User role updated");
      onClose();
    } catch (err) {
      flashMessageFromResponseError(err);
    }
  };
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("dl", null, /* @__PURE__ */ React.createElement("dt", null, "Name"), /* @__PURE__ */ React.createElement("dd", null, user.first_name, " ", user.last_name), /* @__PURE__ */ React.createElement("dt", null, "Connected since"), /* @__PURE__ */ React.createElement("dd", null, new Date(projectUser.created_at).toLocaleDateString(void 0, {
    day: "numeric",
    month: "long",
    year: "numeric"
  }))), /* @__PURE__ */ React.createElement("p", { className: "my-4" }, "Below you can update this user's role on ", project.preferred_name, "'s LifeWeb."), /* @__PURE__ */ React.createElement("div", { className: "flex items-center my-6 w-full" }, /* @__PURE__ */ React.createElement("span", { className: "block flex-shrink-0 mb-0 w-24" }, "Current Role:"), /* @__PURE__ */ React.createElement("div", { className: "flex-shrink-0 w-32" }, /* @__PURE__ */ React.createElement(
    Select,
    {
      onChange: setRole,
      options: [
        { label: "Admin", value: "admin" },
        { label: "Contributor", value: "standard" }
      ],
      value: role === "admin" ? "admin" : "standard"
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "ml-4" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "secondary",
      isDisabled: projectUser.role === role,
      onClick: () => handleUpdateUser({ role })
    },
    "Update"
  ))), /* @__PURE__ */ React.createElement(
    "button",
    {
      className: "mb-4 mt-2 text-red-700 text-center w-full",
      color: "secondary",
      onClick: () => handleUpdateUser({ role: "standard", status: "restricted" })
    },
    project.privacy_mode == "invite_only" ? "Remove access to this LifeWeb" : "Restrict contributions to this LifeWeb"
  ));
};
const UserEditor = (props) => {
  const { onClose, projectUser, project } = props;
  const [title, setTitle] = useState("Manage User");
  const { t } = useTranslation();
  return /* @__PURE__ */ React.createElement("div", { className: "user-modal" }, /* @__PURE__ */ React.createElement("div", { className: "modal-confirmation__top" }, /* @__PURE__ */ React.createElement("button", { className: "modal-confirmation__close", onClick: onClose }, t("modal.close"), /* @__PURE__ */ React.createElement(Icon, { icon: "xSolo" }))), /* @__PURE__ */ React.createElement("div", { className: "modal-confirmation__content columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement(H1, { className: "text-center" }, title)), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", { className: "md:mx-6" }, ["requested", "restricted"].includes(projectUser.status) && /* @__PURE__ */ React.createElement(UserRequestForm, { onClose, projectUser }), projectUser.status === "invited" && /* @__PURE__ */ React.createElement(
    UserInviteForm,
    {
      onConfirm: () => setTitle("Remove User"),
      onClose,
      project,
      projectUser
    }
  ), projectUser.status === "active" && /* @__PURE__ */ React.createElement(
    UserRoleForm,
    {
      onClose,
      project,
      projectUser
    }
  ))));
};
export default UserEditor;
