import { route } from "@/utils/route_utils";
import React from "react";
import {
  Route,
  Switch,
  useParams,
  useRouteMatch
} from "react-router-dom";
import Icon from "@/components/icon/Icon";
import { SettingsNavigation } from "@/contexts/projects/components/ProjectNavigation";
import { BreadcrumbProvider } from "@/contexts/providers/BreadcrumbContext";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import BulkUpload from "./BulkUpload";
import DateSettings from "./DateSettings";
import NotificationSettings from "./NotificationSettings";
import ObituaryEdit from "./obituary/ObituaryEdit";
import PrivacyEdit from "./privacy/PrivacyEdit";
import ProjectDetailsEdit from "./project_details/ProjectDetailsEdit";
import SettingsRelationships from "./SettingsRelationship";
import ThemesEdit from "./themes/ThemesEdit";
import UsersManage from "./user_management/UsersManage";
import WelcomeMessageEdit from "./welcome_message/WelcomeMessageEdit";
const BackLink = () => {
  const { slug } = useParams();
  const {
    params: { tab }
  } = useRouteMatch("/:slug/settings/:tab");
  const href = route(
    ["notifications", "relationship"].includes(tab) ? "projectDetails" : "projectSettings",
    { slug }
  );
  return /* @__PURE__ */ React.createElement("a", { className: "block font-medium", href }, /* @__PURE__ */ React.createElement("span", { className: "inline-block mr-2 w-4" }, /* @__PURE__ */ React.createElement(Icon, { icon: "arrow" })), "Back to Settings");
};
export const settingsRoutes = {
  "Admin Settings": {
    "LifeWeb Details": {
      component: ProjectDetailsEdit,
      path: "/:slug/settings/details"
    },
    "Welcome Message": {
      component: WelcomeMessageEdit,
      path: "/:slug/settings/welcome-message"
    },
    "Dates to Remember": {
      component: DateSettings,
      path: "/:slug/settings/dates-to-remember"
    },
    Obituary: {
      component: ObituaryEdit,
      path: "/:slug/settings/obituary"
    },
    "Privacy Settings": {
      component: PrivacyEdit,
      path: "/:slug/settings/privacy"
    },
    "User Management": {
      component: UsersManage,
      path: "/:slug/settings/user-management"
    },
    "Life Threads": {
      component: ThemesEdit,
      path: "/:slug/settings/life-threads"
    },
    "Bulk Photo Upload": {
      component: BulkUpload,
      path: "/:slug/settings/memories/batch-upload"
    }
  },
  "My Settings": {
    Notifications: {
      component: NotificationSettings,
      path: "/:slug/settings/notifications"
    },
    Relationship: {
      component: SettingsRelationships,
      path: "/:slug/settings/relationship"
    }
  }
};
const flatRoutes = Object.values(
  Object.values(settingsRoutes).reduce(
    (acc, entry) => ({ ...acc, ...entry }),
    {}
  )
).filter(({ component }) => component);
const Settings = () => /* @__PURE__ */ React.createElement("div", { className: "p-0 section with-gradient-background" }, /* @__PURE__ */ React.createElement("div", { className: "md:max-w-[924px] lg:max-w-screen-lg mx-auto px-4 py-12" }, /* @__PURE__ */ React.createElement(BreadcrumbProvider, null, /* @__PURE__ */ React.createElement("div", { className: "flex" }, /* @__PURE__ */ React.createElement("div", { className: "border border-lifeweb-blue self-start hidden lg:block w-1/5" }, /* @__PURE__ */ React.createElement(SettingsNavigation, null)), /* @__PURE__ */ React.createElement("div", { className: "bg-white flex flex-col items-stretch lg:w-4/5 px-6 sm:px-14 py-6 shadow-md w-full" }, /* @__PURE__ */ React.createElement(Switch, null, flatRoutes.map(({ component, path }) => /* @__PURE__ */ React.createElement(Route, { component, key: path, path }))), /* @__PURE__ */ React.createElement(ReactQueryDevtools, { initialIsOpen: false }))))));
export default Settings;
