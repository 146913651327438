import Button from "@/components/button/Button";
import ImagePreviewRail from "@/components/image_preview/ImagePreviewRail";
import VideoPreview from "@/components/video_preview/VideoPreview";
import { flashErrorMessage } from "@/utils/alert_utils";
import FILE_UPLOAD_SETTINGS from "@/variables/file_uploads";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import Facebook from "@uppy/facebook";
import FileInput from "@uppy/file-input";
import GoogleDrive from "@uppy/google-drive";
import ImageEditor from "@uppy/image-editor";
import Instagram from "@uppy/instagram";
import ThumbnailGenerator from "@uppy/thumbnail-generator";
import XHRUpload from "@uppy/xhr-upload";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
const ImageGalleryManager = (props) => {
  var _a, _b, _c;
  const {
    afterUpload,
    images,
    isVideoVisible,
    onNewImagesAdded,
    onAddVideoClick,
    onRemoveImage,
    onRemoveVideo,
    uppyUploadRef,
    uploadPath,
    videoThumbnailUrl
  } = props;
  const { t } = useTranslation();
  const dashboardRef = useRef(null);
  const uploadLimit = useRef(null);
  const [dashboardHidden, setDashboardHidden] = useState(
    !isVideoVisible || videoThumbnailUrl || images.length === FILE_UPLOAD_SETTINGS.maxNumberOfFiles
  );
  useEffect(() => {
    uploadLimit.current = FILE_UPLOAD_SETTINGS.maxNumberOfFiles - images.length;
  });
  const uppy = useRef(null);
  useEffect(() => {
    uppy.current = new Uppy({
      autoProceed: false,
      restrictions: {
        maxFileSize: FILE_UPLOAD_SETTINGS.maxFileSize,
        allowedFileTypes: FILE_UPLOAD_SETTINGS.allowedFileTypes,
        maxNumberOfFiles: uploadLimit.current
      }
    });
    uppy.current.use(FileInput, {
      target: null,
      pretty: true,
      locale: {
        strings: {
          chooseFiles: ""
        }
      }
    });
    uppy.current.use(Dashboard, {
      inline: true,
      target: dashboardRef.current,
      hideProgressAfterFinish: true,
      hideCancelButton: true,
      hideUploadButton: true,
      showRemoveButtonAfterComplete: true,
      proudlyDisplayPoweredByUppy: false
    });
    uppy.current.use(ImageEditor, {
      target: Dashboard
    });
    uppy.current.use(ThumbnailGenerator, {
      thumbnailHeight: 600
    });
    uppy.current.use(GoogleDrive, {
      target: Dashboard,
      companionUrl: "https://companion.lifeweb360.com/"
    });
    uppy.current.use(Facebook, {
      target: Dashboard,
      companionUrl: "https://companion.lifeweb360.com/"
    });
    uppy.current.use(Instagram, {
      target: Dashboard,
      companionUrl: "https://companion.lifeweb360.com/"
    });
    uppy.current.use(XHRUpload, {
      endpoint: uploadPath
    });
    uppy.current.on("file-added", onImageAdded);
    uppy.current.on("file-removed", onImageRemoved);
    uppy.current.on("complete", (result) => {
      if (result.successful.length > 0) {
        const newImages = [];
        result.successful.forEach((file) => {
          newImages.push({
            id: file.id,
            jsonData: JSON.stringify(file.response.body.data),
            thumbnailUrl: file.preview,
            image_url: file.uploadURL
          });
        });
        afterUpload(newImages);
      }
    });
    uppy.current.on("restriction-failed", (file, error) => {
      flashErrorMessage(error);
    });
    uppy.current.on("error", (error) => {
      flashErrorMessage(error);
    });
  }, []);
  useEffect(() => {
    uppyUploadRef.current = () => {
      uppy.current.upload();
    };
  }, []);
  const onImageAdded = () => {
    const imageCount = uploadLimit.current - uppy.current.getFiles().length;
    onNewImagesAdded(true);
    uppy.current.getPlugin("FileInput").setOptions({
      locale: {
        strings: {
          chooseFiles: t("views.memories.actions.addImages", {
            imageCount
          })
        }
      }
    });
  };
  const onImageRemoved = () => {
    if (uppy.current.getFiles().length === 0) {
      onNewImagesAdded(false);
      setDashboardHidden(true);
    }
    uppy.current.getPlugin("FileInput").setOptions({
      locale: {
        strings: {
          chooseFiles: t("views.memories.actions.addImages", {
            imageCount: uploadLimit.current - uppy.current.getFiles().length
          })
        }
      }
    });
  };
  const removeImage = (image) => {
    uploadLimit.current += 1;
    uppy.current.setOptions({
      restrictions: {
        maxNumberOfFiles: uploadLimit.current
      }
    });
    uppy.current.removeFile(image.id);
    onRemoveImage(image);
  };
  const onAddImagesClick = () => {
    setDashboardHidden(false);
  };
  const handleOnAddVideoClick = () => {
    setDashboardHidden(true);
    onAddVideoClick();
  };
  const renderImagePreview = () => {
    if (images.length > 0) {
      return /* @__PURE__ */ React.createElement("div", { className: "columns is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column" }, /* @__PURE__ */ React.createElement(ImagePreviewRail, { images, onRemoveImage: removeImage })));
    }
    if (isVideoVisible) {
      return /* @__PURE__ */ React.createElement(
        VideoPreview,
        {
          thumbnailUrl: videoThumbnailUrl,
          onRemove: onRemoveVideo
        }
      );
    }
  };
  const renderVideoButton = () => {
    const videoButtonDisabled = uppy.current !== null ? images.length === 0 && uppy.current.getFiles().length === 0 : images.length === 0;
    if (videoButtonDisabled) {
      return /* @__PURE__ */ React.createElement(Button, { color: "outline", onClick: handleOnAddVideoClick }, t("views.memories.actions.addVideo"));
    }
  };
  return /* @__PURE__ */ React.createElement("div", { className: "image-gallery-manager" }, renderImagePreview(), /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames("image-gallery-manager__uppy", {
        hidden: isVideoVisible || dashboardHidden
      }),
      ref: dashboardRef
    }
  ), /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames(
        "gap-2 md:gap-0 grid grid-cols-1 md:grid-cols-11 md:px-8 items-center",
        {
          hidden: images.length === 5 || isVideoVisible
        }
      )
    },
    /* @__PURE__ */ React.createElement(
      "div",
      {
        className: classNames("grid-flow-col md:col-span-5", {
          hidden: !dashboardHidden || isVideoVisible
        })
      },
      /* @__PURE__ */ React.createElement(Button, { color: "outline", onClick: onAddImagesClick }, t("views.memories.actions.addImages", {
        imageCount: FILE_UPLOAD_SETTINGS.maxNumberOfFiles - images.length
      }))
    ),
    !isVideoVisible && images.length === 0 && (!((_a = uppy.current) == null ? void 0 : _a.getFiles()) || ((_c = (_b = uppy.current) == null ? void 0 : _b.getFiles()) == null ? void 0 : _c.length) === 0) && /* @__PURE__ */ React.createElement("strong", { className: "grid-flow-col md:col-span-1 text-center" }, "or"),
    /* @__PURE__ */ React.createElement(
      "div",
      {
        className: classNames("grid-flow-col md:col-span-5", {
          hidden: isVideoVisible || images.length
        })
      },
      renderVideoButton()
    )
  ));
};
export default ImageGalleryManager;
