import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
export const Label = (props) => {
  const {
    children,
    description,
    htmlFor,
    isBold,
    isRequired,
    labelNote,
    labelSize,
    withoutPadding,
    isInlineBlock,
    size
  } = props;
  const { t } = useTranslation();
  const descriptionStyles = classNames("form-label__description", {
    "is-small": size === "small"
  });
  const labelStyles = classNames("form-label", {
    "is-bold": isBold,
    "is-large": labelSize === "large",
    "is-small": labelSize === "small"
  });
  const labelNoteStyles = classNames("form-label__note", {
    "is-small": labelSize == "small"
  });
  const asteriskStyles = classNames("form-label", {
    "is-bold": isBold,
    "is-red": true
  });
  const asterisk = isRequired ? t("form.label.required") : "";
  const renderDescription = (() => {
    if (description) {
      if (typeof description === "string") {
        return /* @__PURE__ */ React.createElement("p", { className: descriptionStyles }, description);
      } else {
        return description;
      }
    }
  })();
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames("form-label-wrapper", {
        "has-no-padding-bottom": withoutPadding,
        "inline-block-label": isInlineBlock
      })
    },
    /* @__PURE__ */ React.createElement("label", { className: labelStyles, htmlFor }, children, /* @__PURE__ */ React.createElement("span", { className: asteriskStyles }, asterisk), labelNote && /* @__PURE__ */ React.createElement("span", { className: labelNoteStyles }, " ", labelNote)),
    renderDescription
  );
};
export default Label;
