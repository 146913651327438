import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@/components/button/Button";
import AccessRequestModal from "@/contexts/projects/modules/AccessRequestModal";
import AccessRequestSentModal from "@/contexts/projects/modules/AccessRequestSentModal";
import InvitationResentModal from "@/contexts/projects/modules/InvitationResentModal";
import { route } from "@/utils/route_utils";
const defaultCoverImageSrc = require("@/assets/images/project_default.jpg");
const AccessRequest = ({ fullName, project, projectUser, user }) => {
  const { t } = useTranslation();
  const [accessRequestModalVisible, setAccessRequestModalVisible] = useState(false);
  const [accessRequestSentModalVisible, setAccessRequestSentModalVisible] = useState(false);
  const [resentInviteModalVisible, setResentInviteModalVisible] = useState(false);
  const handleAccessRequestClose = () => {
    setAccessRequestModalVisible(false);
  };
  const handleAccessRequestSubmitted = () => {
    setAccessRequestModalVisible(false);
    setAccessRequestSentModalVisible(true);
  };
  const handleResentInviteClose = () => {
    setResentInviteModalVisible(false);
  };
  const handleAccessRequestSentClose = () => {
    setAccessRequestSentModalVisible(false);
    window.location.reload();
  };
  const handleClickSignIn = () => {
    window.location.href = route("signInPage", {
      redirect_path: window.location.href
    });
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    "img",
    {
      className: "block max-w-xs mx-auto mb-8",
      src: project.cover_image_url || defaultCoverImageSrc
    }
  ), /* @__PURE__ */ React.createElement("div", { className: "columns" }, /* @__PURE__ */ React.createElement("div", { className: "column" }, /* @__PURE__ */ React.createElement("h1", null, t("views.projects.requestAccess.header", { fullName })))), /* @__PURE__ */ React.createElement("div", { className: "columns is-mobile is-centered" }, /* @__PURE__ */ React.createElement("div", { className: "column is-11-mobile is-6-tablet request-access__messages" }, /* @__PURE__ */ React.createElement("p", null, t("views.projects.requestAccess.subtext", { fullName })), user && !["requested", "restricted"].includes(projectUser == null ? void 0 : projectUser.status) && /* @__PURE__ */ React.createElement("p", null, "Your account doesn't have access to ", fullName, "'s LifeWeb."), ["requested", "restricted"].includes(projectUser == null ? void 0 : projectUser.status) && /* @__PURE__ */ React.createElement("p", null, "Your request to access ", fullName, "'s LifeWeb is pending."), !user && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("p", null, "If you already have access,", " ", /* @__PURE__ */ React.createElement(
    "a",
    {
      className: "text-lifeweb-blue font-semibold",
      href: route("signInPage", {
        redirect_path: window.location.href
      })
    },
    "log in"
  ), " ", "to your account."), /* @__PURE__ */ React.createElement("p", null, "If you don't have an account, please", " ", /* @__PURE__ */ React.createElement(
    "a",
    {
      className: "text-lifeweb-blue font-semibold",
      href: route("signUpPage", {
        redirect_path: window.location.href
      })
    },
    "create one"
  ), " ", "to request access.")), /* @__PURE__ */ React.createElement("div", { className: "columns is-mobile is-centered" }, /* @__PURE__ */ React.createElement("div", { className: "column is-10-mobile is-6-tablet" }, user && !["requested", "restricted"].includes(projectUser == null ? void 0 : projectUser.status) && /* @__PURE__ */ React.createElement(Button, { color: "primary", onClick: () => setAccessRequestModalVisible(true) }, "Request Access"), !user && /* @__PURE__ */ React.createElement(Button, { color: "primary", onClick: handleClickSignIn }, "Log In to Access"))), /* @__PURE__ */ React.createElement("div", { className: "columns is-mobile is-centered" }, /* @__PURE__ */ React.createElement("div", { className: "column is-10-mobile is-6-tablet" }, /* @__PURE__ */ React.createElement("div", { className: "request-access__subtext" }, /* @__PURE__ */ React.createElement("a", { href: process.env.external_url["contact"], target: "_blank" }, t("views.projects.requestAccess.subtext3"))))))), /* @__PURE__ */ React.createElement(
    AccessRequestModal,
    {
      afterSubmit: handleAccessRequestSubmitted,
      isVisible: accessRequestModalVisible,
      onClose: handleAccessRequestClose,
      project
    }
  ), /* @__PURE__ */ React.createElement(
    InvitationResentModal,
    {
      fullName,
      isVisible: resentInviteModalVisible,
      onClose: handleResentInviteClose
    }
  ), /* @__PURE__ */ React.createElement(
    AccessRequestSentModal,
    {
      isVisible: accessRequestSentModalVisible,
      onClose: handleAccessRequestSentClose,
      project
    }
  ));
};
export default AccessRequest;
