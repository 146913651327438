import React from "react";
import Typeahead from "@/components/typeahead/Typeahead";
import ErrorMessage from "./ErrorMessage";
import Label from "./Label";
export const TypeaheadGroup = (props) => {
  const {
    description,
    isLabelBold,
    isRequired,
    label,
    labelSize,
    shouldHideTextInput,
    validationMessage,
    ...typeaheadProps
  } = props;
  return /* @__PURE__ */ React.createElement("div", { className: "form-input-group" }, /* @__PURE__ */ React.createElement(
    Label,
    {
      description,
      isBold: isLabelBold,
      isRequired,
      labelSize
    },
    label
  ), !shouldHideTextInput && /* @__PURE__ */ React.createElement(Typeahead, { ...typeaheadProps, sort: true }), validationMessage && /* @__PURE__ */ React.createElement(ErrorMessage, { message: validationMessage }));
};
export default TypeaheadGroup;
