import classNames from "classnames";
import React from "react";
const Modal = (props) => {
  const {
    children,
    hasNoBackground,
    isFullHeight,
    isVisible,
    noPadding,
    onClose,
    size
  } = props;
  const handleModalBackgroundClick = () => {
    if (onClose) {
      onClose();
    }
  };
  if (!isVisible) {
    return null;
  }
  return /* @__PURE__ */ React.createElement("div", { className: "modal is-active", style: { zIndex: 1005 } }, /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames("modal-background", {
        "is-transparent": hasNoBackground
      }),
      onClick: handleModalBackgroundClick
    }
  ), /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames("modal-card", {
        "is-small": size === "small",
        "is-medium": size === "medium",
        "is-large": size === "large",
        "is-full": isFullHeight
      })
    },
    /* @__PURE__ */ React.createElement(
      "div",
      {
        className: classNames("modal-card-body", {
          "has-no-padding": noPadding
        })
      },
      children
    )
  ));
};
export default Modal;
