import Icon from "@/components/icon/Icon";
import Typeahead from "@/components/typeahead/Typeahead";
import useSortedCategories from "@/helpers/hooks/use_sorted_categories";
import classnames from "classnames";
import indefinite from "indefinite";
import Pluralize from "pluralize";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import HonorButton from "./HonorButton";
const FilterButton = ({ collapsed, group, toggled, setToggled }) => {
  if (!collapsed || toggled)
    return;
  return /* @__PURE__ */ React.createElement(
    "button",
    {
      className: "block bg-white border font-semibold m-0 px-5 py-3 rounded-lg text-black text-center text-md uppercase w-full md:w-auto",
      onClick: () => setToggled(true),
      style: {
        borderColor: group.primary_color
      },
      type: "button"
    },
    "Find ",
    indefinite(group.member_name)
  );
};
const GroupFilterBar = ({
  categories,
  group,
  search,
  selectedCategories,
  setSelectedCategories,
  setSearch,
  user
}) => {
  const history = useHistory();
  const location = useLocation();
  const sortedCategories = useSortedCategories(categories);
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const filterRef = useRef(null);
  const selectedCount = (options) => selectedCategories.filter((c) => options.includes(c)).length;
  useEffect(() => {
    const categoryGroupings = Object.entries(sortedCategories).length;
    if (!categoryGroupings)
      return;
    const simulator = filterRef.current.parentNode.cloneNode(
      true
    );
    simulator.style.width = "10000px";
    simulator.style.visibility = "hidden";
    document.body.appendChild(simulator);
    const childNodes = Array.from(simulator.childNodes[0].childNodes);
    const handleResize = (entries) => {
      const collapseWidth = childNodes.reduce(
        (total, node) => total + node.offsetWidth,
        0
      ) + (group.stay_connected_enabled ? 32 : 0);
      setCollapsed(entries[0].contentRect.width < collapseWidth);
    };
    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(filterRef.current);
    return () => {
      resizeObserver.unobserve(filterRef.current);
      document.body.removeChild(simulator);
    };
  }, []);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (search) {
      queryParams.set("search", search);
    }
    const categoryIds = selectedCategories.map((category) => category.id).join(",");
    if (categoryIds) {
      queryParams.set("categories", categoryIds);
    }
    history.replace({
      pathname: location.pathname,
      search: `?${queryParams.toString()}`
    });
  }, [search, selectedCategories]);
  useEffect(() => {
    if (!search)
      return;
    const timeout = setTimeout(() => {
      mixpanel.track("groupsearch_submission", {
        kind: "group",
        group_name: group.slug,
        type: "button"
      });
    }, 5e3);
    return () => clearTimeout(timeout);
  }, [search]);
  const setSelectedCategoriesWithAnalytics = (data) => {
    setSelectedCategories(data);
    Object.entries(sortedCategories).forEach(([parentName, values]) => {
      const childIds = values.map((c) => c.id);
      const selected = data.filter((c) => childIds.includes(c.id));
      if (selected.length) {
        mixpanel.track("groupfilter_complete", {
          kind: "group",
          group_name: group.slug,
          group_category: selected.map((c) => c.private_name).join(","),
          filter_name: parentName,
          type: "button"
        });
      }
    });
  };
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classnames("col-span-full sticky z-30", {
        "-top-[72px]": group.stay_connected_enabled && collapsed,
        "top-0": !group.stay_connected_enabled || !collapsed
      }),
      style: {
        backgroundColor: group.secondary_color || "white"
      }
    },
    /* @__PURE__ */ React.createElement(
      "div",
      {
        className: classnames("grid", {
          "sm:grid-cols-12": group.stay_connected_enabled
        })
      },
      /* @__PURE__ */ React.createElement(
        "div",
        {
          className: classnames("gap-4 md:gap-8 p-4 col-span-12 w-full", {
            "sm:col-start-2 sm:col-span-10": group.stay_connected_enabled,
            "grid md:grid-cols-2": collapsed,
            "flex flex-col md:flex-row justify-center": !collapsed,
            "items-stretch": !toggled || !collapsed,
            "items-start": toggled && collapsed
          }),
          ref: filterRef
        },
        /* @__PURE__ */ React.createElement(
          FilterButton,
          {
            collapsed,
            group,
            toggled,
            setToggled
          }
        ),
        /* @__PURE__ */ React.createElement(
          "div",
          {
            className: classnames("flex", {
              hidden: collapsed && !toggled,
              "gap-8": !collapsed,
              "border bg-white flex-col items-stretch gap-4 p-4 rounded": collapsed
            }),
            style: { borderColor: collapsed ? group.primary_color : "none" }
          },
          collapsed && toggled && /* @__PURE__ */ React.createElement(
            "button",
            {
              className: "ml-auto mr-0 pl-4 w-8",
              onClick: () => setToggled(false),
              type: "button"
            },
            /* @__PURE__ */ React.createElement(Icon, { alt: "Close", icon: "xSolo" }),
            /* @__PURE__ */ React.createElement("span", { className: "sr-only" }, "Close")
          ),
          Object.entries(sortedCategories).length > 0 && Object.entries(sortedCategories).map(([categoryName, values]) => /* @__PURE__ */ React.createElement("div", { className: "md:w-auto w-full", key: categoryName }, /* @__PURE__ */ React.createElement(
            Typeahead,
            {
              availableResults: values,
              emptyResultsText: "No LifeWebs found",
              placeholder: selectedCount(values) > 0 ? `${selectedCount(values)} ${selectedCount(values) > 1 ? Pluralize.plural(categoryName) : categoryName}` : `Filter by ${categoryName}`,
              srLabel: `Filter by ${categoryName}`,
              selectAllEnabled: false,
              selectedResults: selectedCategories,
              updateResults: setSelectedCategoriesWithAnalytics,
              isHighlighted: true,
              withDropdownCaret: true
            }
          ))),
          /* @__PURE__ */ React.createElement("label", { className: "sr-only", htmlFor: "search-by-name" }, "Search by name"),
          /* @__PURE__ */ React.createElement(
            "input",
            {
              className: classnames(
                "border-1/2 border-black font-base px-4 placeholder-gray-900 rounded text-gray-900 h-11 w-full",
                { "md:w-52": !toggled }
              ),
              id: "search-by-name",
              type: "text",
              placeholder: "Search by name",
              onInput: (event) => setSearch(event.target.value),
              value: search
            }
          )
        ),
        group.contributions_enabled && /* @__PURE__ */ React.createElement(HonorButton, { group, user })
      )
    )
  );
};
export default GroupFilterBar;
