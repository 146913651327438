import React from "react";
import CommentBlock from "./CommentBlock";
const CommentManager = (props) => {
  const {
    comments,
    currentUser,
    isAdmin,
    onDeleteClick,
    onSaveClick,
    onStatusClick,
    onUpdateComment
  } = props;
  return /* @__PURE__ */ React.createElement(React.Fragment, null, comments.map((comment, index) => /* @__PURE__ */ React.createElement("div", { key: comment.id }, /* @__PURE__ */ React.createElement(
    CommentBlock,
    {
      comment,
      currentUser,
      index,
      isAdmin,
      onDeleteClick,
      onSaveClick,
      onStatusClick,
      onUpdateComment
    }
  ))));
};
export default CommentManager;
