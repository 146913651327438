import Api from "@/api/Api";
import ManagementControl from "@/components/controls/ManagementControl";
import { default as ManagementControlsWrapper } from "@/components/controls/ManagementControls";
import Meatball from "@/components/controls/Meatball";
import { useDetectClickOutside } from "@/helpers/hooks/useDetectClickOutside";
import useSortedCategories from "@/helpers/hooks/use_sorted_categories";
import {
  flashMessageFromResponseError,
  flashSuccessMessage
} from "@/utils/alert_utils";
import { route } from "@/utils/route_utils";
import { capitalize } from "@/utils/string_utils";
import { getHighContrastTextColor } from "@/utils/text_utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Pluralize from "pluralize";
import React, { useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
const defaultCoverImageSrc = require("@/assets/images/project_default.jpg");
const ManagementControls = ({
  afterUpdate,
  group,
  groupProject,
  visible
}) => {
  if (!visible)
    return;
  const queryClient = useQueryClient();
  const updateGroupProject = async (data) => {
    try {
      await Api.utility.patch(
        route("groupProject", { group_id: group.id, id: groupProject.id }),
        {
          group_project: data
        }
      );
      afterUpdate();
      flashSuccessMessage(
        `${capitalize(group.member_name) || "Member"} ${data.pinned ? "pinned" : "unpinned"}`
      );
    } catch (error) {
      flashMessageFromResponseError(error);
    }
  };
  const groupProjectMutation = useMutation({
    mutationFn: updateGroupProject,
    onSuccess: (_data, { pinned }, _context) => {
      queryClient.invalidateQueries({ queryKey: ["groupProjects"] });
      flashSuccessMessage(
        `${capitalize(group.member_name) || "Member"} ${pinned ? "pinned" : "unpinned"}`
      );
      afterUpdate();
    }
  });
  const pin = async () => {
    groupProjectMutation.mutate({ pinned: true });
  };
  const unpin = async () => {
    groupProjectMutation.mutate({ pinned: false });
  };
  return /* @__PURE__ */ React.createElement(ManagementControlsWrapper, null, groupProject.pinned ? /* @__PURE__ */ React.createElement(
    ManagementControl,
    {
      icon: "pin",
      onClick: unpin,
      text: `Un-Feature ${capitalize(group.member_name || "member")}`
    }
  ) : /* @__PURE__ */ React.createElement(
    ManagementControl,
    {
      icon: "pinFilled",
      onClick: pin,
      text: `Feature ${capitalize(group.member_name || "member")}`
    }
  ));
};
const GroupProject = ({
  backgroundColor,
  group,
  groupProject,
  user
}) => {
  var _a;
  const history = useHistory();
  const { groupName } = useParams();
  const { project } = groupProject;
  const [managementControlsVisible, setManagementControlsVisible] = useState(false);
  const primaryCategory = (_a = Object.values(
    useSortedCategories(
      groupProject.group_categories.filter(
        ({ parent_id }) => parent_id === group.primary_category_id
      )
    )
  )[0]) == null ? void 0 : _a[0];
  const featuredCategory = group.group_categories.find(
    ({ featured, id }) => featured && groupProject.group_categories.find(({ parent_id }) => id === parent_id)
  );
  const afterUpdate = async () => {
    setManagementControlsVisible(false);
  };
  const managementControlsRef = useRef(null);
  useDetectClickOutside(
    managementControlsRef,
    () => setManagementControlsVisible(false)
  );
  const toggleManagementControls = (e) => {
    e.stopPropagation();
    setManagementControlsVisible((current) => !current);
  };
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: `aspect-[2/3] block cursor-pointer hover:opacity-90 mb-0 transition-opacity rounded-lg shadow-md overflow-hidden relative ${getHighContrastTextColor(
        backgroundColor
      )}`,
      style: { backgroundColor },
      onClick: () => {
        var _a2;
        return history.push(
          `/groups/${groupName}/${Pluralize.plural(
            ((_a2 = group == null ? void 0 : group.member_name) == null ? void 0 : _a2.toLowerCase()) || "member"
          )}/${groupProject.id}`
        );
      }
    },
    featuredCategory && featuredCategory.featured_color && /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "absolute flex items-center justify-center top-4 -left-16 h-11 w-52 -rotate-45 z-10",
        style: { backgroundColor: featuredCategory.featured_color }
      },
      featuredCategory.featured_icon_url && /* @__PURE__ */ React.createElement(
        "img",
        {
          alt: `${featuredCategory.name} icon`,
          className: "block h-8 w-auto rotate-45",
          src: featuredCategory.featured_icon_url,
          title: featuredCategory.name
        }
      )
    ),
    /* @__PURE__ */ React.createElement("figure", { className: "flex flex-col font-semibold overflow-hidden p-3 relative" }, (user == null ? void 0 : user.role) === "admin" && /* @__PURE__ */ React.createElement("div", { className: "absolute top-1 right-2" }, /* @__PURE__ */ React.createElement(
      "div",
      {
        ref: managementControlsRef,
        onClick: (e) => e.stopPropagation()
      },
      /* @__PURE__ */ React.createElement("div", { className: "text-lifeweb-blue" }, /* @__PURE__ */ React.createElement(
        Meatball,
        {
          ariaLabel: "Member options",
          hoverColor: group.secondary_color,
          onClick: toggleManagementControls
        }
      )),
      /* @__PURE__ */ React.createElement(
        ManagementControls,
        {
          afterUpdate,
          group,
          groupProject,
          visible: managementControlsVisible
        }
      )
    )), /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "bg-cover bg-top aspect-[20/23] relative w-full",
        style: {
          backgroundImage: `url(${project.cover_image_url || group.project_image_url || defaultCoverImageSrc})`
        }
      },
      groupProject.pinned && /* @__PURE__ */ React.createElement("div", { className: "absolute bg-black bg-opacity-40 bottom-0 font-normal py-2 tracking-widest text-center text-sm text-white uppercase w-full" }, "FEATURED")
    ), /* @__PURE__ */ React.createElement("figcaption", { className: "flex flex-col gap-2 items-center justify-center px-4 text-center" }, /* @__PURE__ */ React.createElement("span", { className: "block my-2 underline" }, project.prefix, " ", project.full_name), primaryCategory && /* @__PURE__ */ React.createElement("span", { className: "block font-normal text-sm" }, primaryCategory.name)))
  );
};
export default GroupProject;
