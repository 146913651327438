import React, { useEffect, useState } from "react";
import Api from "@/api/Api";
import Button from "@/components/button/Button";
import { Input } from "@/components/form/Input";
import { flashErrorMessage, flashSuccessMessage } from "@/utils/alert_utils";
const DERIVATIVE_LIST = ["square", "small", "medium", "large"];
const FixImage = () => {
  const [totalIamge, setTotalImage] = useState(0);
  const [currentId, setCurrentId] = useState(0);
  const [memoryId, setMemoryId] = useState("");
  const [memoryImageId, setMemoryImageId] = useState("");
  const [projectId, setProjectId] = useState("");
  const [coverImage, setCoverImage] = useState(null);
  const [slug, setSlug] = useState("");
  const [imgUrl, setImgUrl] = useState({
    url: "",
    large: "",
    medium: "",
    small: "",
    square: ""
  });
  const [createdAt, setCreatedAt] = useState("");
  const [files, setFiles] = useState([]);
  const [pickedFile, setPickedFiles] = useState(
    {}
  );
  const loadMemoryImage = async (id) => {
    const resp = await Api.utility.get(`/api/v1/fix_image/${id}`);
    if (!resp.data.error) {
      setCurrentId(id);
      setMemoryId(resp.data.memory_id);
      setMemoryImageId(resp.data.id);
      setProjectId(resp.data.project_id);
      setCoverImage(resp.data.cover_image);
      setSlug(resp.data.slug);
      setImgUrl({
        url: resp.data.image_url,
        large: resp.data.large,
        medium: resp.data.medium,
        small: resp.data.small,
        square: resp.data.square
      });
      setCreatedAt(resp.data.created_at_str);
      setFiles(resp.data.files);
      setPickedFiles({});
    }
  };
  useEffect(() => {
    Api.utility.get("/api/v1/fix_image/status").then((resp) => {
      setTotalImage(resp.data.total_image);
      loadMemoryImage(resp.data.current_image);
    });
  }, []);
  const onChangeCurrentId = (value) => {
    if (value.length > 0) {
      setCurrentId(parseInt(value));
    } else {
      setCurrentId(0);
    }
  };
  const onPickId = (value) => {
    loadMemoryImage(value);
  };
  const onPickImg = (file) => {
    const newPickedFile = { ...pickedFile };
    if (pickedFile[file.id]) {
      delete newPickedFile[file.id];
    } else {
      if (Object.keys(newPickedFile).length < 4) {
        newPickedFile[file.id] = {
          ...file,
          derivative: "small"
        };
        const scores = [];
        for (const fid in newPickedFile) {
          scores.push({ fid, size: newPickedFile[fid].size });
        }
        scores.sort((a, b) => {
          return a.size - b.size;
        });
        for (let i = 0; i < scores.length; i++) {
          const fid = scores[i].fid;
          newPickedFile[fid].derivative = DERIVATIVE_LIST[i];
        }
      }
    }
    setPickedFiles(newPickedFile);
  };
  const onRemoveImg = (fid) => {
    const newPickedFile = { ...pickedFile };
    delete newPickedFile[fid];
    setPickedFiles(newPickedFile);
  };
  const markSize = (file, size) => {
    const newPickedFile = { ...pickedFile };
    const newFile = { ...file };
    newFile.derivative = size;
    newPickedFile[file.id] = newFile;
    setPickedFiles(newPickedFile);
  };
  const saveDerivatives = async () => {
    const payload = {
      files: pickedFile
    };
    const resp = await Api.utility.post(
      `/api/v1/fix_image/${memoryImageId}`,
      payload
    );
    if (resp.data.error) {
      flashErrorMessage(resp.data.error);
    } else {
      flashSuccessMessage("Saved");
      loadMemoryImage(currentId);
    }
  };
  return /* @__PURE__ */ React.createElement("div", { style: { display: "flex", flexDirection: "row" } }, /* @__PURE__ */ React.createElement("div", { style: { borderRight: "1px solid #AAAAAA" } }, /* @__PURE__ */ React.createElement(
    "div",
    {
      style: { borderBottom: "1px solid #AAAAAA", flex: 1, padding: 10 }
    },
    /* @__PURE__ */ React.createElement("p", { style: { marginBottom: 10 } }, /* @__PURE__ */ React.createElement("strong", null, "Current Image")),
    /* @__PURE__ */ React.createElement(Input, { value: currentId.toString(), onChange: onChangeCurrentId }),
    /* @__PURE__ */ React.createElement(
      "div",
      {
        style: { display: "flex", flexDirection: "row", margin: "10px 0" }
      },
      /* @__PURE__ */ React.createElement(Button, { onClick: () => onPickId(currentId - 1) }, "<"),
      /* @__PURE__ */ React.createElement(Button, { onClick: () => onPickId(currentId) }, "Pick"),
      /* @__PURE__ */ React.createElement(Button, { onClick: () => onPickId(currentId + 1) }, ">")
    ),
    /* @__PURE__ */ React.createElement("div", { style: { marginBottom: 20 } }, /* @__PURE__ */ React.createElement(Button, { onClick: saveDerivatives, color: "primary" }, "SAVE")),
    /* @__PURE__ */ React.createElement(
      "img",
      {
        src: imgUrl.url,
        style: { width: 240, height: 240, backgroundColor: "#EEEEEE" }
      }
    ),
    /* @__PURE__ */ React.createElement("p", null, createdAt),
    /* @__PURE__ */ React.createElement(
      "div",
      {
        style: {
          display: "flex",
          flexDirection: "row",
          margin: "10px 0",
          justifyContent: "space-between"
        }
      },
      /* @__PURE__ */ React.createElement("strong", null, "Memory"),
      /* @__PURE__ */ React.createElement("a", { href: `/${slug}/${memoryId}`, target: "_blank" }, "Go to memory")
    ),
    /* @__PURE__ */ React.createElement(
      "div",
      {
        style: {
          display: "flex",
          flexDirection: "row",
          margin: "10px 0",
          justifyContent: "space-between"
        }
      },
      /* @__PURE__ */ React.createElement("strong", null, "Project Cover"),
      /* @__PURE__ */ React.createElement("a", { href: `/${slug}`, target: "_blank" }, slug)
    ),
    coverImage ? /* @__PURE__ */ React.createElement(
      "img",
      {
        src: coverImage,
        style: { width: 240, height: 240, backgroundColor: "#EEEEEE" }
      }
    ) : /* @__PURE__ */ React.createElement(
      "div",
      {
        style: { width: 240, height: 240, backgroundColor: "#EEEEEE" }
      }
    ),
    /* @__PURE__ */ React.createElement("p", { style: { marginTop: 20 } }, /* @__PURE__ */ React.createElement("strong", null, "Derivatives:")),
    ["large", "medium", "small", "square"].map((derivative) => /* @__PURE__ */ React.createElement("div", { key: derivative, style: { marginTop: 10 } }, /* @__PURE__ */ React.createElement("p", null, /* @__PURE__ */ React.createElement("strong", null, derivative)), imgUrl[derivative] && /* @__PURE__ */ React.createElement(
      "img",
      {
        src: imgUrl[derivative],
        style: {
          width: 240,
          height: 240,
          backgroundColor: "#EEEEEE"
        }
      }
    )))
  ), /* @__PURE__ */ React.createElement("div", { style: { padding: 10 } }, /* @__PURE__ */ React.createElement("p", null, /* @__PURE__ */ React.createElement("strong", null, "Stats:")), /* @__PURE__ */ React.createElement("p", null, "Total Images: ", currentId, "/", totalIamge), /* @__PURE__ */ React.createElement("p", null, "Progress:", " ", (currentId / (totalIamge === 0 ? 1 : totalIamge) * 100).toFixed(
    2
  ), "%"))), /* @__PURE__ */ React.createElement("div", { style: { flex: 1 } }, /* @__PURE__ */ React.createElement(
    "div",
    {
      style: {
        borderBottom: "1px solid #AAAAAA",
        height: 320,
        display: "flex",
        flexDirection: "row"
      }
    },
    Object.keys(pickedFile).map((fid) => {
      const file = pickedFile[fid];
      return /* @__PURE__ */ React.createElement("div", { key: file.id, style: { padding: 10 } }, /* @__PURE__ */ React.createElement(
        "img",
        {
          src: file.url,
          style: {
            width: 240,
            height: 240,
            backgroundColor: "#EEEEEE",
            cursor: "pointer"
          },
          onClick: () => onRemoveImg(file.id)
        }
      ), /* @__PURE__ */ React.createElement(
        "div",
        {
          style: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }
        },
        /* @__PURE__ */ React.createElement("div", null, (file.size / 1024).toFixed(), "kb"),
        /* @__PURE__ */ React.createElement("div", null, "+", file.t_diff, "s")
      ), /* @__PURE__ */ React.createElement("div", { style: { display: "flex", flexDirection: "row" } }, /* @__PURE__ */ React.createElement(
        "div",
        {
          onClick: () => markSize(file, "square"),
          style: {
            padding: 5,
            cursor: "pointer",
            fontWeight: file.derivative === "square" ? "bold" : "inherit"
          }
        },
        "Square"
      ), /* @__PURE__ */ React.createElement(
        "div",
        {
          onClick: () => markSize(file, "small"),
          style: {
            padding: 5,
            cursor: "pointer",
            fontWeight: file.derivative === "small" ? "bold" : "inherit"
          }
        },
        "Small"
      ), /* @__PURE__ */ React.createElement(
        "div",
        {
          onClick: () => markSize(file, "medium"),
          style: {
            padding: 5,
            cursor: "pointer",
            fontWeight: file.derivative === "medium" ? "bold" : "inherit"
          }
        },
        "Medium"
      ), /* @__PURE__ */ React.createElement(
        "div",
        {
          onClick: () => markSize(file, "large"),
          style: {
            padding: 5,
            cursor: "pointer",
            fontWeight: file.derivative === "large" ? "bold" : "inherit"
          }
        },
        "Large"
      )));
    })
  ), /* @__PURE__ */ React.createElement(
    "div",
    {
      style: {
        padding: 10,
        flexWrap: "wrap",
        flexDirection: "row",
        display: "flex"
      }
    },
    files.map((file) => /* @__PURE__ */ React.createElement(
      "div",
      {
        key: file.id,
        style: {
          padding: 10,
          width: 260,
          backgroundColor: pickedFile[file.id] ? "#DDD" : "white",
          cursor: "pointer"
        },
        onClick: () => onPickImg(file)
      },
      /* @__PURE__ */ React.createElement(
        "img",
        {
          src: file.url,
          style: { width: 240, height: 240, backgroundColor: "#EEEEEE" }
        }
      ),
      /* @__PURE__ */ React.createElement(
        "div",
        {
          style: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }
        },
        /* @__PURE__ */ React.createElement("div", null, (file.size / 1024).toFixed(), "kb"),
        /* @__PURE__ */ React.createElement("div", null, "+", file.t_diff, "s")
      )
    ))
  )));
};
export default FixImage;
