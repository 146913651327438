import React, { useEffect, useMemo, useRef, useState } from "react";
import { useResponsive } from "react-hooks-responsive";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { usePopper } from "react-popper";
import Api from "@/api/Api";
import ActivityIndicator from "@/components/activity/ActivityIndicator";
import MemoryCardBlock from "@/components/memory_card/MemoryCardBlock";
import BackButton from "@/components/modal/BackButton";
import ConfirmationModal from "@/components/modal/ConfirmationModal";
import ModalCard from "@/components/modal/ModalCard";
import Header, {
  HeaderTitle,
  HeaderTitleSection
} from "@/components/navigation/Header";
import MemoriesFilterBar from "@/contexts/memories/components/MemoriesFilterBar";
import MemoriesHeader from "@/contexts/memories/components/MemoriesHeader";
import ProjectConfirmationModal from "@/contexts/memories/modules/ProjectConfirmationModal";
import ProjectImageModal from "@/contexts/memories/modules/ProjectImageModal";
import ProjectMessageModal from "@/contexts/memories/modules/ProjectMessageModal";
import ProjectNameModal from "@/contexts/memories/modules/ProjectNameModal";
import { flashMessageFromResponseError } from "@/utils/alert_utils";
import { chunkArray } from "@/utils/array_utils";
import Modeler from "@/utils/modeler/modeler";
import { route } from "@/utils/route_utils";
import { breakpoints } from "@/utils/screen_utils";
import { usePrevious } from "@/utils/state_utils";
import ProjectMessageStep from "./components/ProjectMessageStep";
import MemoriesPlaceholder from "./MemoriesPlaceholder";
const getQueryFromSearch = (search) => {
  const queryParams = new URLSearchParams(search);
  const getAsResult = (param) => (queryParams.get(param) || "").split(",").filter((item) => item).map((item) => ({ id: item, name: item }));
  const q = {
    sort: queryParams.get("sort") || "-created_at",
    themes: getAsResult("themes"),
    users: getAsResult("users")
  };
  return q;
};
const MemoriesIndex = (props) => {
  const {
    is_admin: isAdmin,
    is_onboarding: isOnboarding,
    memories: initialMemories,
    memories_visible: memoriesVisible,
    memory_quotes,
    project: originProject,
    serialized_project: initialProject,
    serialized_project_user: projectUser,
    user: userData,
    user_names
  } = props;
  const modeledProject = new Modeler(initialProject).build();
  const initialProjectFormState = {
    cover_image: null,
    full_name: "",
    preferred_name: "",
    prefix: "",
    pronoun: "",
    slug: "",
    welcome_message: ""
  };
  const [project, setProject] = useState(modeledProject);
  const [hasMore, setHasMore] = useState(!isOnboarding);
  const [isLoading, setIsLoading] = useState(false);
  const [isProjectConfirmModalVisible, setIsProjectConfirmModalVisible] = useState(false);
  const [isProjectImageModalVisible, setIsProjectImageModalVisible] = useState(false);
  const [isProjectMessageModalVisible, setisProjectMessageModalVisible] = useState(false);
  const [isProjectNameModalVisible, setisProjectNameModalVisible] = useState(false);
  const [memories, setMemories] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [projectForm, setProjectForm] = useState(
    initialProjectFormState
  );
  const [relationship, setRelationship] = useState("");
  const [stepNumber, setStepNumber] = useState(1);
  const nameTargetRef = useRef(null);
  const nameModalRef = useRef(null);
  const { screenIsAtMost } = useResponsive(breakpoints);
  const isTouch = screenIsAtMost("tablet");
  const { styles: nameModalStyles, attributes: nameModalAttributes } = usePopper(nameTargetRef.current, nameModalRef.current, {
    placement: isTouch ? "bottom" : "left",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 20]
        }
      },
      {
        name: "preventOverflow",
        options: {
          mainAxis: false
        }
      }
    ]
  });
  const { t } = useTranslation();
  const isMessageStepVisible = isOnboarding && stepNumber === 5;
  useEffect(() => {
    setStepVisbility();
    window.scrollTo(0, 0);
  }, [stepNumber]);
  const setStepVisbility = () => {
    switch (stepNumber) {
      case 1:
        setIsProjectImageModalVisible(false);
        break;
      case 2:
        setIsProjectImageModalVisible(true);
        break;
      case 3:
        setIsProjectImageModalVisible(false);
        setisProjectNameModalVisible(false);
        break;
      case 4:
        setisProjectNameModalVisible(true);
        setisProjectMessageModalVisible(false);
        break;
      case 5:
        setisProjectNameModalVisible(false);
        setisProjectMessageModalVisible(false);
        setIsProjectConfirmModalVisible(false);
        break;
      case 6:
        setisProjectNameModalVisible(false);
        setisProjectMessageModalVisible(true);
        setIsProjectConfirmModalVisible(false);
        break;
      case 7:
        setisProjectMessageModalVisible(false);
        setIsProjectConfirmModalVisible(true);
        break;
    }
  };
  const updateStepNumber = (nextStep) => {
    setStepNumber(nextStep);
  };
  useEffect(() => {
    setMemories(new Modeler(initialMemories).build());
  }, [initialMemories]);
  const [query, setQuery] = useState(getQueryFromSearch(""));
  const prevPage = usePrevious(page);
  useEffect(() => {
    if (hasMore && prevPage) {
      getPage(page);
    }
  }, [page]);
  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    if (isOnboarding) {
      mixpanel.track("project_started", {
        kind: "project",
        type: "new"
      });
    }
  }, []);
  const memoryQuotes = useMemo(() => {
    if (!memory_quotes)
      return [];
    const modeledQuotes = new Modeler(memory_quotes).build();
    const filteredQuotes = modeledQuotes.filter(
      (quote) => memories.some((m) => m.id === quote.memory_id)
    );
    return filteredQuotes;
  }, [memory_quotes, memories]);
  const arrayChunks = useMemo(() => {
    return chunkArray(memories, 5);
  }, [memories]);
  const modeledProjectUser = new Modeler(projectUser).build();
  const user = useMemo(() => {
    return new Modeler(userData).build();
  }, [userData]);
  const getPage = async (pageNumber) => {
    setIsLoading(true);
    try {
      const url = route("memoriesIndex", {
        project_id: project.id,
        page: pageNumber,
        per: 5
      });
      const response = await Api.utility.get(url);
      const newMemories = new Modeler(response.data.memories).build();
      if (pageNumber === 1) {
        setMemories(newMemories);
      } else {
        setMemories([...memories, ...newMemories]);
      }
      setHasMore(!response.data.memories.meta.last);
      setTotalPages(response.data.memories.meta.total);
    } catch (err) {
      flashMessageFromResponseError(err);
    }
    setIsLoading(false);
  };
  const handleCloseProjectImage = () => {
    setIsProjectImageModalVisible(false);
  };
  const handleCloseProjectMessage = () => {
    setisProjectMessageModalVisible(false);
  };
  const handleCloseProjectName = () => {
    setisProjectNameModalVisible(false);
  };
  const handleScrollToBottom = () => {
    if (hasMore && !isLoading && !isOnboarding && memories.length > 0 && page <= totalPages) {
      setPage(page + 1);
      setIsLoading(true);
    }
  };
  const handleProjectSubmit = async (currentProjectUser, cb) => {
    const { cover_image, slug: _slug, ...formFields } = projectForm;
    const url = route("projects");
    const group = new URLSearchParams(window.location.search).get("group_id");
    const body = {
      project: {
        account_id: currentProjectUser.accounts[0].id,
        creator_id: currentProjectUser.id,
        cover_image: cover_image ? cover_image.jsonData : null,
        group_ids: group ? [group] : null,
        ...formFields
      },
      registration_plus_project_creation: false
    };
    try {
      const { data } = await Api.utility.post(url, body);
      mixpanel.track("project_created", {
        kind: "project",
        project_id: data.project.id
      });
      await handleUpdateProjectUser(data.project_user, data.redirect_path, cb);
    } catch (err) {
      flashMessageFromResponseError(err);
    }
  };
  const handleUpdateProjectUser = async (subject, redirectPath, cb) => {
    const url = route("projectUsersUpdate", {
      project_user_id: subject.id
    });
    const body = {
      project_user: {
        relationship_attributes: {
          description: relationship
        }
      }
    };
    try {
      await Api.utility.patch(url, body);
      if (cb) {
        cb();
      }
      window.location = redirectPath;
    } catch (err) {
      flashMessageFromResponseError(err);
    }
  };
  const updateProject = (updatedProject) => {
    setProject(updatedProject);
  };
  const updateMockProjectField = (field, value) => {
    setProject({
      ...project,
      [field]: value
    });
  };
  const updateProjectField = (field, value) => {
    setProjectForm({
      ...projectForm,
      [field]: value
    });
    updateMockProjectField(field, value);
  };
  const updateProjectImageField = (image) => {
    setProjectForm({
      ...projectForm,
      cover_image: image
    });
    updateMockProjectField("cover_image_url", image.thumbnailUrl);
  };
  const updateRelationshipField = (value) => {
    setRelationship(value);
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Header, null, /* @__PURE__ */ React.createElement(HeaderTitleSection, null, /* @__PURE__ */ React.createElement(HeaderTitle, { link: "/" }, "Ms. Janice Tinbel"))), /* @__PURE__ */ React.createElement(
    MemoriesHeader,
    {
      isAdmin,
      isImageHighlighted: isOnboarding && stepNumber === 1,
      isNameHighlighted: isOnboarding && stepNumber === 3,
      isOnboarding,
      project,
      projectUser,
      nameRef: nameTargetRef,
      onUpdateStepNumber: updateStepNumber,
      stepNumber
    }
  ), memoriesVisible && /* @__PURE__ */ React.createElement(
    MemoriesFilterBar,
    {
      onChangeQuery: setQuery,
      project: originProject,
      query,
      themes: project.themes.filter((theme) => theme.status === "active"),
      userNames: user_names
    }
  ), /* @__PURE__ */ React.createElement("section", { className: "section with-theme-gradient" }, memoriesVisible && /* @__PURE__ */ React.createElement("div", { className: "memory-bar-buffer" }), memories.length === 0 && /* @__PURE__ */ React.createElement(MemoriesPlaceholder, { project }), /* @__PURE__ */ React.createElement("div", { className: "container" }, /* @__PURE__ */ React.createElement("div", { className: "columns" }, /* @__PURE__ */ React.createElement("div", { className: "column memories__welcome-message-wrapper" }, isMessageStepVisible && /* @__PURE__ */ React.createElement(
    ProjectMessageStep,
    {
      onUpdateStepNumber: updateStepNumber,
      onUpdateProjectField: updateProjectField,
      project,
      stepNumber
    }
  ))), memoriesVisible && /* @__PURE__ */ React.createElement("div", { className: "columns", "data-nosnippet": true }, /* @__PURE__ */ React.createElement("div", { className: "column" }, /* @__PURE__ */ React.createElement(
    InfiniteScroll,
    {
      hasMore,
      initialLoad: false,
      loadMore: handleScrollToBottom
    },
    arrayChunks.map((chunk, index) => {
      const memoryQuote = memoryQuotes[index];
      return /* @__PURE__ */ React.createElement(
        MemoryCardBlock,
        {
          admin: isAdmin,
          key: index,
          memories: chunk,
          memoryQuote,
          onClickMemory: () => void 0,
          onClickMemoryQuote: () => void 0,
          project,
          user
        }
      );
    })
  ), isLoading && /* @__PURE__ */ React.createElement(ActivityIndicator, null))))), /* @__PURE__ */ React.createElement(
    ProjectConfirmationModal,
    {
      project,
      onUpdateRelationship: updateRelationshipField,
      relationship,
      isVisible: isProjectConfirmModalVisible,
      onContinueClick: () => handleProjectSubmit(user),
      stepNumber,
      onUpdateStepNumber: updateStepNumber
    }
  ), /* @__PURE__ */ React.createElement(
    ProjectImageModal,
    {
      isOnboarding,
      isVisible: isProjectImageModalVisible,
      onClose: handleCloseProjectImage,
      onUpdateProject: updateProject,
      onUpdateProjectField: updateProjectImageField,
      onUpdateStepNumber: updateStepNumber,
      project,
      stepNumber,
      modeledProjectUser
    }
  ), /* @__PURE__ */ React.createElement(
    ProjectMessageModal,
    {
      isOnboarding,
      isVisible: isProjectMessageModalVisible,
      onClose: handleCloseProjectMessage,
      onUpdateProject: updateProject,
      onUpdateProjectField: updateProjectField,
      onUpdateStepNumber: updateStepNumber,
      project: isOnboarding ? projectForm : project,
      stepNumber,
      modeledProjectUser
    }
  ), /* @__PURE__ */ React.createElement(
    ProjectNameModal,
    {
      isOnboarding,
      isVisible: isProjectNameModalVisible,
      onClose: handleCloseProjectName,
      onUpdateProject: updateProject,
      onUpdateProjectField: updateProjectField,
      onUpdateStepNumber: updateStepNumber,
      project: isOnboarding ? projectForm : project,
      stepNumber
    }
  ), isOnboarding && /* @__PURE__ */ React.createElement(
    ModalCard,
    {
      isHidden: stepNumber !== 3,
      noMargin: true,
      selfRef: nameModalRef,
      size: "small",
      style: nameModalStyles.popper,
      withZIndex: true,
      ...nameModalAttributes.popper
    },
    /* @__PURE__ */ React.createElement("div", { className: "project-name-modal__back-wrapper" }, /* @__PURE__ */ React.createElement(BackButton, { onBack: () => updateStepNumber(stepNumber - 2) })),
    /* @__PURE__ */ React.createElement(
      ConfirmationModal,
      {
        confirmText: t("views.memories.projectNew.name.button"),
        isOnboarding: true,
        onConfirm: () => updateStepNumber(stepNumber + 1),
        subtitle: t("views.memories.projectNew.name.text"),
        title: t("views.memories.projectNew.name.title")
      }
    )
  ));
};
export default MemoriesIndex;
