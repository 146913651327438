import Api from "@/api/Api";
import ActivityIndicator from "@/components/activity/ActivityIndicator";
import IconButton from "@/components/button/IconButton";
import CloseButton from "@/components/modal/CloseButton";
import Modal from "@/components/modal/Modal";
import { H2, H3 } from "@/components/typography";
import { useProjectUserQuery } from "@/queries";
import Modeler from "@/utils/modeler/modeler";
import { route } from "@/utils/route_utils";
import { sanitizeUserInput } from "@/utils/string_utils";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
const Content = ({ author, content, created_at, title }) => {
  const history = useHistory();
  const { slug } = useParams();
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(H3, { className: "mb-1" }, title), /* @__PURE__ */ React.createElement("div", { className: "flex flex-col mb-2 md:flex-row md:gap-5 text-sm text-gray-500" }, /* @__PURE__ */ React.createElement("span", null, new Date(created_at).toLocaleString(void 0, {
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    month: "long",
    timeZoneName: "short",
    year: "numeric"
  })), /* @__PURE__ */ React.createElement("span", null, "Posted by ", author)), /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "bg-lifeweb-yellow px-3 py-2 overflow-hidden max-w-none prose relative",
      dangerouslySetInnerHTML: { __html: sanitizeUserInput(content) }
    }
  ), /* @__PURE__ */ React.createElement("div", { className: "flex justify-end" }, /* @__PURE__ */ React.createElement(
    "button",
    {
      className: "bg-white block border border-lifeweb-blue font-bold my-4 px-6 py-1 rounded text-lifeweb-blue",
      onClick: () => history.push(route("updates", { slug }))
    },
    "Back to Updates"
  )));
};
const ProjectUpdateDetail = () => {
  const history = useHistory();
  const { id, slug } = useParams();
  const { data: projectUser, isLoading: isUserLoading } = useProjectUserQuery(slug);
  const admin = (projectUser == null ? void 0 : projectUser.role) === "admin";
  const [loading, setLoading] = useState(true);
  const [projectUpdate, setProjectUpdate] = useState();
  const handleClose = () => history.push(route("project", { slug }));
  useEffect(() => {
    const fetchProjectUpdate = async () => {
      const url = route("projectUpdate", { id });
      try {
        const response = await Api.utility.get(url);
        setProjectUpdate(new Modeler(response.data).build());
        setLoading(false);
      } catch (e) {
        return handleClose();
      }
    };
    fetchProjectUpdate();
  }, []);
  return /* @__PURE__ */ React.createElement(Modal, { isVisible: true, onClose: handleClose }, /* @__PURE__ */ React.createElement("div", { className: "grid grid-cols-3" }, admin && projectUpdate ? /* @__PURE__ */ React.createElement("div", { className: "flex items-start" }, /* @__PURE__ */ React.createElement(
    IconButton,
    {
      icon: "pencil",
      onClick: () => history.push(route("updateEdit", { id, slug })),
      size: "small",
      text: "Edit"
    }
  )) : /* @__PURE__ */ React.createElement("div", null), projectUpdate && /* @__PURE__ */ React.createElement(H2, { className: "mt-0 text-center" }, "Update #", projectUpdate.update_number), /* @__PURE__ */ React.createElement("div", { className: "flex items-start justify-end" }, /* @__PURE__ */ React.createElement(CloseButton, { onClose: handleClose }))), loading || isUserLoading && /* @__PURE__ */ React.createElement(ActivityIndicator, null), projectUpdate && /* @__PURE__ */ React.createElement(Content, { ...projectUpdate }), !loading && !projectUpdate && /* @__PURE__ */ React.createElement("span", null, "No updates found"));
};
export default ProjectUpdateDetail;
