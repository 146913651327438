import Api from "@/api/Api";
import ActivityIndicator from "@/components/activity/ActivityIndicator";
import { useBreadcrumb } from "@/contexts/providers/BreadcrumbContext";
import Button from "@/components/button/Button";
import CloseButton from "@/components/modal/CloseButton";
import Modal from "@/components/modal/Modal";
import { H2 } from "@/components/typography";
import { useProjectQuery } from "@/queries";
import { flashSuccessMessage } from "@/utils/alert_utils";
import { route } from "@/utils/route_utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SettingsTitle from "../SettingsTitle";
const ConfirmationModal = ({
  onClose,
  onConfirm,
  project,
  visible
}) => {
  return /* @__PURE__ */ React.createElement(Modal, { onClose, isVisible: visible }, /* @__PURE__ */ React.createElement("div", { className: "flex justify-end" }, /* @__PURE__ */ React.createElement(CloseButton, { onClose })), /* @__PURE__ */ React.createElement(H2, { className: "text-center" }, "Are you sure?"), project.privacy_mode === "invite_only" ? /* @__PURE__ */ React.createElement("p", { className: "text-center" }, "When you switch to Community Mode, this LifeWeb will be accessible by any visitor, and anyone with a LifeWeb account will be able to post memories and comments.") : /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("p", { className: "mb-4 text-center" }, 'When you switch to Invite-Only Mode, anyone in the "Connected to this LifeWeb" list will still be able to access the LifeWeb unless an Admin revokes their permissions later.'), /* @__PURE__ */ React.createElement("p", { className: "text-center mb-4" }, "The LifeWeb will ", /* @__PURE__ */ React.createElement("span", { className: "font-semibold" }, "NOT"), " be accessible to any other users unless an Admin grants them access.")), /* @__PURE__ */ React.createElement("div", { className: "flex items-center gap-4 justify-center" }, /* @__PURE__ */ React.createElement("div", { className: "max-w-xs mb-4 mt-8" }, /* @__PURE__ */ React.createElement(Button, { color: "outline", fullWidth: true, type: "submit", onClick: onClose }, "Nevermind")), /* @__PURE__ */ React.createElement("div", { className: "max-w-xs mb-4 mt-8" }, /* @__PURE__ */ React.createElement(Button, { color: "secondary", fullWidth: true, type: "submit", onClick: onConfirm }, "Update Privacy Mode"))));
};
const PrivacyModeButton = ({
  enabled,
  project,
  onClick
}) => /* @__PURE__ */ React.createElement("div", { className: "h-20 flex flex-col items-center justify-center" }, enabled ? /* @__PURE__ */ React.createElement(Button, { color: "outline", onClick }, "Update to", " ", project.privacy_mode === "community" ? "Invite-Only" : "Community") : /* @__PURE__ */ React.createElement("div", { className: "text-center font-semibold text-lifeweb-blue" }, "Current Privacy Mode"));
const PrivacyEdit = () => {
  const breadcrumb = useBreadcrumb();
  const { slug } = useParams();
  const queryClient = useQueryClient();
  const { data: project } = useProjectQuery(slug);
  const [modalVisible, setModalVisible] = useState(false);
  useEffect(() => {
    var _a;
    if (!project)
      return;
    breadcrumb.setCrumbs([
      {
        label: `${(_a = project.prefix) != null ? _a : ""} ${project.full_name}`,
        href: route("project", { slug })
      },
      {
        label: "Admin Settings"
      },
      {
        label: "Privacy Settings"
      }
    ]);
    mixpanel.track("settings_viewed", {
      kind: "project",
      project_id: project.id,
      type: "privacy"
    });
  }, [project]);
  const handleUpdatePrivacyMode = async () => {
    const privacyMode = project.privacy_mode === "community" ? "invite_only" : "community";
    await Api.utility.patch(route("projectsUpdate", { slug }), {
      project: {
        privacy_mode: privacyMode
      }
    });
    setModalVisible(false);
    flashSuccessMessage(
      `Privacy mode updated to ${privacyMode === "community" ? "Community" : "Invite-Only"}`
    );
  };
  const mutation = useMutation({
    mutationFn: handleUpdatePrivacyMode,
    onSuccess: () => queryClient.invalidateQueries({
      queryKey: ["project", { slug }]
    })
  });
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(SettingsTitle, null, "Privacy Settings"), project && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("p", { className: "mb-4" }, "As an admin, you have control over who can view", " ", project.preferred_name, "'s LifeWeb and who can post memories to it. For more information on LifeWeb privacy settings,", " ", /* @__PURE__ */ React.createElement(
    "a",
    {
      href: "https://help.lifeweb360.com/article/54-available-privacy-settings",
      className: "text-lifeweb-brown underline",
      target: "_blank",
      rel: "noreferrer"
    },
    "click here"
  ), "."), /* @__PURE__ */ React.createElement("div", { className: "grid md:grid-cols-2 gap-6 mb-4 " }, /* @__PURE__ */ React.createElement("div", { className: "border border-gray-400 flex flex-col justify-between rounded px-6 py-4" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("h2", { className: "text-center text-xl text-lifeweb-blue mb-2" }, "Community Mode"), /* @__PURE__ */ React.createElement("ul", { className: "list-disc mb-2 ml-4" }, /* @__PURE__ */ React.createElement("li", null, "Anyone can view ", project.preferred_name, "'s LifeWeb"), /* @__PURE__ */ React.createElement("li", null, "Anyone can post memories"), /* @__PURE__ */ React.createElement("li", null, project.preferred_name, "'s LifeWeb and obituary can be found on Google and Bing"))), /* @__PURE__ */ React.createElement(
    PrivacyModeButton,
    {
      enabled: project.privacy_mode !== "community",
      project,
      onClick: () => setModalVisible(true)
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "border border-gray-400 flex flex-col justify-between rounded px-6 py-4" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("h2", { className: "text-center text-xl text-lifeweb-blue mb-2" }, "Invite-Only Mode"), /* @__PURE__ */ React.createElement("ul", { className: "list-disc mb-2 ml-4" }, /* @__PURE__ */ React.createElement("li", null, "Admins manage who can view ", project.preferred_name, "'s LifeWeb"), /* @__PURE__ */ React.createElement("li", null, "Admins manage who can post memories"), /* @__PURE__ */ React.createElement("li", null, project.preferred_name, "'s LifeWeb and obituary can", " ", /* @__PURE__ */ React.createElement("span", { className: "font-bold underline" }, "not"), " be found on Google or Bing"))), /* @__PURE__ */ React.createElement(
    PrivacyModeButton,
    {
      enabled: project.privacy_mode !== "invite_only",
      project,
      onClick: () => setModalVisible(true)
    }
  ))), /* @__PURE__ */ React.createElement(
    ConfirmationModal,
    {
      visible: modalVisible,
      onClose: () => setModalVisible(false),
      onConfirm: mutation.mutate,
      project
    }
  )), !project && /* @__PURE__ */ React.createElement(ActivityIndicator, null));
};
export default PrivacyEdit;
