export const buildKey = (key, value) => {
  if (Array.isArray(value)) {
    const values = value;
    return values.map((v) => `${key}[]=${v}`).join("&");
  }
  return `${key}=${value}`;
};
export const buildPath = (path, query) => {
  if (!query) {
    return `/${cleanPath(path)}`;
  }
  const queryString = typeof query === "string" ? query : Object.keys(query).map((key) => buildKey(key, query[key])).filter((v) => v).join("&");
  return queryString.length > 0 ? `/${cleanPath(path)}?${queryString}` : `/${cleanPath(path)}`;
};
export const cleanPath = (path) => path.replace(/(^\/)|(\/$)/g, "");
