import Api from "@/api/Api";
import PlayButton from "@/components/button/PlayButton";
import {
  InHiddenBanner,
  InReviewBanner
} from "@/components/content_card/ContentCardDetails";
import ManagementControl from "@/components/controls/ManagementControl";
import { default as ManagementControlsWrapper } from "@/components/controls/ManagementControls";
import Meatball from "@/components/controls/Meatball";
import ConfirmationModal from "@/components/modal/ConfirmationModal";
import Modal from "@/components/modal/Modal";
import { useDetectClickOutside } from "@/helpers/hooks/useDetectClickOutside";
import {
  flashMessageFromResponseError,
  flashSuccessMessage
} from "@/utils/alert_utils";
import { route } from "@/utils/route_utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import React, { useRef, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
const defaultVideoThumbnail = require("@/assets/images/project_default.jpg");
const pinImage = require("@/assets/images/memories/pin.png");
const CommentCount = ({ memory }) => {
  const { slug } = useParams();
  const link = route("projectMemory", { slug, memory_id: memory.id });
  const total = memory.comments.length;
  return /* @__PURE__ */ React.createElement(Link, { to: link }, total === 0 ? "Add comment" : total === 1 ? "1 comment" : `${total} comments`);
};
const ImageCount = ({ memory }) => {
  const total = memory.memory_images.length;
  switch (total) {
    case 0:
      return /* @__PURE__ */ React.createElement(React.Fragment, null);
    case 1:
      return /* @__PURE__ */ React.createElement("div", null, "1 photo");
    default:
      return /* @__PURE__ */ React.createElement("div", null, "1 of ", total, " photos");
  }
};
const ManagementControls = ({ afterUpdate, memory, user, visible }) => {
  var _a;
  const [confirmHideMemory, setConfirmHideMemory] = useState(false);
  const pinned = memory.display_status === "pinned";
  const { slug } = useParams();
  const queryClient = useQueryClient();
  const updateMemory = async (data) => {
    try {
      await Api.utility.patch(route("memory", { id: memory.id }), {
        memory: data
      });
      afterUpdate();
      setConfirmHideMemory(false);
    } catch (error) {
      flashMessageFromResponseError(error);
    }
  };
  const projectMutation = useMutation({
    mutationFn: updateMemory,
    onSuccess: (_data, { display_status, publication_status }, _context) => {
      queryClient.invalidateQueries({
        queryKey: ["memories", { project: slug }]
      });
      if (display_status) {
        flashSuccessMessage(
          `Memory ${display_status === "pinned" ? "pinned" : "unpinned"}`
        );
      }
      if (publication_status) {
        flashSuccessMessage(`Memory ${publication_status}`);
      }
    }
  });
  if (!visible)
    return;
  const edit = () => window.location.href = route("memoryEdit", { id: memory.id });
  const hide = () => projectMutation.mutate({ publication_status: "unpublished" });
  const pin = () => projectMutation.mutate({ display_status: "pinned" });
  const unhide = () => projectMutation.mutate({ publication_status: "published" });
  const unpin = () => projectMutation.mutate({ display_status: "normal" });
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(ManagementControlsWrapper, null, pinned ? /* @__PURE__ */ React.createElement(ManagementControl, { icon: "pin", onClick: unpin, text: "Unpin memory" }) : /* @__PURE__ */ React.createElement(ManagementControl, { icon: "pinFilled", onClick: pin, text: "Pin memory" }), /* @__PURE__ */ React.createElement(
    ManagementControl,
    {
      icon: "pencil",
      onClick: edit,
      text: `${((_a = memory.user) == null ? void 0 : _a.id) === (user == null ? void 0 : user.id) ? "Edit" : "Curate"} memory`
    }
  ), memory.publication_status === "published" && /* @__PURE__ */ React.createElement(
    ManagementControl,
    {
      icon: "hide",
      onClick: () => setConfirmHideMemory(true),
      text: "Hide memory"
    }
  ), memory.publication_status === "unpublished" && /* @__PURE__ */ React.createElement(
    ManagementControl,
    {
      icon: "show",
      onClick: unhide,
      text: "Unhide memory"
    }
  )), /* @__PURE__ */ React.createElement(Modal, { size: "medium", isVisible: confirmHideMemory }, /* @__PURE__ */ React.createElement(
    ConfirmationModal,
    {
      cancelText: "Cancel",
      closeText: "Close",
      confirmText: "Hide Memory",
      onClose: () => setConfirmHideMemory(false),
      onConfirm: hide,
      subtitle: "If you change your mind later, you can publish it again.",
      title: "Are you sure?"
    }
  )));
};
const ProjectMemoryTile = ({ user, memory }) => {
  var _a, _b, _c;
  const [managementControlsVisible, setManagementControlsVisible] = useState(false);
  const { slug } = useParams();
  const history = useHistory();
  const memoryType = memory.memory_images.length ? "image" : memory.memory_video ? "video" : "text";
  const managementControlsRef = useRef(null);
  useDetectClickOutside(
    managementControlsRef,
    () => setManagementControlsVisible(false)
  );
  const toggleManagementControls = (e) => {
    e.stopPropagation();
    setManagementControlsVisible((current) => !current);
  };
  const memoryUrl = route("projectMemory", {
    slug,
    memory_id: memory.id
  });
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "aspect-[4/5] bg-lifeweb-yellow overflow-hidden rounded-md shadow-md w-full",
      onClick: () => history.push(memoryUrl),
      role: "link"
    },
    /* @__PURE__ */ React.createElement("figure", { className: "cursor-pointer flex flex-col h-full relative" }, (user == null ? void 0 : user.role) === "admin" && /* @__PURE__ */ React.createElement("div", { className: "absolute top-0 right-0" }, /* @__PURE__ */ React.createElement(
      "div",
      {
        ref: managementControlsRef,
        onClick: (e) => e.stopPropagation()
      },
      /* @__PURE__ */ React.createElement("div", { className: "text-lifeweb-blue" }, /* @__PURE__ */ React.createElement(
        Meatball,
        {
          ariaLabel: "Memory options",
          onClick: toggleManagementControls
        }
      ), /* @__PURE__ */ React.createElement(
        ManagementControls,
        {
          afterUpdate: () => setManagementControlsVisible(false),
          memory,
          user,
          visible: managementControlsVisible
        }
      ))
    )), memory.display_status === "pinned" && /* @__PURE__ */ React.createElement("img", { src: pinImage, className: "absolute block top-1 left-0 z-10" }), /* @__PURE__ */ React.createElement("div", { className: "h-full p-3 flex-auto overflow-hidden" }, /* @__PURE__ */ React.createElement(
      "div",
      {
        className: classNames(
          "bg-center bg-contain bg-no-repeat relative",
          {
            "h-3/4": (memory.description || ((_a = memory.memory_quote) == null ? void 0 : _a.text)) && memoryType !== "text",
            "h-full": !memory.description && !((_b = memory.memory_quote) == null ? void 0 : _b.text)
          }
        ),
        style: {
          backgroundImage: memoryType === "image" ? `url(${memory.memory_images[0].image_url})` : memoryType === "video" ? `url(${memory.memory_video.video_thumbnail_url || defaultVideoThumbnail})` : ""
        }
      },
      memoryType === "video" && /* @__PURE__ */ React.createElement(PlayButton, null)
    ), /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "p-2",
        key: memory.id,
        dangerouslySetInnerHTML: {
          __html: memory.description ? memory.description : (_c = memory.memory_quote) == null ? void 0 : _c.text
        }
      }
    )), /* @__PURE__ */ React.createElement("figcaption", { className: "bg-white px-5 py-3 relative flex-auto" }, memory.publication_status === "published" && /* @__PURE__ */ React.createElement(React.Fragment, null, memory.description && /* @__PURE__ */ React.createElement("div", { className: "absolute bottom-full left-0 bg-gradient-to-b h-full w-full from-transparent from-35% to-lifeweb-yellow opacity-75" }), /* @__PURE__ */ React.createElement("div", { className: "flex items-center justify-between text-sm" }, /* @__PURE__ */ React.createElement("div", null, "Contributed by"), /* @__PURE__ */ React.createElement(ImageCount, { memory })), /* @__PURE__ */ React.createElement("div", { className: "flex gap-2 justify-between min-h-[40px]" }, /* @__PURE__ */ React.createElement("div", { className: "font-semibold" }, memory.contributed_by), /* @__PURE__ */ React.createElement("div", { className: "text-sm whitespace-nowrap" }, /* @__PURE__ */ React.createElement(CommentCount, { memory })))), memory.publication_status === "unpublished" && /* @__PURE__ */ React.createElement(InHiddenBanner, null), ["hidden", "pending"].includes(memory.publication_status) && /* @__PURE__ */ React.createElement(InReviewBanner, null)))
  );
};
export default ProjectMemoryTile;
