import { useTranslation } from "react-i18next";
import { possessivePronoun } from "@/utils/pronoun_utils";
export const mailtoURL = (project) => "mailto:" + encodeURI(
  `?subject=Please help us share memories on ${project.preferred_name}'s LifeWeb!
     &body=Share your memories and celebrate ${project.preferred_name}'s life at ${possessivePronoun(project)} LifeWeb: ${window.location.origin}/${project.slug}
   `
);
export const smsURL = (project) => "sms:" + encodeURI(
  `?&body=Share your memories and celebrate ${project.preferred_name}'s life at ${possessivePronoun(project)} LifeWeb: ${window.location.origin}/${project.slug}`
);
export const fullName = (project) => {
  var _a;
  if (project.prefix) {
    return `${(_a = project.prefix) != null ? _a : ""} ${project.full_name}`;
  } else {
    return project.full_name;
  }
};
export const pronounOptions = () => {
  const { t } = useTranslation();
  return [
    {
      label: t("form.options.project.pronouns.she"),
      value: "she"
    },
    {
      label: t("form.options.project.pronouns.he"),
      value: "he"
    },
    {
      label: t("form.options.project.pronouns.they"),
      value: "they"
    }
  ];
};
export const searchProjectNames = (project, searchQuery) => {
  const names = `${project.full_name} ${project.preferred_name}`.toLowerCase().replace(/\s+/g, " ").trim().split(" ");
  const query = searchQuery.toLowerCase().replace(/\s+/g, " ").trim().split(" ");
  return query.every((q) => names.some((n) => n.includes(q)));
};
