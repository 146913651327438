import Api from "@/api/Api";
import SalesBanner from "@/contexts/settings/SalesBanner";
import Modeler from "@/utils/modeler/modeler";
import { route } from "@/utils/route_utils";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useRouteMatch, Link } from "react-router-dom";
import CloseButton from "./modal/CloseButton";
const defaultCrumbs = () => {
  const pathnames = window.location.pathname.split("/").filter((x) => x);
  return pathnames.map((_, index) => {
    const url = `/${pathnames.slice(0, index + 1).join("/")}`;
    return {
      label: pathnames[index],
      href: url
    };
  });
};
const Breadcrumb = ({ crumbs }) => {
  const [bannerOpen, setBannerOpen] = useState(false);
  const [project, setProject] = useState();
  const { slug } = (() => {
    try {
      const { params } = useRouteMatch(["/projects/:slug", "/:slug"]);
      return params;
    } catch (e) {
      return { slug: window.location.pathname.split("/").filter((x) => x)[1] };
    }
  })();
  useEffect(() => {
    if (!slug)
      return;
    const fetchProject = async () => {
      const { data } = await Api.utility.get(route("projectsUpdate", { slug }));
      setProject(new Modeler(data).build());
    };
    fetchProject();
  }, [slug]);
  const crumbsList = crumbs || defaultCrumbs();
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", { className: "flex flex-col md:flex-row items-center gap-4 justify-between mb-8" }, /* @__PURE__ */ React.createElement("nav", { "aria-label": "breadcrumbs", className: "font-semibold text-slate-950" }, crumbsList.map(({ label, href }, i) => /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames("inline-block", {
        'before:content-[">"] before:mx-2': i > 0
      }),
      key: i
    },
    href ? /* @__PURE__ */ React.createElement(Link, { className: "text-lifeweb-blue underline", to: href }, label) : /* @__PURE__ */ React.createElement("span", null, label)
  ))), crumbsList.length > 0 && project && !crumbsList.some(({ label }) => label === "My Settings") && /* @__PURE__ */ React.createElement("div", { className: "bg-white drop-shadow-md gap-1 sm:gap-6 flex flex-col sm:flex-row justify-between font-medium items-center px-4 py-3" }, /* @__PURE__ */ React.createElement("div", null, "Current LifeWeb Plan:", " ", project.plan === "premium" ? "Premium" : "Free"), project.plan === "premium" ? /* @__PURE__ */ React.createElement(
    "a",
    {
      className: "block text-lifeweb-blue underline",
      href: "https://www.lifeweb360.com/memory-book-process"
    },
    "Learn about Memory Books"
  ) : /* @__PURE__ */ React.createElement(
    "span",
    {
      "aria-controls": "premium-banner",
      className: "block cursor-pointer text-lifeweb-blue underline",
      onClick: () => setBannerOpen(true)
    },
    "Upgrade to Premium"
  ))), project && bannerOpen && /* @__PURE__ */ React.createElement("div", { className: "bg-white drop-shadow-md px-4 pt-4 pb-10" }, /* @__PURE__ */ React.createElement("div", { className: "flex justify-end w-full" }, /* @__PURE__ */ React.createElement(CloseButton, { onClose: () => setBannerOpen(false) })), /* @__PURE__ */ React.createElement("div", { className: "px-4" }, /* @__PURE__ */ React.createElement(SalesBanner, { project }))));
};
export default Breadcrumb;
