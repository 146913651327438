import CloseButton from "@/components/modal/CloseButton";
import Modal from "@/components/modal/Modal";
import React, { useEffect, useState } from "react";
import { scaleOrdinal } from "d3-scale";
import WordCloud from "react-d3-cloud";
import Api from "@/api/Api";
import { route } from "@/utils/route_utils";
import Modeler from "@/utils/modeler/modeler";
const COLORS = [
  "#144257",
  "#8CCBD9",
  "#FFD470",
  "#935A4A"
];
const fill = scaleOrdinal(COLORS);
const WordCloudModal = ({ onClose, project, visible }) => {
  const [words, setWords] = useState([]);
  useEffect(() => {
    const fetchWordCloud = async () => {
      const { data } = await Api.utility.get(route("projectWordCloud", { project_id: project.id }));
      const { word_cloud_words } = new Modeler(data).build();
      setWords(word_cloud_words.filter(({ enabled }) => enabled));
    };
    fetchWordCloud();
  }, []);
  useEffect(() => {
    if (!visible)
      return;
    mixpanel.track("wordcloud_viewed", {
      kind: "project",
      project_id: project.id
    });
  }, [visible]);
  return /* @__PURE__ */ React.createElement(Modal, { isVisible: visible, onClose }, /* @__PURE__ */ React.createElement("div", { className: "flex justify-end" }, /* @__PURE__ */ React.createElement(CloseButton, { onClose })), /* @__PURE__ */ React.createElement(
    WordCloud,
    {
      data: words.map(({ word: text, weight: value }) => ({ text, value: value || 100 })),
      fill: (_, w) => fill(w),
      font: '"Alegreya Sans", sans-serif',
      height: 400,
      padding: 3,
      rotate: 0,
      width: 500
    }
  ));
};
export default WordCloudModal;
