import React, { useState } from "react";
import Icon from "@/components/icon/Icon";
const FilterHeader = (props) => {
  const { children, onClear, onSelectAll, title } = props;
  const [isCollapsed, setIsCollapsed] = useState(false);
  const handleToggleCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };
  const handleClear = (event) => {
    event.stopPropagation();
    onClear();
  };
  const handleSelectAll = (event) => {
    event.stopPropagation();
    onSelectAll();
  };
  const renderActionLinks = (() => {
    if (!isCollapsed) {
      return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("a", { className: "link", onClick: handleClear }, "Clear All"), /* @__PURE__ */ React.createElement("a", { className: "link", onClick: handleSelectAll }, "Select All"));
    }
  })();
  return /* @__PURE__ */ React.createElement("div", { className: "filter-header" }, /* @__PURE__ */ React.createElement("button", { className: "filter-header__bar", onClick: handleToggleCollapsed }, /* @__PURE__ */ React.createElement("div", { className: "filter-header__title" }, /* @__PURE__ */ React.createElement("span", null, title)), /* @__PURE__ */ React.createElement("div", { className: "filter-header__actions" }, renderActionLinks), /* @__PURE__ */ React.createElement("div", { className: "filter-header__icon" }, /* @__PURE__ */ React.createElement(Icon, { icon: "caret", rotation: isCollapsed ? 180 : 0 }))), /* @__PURE__ */ React.createElement("div", { className: "filter-header__content" }, !isCollapsed && children));
};
export default FilterHeader;
