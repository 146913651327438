document.addEventListener("DOMContentLoaded", (event) => {
  const obituaryEditor = document.querySelector("#editor");
  const obituaryField = document.querySelector("#project_obituary");
  const obituaryForm = document.querySelector("#obituary-form");
  const obituarySubmitButton = document.querySelector(
    "#obituary-submit-button"
  );
  const quillWrapper = document.querySelector(".quill");
  if (obituaryForm) {
    if (obituaryEditor.__quill.getLength() > 1 && quillWrapper) {
      quillWrapper.classList.add("is-filled");
    }
    if (obituaryEditor) {
      const tooltip = obituaryEditor.__quill.theme.tooltip;
      const input = tooltip.root.querySelector("input[data-link]");
      input.dataset.link = "https://example.com";
      obituaryEditor.__quill.on(
        "text-change",
        function(delta, oldDelta, source) {
          if (source !== "api" && obituarySubmitButton.disabled === true) {
            obituarySubmitButton.disabled = false;
          }
          if (obituaryEditor.__quill.getLength() > 1) {
            quillWrapper.classList.add("is-filled");
          } else {
            quillWrapper.classList.remove("is-filled");
          }
        }
      );
    }
    if (obituaryField.value) {
      obituaryEditor.__quill.setContents([]);
      const delta = obituaryEditor.__quill.clipboard.convert(obituaryField.value);
      obituaryEditor.__quill.setContents(delta.ops);
    }
    obituaryForm.addEventListener("submit", (event2) => {
      event2.preventDefault();
      obituaryField.value = obituaryEditor.__quill.root.innerHTML;
      obituaryForm.submit();
    });
  }
});
