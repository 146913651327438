import Api from "@/api/Api";
import ActivityIndicator from "@/components/activity/ActivityIndicator";
import Checkbox from "@/components/form/Checkbox";
import SubmitOrCancelButtons from "@/components/form/SubmitOrCancelButtons";
import { useProjectQuery, useProjectUserQuery } from "@/queries";
import { flashSuccessMessage } from "@/utils/alert_utils";
import { route } from "@/utils/route_utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useBreadcrumb } from "../providers/BreadcrumbContext";
import SettingsTitle from "./SettingsTitle";
const NotificationSettings = () => {
  const breadcrumb = useBreadcrumb();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { slug } = useParams();
  const { data: project } = useProjectQuery(slug);
  const { data: projectUser } = useProjectUserQuery(slug);
  const [changed, setChanged] = useState(false);
  const [comments, setComments] = useState();
  const [dates, setDates] = useState();
  const [events, setEvents] = useState();
  const [shared, setShared] = useState();
  useEffect(() => {
    var _a;
    if (!projectUser || !project || typeof comments === "boolean")
      return;
    setComments(projectUser.email_notify_comments);
    setDates(projectUser.email_notify_dates);
    setEvents(projectUser.email_notify_events);
    setShared(projectUser.email_notify_shared);
    breadcrumb.setCrumbs([
      {
        label: `${(_a = project.prefix) != null ? _a : ""} ${project.full_name}`,
        href: route("project", { slug })
      },
      {
        label: "My Settings"
      },
      {
        label: "Notifications"
      }
    ]);
    mixpanel.track("settings_viewed", {
      kind: "project",
      project_id: project.id,
      type: "notifications"
    });
  }, [projectUser, project]);
  const handleUpdateNotificationSettings = async (e) => {
    e.preventDefault();
    await Api.utility.patch(
      route("projectUsersUpdate", { project_user_id: projectUser.id }),
      {
        project_user: {
          email_notify_comments: comments,
          email_notify_dates: dates,
          email_notify_events: events,
          email_notify_shared: shared
        }
      }
    );
  };
  const mutation = useMutation({
    mutationFn: handleUpdateNotificationSettings,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["projectUser"]
      });
      flashSuccessMessage("Notifications successfully updated");
      history.push(route("project", { slug }));
    }
  });
  const handleCheckboxChange = (setterFunction) => (checked) => {
    setChanged(true);
    setterFunction(checked);
  };
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(SettingsTitle, null, "Notifications"), /* @__PURE__ */ React.createElement("p", null, "Set your preferences for the email notifications you'd like to receive for this LifeWeb."), projectUser && project && /* @__PURE__ */ React.createElement(
    "form",
    {
      className: "flex flex-col gap-4 my-6",
      method: "patch",
      onSubmit: mutation.mutate
    },
    /* @__PURE__ */ React.createElement(
      Checkbox,
      {
        label: `Reminders of important dates to check in with ${project.preferred_name}'s community`,
        checked: !!dates,
        onChange: handleCheckboxChange(setDates)
      }
    ),
    /* @__PURE__ */ React.createElement(
      Checkbox,
      {
        label: `When memories are shared on ${project.preferred_name}'s LifeWeb`,
        checked: !!shared,
        onChange: handleCheckboxChange(setShared)
      }
    ),
    /* @__PURE__ */ React.createElement(
      Checkbox,
      {
        label: `When someone comments on a memory you shared or commented on`,
        checked: !!comments,
        onChange: handleCheckboxChange(setComments)
      }
    ),
    /* @__PURE__ */ React.createElement(
      Checkbox,
      {
        label: `Updates posted by the Admins of ${project.preferred_name}'s LifeWeb`,
        checked: !!events,
        onChange: handleCheckboxChange(setEvents)
      }
    ),
    /* @__PURE__ */ React.createElement(
      SubmitOrCancelButtons,
      {
        disabled: !changed,
        onCancel: () => history.push(route("project", {
          slug
        }))
      }
    )
  ), !projectUser && /* @__PURE__ */ React.createElement(ActivityIndicator, null));
};
export default NotificationSettings;
