import classNames from "classnames";
import React from "react";
const ValidationLabel = ({ children, error, htmlFor = "", valid }) => {
  return /* @__PURE__ */ React.createElement(
    "label",
    {
      className: classNames({
        "text-gray-900": valid,
        "text-red-700": !valid
      }),
      htmlFor
    },
    !!error ? error : children
  );
};
export default ValidationLabel;
