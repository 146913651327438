import React, { useEffect, useMemo, useRef, useState } from "react";
import { useResponsive } from "react-hooks-responsive";
import { useTranslation } from "react-i18next";
import Button from "@/components/button/Button";
import Select from "@/components/form/Select";
import Typeahead from "@/components/typeahead/Typeahead";
import { route } from "@/utils/route_utils";
import { breakpoints } from "@/utils/screen_utils";
import MemoriesFilterModal from "./MemoriesFilterModal";
const MemoriesFilterBar = (props) => {
  const { onChangeQuery, project, query, themes, userNames } = props;
  const filterRef = useRef(null);
  const positionRef = useRef(null);
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const { t } = useTranslation();
  const { screenIsAtMost } = useResponsive(breakpoints);
  const isTouch = screenIsAtMost("tablet");
  const newMemoryPath = route("memoriesNew", { slug: project.slug });
  useEffect(() => {
    const listener = () => {
      const target = filterRef.current.offsetTop;
      if (!positionRef.current && window.pageYOffset > target) {
        positionRef.current = target;
        filterRef.current.classList.add("is-fixed");
      } else if (!!positionRef.current && window.pageYOffset < positionRef.current) {
        positionRef.current = null;
        filterRef.current.classList.remove("is-fixed");
      }
    };
    window.addEventListener("scroll", listener);
    return () => window.removeEventListener("scroll", listener);
  }, []);
  const handleUpdateCheckboxGroup = (field, selection) => {
    onChangeQuery((current) => ({ ...current, [field]: selection }));
  };
  const handleApplySelection = () => {
    setIsFilterModalVisible(false);
  };
  const sortOptions = useMemo(() => {
    return [
      {
        label: t("views.memories.filters.sortLatest"),
        value: "-created_at"
      },
      {
        label: t("views.memories.filters.sortOldest"),
        value: "created_at"
      }
    ];
  }, []);
  const formattedUserNames = useMemo(() => {
    return userNames.map((name) => ({
      id: name,
      name
    }));
  }, [userNames]);
  const themeText = useMemo(() => {
    if (query.themes.length === 0) {
      return t("views.memories.filters.threadsNone");
    }
    if (query.themes.length === 1) {
      return t("views.memories.filters.threadsSingle");
    }
    return t("views.memories.filters.threadsMultiple", {
      count: query.themes.length
    });
  }, [query.themes]);
  const userText = useMemo(() => {
    if (query.users.length === 0) {
      return t("views.memories.filters.contributorsNone");
    }
    if (query.users.length === 1) {
      return t("views.memories.filters.contributorsSingle");
    }
    return t("views.memories.filters.contributorsMultiple", {
      count: query.users.length
    });
  }, [query.users]);
  const handleOpenFilterModal = () => {
    setIsFilterModalVisible(true);
  };
  const handleCloseFilterModal = () => {
    setIsFilterModalVisible(false);
  };
  const renderShareLink = (() => {
    return /* @__PURE__ */ React.createElement("div", { className: "flex flex-col md:flex-row md:h-[44px] items-center justify-center md:mx-1 my-1 md:my-0 gap-1 md:gap-2" }, /* @__PURE__ */ React.createElement("a", { href: newMemoryPath, className: "button is-primary" }, t("views.memories.actions.share")), /* @__PURE__ */ React.createElement("a", { href: `/${project.slug}/memories/reflect`, className: "button is-secondary" }, "Help Me Reflect"));
  })();
  const renderFiltersBar = (() => {
    if (!isTouch) {
      return /* @__PURE__ */ React.createElement("div", { className: "memory-bar__filters" }, /* @__PURE__ */ React.createElement("div", { className: "memory-bar__filter" }, /* @__PURE__ */ React.createElement(
        Typeahead,
        {
          availableResults: formattedUserNames,
          emptyResultsText: "No users",
          isHighlighted: true,
          placeholder: userText,
          selectedResults: query.users,
          srLabel: "Filter by Contributor",
          updateResults: (selection) => handleUpdateCheckboxGroup("users", selection),
          sort: true,
          withDropdownCaret: true
        }
      )), /* @__PURE__ */ React.createElement("div", { className: "memory-bar__filter" }, /* @__PURE__ */ React.createElement(
        Typeahead,
        {
          availableResults: themes,
          emptyResultsText: "No threads",
          isHighlighted: true,
          placeholder: themeText,
          selectedResults: query.themes,
          srLabel: "Filter by Life Thread",
          updateResults: (selection) => handleUpdateCheckboxGroup("themes", selection),
          sort: true,
          withDropdownCaret: true
        }
      )), /* @__PURE__ */ React.createElement("div", { className: "-my-2 memory-bar__filter" }, /* @__PURE__ */ React.createElement("label", { className: "sr-only", htmlFor: "filter-sort" }, "Change sort order"), /* @__PURE__ */ React.createElement(
        Select,
        {
          id: "filter-sort",
          isHighlighted: true,
          onChange: (value) => onChangeQuery((current) => ({ ...current, sort: value })),
          options: sortOptions,
          value: query.sort
        }
      )), renderShareLink);
    }
  })();
  const renderMobileActions = (() => {
    if (isTouch) {
      return /* @__PURE__ */ React.createElement("div", { className: "memory-bar__actions" }, renderShareLink, /* @__PURE__ */ React.createElement(Button, { color: "outline", onClick: handleOpenFilterModal }, t("views.memories.filters.sortAndFilter")));
    }
  })();
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "memory-bar", ref: filterRef }, /* @__PURE__ */ React.createElement("div", { className: "memory-bar__bar" }, /* @__PURE__ */ React.createElement("div", { className: "memory-bar__title" }, /* @__PURE__ */ React.createElement("h2", null, t("views.memories.actions.title"))), renderFiltersBar, /* @__PURE__ */ React.createElement("div", { className: "memory-bar__share" })), renderMobileActions), /* @__PURE__ */ React.createElement(
    MemoriesFilterModal,
    {
      isVisible: isFilterModalVisible,
      onApplySelection: handleApplySelection,
      onClose: handleCloseFilterModal,
      selectedUsers: query.users,
      selectedThemes: query.themes,
      themeOptions: themes,
      userNames: formattedUserNames
    }
  ));
};
export default MemoriesFilterBar;
