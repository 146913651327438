import React from "react";
import Paginator from "@/components/paginator/Paginator";
const Table = (props) => {
  const {
    columns,
    currentPage,
    data,
    emptyText,
    handleChangePage,
    isHiddenMobile,
    renderHeaderActions,
    renderRow,
    totalPages,
    withActionColumn
  } = props;
  const renderBody = () => {
    return data.map((item, index) => renderRow(item, index));
  };
  const renderHeader = () => {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, columns.map((column) => /* @__PURE__ */ React.createElement("th", { key: column.propertyName }, column.displayName)), withActionColumn && /* @__PURE__ */ React.createElement("th", null));
  };
  return /* @__PURE__ */ React.createElement("div", { className: "table__wrapper" }, renderHeaderActions && renderHeaderActions(), /* @__PURE__ */ React.createElement("table", { className: "table" }, !isHiddenMobile && /* @__PURE__ */ React.createElement("thead", null, /* @__PURE__ */ React.createElement("tr", null, renderHeader())), /* @__PURE__ */ React.createElement("tbody", null, renderBody())), data.length === 0 && emptyText && /* @__PURE__ */ React.createElement("p", { className: "table__empty-text" }, emptyText), /* @__PURE__ */ React.createElement(
    Paginator,
    {
      currentPage,
      onChangePage: handleChangePage,
      totalPages
    }
  ));
};
export default Table;
