import React from "react";
import Button from "@/components/button/Button";
import Icon from "@/components/icon/Icon";
import ThemeTag from "@/components/theme/ThemeTag";
const ThemeCheckbox = (props) => {
  const { isSelected, onAddTheme, onRemoveTheme, theme } = props;
  const handleOnChange = () => {
    if (isSelected) {
      onRemoveTheme(theme);
    } else {
      onAddTheme(theme);
    }
  };
  return /* @__PURE__ */ React.createElement("div", { className: "theme-checkbox" }, /* @__PURE__ */ React.createElement("label", { htmlFor: theme.id }, /* @__PURE__ */ React.createElement(ThemeTag, { isClickable: true, isSelected }, theme.name)), isSelected && /* @__PURE__ */ React.createElement(Button, { color: "removable", onClick: handleOnChange }, /* @__PURE__ */ React.createElement(Icon, { alt: "Deselect item", icon: "xContained" })), /* @__PURE__ */ React.createElement("input", { hidden: true, id: theme.id, onChange: handleOnChange, type: "checkbox" }));
};
export default ThemeCheckbox;
