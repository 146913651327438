import React from "react";
import { useResponsive } from "react-hooks-responsive";
import { breakpoints } from "@/utils/screen_utils";
import MemoryCard from "./MemoryCard";
const MemoryCardBlock = (props) => {
  const {
    admin,
    memories,
    memoryQuote,
    onClickMemory,
    onClickMemoryQuote,
    project,
    user
  } = props;
  const { screenIsAtMost } = useResponsive(breakpoints);
  const renderMemoryEntry = (n) => {
    const memory = memories[n];
    if (memory) {
      return /* @__PURE__ */ React.createElement(
        MemoryCard,
        {
          admin,
          key: memory.id,
          memory,
          onClickMemory,
          project,
          user
        }
      );
    }
  };
  const renderMemoryCards = (() => {
    const isTouch = screenIsAtMost("tablet");
    if (isTouch) {
      return /* @__PURE__ */ React.createElement("div", { className: "memory-card-block__stack" }, memories.map((memory) => /* @__PURE__ */ React.createElement(
        MemoryCard,
        {
          admin,
          key: memory.id,
          memory,
          onClickMemory,
          user
        }
      )));
    }
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "memory-card-block__left" }, [0, 3].map((n) => renderMemoryEntry(n))), /* @__PURE__ */ React.createElement("div", { className: "memory-card-block__right" }, [1, 2, 4].map((n) => renderMemoryEntry(n))));
  })();
  const renderQuote = (() => {
    if (memoryQuote) {
      return /* @__PURE__ */ React.createElement("div", { className: "memory-card-block__quote" }, /* @__PURE__ */ React.createElement("a", { onClick: () => onClickMemoryQuote(memoryQuote) }, /* @__PURE__ */ React.createElement("span", null, memoryQuote.text)));
    }
  })();
  return /* @__PURE__ */ React.createElement("div", { className: "memory-card-block" }, /* @__PURE__ */ React.createElement("div", { className: "memory-card-block__memories" }, renderMemoryCards), renderQuote);
};
export default MemoryCardBlock;
