export const flashMessage = (message, type) => {
  window.flash(message, type);
};
export const flashErrorMessage = (message) => {
  flashMessage(message, "error");
};
export const flashNoticeMessage = (message) => {
  flashMessage(message, "info");
};
export const flashMessageFromResponseError = (response) => {
  if (Array.isArray(response.response.data.errors)) {
    flashErrorMessage(response.response.data.errors[0]);
  } else {
    flashErrorMessage(response.response.data.errors);
  }
};
export const flashSuccessMessage = (message) => {
  flashMessage(message, "success");
};
