import React from "react";
import ErrorMessage from "./ErrorMessage";
import Input from "./Input";
import Label from "./Label";
export const InputGroup = (props) => {
  const {
    description,
    isLabelBold,
    isRequired,
    label,
    labelNote,
    labelSize,
    validationMessage,
    withoutPadding,
    ...inputProps
  } = props;
  const isInvalid = !!validationMessage;
  return /* @__PURE__ */ React.createElement("div", { className: "form-input-group" }, label && /* @__PURE__ */ React.createElement(
    Label,
    {
      description,
      isBold: isLabelBold,
      isRequired,
      labelNote,
      labelSize,
      withoutPadding
    },
    label
  ), /* @__PURE__ */ React.createElement(Input, { isInvalid, ...inputProps }), validationMessage && /* @__PURE__ */ React.createElement(ErrorMessage, { message: validationMessage }));
};
export default InputGroup;
