import Api from "@/api/Api";
import { useDetectClickOutside } from "@/helpers/hooks/useDetectClickOutside";
import { flashMessageFromResponseError } from "@/utils/alert_utils";
import { route } from "@/utils/route_utils";
import React, { useRef, useState } from "react";
import ManagementControl from "../controls/ManagementControl";
import { default as ManagementControlsWrapper } from "../controls/ManagementControls";
import Meatball from "../controls/Meatball";
import ConfirmationModal from "../modal/ConfirmationModal";
import Modal from "../modal/Modal";
import SquareFrame from "../square_frame/SquareFrame";
import ContentCardBanner from "./ContentCardBanner";
import ContentCardDescription from "./ContentCardDescription";
import ContentCardDetails from "./ContentCardDetails";
import ContentCardQuote from "./ContentCardQuote";
const ManagementControls = ({ memory, user, visible }) => {
  var _a;
  const [confirmHideMemory, setConfirmHideMemory] = useState(false);
  if (!visible)
    return;
  const pinned = memory.display_status === "pinned";
  const updateMemory = async (data) => {
    try {
      await Api.utility.patch(route("memory", { id: memory.id }), {
        memory: data
      });
      window.location.reload();
    } catch (error) {
      flashMessageFromResponseError(error);
    }
  };
  const edit = () => {
    const location = route("memoryEdit", { id: memory.id });
    window.location.href = location;
  };
  const pin = async () => {
    await updateMemory({ display_status: "pinned" });
  };
  const unpin = async () => {
    await updateMemory({ display_status: "normal" });
  };
  const hide = async () => {
    await updateMemory({ publication_status: "unpublished" });
  };
  const unhide = async () => {
    await updateMemory({ publication_status: "published" });
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(ManagementControlsWrapper, { position: "abnormal" }, pinned ? /* @__PURE__ */ React.createElement(ManagementControl, { icon: "pin", onClick: unpin, text: "Unpin memory" }) : /* @__PURE__ */ React.createElement(ManagementControl, { icon: "pinFilled", onClick: pin, text: "Pin memory" }), /* @__PURE__ */ React.createElement(
    ManagementControl,
    {
      icon: "pencil",
      onClick: edit,
      text: `${((_a = memory.user) == null ? void 0 : _a.id) === (user == null ? void 0 : user.id) ? "Edit" : "Curate"} memory`
    }
  ), memory.publication_status === "published" && /* @__PURE__ */ React.createElement(
    ManagementControl,
    {
      icon: "hide",
      onClick: () => setConfirmHideMemory(true),
      text: "Hide memory"
    }
  ), memory.publication_status === "unpublished" && /* @__PURE__ */ React.createElement(
    ManagementControl,
    {
      icon: "show",
      onClick: unhide,
      text: "Unhide memory"
    }
  )), /* @__PURE__ */ React.createElement(Modal, { size: "medium", isVisible: confirmHideMemory }, /* @__PURE__ */ React.createElement(
    ConfirmationModal,
    {
      cancelText: "Cancel",
      closeText: "Close",
      confirmText: "Hide Memory",
      onClose: () => setConfirmHideMemory(false),
      onConfirm: hide,
      subtitle: "If you change your mind later, you can publish it again.",
      title: "Are you sure?"
    }
  )));
};
const ContentCard = (props) => {
  const {
    admin,
    isPinned,
    imageAlt,
    imageUrl,
    memory,
    quote,
    text,
    type,
    inReview,
    user,
    ...detailsProps
  } = props;
  const [managementControlsVisible, setManagementControlsVisible] = useState(false);
  const managementControlsRef = useRef(null);
  useDetectClickOutside(
    managementControlsRef,
    () => setManagementControlsVisible(false)
  );
  const renderCardBody = () => {
    if (type === "quote") {
      return /* @__PURE__ */ React.createElement(ContentCardQuote, { text: quote });
    }
    if (type === "image") {
      return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "content-card__top" }, /* @__PURE__ */ React.createElement(
        ContentCardBanner,
        {
          inReview,
          imageAlt,
          imageUrl
        }
      )), /* @__PURE__ */ React.createElement("div", { className: "content-card__bottom" }, /* @__PURE__ */ React.createElement(
        ContentCardDetails,
        {
          inReview,
          text,
          ...detailsProps
        }
      )));
    }
    if (type === "video") {
      return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "content-card__top" }, /* @__PURE__ */ React.createElement(
        ContentCardBanner,
        {
          inReview,
          imageUrl,
          type: "video"
        }
      )), /* @__PURE__ */ React.createElement("div", { className: "content-card__bottom" }, /* @__PURE__ */ React.createElement(
        ContentCardDetails,
        {
          inReview,
          text,
          ...detailsProps
        }
      )));
    }
    if (type === "text") {
      return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "content-card__top" }, /* @__PURE__ */ React.createElement(
        ContentCardDescription,
        {
          inReview,
          text,
          user: memory.user
        }
      )), /* @__PURE__ */ React.createElement("div", { className: "content-card__bottom" }, /* @__PURE__ */ React.createElement(
        ContentCardDetails,
        {
          inReview,
          text: quote,
          ...detailsProps
        }
      )));
    }
  };
  const pinImage = require("@/assets/images/memories/pin.png");
  const renderPin = (() => {
    if (isPinned) {
      return /* @__PURE__ */ React.createElement("div", { className: "content-card__pin" }, /* @__PURE__ */ React.createElement("img", { src: pinImage }));
    }
  })();
  const toggleManagementControls = () => setManagementControlsVisible((current) => !current);
  return /* @__PURE__ */ React.createElement(SquareFrame, null, /* @__PURE__ */ React.createElement("div", { className: "content-card relative" }, admin && memory && ["published", "unpublished"].includes(memory.publication_status) && /* @__PURE__ */ React.createElement(
    "div",
    {
      ref: managementControlsRef,
      onClick: (e) => e.stopPropagation()
    },
    /* @__PURE__ */ React.createElement(
      Meatball,
      {
        ariaLabel: "Memory options",
        onClick: toggleManagementControls,
        position: "negative"
      }
    ),
    /* @__PURE__ */ React.createElement(
      ManagementControls,
      {
        memory,
        user,
        visible: managementControlsVisible
      }
    )
  ), renderPin, renderCardBody()));
};
export default ContentCard;
