import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
const Link = Quill.import("formats/link");
Quill.register("modules/clipboard", PlainClipboard, true);
Link.sanitize = sanitizeLink;
import {
  formats,
  limitedModules,
  modules,
  PlainClipboard,
  sanitizeLink
} from "@/helpers/quill_editor";
import CharacterCount from "./CharacterCount";
import ErrorMessage from "./ErrorMessage";
import Label from "./Label";
const TextEditor = (props) => {
  const {
    includeLimitedOptions,
    label,
    maxLength,
    onChange,
    onFocus,
    placeholder,
    validationMessage,
    value
  } = props;
  const quillRef = useRef(null);
  useEffect(() => {
    if (!!quillRef.current) {
      const tooltip = quillRef.current.editor.theme.tooltip;
      const input = tooltip.root.querySelector("input[data-link]");
      input.dataset.link = "https://lifeweb360.com";
    }
  }, [quillRef]);
  const span = document.createElement("span");
  span.innerHTML = value;
  const [characterCount, setCharacterCount] = useState(
    span.innerText.length
  );
  const [focused, setFocused] = useState(false);
  const handleSetFocused = () => {
    setFocused(true);
    onFocus == null ? void 0 : onFocus();
  };
  const isFilled = span.innerText.length;
  const handleChange = (newValue, _delta, source) => {
    const length = quillRef.current ? Math.max(0, quillRef.current.getEditor().getLength() - 1) : 0;
    if (onChange) {
      onChange(newValue, length, source);
    }
    if (maxLength) {
      setCharacterCount(length);
    }
  };
  const styles = classNames("quill", {
    "has-large-padding": maxLength,
    "is-filled": focused || isFilled
  });
  return /* @__PURE__ */ React.createElement(React.Fragment, null, label && /* @__PURE__ */ React.createElement(Label, null, label), /* @__PURE__ */ React.createElement("div", { className: "quill-wrapper" }, /* @__PURE__ */ React.createElement(
    ReactQuill,
    {
      className: styles,
      formats,
      modules: includeLimitedOptions ? limitedModules : modules,
      onChange: handleChange,
      onBlur: () => setFocused(false),
      onFocus: handleSetFocused,
      placeholder,
      ref: quillRef,
      theme: "snow",
      value
    }
  ), maxLength && /* @__PURE__ */ React.createElement(
    CharacterCount,
    {
      characterCount,
      maxLength
    }
  )), validationMessage && /* @__PURE__ */ React.createElement(ErrorMessage, { message: validationMessage }));
};
export default TextEditor;
