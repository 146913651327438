import { deserialise } from "kitsu-core";
import { areEqual as areArraysEqual } from "./array_utils";
export const areEqual = (ob1, ob2) => {
  if (!ob1 || !ob2) {
    return true;
  }
  if (Object.keys(ob1).length !== Object.keys(ob2).length) {
    return false;
  }
  const keysToCheck = Object.keys(ob1);
  for (const key of keysToCheck) {
    if (Array.isArray(ob1[key]) && Array.isArray(ob2[key])) {
      if (!areArraysEqual(ob1[key], ob2[key])) {
        return false;
      }
    } else if (ob1[key] !== ob2[key]) {
      return false;
    }
  }
  return true;
};
export const deserialize = (response) => {
  const flatten = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((item) => flatten(item));
    }
    if (typeof obj === "object" && obj !== null) {
      if (obj.data) {
        return flatten(obj.data);
      }
      const newObj = {};
      Object.keys(obj).forEach((key) => {
        newObj[key] = flatten(obj[key]);
      });
      return newObj;
    }
    return obj;
  };
  return flatten(deserialise(response));
};
