import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@/components/button/Button";
import InputGroup from "@/components/form/InputGroup";
import BackButton from "@/components/modal/BackButton";
import Modal from "@/components/modal/Modal";
import { fullName } from "@/utils/project_utils";
const ProjectConfirmationModal = (props) => {
  const {
    isVisible,
    onContinueClick,
    project,
    onUpdateRelationship,
    relationship,
    onUpdateStepNumber,
    stepNumber
  } = props;
  const [isLoading, setIsloading] = useState(false);
  const { t } = useTranslation();
  const onClickContinue = () => {
    setIsloading(true);
    onContinueClick();
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Modal, { isVisible, size: "small" }, /* @__PURE__ */ React.createElement("div", { className: "project-name-modal__back-wrapper" }, /* @__PURE__ */ React.createElement(BackButton, { onBack: () => onUpdateStepNumber(stepNumber - 2) })), /* @__PURE__ */ React.createElement("div", { className: "project-confirmation-modal" }, /* @__PURE__ */ React.createElement("h1", null, t("views.memories.projectNew.confirmation.header")), /* @__PURE__ */ React.createElement("div", { className: "columns" }, /* @__PURE__ */ React.createElement("div", { className: "column project-confirmation-modal__message" }, /* @__PURE__ */ React.createElement("div", { className: "project-confirmation-modal__subhead" }, t("views.memories.projectNew.confirmation.subhead")), /* @__PURE__ */ React.createElement("div", { className: "project-confirmation-modal__para form-label is-small" }, t("views.memories.projectNew.confirmation.message", {
    preferred_name: project.preferred_name || fullName(project)
  }), /* @__PURE__ */ React.createElement("span", { className: "project-confirmation-modal__require" }, t("form.label.required"))), /* @__PURE__ */ React.createElement(
    InputGroup,
    {
      onChange: onUpdateRelationship,
      placeholder: t(
        "views.memories.usersNew.placeholders.relationship"
      ),
      value: relationship,
      maxLength: 100
    }
  ), /* @__PURE__ */ React.createElement("div", { className: "project-name-modal__subtext-wrapper" }, t("views.memories.usersNew.relationshipSubtext")))), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "column is-11-mobile is-9-tablet project-confirmation-modal__button-right-wrapper"
    },
    /* @__PURE__ */ React.createElement(
      Button,
      {
        color: "primary",
        loading: isLoading,
        onClick: onClickContinue,
        isDisabled: isLoading || relationship.length === 0
      },
      t(
        "views.memories.projectNew.confirmation.buttons.continue"
      )
    )
  )))));
};
export default ProjectConfirmationModal;
