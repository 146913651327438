import Api from "@/api/Api";
import Modeler from "@/utils/modeler/modeler";
import { route } from "@/utils/route_utils";
import { useQuery } from "@tanstack/react-query";
export const useGroupQuery = (slug, options = {}) => useQuery({
  queryKey: ["group", { slug }],
  queryFn: async () => {
    try {
      const response = await Api.utility.get(route("group", { slug }));
      return new Modeler(response.data).build();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  ...options
});
export const useIdentityQuery = () => useQuery({
  queryKey: ["user", { id: "me" }],
  queryFn: async () => {
    try {
      const response = await Api.utility.get(route("userShow"));
      return new Modeler(response.data).build();
    } catch (error) {
      return Promise.reject(error);
    }
  }
});
export const useProjectQuery = (slug, options = {}) => useQuery({
  queryKey: ["project", { slug }],
  queryFn: async () => {
    try {
      const response = await Api.utility.get(
        route("projectsUpdate", { slug })
      );
      return new Modeler(response.data).build();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  ...options
});
export const useProjectUserQuery = (slug, options = {}) => useQuery({
  queryKey: ["projectUser", { slug, id: "me" }],
  queryFn: async () => {
    try {
      const response = await Api.utility.get(
        route("projectUser", { project_id: slug })
      );
      return new Modeler(response.data).build();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  ...options
});
export const useProjectThemesQuery = (slug) => useQuery({
  queryKey: ["project", { slug }, "themes"],
  queryFn: async () => {
    try {
      const response = await Api.utility.get(
        route("themesIndex", { project_id: slug })
      );
      return new Modeler(response.data).build();
    } catch (error) {
      return Promise.reject(error);
    }
  }
});
