import Api from "@/api/Api";
import ActivityIndicator from "@/components/activity/ActivityIndicator";
import { flashMessageFromResponseError } from "@/utils/alert_utils";
import Modeler from "@/utils/modeler/modeler";
import { searchProjectNames } from "@/utils/project_utils";
import { route } from "@/utils/route_utils";
import { capitalize } from "@/utils/string_utils";
import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";
import indefinite from "indefinite";
import Pluralize from "pluralize";
import queryString from "query-string";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import GroupMemory from "./components/GroupMemory";
import GroupFilterBar from "./GroupFilters";
import GroupMemoryDetail from "./GroupMemoryDetail";
import GroupProject from "./GroupProject";
import GroupProjectDetail from "./GroupProjectDetail";
import GroupProjectEdit from "./GroupProjectEdit";
const GroupProjects = ({ categories, group, user }) => {
  const groupUser = (user == null ? void 0 : user.group_users) ? user.group_users.find(({ group_id }) => group_id === group.id) : null;
  const [loading, setLoading] = useState(true);
  const initialSearchParams = new URLSearchParams(location.search);
  const initialSearch = initialSearchParams.get("search") || "";
  const initialCategoryIds = initialSearchParams.get("categories") || "";
  const initialSelectedCategories = initialCategoryIds.split(",").map(
    (categoryId) => categories.find((category) => category.id === categoryId)
  ).filter(Boolean);
  const [search, setSearch] = useState(initialSearch);
  const [selectedCategories, setSelectedCategories] = useState(
    initialSelectedCategories
  );
  const fetchMemories = async ({ pageParam }) => {
    try {
      const url = pageParam || route("groupMemories", { group_id: group.id, "page[size]": 8 });
      const response = await Api.utility.get(url);
      const modeledData = new Modeler(response.data).build();
      return { links: response.data.links, data: modeledData };
    } catch (err) {
      return Promise.reject(flashMessageFromResponseError(err));
    }
  };
  const { data: memoriesData, fetchNextPage: fetchNextMemories } = useInfiniteQuery({
    queryKey: ["groupMemories", { group_id: group.id }],
    queryFn: fetchMemories,
    getNextPageParam: (lastPage) => {
      var _a;
      return (_a = lastPage == null ? void 0 : lastPage.links) == null ? void 0 : _a.next;
    },
    initialPageParam: 0,
    refetchOnWindowFocus: false
  });
  const fetchProjects = async ({
    pageParam
  }) => {
    try {
      const url = pageParam || queryString.stringifyUrl(
        {
          url: route("groupProjects", { group_id: group.id }),
          query: {
            search,
            group_category_ids: selectedCategories.map(
              (category) => category.id
            )
          }
        },
        { arrayFormat: "bracket" }
      );
      const response = await Api.utility.get(url);
      const modeledData = new Modeler(
        response.data
      ).build();
      setLoading(false);
      return { links: response.data.links, data: modeledData };
    } catch (err) {
      return Promise.reject(flashMessageFromResponseError(err));
    }
  };
  const {
    data,
    fetchNextPage: fetchNextProjects,
    isFetchingNextPage: isFetchingNextProjects,
    isFetching: isFetchingProjects
  } = useInfiniteQuery({
    queryKey: [
      "groupProjects",
      { group_id: group.id, search, selectedCategories }
    ],
    queryFn: fetchProjects,
    getNextPageParam: (lastPage) => {
      var _a;
      return (_a = lastPage == null ? void 0 : lastPage.links) == null ? void 0 : _a.next;
    },
    initialPageParam: 0,
    placeholderData: keepPreviousData
  });
  const memories = useMemo(() => {
    if (!memoriesData)
      return [];
    return memoriesData.pages.map((page) => page.data).flat();
  }, [memoriesData]);
  const groupProjects = useMemo(() => {
    if (!data)
      return [];
    return data.pages.map((page) => page.data).flat();
  }, [data]);
  const { ref: inViewRef, inView } = useInView();
  useEffect(() => {
    if (inView && !isFetchingProjects) {
      fetchNextMemories();
      fetchNextProjects();
    }
  }, [inView, isFetchingProjects]);
  const filteredProjects = useMemo(
    () => groupProjects.filter(({ project }) => searchProjectNames(project, search)).filter(({ group_categories }) => {
      if (selectedCategories.length === 0)
        return true;
      const groupedSelectedCategories = selectedCategories.reduce(
        (accumulator, category) => ({
          ...accumulator,
          [category.parent_id]: (accumulator[category.parent_id] || []).concat(category)
        }),
        {}
      );
      const projectCategoryIds = group_categories.map(({ id }) => id);
      return Object.values(groupedSelectedCategories).every(
        (children) => (
          // There must be at least some categories in common with this project
          children.some(
            ({ id: selectedId }) => projectCategoryIds.includes(selectedId)
          )
        )
      );
    }),
    [groupProjects, search, selectedCategories]
  );
  const isSearching = search.length > 0 || selectedCategories.length > 0;
  const getMemoryIndex = (tileIndex) => [1, 6, 8, 9].indexOf(tileIndex % 12) + 4 * Math.floor(tileIndex / 12);
  const shouldShowMemory = (index) => !isSearching && memories.length > 2 && [1, 6, 8, 9].includes(index % 12) && getMemoryIndex(index) < memories.length;
  const sortedProjects = useMemo(
    () => [...filteredProjects].sort((a, b) => {
      if (a.pinned || b.pinned) {
        return b.pinned ? 1 : -1;
      }
      return Date.parse(b.created_at) - Date.parse(a.created_at);
    }),
    [filteredProjects]
  );
  const getVisibleMemoryCount = () => sortedProjects.reduce(
    (accumulator, _, index) => shouldShowMemory(index) ? accumulator + 1 : accumulator,
    0
  );
  useEffect(() => {
    mixpanel.track("grouppage_viewed", {
      kind: "group",
      group_name: group.slug
    });
  }, []);
  return /* @__PURE__ */ React.createElement(Router, null, /* @__PURE__ */ React.createElement(Route, { path: "/groups/:groupName" }, /* @__PURE__ */ React.createElement(
    GroupFilterBar,
    {
      categories,
      group,
      search,
      selectedCategories,
      setSelectedCategories,
      setSearch,
      user
    }
  ), !loading && filteredProjects.length === 0 && /* @__PURE__ */ React.createElement("div", { className: "col-span-full my-8 px-6 text-center" }, "Sorry, it doesn't seem any ", Pluralize.plural(group.member_name), " ", "match your search. Click the", " ", /* @__PURE__ */ React.createElement("strong", null, "Honor ", indefinite(capitalize(group.member_name)), " "), "button above to add a new ", group.member_name, " to this page."), /* @__PURE__ */ React.createElement("div", { className: "gap-10 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 items-center max-w-screen-xl mx-auto my-10 px-9 auto-rows-min" }, (isSearching ? filteredProjects : sortedProjects).map(
    (project, index) => {
      const memoryIndex = shouldShowMemory(index) ? getMemoryIndex(index) : null;
      return /* @__PURE__ */ React.createElement(Fragment, { key: project.id }, memoryIndex !== null && /* @__PURE__ */ React.createElement(
        GroupMemory,
        {
          backgroundColor: group.memory_card_color,
          memory: memories[memoryIndex]
        }
      ), /* @__PURE__ */ React.createElement(
        GroupProject,
        {
          backgroundColor: group.secondary_color,
          group,
          groupProject: project,
          user: groupUser
        }
      ));
    }
  )), /* @__PURE__ */ React.createElement("div", { ref: inViewRef }), (loading || isFetchingNextProjects) && /* @__PURE__ */ React.createElement(ActivityIndicator, null)), /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(Route, { exact: true, path: "/groups/:groupName/memories/:id" }, /* @__PURE__ */ React.createElement(
    GroupMemoryDetail,
    {
      group,
      memories: memories.slice(0, getVisibleMemoryCount())
    }
  )), /* @__PURE__ */ React.createElement(Route, { exact: true, path: "/groups/:groupName/:memberName/:id" }, /* @__PURE__ */ React.createElement(GroupProjectDetail, { group })), /* @__PURE__ */ React.createElement(Route, { exact: true, path: "/groups/:groupName/:memberName/:id/edit" }, /* @__PURE__ */ React.createElement(GroupProjectEdit, { group }))));
};
export default GroupProjects;
