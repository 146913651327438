import Api from "@/api/Api";
import { route } from "@/utils/route_utils";
import React from "react";
import NavigationAnchor from "./NavigationAnchor";
import NavigationItem from "./NavigationItem";
const SharedNav = ({ project, user }) => {
  const signOut = async (e) => {
    e.preventDefault();
    await Api.utility.delete("/users/sign_out");
    window.location.href = "/users/sign_in";
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, (user == null ? void 0 : user.id) && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(NavigationAnchor, { link: "/", title: "My LifeWebs" }), /* @__PURE__ */ React.createElement(NavigationAnchor, { link: route("profile"), title: "My Profile" })), (user == null ? void 0 : user.is_superadmin) && project && /* @__PURE__ */ React.createElement(
    NavigationAnchor,
    {
      link: route("bookNew", { slug: project.slug }),
      title: "Generate Book"
    }
  ), /* @__PURE__ */ React.createElement(
    NavigationAnchor,
    {
      link: "https://www.lifeweb360.com/contact-us",
      mobile: true,
      title: "Help"
    }
  ), /* @__PURE__ */ React.createElement(
    NavigationAnchor,
    {
      link: "https://www.lifeweb360.com",
      title: "LifeWeb Home",
      mobile: true
    }
  ), (user == null ? void 0 : user.id) && /* @__PURE__ */ React.createElement(NavigationItem, { onClick: signOut, title: "Log Out" }), !(user == null ? void 0 : user.id) && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(NavigationAnchor, { link: "/users/sign_in", title: "Log In" }), /* @__PURE__ */ React.createElement(NavigationAnchor, { link: "/users/sign_up", title: "Sign Up" })));
};
export default SharedNav;
