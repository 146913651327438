import React from "react";
const EmbedIcon = (props) => {
  const { children, icon, isActive, size } = props;
  const getIconSource = (i) => {
    return icons[i];
  };
  const getIconWidth = (i) => {
    return iconSize[i].width;
  };
  const getIconHeight = (i) => {
    return iconSize[i].width;
  };
  const iconSize = {
    small: { width: "24", height: "24" },
    medium: { width: "50", height: "50" },
    large: { width: "100", height: "100" }
  };
  const icons = {
    tick: /* @__PURE__ */ React.createElement(
      "svg",
      {
        className: "inline",
        xmlns: "http://www.w3.org/2000/svg",
        width: getIconWidth(size),
        height: getIconHeight(size),
        style: {
          padding: "5px",
          verticalAlign: "middle"
        },
        viewBox: "0 0 24 24"
      },
      /* @__PURE__ */ React.createElement(
        "path",
        {
          fill: !isActive ? "#808080" : "#00FF00",
          d: "M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.393 7.5l-5.643 5.784-2.644-2.506-1.856 1.858 4.5 4.364 7.5-7.643-1.857-1.857z"
        }
      )
    ),
    cross: /* @__PURE__ */ React.createElement(
      "svg",
      {
        className: "inline",
        xmlns: "http://www.w3.org/2000/svg",
        width: getIconWidth(size),
        height: getIconHeight(size),
        style: {
          padding: "5px",
          verticalAlign: "middle"
        },
        viewBox: "0 0 24 24"
      },
      /* @__PURE__ */ React.createElement(
        "path",
        {
          fill: !isActive ? "#808080" : "#FF0000",
          d: "M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 16.538l-4.592-4.548 4.546-4.587-1.416-1.403-4.545 4.589-4.588-4.543-1.405 1.405 4.593 4.552-4.547 4.592 1.405 1.405 4.555-4.596 4.591 4.55 1.403-1.416z"
        }
      )
    )
  };
  return /* @__PURE__ */ React.createElement("span", null, getIconSource(icon), /* @__PURE__ */ React.createElement("span", null, children));
};
export default EmbedIcon;
