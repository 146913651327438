import { sanitizeUserInput } from "@/utils/string_utils";
import { getHighContrastTextColor } from "@/utils/text_utils";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
const GroupMemory = ({ backgroundColor, memory }) => {
  const history = useHistory();
  const { groupName } = useParams();
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: `aspect-[2/3] cursor-pointer flex items-center justify-center h-full hover:opacity-90 mb-0 overflow-hidden transition-opacity relative select-none rounded-lg shadow-md ${getHighContrastTextColor(
        backgroundColor
      )}`,
      style: { backgroundColor },
      onClick: () => history.push(`/groups/${groupName}/memories/${memory.id}`)
    },
    /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "text-center w-full p-3 sm:text-sm m-auto",
        dangerouslySetInnerHTML: {
          __html: sanitizeUserInput(memory.description)
        }
      }
    ),
    /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "absolute bottom-0 h-16 inset-x-0",
        style: {
          backgroundImage: `linear-gradient(transparent, ${backgroundColor})`
        }
      }
    )
  );
};
export default GroupMemory;
