import React from "react";
import BookBlock from "./BookBlock";
export default function BookPage({
  pageLayout,
  selectedMemoryId,
  handleMemoryOnClick,
  setBlockTranslate
}) {
  return /* @__PURE__ */ React.createElement("div", { className: "books__page" }, /* @__PURE__ */ React.createElement("div", { className: "books__block-container" }, pageLayout.blocks.map((block) => /* @__PURE__ */ React.createElement(
    "div",
    {
      onClick: () => handleMemoryOnClick(block.memory_id, false),
      key: `${block.block_id}${block.element_id}`
    },
    /* @__PURE__ */ React.createElement(
      BookBlock,
      {
        block,
        selected: block.memory_id === selectedMemoryId,
        setBlockTranslate
      }
    )
  ))));
}
