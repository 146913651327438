import React from "react";
import { Trans, useTranslation } from "react-i18next";
import ActivityIndicator from "@/components/activity/ActivityIndicator";
import Button from "@/components/button/Button";
import InputGroup from "@/components/form/InputGroup";
import CloseButton from "@/components/modal/CloseButton";
import { isValueEmpty } from "@/utils/form_utils";
const MemoryAddVideo = (props) => {
  const { isLoading, video_url, onCloseClick, onSaveClick, onUpdateField } = props;
  const { t } = useTranslation();
  const isSaveButtonDisabled = isValueEmpty(video_url) || isLoading;
  return /* @__PURE__ */ React.createElement("div", { className: "memory-add-video" }, /* @__PURE__ */ React.createElement("div", { className: "memory-add-video__close-wrapper" }, /* @__PURE__ */ React.createElement(CloseButton, { onClose: onCloseClick })), /* @__PURE__ */ React.createElement("h1", null, t("views.memories.video.header")), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-9-desktop memory-add-video__subheader" }, /* @__PURE__ */ React.createElement(Trans, { i18nKey: "views.memories.video.subheader" }, /* @__PURE__ */ React.createElement("a", { href: process.env.external_url["upload_video"], target: "_blank" })))), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-9-desktop" }, /* @__PURE__ */ React.createElement(
    InputGroup,
    {
      isRequired: true,
      label: t("form.label.memory.memoryVideo"),
      labelSize: "small",
      onChange: (value) => onUpdateField("video_url", value),
      placeholder: t("form.placeholder.memory.memoryVideo"),
      value: video_url
    }
  ))), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-11-mobile is-6-desktop memory-add-video__save-wrapper" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "primary",
      isDisabled: isSaveButtonDisabled,
      onClick: onSaveClick
    },
    isLoading && /* @__PURE__ */ React.createElement(ActivityIndicator, null),
    t("form.actions.save")
  ))));
};
export default MemoryAddVideo;
