import axios from "axios";
export class Api {
  constructor() {
    var _a;
    this.baseHeaders = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": ((_a = document.querySelector('meta[name="csrf-token"]')) == null ? void 0 : _a.getAttribute("content")) || ""
    };
    this.baseUrl = this.setBaseUrl();
    this.baseConfig = {
      baseURL: this.baseUrl,
      timeout: 3e4,
      headers: this.baseHeaders,
      validateStatus: (status) => {
        return status >= 200 && status < 300;
      }
    };
    this.utility = axios.create(this.baseConfig);
  }
  setAuthUtility(authToken) {
    this.utility.defaults.headers.common.Authorization = authToken;
  }
  setBaseUtility() {
    this.utility.defaults.headers.common.Authorization = void 0;
  }
  setBaseUrl() {
    if (process.env.NODE_ENV && process.env.NODE_ENV === "production") {
      return "https://" + location.hostname;
    } else {
      return "http://localhost:3000";
    }
  }
}
const api = new Api();
export default api;
