import ConfirmationModal from "@/components/modal/ConfirmationModal";
import ModalCard from "@/components/modal/ModalCard";
import React from "react";
import { useTranslation } from "react-i18next";
const ResetTokenExpModal = (props) => {
  const { visible, onConfirm } = props;
  const { t } = useTranslation();
  return /* @__PURE__ */ React.createElement(ModalCard, { position: "centerFullScreen", size: "small", isHidden: !visible }, /* @__PURE__ */ React.createElement(
    ConfirmationModal,
    {
      title: t("views.devise.modal.resetPassword.expiredLink.title"),
      subtitle: t(
        "views.devise.modal.resetPassword.expiredLink.subTitle"
      ),
      confirmText: t(
        "views.devise.modal.resetPassword.expiredLink.confirmText"
      ),
      onConfirm,
      isOnboarding: true
    }
  ));
};
export default ResetTokenExpModal;
