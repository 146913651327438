import classnames from "classnames";
import React from "react";
const SalesCard = ({ children, color = "white", title }) => {
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classnames("shadow-lg px-10 py-6 rounded", {
        "bg-lifeweb-white border-zinc-200 border": color === "white"
      }),
      style: color === "blue" ? { backgroundColor: "rgb(165, 215, 220)" } : {}
    },
    /* @__PURE__ */ React.createElement("h3", { className: "mb-4 text-xl text-lifeweb-blue" }, title),
    children
  );
};
const SalesCardButton = ({
  children,
  disabled,
  link
}) => {
  return disabled ? /* @__PURE__ */ React.createElement(
    "button",
    {
      className: "bg-zinc-200 block cursor-not-allowed font-bold mt-6 px-6 py-3 rounded text-center text-zinc-500 w-full",
      disabled: true
    },
    children
  ) : /* @__PURE__ */ React.createElement(
    "a",
    {
      href: link,
      className: "bg-lifeweb-gold block mt-6 font-bold px-6 py-3 rounded text-center text-slate-800 w-full",
      target: "_blank"
    },
    children
  );
};
export { SalesCard as default, SalesCardButton };
