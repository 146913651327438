import classNames from "classnames";
import React from "react";
const Select = (props) => {
  const {
    id = "",
    isHighlighted,
    onChange,
    onInput,
    options,
    placeholder,
    value
  } = props;
  const isFilled = !!value;
  const handleChange = (event) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };
  return /* @__PURE__ */ React.createElement("div", { className: "my-2 select-wrapper" }, /* @__PURE__ */ React.createElement(
    "select",
    {
      className: classNames("form-select", {
        "is-filled": isFilled,
        "is-highlighted": isHighlighted,
        "text-gray-400": !isFilled
      }),
      id,
      onChange: handleChange,
      onInput,
      value: value || placeholder
    },
    placeholder && /* @__PURE__ */ React.createElement("option", { disabled: true, value: placeholder }, placeholder),
    options.map((option) => /* @__PURE__ */ React.createElement("option", { key: option.value, value: option.value }, option.label))
  ), /* @__PURE__ */ React.createElement("div", { className: "select-caret" }, /* @__PURE__ */ React.createElement(
    "img",
    {
      alt: "View options",
      src: require("@/assets/images/icons/caret-black-down.svg")
    }
  )));
};
export default Select;
