import React from "react";
import { useTranslation } from "react-i18next";
const CommentButtons = (props) => {
  const {
    comment,
    currentUser,
    isAdmin,
    onCollapseClick,
    onDeleteClick,
    onEditClick,
    onStatusClick
  } = props;
  const { t } = useTranslation();
  const isCommentHidden = comment.status === "hidden";
  const isCurrentUsersComment = comment.user && currentUser.id === comment.user.id;
  const handleDeleteClick = () => {
    onDeleteClick(comment);
  };
  const handleEditClick = () => {
    onEditClick();
  };
  const handleStatusClick = () => {
    onStatusClick(comment);
  };
  const renderToggleStatus = () => {
    if (isCommentHidden) {
      return /* @__PURE__ */ React.createElement("div", { className: "comment-button__toggle-container" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("span", { className: "comment-button__note" }, t("comments.hiddenNote")), /* @__PURE__ */ React.createElement(
        "button",
        {
          className: "comment-button__action-button",
          onClick: handleStatusClick
        },
        t("comments.unhide")
      )), /* @__PURE__ */ React.createElement(
        "button",
        {
          className: "comment-button__action-button",
          onClick: onCollapseClick
        },
        t("comments.collapse")
      ));
    } else {
      if (!isCurrentUsersComment && comment.status === "posted") {
        return /* @__PURE__ */ React.createElement(
          "button",
          {
            className: "comment-button__action-button",
            onClick: handleStatusClick
          },
          t("comments.hide")
        );
      }
    }
  };
  return /* @__PURE__ */ React.createElement("div", { className: "comment-button" }, isCurrentUsersComment && !isCommentHidden && /* @__PURE__ */ React.createElement(
    "button",
    {
      className: "comment-button__action-button",
      onClick: handleEditClick
    },
    t("comments.edit")
  ), isCurrentUsersComment && !isCommentHidden && /* @__PURE__ */ React.createElement(
    "button",
    {
      className: "comment-button__action-button",
      onClick: handleDeleteClick
    },
    t("comments.remove")
  ), isAdmin && renderToggleStatus());
};
export default CommentButtons;
