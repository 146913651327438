import classNames from "classnames";
import React from "react";
const Row = (props) => {
  const { children, isHighlighted } = props;
  return /* @__PURE__ */ React.createElement(
    "tr",
    {
      className: classNames("table__row", {
        "is-highlighted": isHighlighted
      })
    },
    children
  );
};
export default Row;
