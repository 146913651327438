import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@/components/button/Button";
const TypeaheadResults = (props) => {
  const {
    availableResults,
    emptyResultsText,
    isVisible,
    onApplyClick,
    selectAllEnabled = true,
    selectedResults,
    shouldDisplayEmptyResults,
    sort,
    updateResults
  } = props;
  const [selected, setSelected] = useState(selectedResults);
  const { t } = useTranslation();
  const checkboxIcon = require("@/assets/images/icons/selected.svg");
  if (!isVisible) {
    return null;
  }
  const orderedResults = sort ? availableResults.sort(
    (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  ) : availableResults;
  const handleOnChange = (result) => {
    const entry = selected.find((r) => r.name === result.name);
    if (!entry) {
      setSelected([...selected, result]);
    } else {
      const results = selected.filter((r) => r !== entry);
      setSelected(results);
    }
  };
  const handleOnApply = (event) => {
    event.preventDefault();
    updateResults(selected);
    onApplyClick();
  };
  const handleClearAll = (_) => {
    setSelected([]);
  };
  const handleSelectAll = (_) => {
    if (!selectAllEnabled)
      return;
    setSelected(availableResults);
  };
  const renderResults = () => {
    if (orderedResults.length === 0 && shouldDisplayEmptyResults) {
      return /* @__PURE__ */ React.createElement("span", null, emptyResultsText);
    }
    return orderedResults.map((result) => {
      const isSelected = !!(selected == null ? void 0 : selected.find(
        (r) => r.name === result.name
      ));
      return /* @__PURE__ */ React.createElement("div", { key: result.id }, /* @__PURE__ */ React.createElement(
        "label",
        {
          htmlFor: result.id,
          className: "typeahead-results__result-wrapper"
        },
        /* @__PURE__ */ React.createElement("div", { className: "checkbox" }, isSelected && /* @__PURE__ */ React.createElement("img", { className: "checkbox", src: checkboxIcon })),
        result.name
      ), /* @__PURE__ */ React.createElement(
        "input",
        {
          hidden: true,
          id: result.id,
          onChange: () => handleOnChange(result),
          type: "checkbox"
        }
      ));
    });
  };
  return /* @__PURE__ */ React.createElement("div", { className: "typeahead-results" }, /* @__PURE__ */ React.createElement("div", { className: "typeahead-results__top" }, renderResults()), /* @__PURE__ */ React.createElement("div", { className: "typeahead-results__bottom" }, /* @__PURE__ */ React.createElement("div", { className: "typeahead-results__button-wrapper" }, /* @__PURE__ */ React.createElement(
    "button",
    {
      className: "typeahead-results__button",
      onClick: handleClearAll,
      type: "button"
    },
    t("button.clearAll")
  ), selectAllEnabled && /* @__PURE__ */ React.createElement(
    "button",
    {
      className: "typeahead-results__button",
      onClick: handleSelectAll,
      type: "button"
    },
    t("button.selectAll")
  )), /* @__PURE__ */ React.createElement(Button, { color: "outline", onMouseDown: handleOnApply }, t("button.apply"))));
};
export default TypeaheadResults;
