import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Textfit } from "react-textfit";
import Icon from "@/components/icon/Icon";
import ConfirmationModal from "@/components/modal/ConfirmationModal";
import ModalCard from "@/components/modal/ModalCard";
import WordCloudModal from "@/contexts/word_clouds/WordCloudModal";
import { fullName } from "@/utils/project_utils";
import { route } from "@/utils/route_utils";
import { addHyperlink, sanitizeUserInput } from "@/utils/string_utils";
const MemoriesHeader = (props) => {
  const {
    isAdmin,
    isImageHighlighted,
    isNameHighlighted,
    isOnboarding,
    nameRef,
    onUpdateStepNumber,
    project,
    projectUser,
    stepNumber = 1
  } = props;
  const { t } = useTranslation();
  const [showWordCloud, setShowWordCloud] = useState(false);
  const history = useHistory();
  const isHighlighted = isImageHighlighted || isNameHighlighted;
  const goToNextStep = () => {
    onUpdateStepNumber(stepNumber + 1);
  };
  const goToStepAfterNext = () => {
    onUpdateStepNumber(stepNumber + 2);
  };
  const headerBackgroundImage = () => {
    if (project.cover_image_url_large || project.cover_image_url) {
      return project.cover_image_url_large || project.cover_image_url;
    } else {
      return require("@/assets/images/project_default.jpg");
    }
  };
  const welcomeMessage = (() => {
    if (project.welcome_message) {
      const message = addHyperlink(project.welcome_message);
      return /* @__PURE__ */ React.createElement(
        "h4",
        {
          className: classNames("memories__project-welcome prose !prose-invert max-w-none text-xl text-white", {
            "is-highlighted": isOnboarding && stepNumber === 5
          }),
          dangerouslySetInnerHTML: {
            __html: project.embedding_enabled ? message : sanitizeUserInput(message, projectUser)
          }
        }
      );
    }
  })();
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames("project-header", {
        "is-overflow-hidden": !isHighlighted
      })
    },
    /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "project-header__image",
        style: { backgroundImage: `url(${headerBackgroundImage()})` }
      }
    ),
    /* @__PURE__ */ React.createElement("div", { className: "project-header__content" }, isHighlighted && /* @__PURE__ */ React.createElement("div", { className: "cover-wrapper" }), /* @__PURE__ */ React.createElement("div", { className: "section container" }, /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile cover__content-wrapper" }, /* @__PURE__ */ React.createElement("div", { className: "column is-11-mobile is-6-tablet cover__image-wrapper" }, /* @__PURE__ */ React.createElement("div", { className: "cover__image" }, /* @__PURE__ */ React.createElement(
      "img",
      {
        alt: `Profile image for ${fullName(project)}'s LifeWeb`,
        className: isImageHighlighted ? "is-highlighted" : null,
        src: headerBackgroundImage()
      }
    ))), /* @__PURE__ */ React.createElement("div", { className: "column is-11-mobile is-6-tablet cover__name-wrapper" }, /* @__PURE__ */ React.createElement("div", { ref: nameRef }, /* @__PURE__ */ React.createElement(
      Textfit,
      {
        mode: "multi",
        className: classNames("cover__name", {
          "is-highlighted": isNameHighlighted
        })
      },
      /* @__PURE__ */ React.createElement("h1", null, fullName(project))
    )), isImageHighlighted && /* @__PURE__ */ React.createElement(ModalCard, { position: "left", size: "small" }, /* @__PURE__ */ React.createElement(
      ConfirmationModal,
      {
        cancelText: t(
          "views.memories.projectNew.image.buttons.skip"
        ),
        confirmText: t(
          "views.memories.projectNew.image.buttons.add"
        ),
        isOnboarding: true,
        message: t(
          "views.memories.projectNew.image.text.skip"
        ),
        onCancel: goToStepAfterNext,
        onConfirm: goToNextStep,
        subtitle: t(
          "views.memories.projectNew.image.text.add"
        ),
        title: t(
          "views.memories.projectNew.image.titles.photo"
        ),
        project
      }
    )))), /* @__PURE__ */ React.createElement("div", { className: "relative text-white" }, welcomeMessage), /* @__PURE__ */ React.createElement("div", { className: "flex gap-8 items-center justify-center w-full" }, project.obituary_url && !project.obituary && /* @__PURE__ */ React.createElement(
      "a",
      {
        className: "bg-white border-lifeweb-blue font-bold mt-3 px-6 py-3 rounded text-lifeweb-blue",
        href: project.obituary_url,
        onClick: () => {
          mixpanel.track("obituary_external", {
            kind: "project",
            project_id: project.id
          });
        },
        target: "_blank"
      },
      "Obituary",
      /* @__PURE__ */ React.createElement("span", { className: "inline-block ml-2 w-4" }, /* @__PURE__ */ React.createElement(Icon, { icon: "openInNewBlue" }))
    ), project.obituary && /* @__PURE__ */ React.createElement(
      "a",
      {
        className: "bg-white border-lifeweb-blue font-bold mt-3 px-6 py-3 rounded text-lifeweb-blue",
        href: route("projectObituary", { slug: project.slug })
      },
      "Obituary"
    ), project.word_cloud && /* @__PURE__ */ React.createElement(
      "button",
      {
        className: "bg-white border-lifeweb-blue font-bold mt-3 px-6 py-3 rounded text-lifeweb-blue",
        onClick: () => setShowWordCloud(true)
      },
      "Word Cloud"
    ), (project.updates_count > 0 || isAdmin) && /* @__PURE__ */ React.createElement(
      "button",
      {
        className: "bg-white border-lifeweb-blue font-bold mt-3 px-6 py-3 rounded text-lifeweb-blue",
        onClick: () => history.push(route("updates", { slug: project.slug }))
      },
      "Updates",
      " ",
      project.updates_count > 0 && `(${project.updates_count})`
    )), project.groups && project.groups.length > 0 && /* @__PURE__ */ React.createElement("div", { className: "flex gap-2 justify-center mt-8 text-white" }, /* @__PURE__ */ React.createElement("span", { className: "block font-bold" }, "Connected to:"), project.groups.sort().map((group) => /* @__PURE__ */ React.createElement(
      "a",
      {
        href: route("groupShow", { slug: group.slug }),
        className: "block hover:text-gray-200 underline",
        key: group.id
      },
      group.name
    ))))),
    project.word_cloud && /* @__PURE__ */ React.createElement(
      WordCloudModal,
      {
        visible: showWordCloud,
        project,
        onClose: () => setShowWordCloud(false)
      }
    )
  );
};
export default MemoriesHeader;
