import React from "react";
import { useTranslation } from "react-i18next";
import BackButton from "@/components/modal/BackButton";
import ConfirmationModal from "@/components/modal/ConfirmationModal";
import ModalCard from "@/components/modal/ModalCard";
import { fullName } from "@/utils/project_utils";
import { objectivePronoun } from "@/utils/pronoun_utils";
const ProjectMessageStep = (props) => {
  const { stepNumber, onUpdateProjectField, onUpdateStepNumber, project } = props;
  const { t } = useTranslation();
  const defaultWelcomeMessage = t(
    "views.memories.projectEdit.welcomeMessage.defaultMessage",
    {
      preferred_name: project.preferred_name,
      pronoun: objectivePronoun(project),
      full_name: fullName(project)
    }
  );
  const goToNextStep = () => {
    onUpdateStepNumber(stepNumber + 1);
  };
  const goToStepAfterNext = () => {
    onUpdateProjectField("welcome_message", defaultWelcomeMessage);
    onUpdateStepNumber(stepNumber + 2);
  };
  return /* @__PURE__ */ React.createElement(ModalCard, { position: "top", size: "small" }, /* @__PURE__ */ React.createElement("div", { className: "project-name-modal__back-wrapper" }, /* @__PURE__ */ React.createElement(BackButton, { onBack: () => onUpdateStepNumber(stepNumber - 1) })), /* @__PURE__ */ React.createElement(
    ConfirmationModal,
    {
      cancelText: t("views.memories.projectNew.message.buttons.skip"),
      confirmText: t(
        "views.memories.projectNew.message.buttons.customize"
      ),
      isOnboarding: true,
      onCancel: goToStepAfterNext,
      onConfirm: goToNextStep,
      subtitle: t("views.memories.projectNew.message.text"),
      title: t("views.memories.projectNew.message.title")
    }
  ));
};
export default ProjectMessageStep;
