import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "@/api/Api";
import Button from "@/components/button/Button";
import TextEditor from "@/components/form/TextEditor";
import BackButton from "@/components/modal/BackButton";
import CloseButton from "@/components/modal/CloseButton";
import Modal from "@/components/modal/Modal";
import { flashMessageFromResponseError } from "@/utils/alert_utils";
import Modeler from "@/utils/modeler/modeler";
import { fullName } from "@/utils/project_utils";
import { objectivePronoun } from "@/utils/pronoun_utils";
import { route } from "@/utils/route_utils";
const ProjectMessageModal = (props) => {
  const {
    isOnboarding,
    isVisible,
    onClose,
    onUpdateProject,
    onUpdateProjectField,
    onUpdateStepNumber,
    project,
    stepNumber,
    modeledProjectUser
    // quillRef,
  } = props;
  const { t } = useTranslation();
  const [welcomeMessage, setWelcomeMessage] = useState(
    project.welcome_message || ""
  );
  const defaultWelcomeMessage = t(
    "views.memories.projectEdit.welcomeMessage.defaultMessage",
    {
      preferred_name: project.preferred_name,
      pronoun: objectivePronoun(project),
      full_name: fullName(project)
    }
  );
  useEffect(() => {
    if (isOnboarding && project.welcome_message === "") {
      setWelcomeMessage(defaultWelcomeMessage);
    }
  }, [project]);
  const goBackOneStep = () => {
    onUpdateStepNumber(stepNumber - 1);
  };
  const goToNextStep = () => {
    onUpdateStepNumber(stepNumber + 1);
  };
  const updateWelcomeMessage = (text) => {
    setWelcomeMessage(text);
  };
  const handleClickSave = () => {
    if (isOnboarding) {
      onUpdateProjectField("welcome_message", welcomeMessage);
      goToNextStep();
    } else {
      submit();
    }
  };
  const submit = async () => {
    const url = route("projectsUpdate", { slug: project.slug });
    const body = {
      project: {
        welcome_message: welcomeMessage
      }
    };
    try {
      const response = await Api.utility.patch(url, body);
      const updatedProject = new Modeler(response.data).build();
      onUpdateProject(updatedProject);
      onClose();
    } catch (err) {
      flashMessageFromResponseError(err);
    }
  };
  const renderTopButton = () => {
    if (isOnboarding) {
      return /* @__PURE__ */ React.createElement("div", { className: "project-message-modal__back-wrapper" }, /* @__PURE__ */ React.createElement(BackButton, { onBack: goBackOneStep }));
    } else {
      return /* @__PURE__ */ React.createElement("div", { className: "project-message-modal__close-wrapper" }, /* @__PURE__ */ React.createElement(CloseButton, { onClose }));
    }
  };
  return /* @__PURE__ */ React.createElement(Modal, { isVisible, size: "medium" }, /* @__PURE__ */ React.createElement("div", { className: "project-message-modal" }, renderTopButton(), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column" }, /* @__PURE__ */ React.createElement("h1", null, t("views.memories.projectEdit.welcomeMessage.header")))), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-10-tablet" }, /* @__PURE__ */ React.createElement("p", { className: "project-message-modal__details" }, /* @__PURE__ */ React.createElement("span", null, t("views.memories.projectEdit.welcomeMessage.details.text")), /* @__PURE__ */ React.createElement(
    "a",
    {
      className: "project-message-modal__details link",
      href: process.env.external_url["welcome_examples"],
      target: "_blank"
    },
    t("views.memories.projectEdit.welcomeMessage.details.link")
  )))), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-10-tablet" }, /* @__PURE__ */ React.createElement(
    TextEditor,
    {
      includeLimitedOptions: true,
      onChange: (value) => updateWelcomeMessage(value),
      placeholder: defaultWelcomeMessage,
      value: welcomeMessage
    }
  ))), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-6-tablet project-message-modal__save-button-wrapper" }, /* @__PURE__ */ React.createElement(Button, { color: "primary", onClick: handleClickSave }, t("views.memories.projectEdit.saveButton"))))));
};
export default ProjectMessageModal;
