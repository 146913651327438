import React from "react";
const defaultCoverImageSrc = require("@/assets/images/project_default.jpg");
const stringWithBoldedMatch = (subject, substring) => {
  const escape = (v) => v.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  const regex = new RegExp(escape(substring), "i");
  const textArray = subject.split(regex);
  if (textArray.length > 1) {
    const match = subject.match(regex);
    textArray.splice(1, 0, /* @__PURE__ */ React.createElement("b", null, match));
  }
  return /* @__PURE__ */ React.createElement("span", null, textArray);
};
const SearchResult = ({ cover_image_url, full_name, search, slug }) => /* @__PURE__ */ React.createElement(
  "a",
  {
    className: "block hover:opacity-90 mb-0 transition-opacity",
    href: `/${slug}`,
    target: "_blank"
  },
  /* @__PURE__ */ React.createElement("figure", { className: "bg-white flex flex-col overflow-hidden relative rounded-lg shadow-md text-gray-900" }, /* @__PURE__ */ React.createElement(
    "div",
    {
      style: {
        background: `url(${cover_image_url || defaultCoverImageSrc}) center/cover`,
        height: 0,
        paddingBottom: "115%",
        width: "100%"
      }
    }
  ), /* @__PURE__ */ React.createElement("figcaption", { className: "flex flex-col h-24 items-center justify-center px-4 text-center" }, /* @__PURE__ */ React.createElement("div", { className: "my-2 text-lg" }, stringWithBoldedMatch(full_name, search)), /* @__PURE__ */ React.createElement("div", { className: "text-sm" }, "https://memories.lifeweb360.com/", stringWithBoldedMatch(slug, search))))
);
const SearchResults = ({ results = [], search = "" }) => /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", { className: "my-5" }, results.length, " ", results.length === 1 ? "LifeWeb matches" : "LifeWebs match", " your search"), /* @__PURE__ */ React.createElement("div", { className: "gap-5 grid sm:grid-cols-2 md:grid-cols-3 max-w-screen-lg mx-auto" }, results.map((result) => /* @__PURE__ */ React.createElement(SearchResult, { key: result.id, ...result, search }))), /* @__PURE__ */ React.createElement("div", { className: "my-12" }, "Still can't find a LifeWeb?", " ", /* @__PURE__ */ React.createElement(
  "a",
  {
    className: "text-lifeweb-blue underline",
    href: "https://www.lifeweb360.com/contact-us",
    target: "_blank"
  },
  "Contact us"
)));
export default SearchResults;
