import React from "react";
import { useTranslation } from "react-i18next";
import CloseButton from "@/components/modal/CloseButton";
import Modal from "@/components/modal/Modal";
const InvitationResentModal = (props) => {
  const { fullName, isVisible, onClose } = props;
  const { t } = useTranslation();
  return /* @__PURE__ */ React.createElement(Modal, { noPadding: true, isVisible, size: "medium" }, /* @__PURE__ */ React.createElement("div", { className: "access-request-modal__close-wrapper" }, /* @__PURE__ */ React.createElement(CloseButton, { onClose })), /* @__PURE__ */ React.createElement("div", { className: "access-request-modal" }, /* @__PURE__ */ React.createElement("div", { className: "access-request-modal__header-wrapper" }, /* @__PURE__ */ React.createElement("h1", null, t("views.projects.modals.invitationResent.header"))), /* @__PURE__ */ React.createElement("div", { className: "columns" }, /* @__PURE__ */ React.createElement("div", { className: "column access-request-modal__message" }, /* @__PURE__ */ React.createElement("p", null, t("views.projects.modals.invitationResent.subheader", {
    fullName
  }))))));
};
export default InvitationResentModal;
