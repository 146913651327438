const icons = {
  close: require("@/assets/images/icons/x-solo.svg"),
  error: require("@/assets/images/icons/alert_error.svg"),
  info: require("@/assets/images/icons/alert_info.svg"),
  success: require("@/assets/images/icons/alert_success.svg")
};
export {};
const createFlashElement = (message, type, id) => {
  const headerText = (() => {
    switch (type) {
      case "error":
        return "Uh Oh";
      case "success":
        return "Success";
      default:
        return "Just so ya know";
    }
  })();
  const markup = `
    <div class="alert alert__${type}" id="${id}">
      <span class="alert__bar"></span>

      <div class="alert__icon">
        <img src="${icons[type]}" />
      </div>

      <div class="alert__content">
        <p class="alert__header">${headerText}</p>
        <div class="alert__message">${message}</div>
      </div>

      <div class="alert__close">
        <img src="${icons["close"]}"
      </div>
    </div>
  `;
  return markup;
};
window.flash = (message, type = "info") => {
  const container = document.getElementById("alerts");
  const flashId = `alert alert__${type} flash-alert-${Math.random().toString().slice(2)}`;
  const element = createFlashElement(message, type, flashId);
  container.innerHTML = element;
  const alert = document.getElementById(flashId);
  alert.querySelector(".alert__close").addEventListener("click", () => {
    alert.classList.add("is-hidden");
  });
};
