import { useEffect } from "react";
const useHelpScoutBeacon = (beaconId) => {
  useEffect(() => {
    const loadScript = () => {
      if (window.Beacon)
        return false;
      (function(e, t, n) {
        function a() {
          var _a;
          const e2 = t.getElementsByTagName("script")[0], n2 = t.createElement("script");
          n2.async = true, n2.src = "https://beacon-v2.helpscout.net", (_a = e2.parentNode) == null ? void 0 : _a.insertBefore(n2, e2);
        }
        if (e.Beacon = n = function(t2, n2, a2) {
          e.Beacon.readyQueue.push({ method: t2, options: n2, data: a2 });
        }, n.readyQueue = [], "complete" === t.readyState)
          return a();
        e.attachEvent ? e.attachEvent("onload", a) : e.addEventListener("load", a, false);
      })(window, document, window.Beacon || function() {
      });
      return true;
    };
    loadScript();
    window.Beacon("init", beaconId);
  }, [beaconId]);
};
export default useHelpScoutBeacon;
