document.addEventListener("DOMContentLoaded", () => {
  const triggers = Array.from(
    document.querySelectorAll('[data-toggle="collapsable"]')
  );
  document.addEventListener(
    "click",
    (event) => {
      const element = event.target;
      if (triggers.includes(element)) {
        const selector = element.getAttribute("data-target");
        collapsable(selector, element);
      }
    },
    false
  );
  const collapsable = (selector, element) => {
    const target = document.querySelector(selector);
    target.classList.toggle("is-showing");
    const icon = element.getElementsByTagName("img")[0];
    icon.classList.toggle("is-open");
  };
});
