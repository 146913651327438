import classnames from "classnames";
import React from "react";
const ManagementControls = ({ children, position = "normal" }) => /* @__PURE__ */ React.createElement(
  "div",
  {
    className: classnames(
      "absolute cursor-default drop-shadow-lg bg-white min-w-max rounded-lg p-4 text-black z-10",
      {
        "right-0 top-3": position === "abnormal",
        "right-2.5 top-6": position === "normal"
      }
    )
  },
  /* @__PURE__ */ React.createElement("div", { className: "flex flex-col gap-3 pr-8" }, children)
);
export default ManagementControls;
